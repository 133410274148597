import React from 'react';
import Settings from 'services/config/Settings';
import { Trans, t } from 'components/Translate/Translate';

class Contact extends React.Component {
  componentDidMount = () => {
    const pageTitle = t('pageTitles.contactUs', { defaultValue: 'Contact us'});
    document.title = `${pageTitle} | ${Settings.title}`;
  }
  render() {
    return (
      <div className="wallet">
        <div className="wallet__container">
          <div className="wallet__header mgn-zero">
            <div className="wallet__titlePage"><Trans i18nKey="contact_us_page__title">Contact Us</Trans></div>
          </div>
          <div className="wallet__text">
            <h2><Trans i18nKey="contact_us_page__help_is_needed">Help is needed?</Trans></h2>
            <p><Trans i18nKey="contact_us_page__we_recommend_faq">We recommend you to review our Frequently Asked Questions.</Trans> <a href="/faq"><Trans i18nKey="contact_us_page__go_to_faq">Go to FAQ</Trans></a></p>

            <h2><Trans i18nKey="contact_us_page__any_other_by_email">If you have any questions about support, please contact us via email:</Trans> <a href="mailto:support@electrontogo.com">support@electrontogo.com</a></h2>
          </div>
        </div>
      </div>
    )
  }
};

export default Contact;