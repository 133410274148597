import React from 'react';
import moment from 'moment';
import Analytics from 'services/utils/Analytics';
import Button from 'components/Button/Button';
import { t, Trans } from 'components/Translate/Translate';

import './styles.less';

require("moment-duration-format");

export default (props) => {	
	props.propSetState({
		dialogState: 0,
		headerState: 5,
		showUserController: false
	})
	const buttonText = t('have_charger_screen__find_on_map', { defaultValue:  'Find station on map' });
	
	let sessions = props.state.user.sessions ? props.state.user.sessions.slice() : [];
	const { total_sessions } = props.state.user;

	class HaveBatteryScreen extends React.Component {
		state = {
			showGuide: false,
			showReport: true
		}
		sessionList = () => {
			return sessions.map(session => {
				const start = moment.unix(session.start);
				const deadline = moment.unix(session.deadline);
				const ms = moment().diff(start);
				const dur = moment.duration(ms);

				const formatDateTemplateD = t('haveBattery.dateFormat.days', { defaultValue:  'd' });
				const formatDateTemplateH = t('haveBattery.dateFormat.hours', { defaultValue:  'h' });
				const formatDateTemplateMin = t('haveBattery.dateFormat.min', { defaultValue:  'min' });

				let formatDate = "d [{{d}}] h [{{h}}] m [{{min}}]";

				formatDate = formatDate.replace('{{d}}', formatDateTemplateD);
				formatDate = formatDate.replace('{{h}}', formatDateTemplateH);
				formatDate = formatDate.replace('{{min}}', formatDateTemplateMin);

				const durationSession = dur.format(formatDate);
				let message = '';

				if (session.status) {
					let haveOrExpiredPeriod = deadline.diff(start);
					if (session.status === 'normal') {
						haveOrExpiredPeriod = deadline.diff(moment());
					} else if (session.status === 'expired') {
						haveOrExpiredPeriod = moment().diff(deadline);
					}
					const duration = moment.duration(haveOrExpiredPeriod).format(formatDate);
					if (session.status === 'normal') {
						const timeLeftTemplate = t('haveBattery.timeLeftToReturn', { defaultValue:  'You have {{ duration }} to return' });
						message = timeLeftTemplate.replace('{{ duration }}', duration);// `You have ${duration} to return`;
					} else if (session.status === 'expired') {
						message = t('haveBattery.returnSoOtherCanUse', { defaultValue:  'Return your Electron so others can use it.' });
					}
				}

				return (
					<div key={session.id} className="sessions__item">
						<div className="sessions__title"><Trans i18nKey="have_charger_screen__title">Charger session</Trans></div>
						<div className="sessions__duration">{durationSession}</div>
						{message ? <div className={"sessions__message "+session.status}>{message}</div> : null }
						<button className="sessions__help" onClick={() => this.showHelp(session.id)}>
							<span className="sessions__help-text">
								<Trans i18nKey="haveBattery.helpText">Help</Trans>
							</span>
						</button>
					</div>
				);
			});
		}
		showGuide = () => {
			this.setState({
				showGuide: true
			})
		}
		showHelp = (sessionId) => {
			const { can_do_pit_stop } = props.state.user;
			props.propSetState({
				canExchangeBattery: can_do_pit_stop || false,
				showReport: true,
				reportItem: 'session',
				reportItemId: sessionId,
			});
		}
		goNext = () => {
			props.propSetState({
				fullScreenStore: null,
				background: 'dark',
				activeStep: props.state.activeStep + 1,
			}, () => {
				props.moveConversation();
			});
		}
		openMap = () => {
			Analytics('User open the map');
			props.state.headerState = 3;
			props.state.modalMap = true;
		}
		render() {
			const { showGuide } = this.state;
			const guideSrc = 'https://getjuice.nyc3.cdn.digitaloceanspaces.com/etg_guide_return_electron.gif';
			return (
				<React.Fragment>
					<div className="sessions__wrapper">
						<div className="sessions">
						{ this.sessionList() }
						{ total_sessions < 4 ?
							<div className="sessionsGuide">
								<div className="sessionsGuide__title"><Trans i18nKey="have_charger_screen__instruction_title">How to return the Electron:</Trans></div>
								<div className="sessionsGuide__option"><Trans i18nKey="have_charger_screen__instruction_steps_1">1. Return Electron to any empty nest</Trans>
									<div className={"sessionsGuide__gif " +(showGuide ? 'show' : 'hide')}>
										<img src={guideSrc} alt="guide electorntogo" onLoad={this.showGuide} className="sessionsGuide__img" />
									</div>
								</div>
								<div className="sessionsGuide__option"><Trans i18nKey="have_charger_screen__instruction_steps_2">2. You should receive a text confirmation with your return confirmation</Trans></div>
								<div className="sessionsGuide__option"><Trans i18nKey="have_charger_screen__instruction_steps_3">3. If you want to exchange your Electron for a fresh Election, follow the express link in the return confirmation</Trans></div>
							</div>
						: null }
						</div>
						<div className="sessions__controls">
							<div className="sessions__controls_inner">
								<Button
									onClick={this.openMap}
									text={buttonText} />
							</div>
						</div>
					</div>
				</React.Fragment>
			)
		}
	};
	
	props.propSetState({
		fullScreenStore: <HaveBatteryScreen />
	});
	
}