const dictionary = {
  "auth-dialog_write-number": "Пожалуйста, введите ваш номер телефона",
  "auth-dialog_request_code": "Запросить новый код",
  "auth-dialog_request_edit_num": "Изменить номер",
  "auth-dialog_what_is_num": "Какой у тебя номер телефона?",
  "auth-dialog_get_new_code": "Получить новый код",
  "auth-dialog_user-edit-num": "Изменить номер",
  "lostBattery-yes-i-do": "Да, я потерял электрон",
  "lostBattery-no-i-check": "НЕТ, я проверю еще раз",
  "lost_battery-are-you-sure": "Вы уверены, что нет никаких шансов, чтобы найти и вернуть Electron <br/> Если нет, мы должны взимать плату за $ 60 количество по умолчанию <br/> Read <B стиль = «текст-отделка: подчеркнуть;»>? Возвращение и штрафных санкций Правила </ b>",
  "lostBattery-sorry-penalty": "К сожалению, мы должны взимать плату $ 40 количество по умолчанию",
  "block-user-screen": "Счет был приостановлен.",
  "hello-user_userNoBattery": "Получите мое зарядное устройство",
  "hello-user_userNoBattery-lets-get-juice": "Давайте электрон",
  "supernova-type-code": "Пожалуйста, введите 4-значный код с плакатом возле станции Supernova",
  "supernova-get-success": "Вау, Electron доступен!",
  "take-battery-screen-label": "Возьмите батарею Electron!",
  "take-battery-screen-take-btn": "Возьми это!",
  "single-venue__get-direction-btn": "Получить направление",
  "took-success-open-map-btn": "Найти место на карте",
  "took-success-label": "Для Электронно-To-Go сообщества очень важно сохранить Electron Return Rate <B> на менее чем 24 часа уровень. </ B>",
  "supernova-help-screen_openMap": "Найти место на карте",
  "single-venue_get-battery-button": "Получите мое зарядное устройство",
  "took-success-message": "Вытяните кабель от Electron рамы с помощью красной указательного пальца. Включите ее и Go!",
  "refund-yes-doRefund": "да",
  "refund-no-refund": "нет",
  "refund-weSorry": "Нам очень жаль..",
  "refund-inProgress": "Возмещение в процессе",
  "refund-question-really": "Вы действительно оставить нас?",
  "refund-right-choice-back": "Правильный выбор!",
  "auth-dialog_wrong-num": "Неправильный номер, попробуйте еще раз",
  "header-btn-find-on-map": "Найти на карте",
  "hello-user_without-plan": "Добро пожаловать!",
  "hello-user-click-for-annual": "Годовая подписка # ЦЕНА #",
  "hello-user-click-for-daily": "Хотите попробовать? <br/> Нажмите здесь <SPAN> день доступа </ SPAN>",
  "community-rules_accept-btn": "Принять Правила сообщества",
  "community-rules_accept-message": "Для Электронно-To-Go сообщества очень важно сохранить Electron Return Rate <B> на менее чем 24 часа уровень. </ B>",
  "community-rules_read": "Читать",
  "community-rules_returnPolicy": "Период аренды и возврата Правила",
  "hello-user__userNoPlan-money-back": "Один месяц деньги обратно гарантировано.",
  "return-policy-message-more-48": "Это более <B> затем 48 часов </ b>, что допускается",
  "find-supernova-screen_btn-no": "нет",
  "find-supernova-screen_question": "Вы рядом со станцией Supernova?",
  "promo-add-payment": "По соображениям безопасности <br/> нам нужен способ оплаты",
  "refund-done": "Готово!",
  "map-ask-user-share-location": "Для удобства навигации, давайте использовать ваше местоположение.",
  "find-supernova-correct-venue": "Является ли это правильное место, где вы находитесь?",
  "take-battery-screen-sorry-didNotTake": "К сожалению, вы не взяли батарею. Повторение?",
  "subscribe-order-title": "Давайте выберем подписку",
  "supernova-get-error": "Извините, но это Supernova вне форума или это был неверный код. Пожалуйста, попробуйте еще раз.",
  "take-battery-screen-sorry-no-free-batteries": "Кажется, нет свободных батарей",
  "subscribe-payments-title": "Выберите метод оплаты",
  "subscribe-review-title": "Обзор",
  "subscribe-process-title": "Оплата",
  "subscribe-fail-title": "Потерпеть поражение",
  "subscribe-purchase-title": "Оплата",
  "ReturnBatteryFail_return-soon-a-spossible": "Пожалуйста, верните аккумулятор, как только это возможно",
  "take-battery-screen-sorry-there-queue": "К сожалению, есть очереди. Подождите 10 секунд",
  "hello-user_userWithBattery": "Возвращение батареи",
  "welcome__item__2": "Молнии и USB-C <br/> разъемы встроены",
  "welcome__item__1": "Получите неограниченный доступ к <br/> разделяемых зарядные устройства",
  "welcome__item__3": "После оплаты, <br/> аккумулятор разблокируется",
  "welcome__item__4": "Возврат в течение 24 часов <br/> в любой из наших",
  "welcome__btn__first": "Как пользоваться",
  "welcome__item__btn__next": "следующий",
  "auth-dialog__type_phone_placeholder": "Тип телефон",
  "auth-dialog__type_code_from_sms": "Введите код с SMS",
  "auth-dialog__type_code_placeholder": "Тип кода",
  "subscribe_order__title": "Выберите зарядный <br/> вариант",
  "subscribe_order__have_promo": "У меня есть PromoCode",
  "subscribe_order__continue_btn": "Продолжить",
  "subscribe_order__1_month_plan_label": "За 1 месяц",
  "subscribe_order__1_day_plan_label": "За 1 день",
  "subscribe_order__daily_plan_title": "Оплатить # ЦЕНА # для заряда",
  "subscribe_order__daily_offer": "Ограниченный доступ на 1 день",
  "subscribe_order__monthly_offer_1": "1 месяц без ограничения расходов",
  "subscribe_order__monthly_offer_2": "Свободный доступ к другу в течение 1 дня",
  "subscribe_order__monthly_plan_title": "Неограниченный доступ к",
  "subscribe_order__promo_plan_title": "Promo доступ:",
  "subscribe_order__promo_input_placeholder": "Введите промо",
  "subscribe__payments_description_no_payment_methods": "Выберите удобный способ оплаты для вас",
  "subscribe__payments_description_has_payment_methods": "Выберите сохраненный способ оплаты и добавить новый",
  "subscribe__payments_description_promo": "Нам необходимо иметь действительный способ оплаты, чтобы контролировать зарядное возвращение электронной батареи",
  "subscribe__payments_billing_info_title": "Информация об оплате",
  "subscribe__payments_add_payment_method_btn": "Добавить новый способ оплаты",
  "subscribe__payments_billing_post_code_placeholder": "ZIP",
  "subscribe__payments_billing_email_placeholder": "Эл. адрес",
  "subscribe_order__promo_error_1": "Промо-код не найден или не действителен",
  "subscribe_order__promo_applied": "Промо-код применяется",
  "subscribe_order__promo_error_403": "Вы уже использовали этот промо-код",
  "subscribe_order__promo_error_405": "Вы не можете использовать эту ссылку промо-код более чем 1 раз",
  "subscribe_order__promo_error_expired": "Промо-код истек",
  "subscribe__review-description": "Проверьте свой выбор",
  "subscribe__review_membership_title": "членство",
  "subscribe__review_membership_valid_until": "Членский действует до",
  "subscribe__review_payment_method_selected": "Способ оплаты",
  "subscribe__review_promo_description": "Мы не берем деньги за промо-план, но мы будем просить $ 1 от способа оплаты, чтобы проверить его. Деньги будут немедленно вернуться на свой банковский счет.",
  "subscribe__review_agree_terms_privacy": "Я согласен # TERMS_LINK_OPEN # Правила и условия # TERMS_LINK_CLOSE # и # # PRIVACY_LINK_OPEN Конфиденциальность # PRIVACY_LINK_CLOSE #",
  "subscribe-fail-help": "Помощь нужна? Связаться с нами",
  "subscribe-fail-retry": "Retry",
  "subscribe__pay_start_payment_btn": "PAY",
  "subscribe__pay_add_card_btn": "ADD CARD",
  "subscribe-purchase-description": "успех",
  "subscribe-purchase-got_it_btn": "Понял",
  "subscribe-purchase-24-hours-rules": "Это очень важно для нашего сообщества, что все вернуть использованное Electron <B> в течение 24 часов </ b>.",
  "hello_user_userNoBattery__select_plan_btn": "Выберите план",
  "supernova-type-code_input_label_text": "Нажмите здесь, чтобы ввести код станции",
  "supernova-type-code_input_placeholder": "Введите код здесь",
  "take-battery-unlock-my-charger_btn": "Открой мое зарядное устройство",
  "take-battery-screen-something-wrong": "Что-то пошло не так. Пожалуйста, повторите попытку через 5 минут или свяжитесь с нами по адресу support@electrontogo.com",
  "took-success-having-problem-btn": "Имея проблемы?",
  "exchange_charger__title": "Supernova полон?",
  "exchange_charger__description": "Обменять свой Electron с новым Electron, выполнив следующие действия:",
  "exchange_charger__steps_1": "1. Нажмите на кнопку «Обмен батареи» и введите номер Supernova станции",
  "exchange_charger__steps_2": "2. Возьмите новую батарею",
  "exchange_charger__steps_3": "3. Когда гнездо закрывается, возвращает пустую батарею",
  "exchange_charger__understand_rules_check": "я понимаю",
  "exchange_charger__start_btn": "Батарея Обмен",
  "have_charger_screen__title": "зарядное устройство сессии",
  "have_charger_screen__instruction_title": "Как вернуть Electron:",
  "have_charger_screen__instruction_steps_1": "1. Возвращение Electron в любое пустое гнездо",
  "have_charger_screen__instruction_steps_2": "2. Вы должны получить текст подтверждения с подтверждением возврата",
  "have_charger_screen__instruction_steps_3": "3. Если вы хотите обменять Electron на новые выборы, следует экспресс-ссылке в подтверждении возврата",
  "have_charger_screen__find_on_map": "Найти станцию ​​на карте",
  "report__how_return_question": "Как вернуть батарею?",
  "report__how_return_answer": "Вернуть батарею гнездо любому доступному станции. Пожалуйста, убедитесь, что полностью вставить кабель обратно в рамку батареи.",
  "report__how_battery_doesnt_work_question": "Батарея не работает",
  "report__how_battery_doesnt_work_answer": "Верните батарею на станцию ​​и получить другой.",
  "report__exchange_charger_question": "Станция полностью: Обменять свой электрон с одной из других электронов на этой станции.",
  "report__cant_take_battery_question": "Я не могу получить батарею",
  "report__supernova_doesnt_work_question": "Станция не работает",
  "report__session_is_still_open_question": "Я уже вернулся батарея, но моя сессия остается открытой",
  "report__other_question": "Другая проблема",
  "report__title": "Нужна помощь? Выберите один из вариантов или написать свой вопрос",
  "report__exchange_charger_answer": "Вы можете использовать обменный аккумулятор только если нет свободных гнезд на станции.",
  "report__exchange_charger_btn_start": "Начать обмен батареи",
  "report__find_battery_placeholder": "Номер станции или Местонахождение",
  "report__find_battery_describe_field_placeholder": "Опишите проблему",
  "report__find_battery_warning": "Пожалуйста, предоставьте следующую информацию:",
  "report__find_battery_label": "Номер станции или Местонахождение",
  "report__find_battery_describe": "Опишите проблему",
  "report__find_battery_submit": "Отправить",
  "report__custom_question": "Твое сообщение",
  "report__submit_question": "Отправить",
  "referral__share_subject": "Получите бесплатный месяц доступа к сети зарядных устройств",
  "referral__share_text": "Используйте мой код приглашения # UNIQUE_CODE # и получить бесплатный доступ в месяц к сети зарядных устройств. Обменять его на https://e2g.app/r/#UNIQUE_CODE#",
  "referral__title": "Дайте # LOCAL_AMOUNT #, прибудет # LOCAL_AMOUNT #",
  "referral__description": "Пригласить друга попробовать e2g.app, вы оба будете зарабатывать # LOCAL_AMOUNT # месячную подписку на один раз он или она начинает использовать зарядное устройство Electron.",
  "menu__referral": "Получить бесплатно",
  "menu__payments": "Мои платежи",
  "menu__charges": "Мои сборы",
  "menu__map": "карта",
  "map__faq": "Помогите",
  "menu__about_etg": "О Electron-To-Go",
  "menu__logout": "Выйти",
  "payments_page__cancel_membership": "Отменить подписку",
  "payments_page__refund_btn": "Возвращать деньги",
  "payments_page__title": "Способы оплаты",
  "payments_page__payment_history_link": "История платежей",
  "payments_page__payment_method_list_title": "Способ оплаты",
  "payments_page__currently_active_membership_promo": "рекламный",
  "payments_page__currently_active_membership_annual": "годовой",
  "payments_page__currently_active_membership_daily": "Ежедневно",
  "payments_page__currently_active_membership_monthly": "ежемесячно",
  "payments_page__currently_active_membership_semester": "Семестр",
  "payments_page__currently_active_membership_referral": "Направления",
  "payments_page__currently_active_title": "В настоящее время активный",
  "payments_page__membership_word": "членство",
  "payments_page__expires_in_period": "Истекает",
  "payments_page__delete_payment_method_question": "Удалить способ оплаты?",
  "payment_history_page__title": "История платежей",
  "payment_history_page__transaction_word": "Сделка",
  "payment_history_page__status_paid_word": "оплаченный",
  "payment_history_page__date": "Дата",
  "payment_history_page__plan_status": "статус План",
  "payment_history_page__order_id": "номер заказа",
  "payment_history_page__order_status": "Статус заказа",
  "payment_history_page__order_amount": "Сумма заказа",
  "payment_history_page__payment_method": "Способ оплаты",
  "payment_history_page__payment_status": "Статус платежа",
  "payment_history_page__no_transactions": "У вас нет активных операций.",
  "my_charges_page__title": "Мои расходы",
  "my_charges_page__amount_charges_word": "расходы",
  "my_charges_page__amount_hours_word": "часов",
  "my_charges_page__no_charges_yet": "У вас нет активных зарядов.",
  "my_charges_page__electron_id": "Электронный идентификатор",
  "faq_page__title": "Часто задаваемые вопросы",
  "faq_page__where_can_return_answer": "Любое avaialable для возвращенного места, которое вы можете найти на нашей <a href=\"https://electrontogo.app/map\"> карта </a>.",
  "faq_page__what_id_supernova_not_respond_answer": "Подождите и повторите попытку. Если он по-прежнему не работает, пожалуйста, свяжитесь с нами по: <a href=\"mailto:support@electrontogo.com\"> support@electrontogo.com </a> мы ответим как можно скорее",
  "faq_page__how_do_i_get_refund_answer": "Вы можете отменить свой план на странице платежей (<a href=\"https://electrontogo.app/payments\"> https://electrontogo.app/payments </a>). Только первый раз абонент может получить возмещение.",
  "faq_page__if_you_didnt_find_answer_question": "Если вы не нашли вы ответите пожалуйста <a href=\"https://electrontogo.app/contact\"> свяжитесь с нами. </a>",
  "faq_page__what_is_supernova_question": "Что такое Supernova?",
  "faq_page__what_is_supernova_answer": "Это наша зарядная станция.",
  "faq_page__what_is_electron_question": "Что такое электрон?",
  "faq_page__what_is_electron_answer": "Это одно портативное зарядное устройство.",
  "faq_page__how_it_will_charge_question": "Как это будет зарядить свой телефон?",
  "faq_page__how_it_will_charge_answer": "Каждый электрон имеет встроенный кабель для обеих iPhone'ов и Andriod Type-C (но не для микро-USB).",
  "faq_page__is_it_wireless_question": "Это беспроводная зарядка?",
  "faq_page__is_it_wireless_answer": "Количество тока Беспроводные протоколы зарядки являются inificient и сократить свой смартфон аккумуляторный.",
  "faq_page__what_type_of_phone_can_change_question": "Какой тип телефона может заряд электрона?",
  "faq_page__what_type_of_phone_can_change_answer": "Apple, iPhone от iPhone 5 модели и телефонов Android с разъемом USB Type-C.",
  "faq_page__how_fast_it_question": "Как быстро я могу зарядить свой телефон?",
  "faq_page__how_fast_it_answer": "В зависимости от ваших возможностей телефона. Электроны могут зарядить свой телефон до 2А, что на 1% в минуту для большинства телефонов.",
  "faq_page__how_much_charge_I_can_get_question": "Сколько заряда я могу получить?",
  "faq_page__how_much_charge_I_can_get_answer": "В зависимости от размера Вашего телефона батареи. Емкость Electron является 2700mAh, что составляет примерно 70-100% заряда для большинства телефонов.",
  "faq_page__how_do_i_know_electron_charged_question": "Как я знаю, что если электрон заряжен?",
  "faq_page__how_do_i_know_electron_charged_answer": "Нажмите красную кнопку на Electron и вы можете видеть светодиодные индикаторы на задней стороне Electron. 3 или 4 LED ON означает Electron полностью заряжена.",
  "faq_page__where_can_return_question": "Где я могу вернуть мое зарядное устройство?",
  "faq_page__what_happend_if_not_return_on_time_question": "Что произойдет, если я не вернусь электрон во время?",
  "faq_page__what_happend_if_not_return_on_time_answer": "Если вы ежедневно пользователь, мы будем взимать плату $ 1 в день, а после 3-х дней, мы будем поднимать подписку на месяц и взимать плату $ 10. Если вы ежемесячные абонентские, у вас есть до 7 дней, чтобы вернуть Electron, после чего мы возвышаем подписку на однолетний и взимать плату $ 60. Если вы Годовой пользователь, у вас есть до 7 дней, чтобы вернуться, после чего мы заблокируем ваш Acount до тех пор пока вы возвращаете Electron.",
  "faq_page__what_if_lost_question": "Что происходит, если электрон теряется?",
  "faq_page__what_if_lost_answer": "Ваша подписка будет обновлена ​​до года, и вы будете платить $ 60,00. Годовой абонент будет платить $ 50,00",
  "faq_page__what_if_break_question": "Что произойдет, если я нарушу или повредить Electron?",
  "faq_page__what_if_break_answer": "В зависимости от ущерба, мы будем взимать плату, не превышающую $ 60,00.",
  "faq_page__what_id_supernova_not_respond_question": "Что делать, если Supernova не отвечает?",
  "faq_page__electron_doesnt_work_what_to_do_question": "Electron не работает, что я должен делать?",
  "faq_page__electron_doesnt_work_what_to_do_answer": "Возвращение Electron на станцию ​​и получить другой. Если это единственный Electron на станции, вернуть его и получить его снова. Если он по-прежнему не работает, найти другое место, чтобы получить Electron на нашем <a href=\"https://electrontogo.app/map\"> карте </a>. Извините за incovinience. Выводится poblem нам с электронной ID и получить бонусные баллы на свой рейтинг.",
  "faq_page__what_if_i_am_in_other_city_question": "Что делать, если я нахожусь в другом городе и не могу вернуть Electron?",
  "faq_page__what_if_i_am_in_other_city_answer": "Свяжитесь с нами: support@electrontogo.com",
  "faq_page__what_is_juize_inc_question": "Что такое Juize Inc?",
  "faq_page__what_is_juize_inc_answer": "Juize Inc является нашим юридическим названием.",
  "faq_page__electron_too_hot_question": "Мой Electron получил слишком жарко. Что Шоуда мне делать?",
  "faq_page__electron_too_hot_answer": "Отключите и подождите пару минут и снова включите его. Мы не рекомендуем зарядки телефона в кармане брюк.",
  "faq_page__how_many_electrons_i_can_get_question": "Сколько Electron батареи я могу получить?",
  "faq_page__how_many_electrons_i_can_get_answer": "Только один, но забрать и вернуть столько раз, сколько вам нравится",
  "faq_page__how_log_i_kan_keep_charger_question": "Как долго я могу держать зарядное устройство?",
  "faq_page__how_log_i_kan_keep_charger_answer": "Пожалуйста, вернитесь каждый в течение 24 часов. См терминов пользователя к деталям на вашем плане",
  "faq_page__why_do_i_need_add_payment_when_promo_question": "Почему мне нужно добавить способ оплаты, когда у меня есть промо-код?",
  "faq_page__why_do_i_need_add_payment_when_promo_answer": "Для того, чтобы обеспечить возвращение аккумулятора Electron ™ до станции",
  "faq_page__does_plans_auto-renews_question": "Имеет ли мой план Auto-Renew?",
  "faq_page__does_plans_auto-renews_answer": "Нет, вы должны вручную обновить каждый раз,",
  "faq_page__what_if_i_dont_like_question": "Что делать, если мне не нравится обслуживание?",
  "faq_page__what_if_i_dont_like_answer": "У вас есть первые две недели, чтобы получить полный возврат вашего ежемесячного, Семестр или ежегодной подписки.",
  "faq_page__how_do_i_get_refund_question": "Как мне получить деньги?",
  "about_page__contact_us": "СВЯЗАТЬСЯ С НАМИ",
  "about_page__about_us": "НАСЧЕТ НАС",
  "about_page__help": "Помогите",
  "about_page__terms_and_conditions": "Условия обслуживания",
  "about_page__privacy_policy": "политика конфиденциальности",
  "about_page__become_venue": "Стал место",
  "contact_us_page__title": "Связаться с нами",
  "contact_us_page__help_is_needed": "Помощь нужна?",
  "contact_us_page__we_recommend_faq": "Мы рекомендуем вам ознакомиться с нашими Часто задаваемыми вопросами.",
  "contact_us_page__go_to_faq": "Перейти к FAQ",
  "contact_us_page__any_other_by_email": "Если у вас есть какие-либо вопросы поддержки, пожалуйста, свяжитесь с нами по электронной почте:"
};
export default dictionary;