import * as actions from '../actions';
import Analytics from 'services/utils/Analytics';

export default (props) => {
	switch (props.activeStep.name) {
		case 'Welcome': {
			Analytics('Step - Welcome');
			actions.Welcome(props);
			break;
		}
		case 'MoveConversation': {
			actions.MoveConversation(props);
			break;
		}
		case 'UserJourney': {
			const UserJourney = new actions.UserJourney(props);
			UserJourney.getStarted();
			break;
		}
		case 'SendMessageToDialog': {
			actions.SendMessageToDialog(props);
			break;
		}
		case 'UserWriteToDialog': {
			actions.UserWriteToDialog(props);
			break;
		}
		case 'numberSupernova': {
			Analytics('Step - NumberSupernova');
			actions.NumberSupernova(props);
			break;
		}
		case 'saveName': {
			Analytics('Step - SaveName');
			actions.SaveName(props);
			break;
		}
		case 'phoneAuth': {
			Analytics('Step - PhoneAuth');
			actions.PhoneAuth(props);
			break;
		}
		case 'shareBtn': {
			Analytics('Step - ShareBtn');
			actions.ShareBtn(props);
			break;
		}	
		case 'buttonNext': {
			Analytics('Step - buttonNext - ' + props.activeStep.value);
			actions.ButtonNext(props);
			break;
		}
		case 'ButtonForward': {
			Analytics('Step - ButtonForward - ' + props.activeStep.value);
			actions.ButtonForward(props);
			break;
		}
		case 'ChooseStation': {
			Analytics('Step - ChooseStation');
			actions.ChooseStation(props);
			break;
		}
		case 'TakeBattery': {
			Analytics('Step - ChooseStation');
			actions.TakeBattery(props);
			break;
		}
		case 'ReturnBattery': {
			Analytics('Step - ReturnBattery');
			actions.ReturnBattery(props);
			break;
		}
		case 'TookBattery': {
			Analytics('Step - TookBattery');
			actions.TookBattery(props);
			break;
		}
		case 'BatteryStory': {
			Analytics('Step - BatteryStory');
			actions.BatteryStory(props);
			break;
		}
		case 'LostBattery': {
			Analytics('Step - LostBattery');
			actions.LostBattery(props);
			break;
		}
		case 'WelcomeScreen': {
			Analytics('Step - WelcomeScreen');
			actions.WelcomeScreen(props);
			break;
		}
		case 'HelloUserScreen': {
			Analytics('Step - HelloUserScreen');
			actions.HelloUserScreen(props);
			break;
		}
		case 'SuccessPaymentScreen': {
			Analytics('Step - SuccessPaymentScreen');
			actions.SuccessPaymentScreen(props);
			break;
		}
		case 'CommunityRulesScreen': {
			Analytics('Step - CommunityRulesScreen');
			actions.CommunityRulesScreen(props);
			break;
		}
		case 'SupernovaFindScreen': {
			Analytics('Step - SupernovaFindScreen');
			actions.SupernovaFindScreen(props);
			break;
		}
		case 'VenueSelectScreen': {
			Analytics('Step - VenueSelectScreen');
			actions.VenueSelectScreen(props);
			break;
		}
		case 'BlockUserScreen': {
			Analytics('Step - BlockUserScreen');
			actions.BlockUserScreen(props);
			break;
		}
		case 'AdsPromoScreen': {
			Analytics('Step - AdsPromoScreen');
			actions.AdsPromoScreen(props);
			break;
		}
		case 'Refund': {
			Analytics('Step - Refund');
			actions.Refund(props);
			break;
		}
		case 'HaveBatteryScreen': {
			Analytics('Step - HaveBatteryScreen');
			actions.HaveBatteryScreen(props);
			break;
		}
		case 'PitStopRulesScreen': {
			Analytics('Step - Switch Battery Rules');
			actions.PitStopRules(props);
			break;
		}
		default: 
			props.sendMessageToDialog({ 'type': 'error', 'value': 'There is nothing to send' });
			break;
	}
}