import React from 'react';
import Header from 'components/Header/Header';

class AppWrapper extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        { this.props.children }
      </React.Fragment>
    )
  }
};

export default AppWrapper;