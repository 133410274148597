import ReactGA from 'react-ga';

export default (props) => {
	
	
	const dialogMessages = props.state.dialogMessages;
	dialogMessages.push(
		{
			type: 'social-btn',
			value: true,
			side: true
		}
	);
	props.propSetState({
		dialogMessages
	});
	props.sendMessageToDialog({
		'type': 'text',
		'value': `Contact us if you have any questions or feedback`
	});
	props.sendMessageToDialog({
		'type': 'choice',
		'value': [
			{
				title: 'info@getjuize.com',
				class: 'dialog__collapse-btn--alone',
				func: (e) => {
					ReactGA.event({
						category: 'User',
						action: 'Share step - clicked on the mail'
					});
					window.location = "mailto:info@getjuize.com";
				},
			}
		]
	});
}