export default (props) => {
	if (props.activeStep.type === 'withText') {
		console.log(props.activeStep)
		props.sendMessageToDialog({
			'type': 'agreement-button',
			'value': {
				message: props.activeStep.value.text,
				title: props.activeStep.value.button,
				class: 'dialog__agreement-btn',
				func: (e) => {
					var Children = [...e.target.parentNode.getElementsByClassName("dialog__agreement-btn")];
					Children.forEach((obj, index)=>{
						obj.setAttribute('disabled', 'true');
					});
					let next = props.state.activeStep + 1;
					props.userWriteToDialog(props.activeStep.value.button);
					props.propSetState({ 
						typingText: true,
						activeStep: next,
						attempts: 0
					}, () => {
						props.moveConversation();
					});
				},
			}
		})
	} else {
		props.sendMessageToDialog({
			'type': 'choice',
			'value': [
				{
					title: props.activeStep.value,
					class: 'dialog__collapse-btn--alone',
					func: (e) => {
						var Children = [...e.target.parentNode.getElementsByClassName("dialog__collapse-btn")];
						Children.forEach((obj, index)=>{
							obj.setAttribute('disabled', 'true');
						});
						let next = props.state.activeStep + 1;
						props.userWriteToDialog(props.activeStep.value);
						props.propSetState({ 
							typingText: true,
							activeStep: next,
							attempts: 0
						}, () => {
							props.moveConversation();
						});
					},
				}
			]
		})
	}
	
}