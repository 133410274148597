import React from 'react';
import Button from 'components/Button/Button';
import { t } from 'components/Translate/Translate';

export default (props) => {
	props.propSetState({
		dialogState: 0,
		userActions: {}
	})
	let buttonText = t("community-rules_accept-btn", { defaultValue:  'Accept Community Rules' });
	let message = {
		type: 'warn',
		text: t("community-rules_accept-message", { defaultValue:  'For Juice Community is very important to keep Batteries Return Rate <b>on less then 48 hours level.</b>' })
	}
	class CommunityRulesScreen extends React.Component {
		goForward = () => {
			props.propSetState({
				activeStep: props.state.activeStep + 1,
			}, () => {
				console.log('groupId', props.state.groupId);
				if (props.state.groupId) {
					props.propSetState({
						userActions: {},
						dialogState: 1
					}, () => {
						props.sendMessageToDialog({ 'type': 'venue', 'value': props.state.selectedVenue });
						props.sendMessageToDialog({ 'type': 'text', 'value': t("supernova-get-success", { defaultValue:  'Whoa, Electron is available!' }) });
						props.moveConversation({ name: 'TakeBattery', step: 0 });
					});
				} else {
					props.doParticipantAction(false, { name: 'SupernovaFindScreen' });
				}
				// props.moveConversation({ name: 'supernova' });
			});
		}
		openTerms = () => {
			props.state.modalTerms = true;
		}
		render() {
			const read = t("community-rules_read", { defaultValue:  'Read' });
			const returnPolicy = t("community-rules_returnPolicy", { defaultValue:  'Return and Penalty Rules' });
			return (
				<div className="app-container">
					<div className="dialog-screen">
						<div className="dialog-screen__container">
							<div className="dialog-screen__message">
								<div className={"dialog-screen__alert type-" + message.type} dangerouslySetInnerHTML={{__html : message.text }}></div>
							</div>
						</div>
					</div>
					<div>
						<div className="dialog-bottom__container">
							<div className="UserController__label">{read} <button className="dialog-bottom__link underline" onClick={this.openTerms}>{returnPolicy}</button></div>
							<Button
								onClick={this.goForward}
								text={buttonText} />
						</div>
					</div>
				</div>
			)
		}
	};
	
	props.propSetState({
		fullScreenStore: <CommunityRulesScreen />
	});
	
}