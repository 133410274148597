import React from 'react';
import { Link } from 'react-router-dom';
import Map from '../../components/Map/Map';
import Button from 'components/Button/Button';
import { t, Trans } from 'components/Translate/Translate';
// import ApproveVenue from './ApproveVenue';

export default (props) => {	
	props.propSetState({
		dialogState: 0,
		headerState: 3,
		showUserController: false
	})
	let buttonText;
	let userWithPlan = true;
	props.state.headerState = 5;

	if (props.state.userStory === 'userNoPlan') {
		if (!props.state.modalMap) {
			props.history.push("/station");
		}
	}



	switch (props.state.userStory) {
		case 'userNoBattery':
			buttonText = t("hello-user_userNoBattery", { defaultValue:  'Get battery' });
			break;
		default: {
			props.state.headerState = 2;
			userWithPlan = false;
			break;
		}
	}
	const showMap = true;

	class HelloUserScreen extends React.Component {
		goForward = () => {
			if (userWithPlan) {
				props.history.push('/station');
				return;
			}
			props.propSetState({
				activeStep: props.state.activeStep + 1,
				fullScreenStore: null,
			}, () => {
				props.moveConversation();
			});
		}

		openMap = () => {
			props.state.headerState = 3;
			props.state.modalMap = true;
		}
		render() {
			return (
				<React.Fragment>
					{showMap ? <Map
						userStory={props.state.userStory}
						location={props.state.location}
						state={props.state}
						setState={props.propSetState}
						sendMessageToDialog={props.sendMessageToDialog}
						moveConversation={props.moveConversation}
						userWriteToDialog={props.userWriteToDialog}
						doParticipantAction={props.doParticipantAction}
						/>
					: null }
					{ !showMap ? <div className="dialog-screen">
						<div className="dialog-screen__container">
							<div className="dialog-screen__message">
								<span role="img" aria-label="Star">✨</span><br/><br/>
								{ userWithPlan ? 
									<Trans i18nKey="hello-user_welcome-back" >Welcome back!</Trans>
									: <Trans i18nKey="hello-user_without-plan" >Welcome!</Trans> }
								{ props.state.userStory === 'userNoBattery' ?
								<span>
									<br/>
									<Trans i18nKey="hello-user_userNoBattery-lets-get-juice" >Lets get some Juice</Trans>
								</span>
								: null }
							</div>
						</div>
					</div> : null }
					{ buttonText ?
					<React.Fragment>
						<div className="maps__control-types">
							<div className="dialog-bottom__container">
								{ !showMap ? <div style={{marginBottom: '12px'}}>
									<Button
										onClick={this.openMap}
										text={'Find spot on map'} />
								</div> : null }
								<div>
									<Button
										onClick={this.goForward}
										text={buttonText} />
								</div>
							</div>
						</div>
					</React.Fragment>
					: null }

					{
						userWithPlan ? null :
						<div className="maps__control-types">
							<div className="dialog-bottom__container">
								<Link className="UserController__btn" to="/subscribe"><Trans i18nKey="hello_user_userNoBattery__select_plan_btn">Select a plan</Trans></Link>
							</div>
						</div>
					}

				</React.Fragment>
			)
		}
	};

	props.propSetState({
		fullScreenStore: <HelloUserScreen />
	});
}