import React from 'react';
import Cookies from 'js-cookie';
import Analytics from 'services/utils/Analytics';
import Settings from 'services/config/Settings';
import fetchJSON from 'services/utils/fetchJSON';

class ReferralCode extends React.Component {
	componentDidMount = () => {
		if (this.props.match.params) {
			const referralCode = this.props.match.params.referralCode ? this.props.match.params.referralCode : false;
			if (referralCode) {
				Analytics('User used Referral Link', { Referral: referralCode });
				fetchJSON(Settings.endpoindApi+'/api/v1/promo/link', {
					method: 'post',
					body: {
						promocode_key: referralCode
					},
					headers: {
						Source: 'Referral'
					}
				}).then(data => {
					if (data.code === 200) {
						Analytics('Сorrect Referral Link', { Referral: referralCode });
						Cookies.set('referral', referralCode, { expires: 365 });
						this.props.history.push("/?m=referral");
					} else {
						this.goToFrontPage();
					}
				})
				.catch(error => {
					console.log(error);
					this.goToFrontPage();
				});
			} else {
				this.goToFrontPage();
			}
		} else {
			this.goToFrontPage();
		}
  }
  goToFrontPage() {
  	Analytics('Referral Link is invalid');
  	Cookies.remove('referral');
  	this.props.history.push('/');
  }
  render() {
  	return null;
  }
}


export default ReferralCode;