import React from 'react';
import './styles.less';
//import Analytics from 'services/utils/Analytics';
import {observer} from "mobx-react"
import { Trans } from 'components/Translate/Translate';


@observer
class UserController extends React.Component {
	constructor(props) {
    super(props);
		this.state = {
			opened: false,
			message: '',
			label: '',
			focused: false,
			readOnly: false,
		}
	}
	onFocus = () => {
		this.setState({
			focused: true
		});
  }
	onChange = (e) => {
		const controller = this.props.controller;
		controller['value'] = e.target.value;
		this.props.propSetState({
			controller
		});
	}
	onBlur = () => {
		this.setState({
			focused: false
		})
  }
	sendToDialog = () => {
		if (this.props.controller.value) {
			const controller = this.props.controller;
			const value = this.props.controller.value;
			this.props.userWriteToDialog(value);
			this.props.propSetState({
				activeMessageFromUser: value
			});
			controller.value = '';
			this.props.propSetState({
				controller
			}, () => {
				this.props.moveConversation()
			});
		}
	}
	_handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.sendToDialog();
    }
  }
	showActions = () => {
		if (this.props.userActions) {
			let DOM = [];
			const buttons = this.props.userActions.buttons ? this.props.userActions.buttons.slice() : [];
			if (buttons) {
				buttons.forEach((button, index) => {
					DOM.push(
						<button key={index} className={"UserController__btn" + (button.class ? ` ${button.class}`: '') } onClick={button.func}  dangerouslySetInnerHTML={{__html : button.text }}></button>
					)
				});
			}
			return DOM;
		}
		return [];
	}
  render() {
		let style = {
			display: this.props.userActions.active ? 'block' : 'none'
		}
    return (
			<div className="control__fixed">
				<div className="control__tableRow blurred" style={style}>
					<div className="UserController">
						{ this.props.userActions.message ? <div className="UserController__message">{this.props.userActions.message}</div> : '' }
						{ this.props.userActions.label ? <div className="UserController__label">{this.props.userActions.label}</div> : '' }
						{ this.props.userActions ? <div className="UserController__group"> { this.showActions() } </div> : '' }
					</div>
				</div>
				<div className={"control__tableRow blurred" + (this.props.controller.status ? '' : ' disabled' )} style={this.state.styles}>
					<div className="control__container">
						{this.props.controller.label ? <span className="control__label">{this.props.controller.label }</span> : null }
						<div className="control__group">
							{ this.props.controller.name === 'phone' ?
								<input
									readOnly={ this.state.readOnly }
									onFocus={ this.onFocus }
									onBlur={ this.onBlur }
									onChange={this.onChange }
									onKeyPress={this._handleKeyPress}
									className={"control__controller" + (this.props.controller.status && this.props.controller.status !== 'showTab' ? '' : ' disabled') }
									type={this.props.controller.type ? this.props.controller.type : 'text' }
									name="controllerValue"
									id="controllerValue"
									value={this.props.controller.value ? this.props.controller.value : ''}
									placeholder={this.props.controller.placeholder ? this.props.controller.placeholder : '' }
								/>
							 :
							 <input
									readOnly={ this.state.readOnly }
									onFocus={ this.onFocus }
									onBlur={ this.onBlur }
									onChange={this.onChange}
									onKeyPress={this._handleKeyPress}
									className={"control__controller" + (this.props.controller.status && this.props.controller.status !== 'showTab' ? '' : ' disabled') }
									type={this.props.controller.type ? this.props.controller.type : 'text' }
									name="controllerValue"
								 	id="controllerValue"
									value={this.props.controller.value ? this.props.controller.value : ''}
									placeholder={this.props.controller.placeholder ? this.props.controller.placeholder : '' }
								/>
							}
							<button
								className="control__button-send"
								type="button"
								onClick={this.sendToDialog}
								disabled = {this.state.focused || this.props.controller.value ? false : true }
								>
								<Trans i18nKey="input_controller_send_request">SEND</Trans>
							</button>
						</div>
					</div>
				</div>
			</div>
    )
  }
};

export default UserController;