import React from 'react';

import './styles.less';

class Background extends React.Component {
  render() {
  	const {type} = this.props;
    return (
      <div className={"bg "+type}>
				<div className="bg__item">
				</div>
			</div>
    )
  }
};

export default Background;