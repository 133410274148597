import fetchJSON from 'services/utils/fetchJSON';
import { t } from 'components/Translate/Translate';


export default (props) => {
	const userActions = {
		active: true,
		label: false,
		buttons: [
			{
				text: t("refund-yes-doRefund", { defaultValue:  'Yes' }),
				class: 'btn-half',
				func: (e) => {
					props.propSetState({
						userActions: {}
					})
					props.sendMessageToDialog({ 'type': 'text', 'value': t("refund-weSorry", { defaultValue:  'We are very sorry..' }) });
					setTimeout(() => {
						props.sendMessageToDialog({ 'type': 'text', 'value': t("refund-inProgress", { defaultValue:  'Refund in progress' }) });
						props.propSetState({
							typingText: true
						})
					}, 1000);
					fetchJSON('/api/v1/braintree/subscriptions/refund', {
						method: 'post'
					}).then(data => {
						console.log(data);
						if (data.code === 200) {
							props.propSetState({
								typingText: false
							}, () => {
								props.sendMessageToDialog({ 'type': 'text', 'value': t("refund-done", { defaultValue:  'Done!' }) });
								setTimeout(() => {
									props.history.push('/');
									props.doParticipantAction(null, { name: 'Welcome' });
								}, 2000)
							})
						} else {
							props.history.push('/');
							props.doParticipantAction(null, { name: 'Welcome' });
						}
					}).catch(error => {
						console.log(error);
				 	});
				}
			},
			{
				text: t("refund-no-refund", { defaultValue:  'No' }),
				class: 'btn-half',
				func: (e) => {
					props.propSetState({
						userActions: {}
					})
					props.sendMessageToDialog({ 'type': 'text', 'value': t("refund-right-choice-back", { defaultValue:  'Right choice!' }) });
					setTimeout(() => {
						props.history.push('/payments');
					}, 500)
				}
			},
		]
	}
	const next = props.state.activeStep + 1;
	props.setState({ 
		userActions,
		activeStep: next,
	}, () => {
		props.moveConversation();
	});

}