import React from 'react';
import { t } from 'components/Translate/Translate';

class PromoV2 extends React.Component {
  render() {
  	const {
  		promo,
  		showInput,
  		submitDisabled,
  		alert,
  		isFetching,
      showPromo
  	} = this.props;

    return (
    	<React.Fragment>
	      { showPromo ? <li className="sbOrderV2__item">
	        <form onSubmit={this.props.onSubmit} className="sbOrderPromoV2">

		        <input
	        			className={"sbOrderPromoV2__input "+(showInput ? 'bordered' : 'borderless')}
	      				id="promo"
	      				onChange={this.props.onChanged}
	      				onFocus={this.props.onFocus}
								onBlur={this.props.onBlur}
	      				type="text"
	      				name="promo"
	      				placeholder={t("subscribe_order__promo_input_placeholder", { defaultValue:  'Enter promo' })}
	      				value={promo}
	    				/>
	    			<button
	  					className={"sbOrderPromoV2__submit "+(showInput ? 'show' : 'hide')}
	  					type="submit"
	  					disabled={submitDisabled || isFetching}
	  					title="submit"></button>
		      </form>
	      </li> : null }
	      { alert.active ?
	        <div className={"sbOrderPromoV2__message "+alert.type}>{alert.value}</div>
	      : null }
      </React.Fragment>
    )
  }
};

export default PromoV2;