import React from 'react';
import Settings from 'services/config/Settings';
import { t, Trans } from 'components/Translate/Translate';

import './styles.less';

class Help extends React.Component {
	componentDidMount = () => {
		const pageTitle = t('pageTitles.faq', { defaultValue: 'FAQ'});
		document.title = `${pageTitle} | ${Settings.title}`;
  }
  render() {
    return (
    	<div className="wallet">
        <div className="wallet__container">
	       	<div className="wallet__header mgn-zero">
					  <div className="wallet__titlePage"><Trans i18nKey="faq_page__title">FAQ</Trans></div>
					</div>
					<div className="wallet__text">
						<h2><Trans i18nKey="faq_page__what_is_supernova_question">What is the Supernova?</Trans></h2>
						<p><Trans i18nKey="faq_page__what_is_supernova_answer">It is our charging station.</Trans></p>
						<h2><Trans i18nKey="faq_page__what_is_electron_question">What is an Electron?</Trans></h2>
						<p><Trans i18nKey="faq_page__what_is_electron_answer">It is a single portable charger.</Trans></p>
						<h2><Trans i18nKey="faq_page__how_it_will_charge_question">How it will charge my phone?</Trans></h2>
						<p><Trans i18nKey="faq_page__how_it_will_charge_answer">Each electron has built-in cable for both iPhones and Andriod Type-C ( but not for micro USB).</Trans></p>
						<h2><Trans i18nKey="faq_page__is_it_wireless_question">Is it wireless charge?</Trans></h2>
						<p><Trans i18nKey="faq_page__is_it_wireless_answer">No. Current Wireless charging protocols are inificient and shorten your smartphone battery cell.</Trans></p>
						<h2><Trans i18nKey="faq_page__what_type_of_phone_can_change_question">What type of phone can an Electron Charge?</Trans></h2>
						<p><Trans i18nKey="faq_page__what_type_of_phone_can_change_answer">Apple iPhone from iPhone 5 model and Android phones with USB Type-C connector.  </Trans></p>
						<h2><Trans i18nKey="faq_page__how_fast_it_question">How fast can I charge my phone?</Trans></h2>
						<p><Trans i18nKey="faq_page__how_fast_it_answer">Depends on your phone capabilities. Electrons can charge your phone up to 2A, which is 1% per minute for most of the phones.</Trans></p>
						<h2><Trans i18nKey="faq_page__how_much_charge_I_can_get_question">How much charge I can get?</Trans></h2>
						<p><Trans i18nKey="faq_page__how_much_charge_I_can_get_answer">Depends on size of your phones battery. Electron capacity is 2700mAh, which is approximately 70-100% charge for most phones.</Trans></p>
						<h2><Trans i18nKey="faq_page__how_do_i_know_electron_charged_question">How do I know if an Electron is charged?</Trans></h2>
						<p><Trans i18nKey="faq_page__how_do_i_know_electron_charged_answer">Press the red button on Electron and you can see LED indicators on back side of Electron. 3 or 4 LED ON means Electron is fully charged.</Trans></p>
						<h2><Trans i18nKey="faq_page__where_can_return_question">Where can I return my charger?</Trans></h2>
						<p dangerouslySetInnerHTML={{ __html: t('faq_page__where_can_return_answer', { defaultValue:  'To any avaialable for return spot that you can find on our <a href="https://electrontogo.app/map">map</a>.' }) }}></p>
						<h2><Trans i18nKey="faq_page__what_happend_if_not_return_on_time_question">What happens if I don't return an Electron in time?</Trans></h2>
						<p><Trans i18nKey="faq_page__what_happend_if_not_return_on_time_answer">If you are daily user we will charge you $1 a day and after 3 days, we will elevate your subscription to monthly and charge you $10. If you are Monthly subscriber, you have up to 7 days to return the Electron, after which we will elevate your subscription to annual and charge you $60. If you are Annual user, you have up to 7 days to return after which we will suspend your acount untill you return the Electron. </Trans></p>
						<h2><Trans i18nKey="faq_page__what_if_lost_question">What happens if an Electron is lost?</Trans></h2>
						<p><Trans i18nKey="faq_page__what_if_lost_answer">Your subscription is upgraded to a year and you will be charged $60.00.  An annual subscriber will be charged $50.00</Trans></p>
						<h2><Trans i18nKey="faq_page__what_if_break_question">What happens if I break or damage an Electron?</Trans></h2>
						<p><Trans i18nKey="faq_page__what_if_break_answer">Depending on the damage, we will charge a fee not to exceed $60.00.</Trans></p>
						<h2><Trans i18nKey="faq_page__what_id_supernova_not_respond_question">What do I do if the Supernova does not respond?</Trans></h2>
						<p dangerouslySetInnerHTML={{ __html: t('faq_page__what_id_supernova_not_respond_answer', { defaultValue:  'Wait and try again. If it\'s still not working please contact us by: <a href="mailto:support@electrontogo.com">support@electrontogo.com</a> we will respond ASAP' }) }}></p>
						<h2><Trans i18nKey="faq_page__electron_doesnt_work_what_to_do_question">The Electron doesn’t work, what should I do?</Trans></h2>
						<p><Trans i18nKey="faq_page__electron_doesnt_work_what_to_do_answer">Return the Electron to the station and get another one. If this is the only Electron on the station, return it and get it again. If it's still doesn't work, find another location to get Electron on our <a href="https://electrontogo.app/map">map</a>. Sorry for the incovinience. Report the poblem to us with electron ID and get bonus points to your rating.</Trans></p>
						<h2><Trans i18nKey="faq_page__what_if_i_am_in_other_city_question">What if I’m in another city & can’t return the Electron?</Trans></h2>
						<p><Trans i18nKey="faq_page__what_if_i_am_in_other_city_answer">Contact us: support@electrontogo.com</Trans></p>
						<h2><Trans i18nKey="faq_page__what_is_juize_inc_question">What is Juize Inc?</Trans></h2>
						<p><Trans i18nKey="faq_page__what_is_juize_inc_answer">Juize Inc is our legal name.</Trans></p>
						<h2><Trans i18nKey="faq_page__electron_too_hot_question">My Electron got too hot. What shoud I do?</Trans></h2>
						<p><Trans i18nKey="faq_page__electron_too_hot_answer">Unplug and wait for couple of minutes and plug it back in. We do not recommend to charging your phone in your pants pocket.</Trans></p>
						<h2><Trans i18nKey="faq_page__how_many_electrons_i_can_get_question">How many Electron batteries can I get?</Trans></h2>
						<p><Trans i18nKey="faq_page__how_many_electrons_i_can_get_answer">Only one but pick up and return as many times as you like</Trans></p>
						<h2><Trans i18nKey="faq_page__how_log_i_kan_keep_charger_question">How long can I keep the charger?</Trans></h2>
						<p><Trans i18nKey="faq_page__how_log_i_kan_keep_charger_answer">Please return every within 24 hours. See User terms for detail on your plan</Trans></p>
						<h2><Trans i18nKey="faq_page__why_do_i_need_add_payment_when_promo_question">Why do I need to add a payment method when I have a promotional code?</Trans></h2>
						<p><Trans i18nKey="faq_page__why_do_i_need_add_payment_when_promo_answer">To ensure the return of the Electron™ battery to the station</Trans></p>
						<h2><Trans i18nKey="faq_page__does_plans_auto-renews_question">Does my plan Auto-Renew?</Trans></h2>
						<p><Trans i18nKey="faq_page__does_plans_auto-renews_answer">No, you must manually renew each time</Trans></p>
						<h2><Trans i18nKey="faq_page__what_if_i_dont_like_question">What if I don’t like the service?</Trans></h2>
						<p><Trans i18nKey="faq_page__what_if_i_dont_like_answer">You have the first two weeks to get a full refund of your Monthly, Semester or Annual subscription.</Trans></p>
						<h2><Trans i18nKey="faq_page__how_do_i_get_refund_question">How do I get my refund?</Trans></h2>
						<p dangerouslySetInnerHTML={{ __html: t('faq_page__how_do_i_get_refund_answer', { defaultValue:  'You can cancel your plan on payments page (<a href="https://electrontogo.app/payments">https://electrontogo.app/payments</a>). Only first time subscriber can get a refund.' }) }}></p>
						<h2 dangerouslySetInnerHTML={{ __html: t('faq_page__if_you_didnt_find_answer_question', { defaultValue:  'If you didnt find you answer please <a href="https://electrontogo.app/contact">contact us.</a>' }) }}></h2>
					</div>
        </div>
	    </div>
    )
  }
};

export default Help;