import dictionary from './dictionary';
import fetchJSON from 'services/utils/fetchJSON';
import Cookies from 'js-cookie';

const dictionary1 = {
 
};

class TranslateController {
	constructor(props) {
    this.props = props;
    this.timeout = 0;
    this.promises = [];
    this.dictionaryActive = 0;
    if (dictionary1) {
    	Object.keys(dictionary1).forEach((key) => {
				this.promises.push({
					key: key,
					value: dictionary1[key]
				});
	    });
    }
    
   //  console.log('this.promises', this.promises)
		this.intervalForSending = setInterval(() => {
			try {
				this.doPromises();
			} catch(err) {
				//
			}
		}, 500);
  }
	doPromises() {
		//console.log('doPromises', this.promises);
		if (!this.dictionaryActive && this.promises && this.promises.length) {
			this.dictionaryActive = 1;
			//console.log('this.promises', this.promises, this.promises.length)
			//const nextToSend = this.promises[this.promises.length - 1];
			const clonePromises = this.promises.slice();
 			this.promises = [];
			if (clonePromises) {
				//console.log('nextToSend', nextToSend);
				fetchJSON('/api/v1/dictionary', {
					method: 'post',
					body: {
						dictionary: clonePromises
					}
				}).then(data => {
					this.dictionaryActive = 0;
					// this.promises.pop();
					//console.log('dictionary', data);
				}).catch(error => {
					// this.promises.pop();
					this.dictionaryActive = 0;
					//console.error('TranslateController error', error);
					return false
				});
			}
		}
	}
	getDictionary() {
		return new Promise((resolve, reject) => {
			this.props.setState({
				dictionary
			});
			resolve(true);
		})
	}
  put (translate) {
  	let pass = process.env.REACT_APP_DEV_ENV;
  	let appLanguage = Cookies.get('app_language') || 'en';
  	pass = true;
  	if (pass) {
  		this.promises.push({
				key: translate.key,
				value: typeof translate.value === 'string' ? translate.value : translate.value.toString(),
				language: appLanguage
			});
  		// this.promises.push(Promise.resolve().then(() => {
  		// 	return fetchJSON('/api/v1/dictionary', {
				// 	method: 'post',
				// 	body: {
				// 		key: translate.key,
				// 		value: typeof translate.value === 'string' ? translate.value : translate.value.toString(),
				// 		language: appLanguage
				// 	}
				// }).then(data => {
				// 	//console.log('dictionary', data);
				// }).catch(error => {
				// 	console.error('TranslateController error', error);
				// 	return false
				// })
  		// }));
  	}
   	
		return translate.value;
	}
}

export default TranslateController;