import { t } from 'components/Translate/Translate';

let socketUrl = 'https://supernovas.cloud';

if (process.env.REACT_APP_SUPERNOVAS_SOCKET) {
	socketUrl = process.env.REACT_APP_SUPERNOVAS_SOCKET;
}

const Settings = {
	// branding name for our app
	title: t("appSettings.pageTitle", { defaultValue:  'Electron®. Phone charger to Go.' }),
	menuDropdown: {
		'Start Over' : '/logout',
		'Contact Us' : 'mailto:info@electrontogo.com',
		'info@getjuize.com' : 'mailto:info@electrontogo.com'
	},
	
	// Web analytics settings
  analytics: {
    google: process.env.REACT_APP_GA,
		heap: process.env.REACT_APP_HEAP,
		gtm: process.env.REACT_APP_GTM
  },
	
	endpoindApi: '',
	
	// endpoint for socket
	api: {
		socketUrl: socketUrl
		//socketUrl: 'https://supernovas.cloud'
	},
	facebookAppId: 354265578351392,
	trackJS: {
	  token: "5366b72791944ae1bb547dc90956ce46",
    application: "electron-to-go-prod"
	},
	trackJsEnable: false,
	jwtDeviceToken: "Wu\"sza';^38pnjhs~=%`]aFDd?PB`LR~)`"
};
 
if (process.env.NODE_ENV !== "development") {
	Settings.trackJsEnable = true;
}

export default Settings;
