const dictionary = {
  "auth-dialog_write-number": "Please type your phone number",
  "auth-dialog_request_code": "Request new code",
  "auth-dialog_request_edit_num": "Edit number",
  "auth-dialog_what_is_num": "What is your phone number?",
  "auth-dialog_get_new_code": "Get a new code",
  "auth-dialog_user-edit-num": "Edit number",
  "lostBattery-yes-i-do": "Yes, I lost an Electron",
  "lostBattery-no-i-check": "NO, I will check again",
  "lost_battery-are-you-sure": "Are you sure there is no chance to find and return the Electron?<br/>If not we have to charge you for $60 default amount<br/>Read <b style=\"text-decoration: underline;\">Return and Penalty Rules</b>",
  "lostBattery-sorry-penalty": "Unfortunately, we have to charge you a $40 default amount",
  "block-user-screen": "Account has been suspended.",
  "hello-user_userNoBattery": "Get my charger",
  "hello-user_userNoBattery-lets-get-juice": "Let's get an Electron",
  "supernova-type-code": "Please type 4 digit code from placard near Supernova station",
  "supernova-get-success": "Whoa, Electron is available!",
  "take-battery-screen-label": "Take the Electron battery!",
  "take-battery-screen-take-btn": "Take it!",
  "single-venue__get-direction-btn": "Get direction",
  "took-success-open-map-btn": "Find spot on map",
  "took-success-label": "For Electron-to-Go Community it is very important to keep the Electron Return Rate <b>on less then 24 hour level.</b>",
  "supernova-help-screen_openMap": "Find spot on map",
  "single-venue_get-battery-button": "Get my charger",
  "took-success-message": "Pull cable from Electron frame using red finger tab. Plug it in and Go!",
  "refund-yes-doRefund": "Yes",
  "refund-no-refund": "No",
  "refund-weSorry": "We are very sorry..",
  "refund-inProgress": "Refund in progress",
  "refund-question-really": "Are you really leaving us?",
  "refund-right-choice-back": "Right choice!",
  "auth-dialog_wrong-num": "Wrong number, try again",
  "header-btn-find-on-map": "Find on map",
  "hello-user_without-plan": "Welcome!",
  "hello-user-click-for-annual": "Annual subscription #PRICE#",
  "hello-user-click-for-daily": "Want to try?<br/>Click here for <span>day access</span>",
  "community-rules_accept-btn": "Accept Community Rules",
  "community-rules_accept-message": "For Electron-to-Go Community it is very important to keep the Electron Return Rate <b>on less then 24 hour level.</b>",
  "community-rules_read": "Read",
  "community-rules_returnPolicy": "Rental Period and Return Rules",
  "hello-user__userNoPlan-money-back": "One-month money-back guaranteed.",
  "return-policy-message-more-48": "It is more <b>then 48 hours</b> that is allowed",
  "find-supernova-screen_btn-no": "No",
  "find-supernova-screen_question": "Are you near Supernova station?",
  "promo-add-payment": "For security reason<br/>we need payment method",
  "refund-done": "Done!",
  "map-ask-user-share-location": "For ease of navigation, let us use your location.",
  "find-supernova-correct-venue": "Is this the right venue where you are?",
  "take-battery-screen-sorry-didNotTake": "Sorry, you did not take your battery. Repeat?",
  "subscribe-order-title": "Let's choose a subscription",
  "supernova-get-error": "Sorry but this Supernova is offline or that was incorrect code. Please try again.",
  "take-battery-screen-sorry-no-free-batteries": "It seems there are no free batteries",
  "subscribe-payments-title": "Select a Payment Method",
  "subscribe-review-title": "Review",
  "subscribe-process-title": "Payment",
  "subscribe-fail-title": "Fail",
  "subscribe-purchase-title": "Payment",
  "ReturnBatteryFail_return-soon-a-spossible": "Please return battery as soon as it possible",
  "take-battery-screen-sorry-there-queue": "Sorry, there's a queue. Wait 10 seconds",
  "hello-user_userWithBattery": "Return battery",
  "welcome__item__2": "Lightning and USB-C<br/> connectors are built-in",
  "welcome__item__1": "Get unlimited access<br/> to shareable chargers",
  "welcome__item__3": "After payment,<br/> your battery will unlock",
  "welcome__item__4": "Return within 24 hours<br/> to any of our locations",
  "welcome__btn__first": "How to use",
  "welcome__item__btn__next": "Next",
  "auth-dialog__type_phone_placeholder": "Type phone",
  "auth-dialog__type_code_from_sms": "Type code from SMS",
  "auth-dialog__type_code_placeholder": "Type code",
  "subscribe_order__title": "Choose charging<br/> option",
  "subscribe_order__have_promo": "I have a promocode",
  "subscribe_order__continue_btn": "Continue",
  "subscribe_order__1_month_plan_label": "For 1 month",
  "subscribe_order__1_day_plan_label": "For 1 day",
  "subscribe_order__daily_plan_title": "Pay #PRICE# for charge",
  "subscribe_order__daily_offer": "Limited access for 1 day",
  "subscribe_order__monthly_offer_1": "1 month with no limit of charges",
  "subscribe_order__monthly_offer_2": "Free access for a friend for 1 day",
  "subscribe_order__monthly_plan_title": "Unlimited access for",
  "subscribe_order__promo_plan_title": "Promo access:",
  "subscribe_order__promo_input_placeholder": "Enter promo",
  "subscribe__payments_description_no_payment_methods": "Choose a convenient payment method for you",
  "subscribe__payments_description_has_payment_methods": "Select your saved payment method and add a new one",
  "subscribe__payments_description_promo": "We need to have your valid payment method to control the electron charger battery return",
  "subscribe__payments_billing_info_title": "Billing information",
  "subscribe__payments_add_payment_method_btn": "Add new payment method",
  "subscribe__payments_billing_post_code_placeholder": "ZIP",
  "subscribe__payments_billing_email_placeholder": "Email",
  "subscribe_order__promo_error_1": "The promo code not found or not valid",
  "subscribe_order__promo_applied": "The promo code applied",
  "subscribe_order__promo_error_403": "You have already used this promo code",
  "subscribe_order__promo_error_405": "You can't use the referral promotional code more than 1 time",
  "subscribe_order__promo_error_expired": "The promo code is expired",
  "subscribe__review-description": "Check out your selection",
  "subscribe__review_membership_title": "Membership",
  "subscribe__review_membership_valid_until": "Membership valid until",
  "subscribe__review_payment_method_selected": "Payment method",
  "subscribe__review_promo_description": "We will not charge money for a promo plan, but we will request $1 from your payment method to verify it. The money will immediately return to your bank account.",
  "subscribe__review_agree_terms_privacy": "I agree #TERMS_LINK_OPEN#Terms and Conditions#TERMS_LINK_CLOSE# and #PRIVACY_LINK_OPEN#Privacy Policy#PRIVACY_LINK_CLOSE#",
  "subscribe-fail-help": "Help is needed? Contact us",
  "subscribe-fail-retry": "Retry",
  "subscribe__pay_start_payment_btn": "PAY",
  "subscribe__pay_add_card_btn": "ADD CARD",
  "subscribe-purchase-description": "Success",
  "subscribe-purchase-got_it_btn": "Got it",
  "subscribe-purchase-24-hours-rules": "It is very important for our Community that everyone return their used Electron <b>within 24 hours</b>.",
  "hello_user_userNoBattery__select_plan_btn": "Select a plan",
  "supernova-type-code_input_label_text": "Click here to type station code",
  "supernova-type-code_input_placeholder": "Type code here",
  "take-battery-unlock-my-charger_btn": "Unlock my charger",
  "take-battery-screen-something-wrong": "Something went wrong. Please try again in 5 minutes or contact us at support@electrontogo.com",
  "took-success-having-problem-btn": "Having problem?",
  "exchange_charger__title": "Supernova is full?",
  "exchange_charger__description": "Exchange your Electron with a new Electron by following these steps:",
  "exchange_charger__steps_1": "1. Hit the “Exchange Battery” button & enter the Supernova station number",
  "exchange_charger__steps_2": "2. Take a new battery",
  "exchange_charger__steps_3": "3. When the nest closes, return the empty battery",
  "exchange_charger__understand_rules_check": "I understand",
  "exchange_charger__start_btn": "Exchange Battery",
  "have_charger_screen__title": "Charger session",
  "have_charger_screen__instruction_title": "How to return the Electron:",
  "have_charger_screen__instruction_steps_1": "1. Return Electron to any empty nest",
  "have_charger_screen__instruction_steps_2": "2. You should receive a text confirmation with your return confirmation",
  "have_charger_screen__instruction_steps_3": "3. If you want to exchange your Electron for a fresh Election, follow the express link in the return confirmation",
  "have_charger_screen__find_on_map": "Find station on map",
  "report__how_return_question": "How do I return the battery?",
  "report__how_return_answer": "Return the battery to any available Station’s nest. Please be sure to fully insert cable back into the battery frame.",
  "report__how_battery_doesnt_work_question": "The battery doesn’t work",
  "report__how_battery_doesnt_work_answer": "Return the battery to the station and get another one.",
  "report__exchange_charger_question": "Station is full: Exchange your Electron with one of the other Electrons at this station.",
  "report__cant_take_battery_question": "I can't get the battery",
  "report__supernova_doesnt_work_question": "The station is not working",
  "report__session_is_still_open_question": "I already returned the battery, but my session is still open",
  "report__other_question": "Other problem",
  "report__title": "Need help? Choose one of the options or write your question",
  "report__exchange_charger_answer": "You can use an exchange battery only if there are no free nests in the station.",
  "report__exchange_charger_btn_start": "Start battery exchange",
  "report__find_battery_placeholder": "Station number or Location",
  "report__find_battery_describe_field_placeholder": "Describe the problem",
  "report__find_battery_warning": "Please provide the following details:",
  "report__find_battery_label": "Station number or Location",
  "report__find_battery_describe": "Describe the problem",
  "report__find_battery_submit": "Submit",
  "report__custom_question": "Your message",
  "report__submit_question": "Submit",
  "referral__share_subject": "Get Free month access to the network of chargers",
  "referral__share_text": "Use my invite code #UNIQUE_CODE# and get free month access to the network of chargers. Redeem it at https://e2g.app/r/#UNIQUE_CODE#",
  "referral__title": "Give #LOCAL_AMOUNT#, get #LOCAL_AMOUNT#",
  "referral__description": "Invite a friend to try e2g.app, you both will earn a #LOCAL_AMOUNT# monthly subscription once she or he starts using an Electron charger.",
  "menu__referral": "Get for Free",
  "menu__payments": "My Payments",
  "menu__charges": "My Charges",
  "menu__map": "Map",
  "map__faq": "Help",
  "menu__about_etg": "About Electron-to-Go",
  "menu__logout": "Logout",
  "payments_page__cancel_membership": "Cancel membership",
  "payments_page__refund_btn": "Refund",
  "payments_page__title": "Payment Methods",
  "payments_page__payment_history_link": "Payment history",
  "payments_page__payment_method_list_title": "Payment Method",
  "payments_page__currently_active_membership_promo": "Promo",
  "payments_page__currently_active_membership_annual": "Annual",
  "payments_page__currently_active_membership_daily": "Daily",
  "payments_page__currently_active_membership_monthly": "Monthly",
  "payments_page__currently_active_membership_semester": "Semester",
  "payments_page__currently_active_membership_referral": "Referral",
  "payments_page__currently_active_title": "Currently active",
  "payments_page__membership_word": "membership",
  "payments_page__expires_in_period": "Expires in",
  "payments_page__delete_payment_method_question": "Delete payment method?",
  "payment_history_page__title": "Payment History",
  "payment_history_page__transaction_word": "Transaction",
  "payment_history_page__status_paid_word": "Paid",
  "payment_history_page__date": "Date",
  "payment_history_page__plan_status": "Plan status",
  "payment_history_page__order_id": "Order ID",
  "payment_history_page__order_status": "Order status",
  "payment_history_page__order_amount": "Order amount",
  "payment_history_page__payment_method": "Payment method",
  "payment_history_page__payment_status": "Payment status",
  "payment_history_page__no_transactions": "You have no active transactions.",
  "my_charges_page__title": "My charges",
  "my_charges_page__amount_charges_word": "Charges",
  "my_charges_page__amount_hours_word": "Hours",
  "my_charges_page__no_charges_yet": "You have no active charges.",
  "my_charges_page__electron_id": "Electron id",
  "faq_page__title": "FAQ",
  "faq_page__where_can_return_answer": "To any avaialable for return spot that you can find on our <a href=\"https://electrontogo.app/map\">map</a>.",
  "faq_page__what_id_supernova_not_respond_answer": "Wait and try again. If it's still not working please contact us by: <a href=\"mailto:support@electrontogo.com\">support@electrontogo.com</a> we will respond ASAP",
  "faq_page__how_do_i_get_refund_answer": "You can cancel your plan on payments page (<a href=\"https://electrontogo.app/payments\">https://electrontogo.app/payments</a>). Only first time subscriber can get a refund.",
  "faq_page__if_you_didnt_find_answer_question": "If you didnt find you answer please <a href=\"https://electrontogo.app/contact\">contact us.</a>",
  "faq_page__what_is_supernova_question": "What is the Supernova?",
  "faq_page__what_is_supernova_answer": "It is our charging station.",
  "faq_page__what_is_electron_question": "What is an Electron?",
  "faq_page__what_is_electron_answer": "It is a single portable charger.",
  "faq_page__how_it_will_charge_question": "How it will charge my phone?",
  "faq_page__how_it_will_charge_answer": "Each electron has built-in cable for both iPhones and Andriod Type-C ( but not for micro USB).",
  "faq_page__is_it_wireless_question": "Is it wireless charge?",
  "faq_page__is_it_wireless_answer": "No. Current Wireless charging protocols are inificient and shorten your smartphone battery cell.",
  "faq_page__what_type_of_phone_can_change_question": "What type of phone can an Electron Charge?",
  "faq_page__what_type_of_phone_can_change_answer": "Apple iPhone from iPhone 5 model and Android phones with USB Type-C connector.",
  "faq_page__how_fast_it_question": "How fast can I charge my phone?",
  "faq_page__how_fast_it_answer": "Depends on your phone capabilities. Electrons can charge your phone up to 2A, which is 1% per minute for most of the phones.",
  "faq_page__how_much_charge_I_can_get_question": "How much charge I can get?",
  "faq_page__how_much_charge_I_can_get_answer": "Depends on size of your phones battery. Electron capacity is 2700mAh, which is approximately 70-100% charge for most phones.",
  "faq_page__how_do_i_know_electron_charged_question": "How do I know if an Electron is charged?",
  "faq_page__how_do_i_know_electron_charged_answer": "Press the red button on Electron and you can see LED indicators on back side of Electron. 3 or 4 LED ON means Electron is fully charged.",
  "faq_page__where_can_return_question": "Where can I return my charger?",
  "faq_page__what_happend_if_not_return_on_time_question": "What happens if I don't return an Electron in time?",
  "faq_page__what_happend_if_not_return_on_time_answer": "If you are daily user we will charge you $1 a day and after 3 days, we will elevate your subscription to monthly and charge you $10. If you are Monthly subscriber, you have up to 7 days to return the Electron, after which we will elevate your subscription to annual and charge you $60. If you are Annual user, you have up to 7 days to return after which we will suspend your acount untill you return the Electron.",
  "faq_page__what_if_lost_question": "What happens if an Electron is lost?",
  "faq_page__what_if_lost_answer": "Your subscription is upgraded to a year and you will be charged $60.00. An annual subscriber will be charged $50.00",
  "faq_page__what_if_break_question": "What happens if I break or damage an Electron?",
  "faq_page__what_if_break_answer": "Depending on the damage, we will charge a fee not to exceed $60.00.",
  "faq_page__what_id_supernova_not_respond_question": "What do I do if the Supernova does not respond?",
  "faq_page__electron_doesnt_work_what_to_do_question": "The Electron doesn’t work, what should I do?",
  "faq_page__electron_doesnt_work_what_to_do_answer": "Return the Electron to the station and get another one. If this is the only Electron on the station, return it and get it again. If it's still doesn't work, find another location to get Electron on our <a href=\"https://electrontogo.app/map\"> map</a> . Sorry for the incovinience. Report the poblem to us with electron ID and get bonus points to your rating.",
  "faq_page__what_if_i_am_in_other_city_question": "What if I’m in another city & can’t return the Electron?",
  "faq_page__what_if_i_am_in_other_city_answer": "Contact us: support@electrontogo.com",
  "faq_page__what_is_juize_inc_question": "What is Juize Inc?",
  "faq_page__what_is_juize_inc_answer": "Juize Inc is our legal name.",
  "faq_page__electron_too_hot_question": "My Electron got too hot. What shoud I do?",
  "faq_page__electron_too_hot_answer": "Unplug and wait for couple of minutes and plug it back in. We do not recommend to charging your phone in your pants pocket.",
  "faq_page__how_many_electrons_i_can_get_question": "How many Electron batteries can I get?",
  "faq_page__how_many_electrons_i_can_get_answer": "Only one but pick up and return as many times as you like",
  "faq_page__how_log_i_kan_keep_charger_question": "How long can I keep the charger?",
  "faq_page__how_log_i_kan_keep_charger_answer": "Please return every within 24 hours. See User terms for detail on your plan",
  "faq_page__why_do_i_need_add_payment_when_promo_question": "Why do I need to add a payment method when I have a promotional code?",
  "faq_page__why_do_i_need_add_payment_when_promo_answer": "To ensure the return of the Electron™ battery to the station",
  "faq_page__does_plans_auto-renews_question": "Does my plan Auto-Renew?",
  "faq_page__does_plans_auto-renews_answer": "No, you must manually renew each time",
  "faq_page__what_if_i_dont_like_question": "What if I don’t like the service?",
  "faq_page__what_if_i_dont_like_answer": "You have the first two weeks to get a full refund of your Monthly, Semester or Annual subscription.",
  "faq_page__how_do_i_get_refund_question": "How do I get my refund?",
  "about_page__contact_us": "CONTACT US",
  "about_page__about_us": "ABOUT US",
  "about_page__help": "Help",
  "about_page__terms_and_conditions": "Terms of service",
  "about_page__privacy_policy": "Privacy Policy",
  "about_page__become_venue": "Became a venue",
  "contact_us_page__title": "Contact Us",
  "contact_us_page__help_is_needed": "Help is needed?",
  "contact_us_page__we_recommend_faq": "We recommend you to review our Frequently Asked Questions.",
  "contact_us_page__go_to_faq": "Go to FAQ",
  "contact_us_page__any_other_by_email": "If you have any questions about support, please contact us via email:"
};
export default dictionary;