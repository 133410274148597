import Cookies from 'js-cookie';

class GroupId {
  
  /**
   * Save the token in Local Storage
   *
   * @param {string} token
   */
  static add(group_id) {
    var inFiveMinutes = new Date(new Date().getTime() + (10 * 60 * 1000));
    Cookies.set('group_id', group_id, { expires: inFiveMinutes });
  }

  /**
   * Save the token in Local Storage
   *
   * @param {string} token
   */
  static addCableType(cableType) {
    var inFiveMinutes = new Date(new Date().getTime() + (10 * 60 * 1000));
    Cookies.set('cable_type', cableType, { expires: inFiveMinutes });
  }

  /**
   * Checking user authorization
   *
   * @returns {boolean}
   */
  static isSelected() {
    return Cookies.get('group_id') !== undefined;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static remove() {
    Cookies.remove('group_id');
  }

  static removeCableType() {
    Cookies.remove('cable_type');
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static get() {
    return Cookies.get('group_id');
  }
  
  static getCableType() {
    return Cookies.get('cable_type');
  }
}

export default GroupId;