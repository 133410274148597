import React from 'react';
import Loading from 'components/Loading';
import fetchJSON from 'services/utils/fetchJSON';
import Analytics from 'services/utils/Analytics';
import GroupId from 'services/utils/GroupId';
import _ from 'lodash';

class Subscribe extends React.Component {
	componentDidMount = () => {
		this.subscribeStart();
  }
  subscribeStart = () => {
  	fetchJSON('/api/v1/subscribe/start', {
			method: 'post',
			body: {
				settings: {
					group_id: GroupId.get()
				}
			}
		}).then(data => {
			console.log('start', data);
			if (data.code === 200) {
				Analytics('New order', {OrderId: data.order.key_id});
				const cableType = _.get(data, 'order.order.cableType');
				if (data.group_domestic_id && cableType) {
					this.props.history.push(`/subscribe/${data.order.key_id}`);
				} else if (data.group_domestic_id && !cableType) {
					this.props.history.push(`/station?group=ok&cableType=pick`);
				} else {
					this.props.history.push(`/station`);
				}
			} else {
				this.props.history.push('/');
			}
		}).catch(error => {
			console.error(error);
			this.props.history.push('/');
		});
  }
  render() {
    return <Loading loading = {true} className="fill-bg" />
  }
};

export default Subscribe;