import ReactGA from 'react-ga';
import Cookies from 'js-cookie';
import getRenderer from './GPUModel';
import UAParser from 'ua-parser-js';
import jwt from 'jsonwebtoken';
import Settings from 'services/config/Settings';

export const getUniqueKey = () => {
	if (Cookies.get('wheeler') === undefined) {
		const generate = require('nanoid/generate');
		const wheeler = generate('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 15);
		Cookies.set('wheeler', wheeler, { expires: 365 });
		return wheeler;
	} else {
		return Cookies.get('wheeler');
	}
}

const getResolution = () => {
	const ratio = window.devicePixelRatio || 1;
	return (Math.min(window.screen.width, window.screen.height) * ratio)
			+ 'x' + (Math.max(window.screen.width, window.screen.height) * ratio);
}
const gpu = getRenderer();
const resolution = getResolution();
const isIos = () => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	return /iphone|ipad|ipod/.test(userAgent);
}
const guessiPhone = () => {
	const devices = [
		['a7', '640x1136', 'Apple iPhone 5/5s'],
    ['a7', '1536x2048', 'Apple iPad Air/Mini 2/Mini 3'],
    ['a8', '640x1136', 'Apple iPod touch (6th gen)'],
    ['a8', '750x1334', 'Apple iPhone 6'],
    ['a8', '1242x2208', 'Apple iPhone 6 Plus'],
    ['a8', '1536x2048', 'Apple iPad Air 2/Mini 4'],
    ['a9', '640x1136', 'Apple iPhone SE'],
    ['a9', '750x1334', 'Apple iPhone 6s'],
    ['a9', '1242x2208', 'Apple iPhone 6s Plus'],
    ['a9x', '1536x2048', 'Apple iPad Pro (1st gen 9.7-inch)'],
    ['a9x', '2048x2732', 'Apple iPad Pro (1st gen 12.9-inch)'],
    ['a10', '750x1334', 'Apple iPhone 7'],
    ['a10', '1242x2208', 'Apple iPhone 7 Plus'],
    ['a10x', '1668x2224', 'Apple iPad Pro (2th gen 10.5-inch)'],
    ['a10x', '2048x2732', 'Apple iPad Pro (2th gen 12.9-inch)'],
    ['a11', '750x1334', 'Apple iPhone 8'],
    ['a11', '1242x2208', 'Apple iPhone 8 Plus'],
    ['a11', '1125x2436', 'Apple iPhone X'],
    ['a12', '828x1792', 'Apple iPhone Xr'],
    ['a12', '1125x2436', 'Apple iPhone Xs'],
    ['a12', '1242x2688', 'Apple iPhone Xs Max'],
    ['a12x', '1668x2388', 'Apple iPad Pro (3rd gen 11-inch)'],
    ['a12x', '2048x2732', 'Apple iPad Pro (3rd gen 12.9-inch)']
	];
	var GPU, matches, res;
	
	if (gpu) {
		GPU = typeof gpu === 'string' ? gpu : (gpu.length ? gpu[0] : '');
		matches = GPU.match(/^apple\s+([_a-z0-9-]+)\s+gpu$/i);
		res = resolution;
	}
	
	
	let model = false;
	if (matches) {
		for (var i = 0; i < devices.length; i ++) {
			var device = devices[i];

			if (matches[1].toLowerCase() === device[0]
					&& res === device[1]) {
					model = device[2];
					break;
			}
		}
	}
	return model;
}
let model = null;
try {
	var parser = new UAParser();
	if (isIos()) {
		const fetchIphone = guessiPhone();
		if (fetchIphone) {
			model = fetchIphone;
		}
	}
	if (!model) {
		const device = parser.getDevice();
		if (device.vendor) {
			model = device.vendor;
		}
		if (device.model) {
			model = model && model.length ? `${model} ${device.model}` : device.model;
		}
		// alert(`model = ${model}`);
	}
} catch(err) {
	console.error('err', err)
}

let deviceToken = false;
export const getDeviceParams = () => {
	let gpuObj = '';
	try {
		if (gpu) {
			gpuObj = typeof gpu === 'string' ? gpu : JSON.stringify(gpu);
		}
	} catch(err) {
		console.error('err', err)
	}
	const body = {
		gpu: gpuObj,
		resolution: resolution,
		model: model
	};
	if (!deviceToken) {
		deviceToken = jwt.sign(body, Settings.jwtDeviceToken)
	}
	return deviceToken;
}

//console.log('analytics file');
export default (event, params = {}) => {
	/* eslint-disable */
	heap.track(event, params);
	/* eslint-enable */
	if (process.env.NODE_ENV !== "development") {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: event,
			payload: params
		});
		return ReactGA.event({
			category: 'User',
			action: event
		});	
	}
	
}