//import * as Stickers from 'scenarios/components/Stickers';

const HelloUser = {
	name: 'HelloUser',
	steps: [
		{
			action: 'func',
			name: 'HelloUserScreen'
		},
// 		{
// 			action: 'message',
// 			type: 'text',
// 			value: 'Welcome back!',
// 			timeout: 1000,
// 		},
// 		{
// 			action: 'message',
// 			type: 'sticker',
// 			value: Stickers.SupernovaSuccess,
// 			timeout: 1000,
// 			typing: false
// 		}
	]
};

export default HelloUser;