import React from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import fetchJSON from 'services/utils/fetchJSON';
import Loading from 'components/Loading';
import Settings from 'services/config/Settings';
import BackIcon from 'components/images/ic-back.svg';
import Analytics from 'services/utils/Analytics';
import Promo from '../components/Promo';
import PromoV2 from '../components/PromoV2';
import HowDoPlansWork from '../components/HowDoPlansWork';
import { t, Trans } from 'components/Translate/Translate';
import windowDimensions from 'react-window-dimensions';
import _ from 'lodash';

const defaultStatePromo = {
	promoInput: '',
	showLabel: true,
	showInput: false,
	submitDisabled: true,
	alert: {
    type: '',
    value: '',
    active: 0
  }
};

class SubscribeOrder extends React.Component {
	state = {
		plans: [],
		fetch: true,
		showModal: false,
		plan_id: null,
		promo_id: null,
		promo: {
			id: null,
			priceView: null,
			view_label: null,
			promo: false
		},
		who_pays_for_electrons: 'users',
		showPromo: true,
		promoActivated: false,
		order: {},
		disabledNextBtn: false,
		has_payment_methods: 0,
		promoInput: '',
		showLabel: true,
		showInput: false,
		submitDisabled: true,
		alert: {
	    type: '',
	    value: '',
	    active: 0
	  },
		promoChanged: false,
		promoSubmited: false,
		total_sessions: 10,
		free_option: false,
		venue: {},
		sessionBookingId: '',
		haveSemester: false,
		haveMonthly: false,
		currency_template: '$1',
		currency_grapheme: '$',
		termsAgree: false,
		request_payment_information: 1,
		agree_to_terms: false,
		firstTimeNoPayments: 0
	}
	componentDidMount = () => {
		const pageTitle = t('pageTitles.Order', { defaultValue: 'Order'});
		document.title = `${pageTitle} | ${Settings.title}`;
		const orderId = this.props.match.params.orderId;
		if (orderId) {
			this.subscribeStart(orderId);
		} else {
			this.props.history.push('/');
		}
  }
  subscribeStart = (orderId) => {
  	fetchJSON(`/api/v1/subscribe/process/${orderId}`, {
			method: 'get'
		}).then(data => {
			if (data.code === 200) {
				console.log(data);
				if (data.order.status === 'paid') {
					this.props.history.push(`/subscribe/${orderId}/purchase`);
					return false;
				}
				
				const order = data.order.order;
				
				if (data.freeFirstDay) {
					if (order.promo) {
						if (order.promo.view_label === "ONEDAYFREE" && data.free_option) {
							order.promo = {};
							order.promo_id = null;
						}
					}
				}
				let has_payment_methods = 0;
				
				if (data.user) {
					if (data.user.has_payment_methods) {
						has_payment_methods = 1;
					}
				}
				let termsAgree = false;
        if (data.user) {
          if (data.user.agree_to_terms) {
            termsAgree = true;
          }
        }

				const sessionBookingId = _.get(data, 'order.order.sessionBookingId');
				const group_id = _.get(data, 'order.order.group_id');
				console.log('group_id', group_id);

				if (!sessionBookingId) {
					this.props.history.push('/station');
					return false;
				}
				const currency_grapheme = _.get(data, 'order.order.currency_grapheme');
				const currency_template = _.get(data, 'order.order.currency_template');

				this.setState({
					plan_id: order.plan_id,
					promo: order.promo,
					promo_id: order.promo_id,
					order,
					total_sessions: data.total_sessions,
					free_option: data.free_option,
					who_pays_for_electrons: data.who_pays_for_electrons,
					request_payment_information: data.request_payment_information,
					firstTimeNoPayments: data.firstTimeNoPayments,
					has_payment_methods,
					sessionBookingId,
					currency_grapheme,
					currency_template,
					termsAgree,
					agree_to_terms: termsAgree
				}, () => {
					if (!termsAgree && data.who_pays_for_electrons === 'owner' && data.request_payment_information === 0) {
						this.setState({
							disabledNextBtn: true
						});
					}
				});
				
				if (data.who_pays_for_electrons === 'users') {
					Analytics('The user sees a list of plans');
					this.getPlansList();
				} else {
					Analytics('The user sees a free option');
					if (termsAgree && data.who_pays_for_electrons === 'owner' && data.request_payment_information === 0) {
						console.log('we can submit now!!');
						this.submitForFreeUse(true);
						return;
					}
					this.applyFreeAndGetVenue();
				}

			} else {
				this.props.history.push(`/subscribe`);
			}
		}).catch(error => {
			console.error(error);
			this.props.history.push('/');
		});
  }
  applyFreeAndGetVenue = () => {
  	const { group_id } = this.state.order;
  	if (!group_id) {
  		this.props.history.push(`/station`);
  		return;
  	}
		// const orderId = this.props.match.params.orderId;
		console.log('group_id', group_id)
		fetchJSON(Settings.endpoindApi+'/api/v1/groups/get/'+group_id, {
			method: 'get',
			headers: {
				Source: 'order_page'
			}
		}).then(data => {
			console.log('venues get', data);
			if (data.code === 200) {
				Analytics('Choose group - status: success', { group_id: group_id });
				const getElectrons = data.venue.state.electrons.get;
				if (getElectrons) {
					console.log('data.venue', data.venue);
					this.setState({
						fetch: false,
						showPromo: false,
						venue: data.venue
					});
				} else {
					Analytics('ERROR Group - No batteries found', { group_id: group_id });
					this.props.history.push('/station');
				}
			} else {
				Analytics('Choose group - status: error', { group_id: group_id });
				this.props.history.push('/station');
			}
		}).catch(error => {
			console.log(error);
			
		});
  }
  getPlansList = () => {
  	const { orderId } = this.props.match.params;
  	
		fetchJSON(`/api/v1/subscribe/plans/${orderId}`, {
			method: 'get'
		}).then(data => {
			if (data.code === 200) {
				const plans = [];
				const { currency_grapheme, currency_template, firstTimeNoPayments } = this.state;
				let haveSemester = false;
				let haveMonthly = false;

				data.plans.sort((a, b) => {
				    return a.view_index - b.view_index;
				}).forEach((plan) => {
					if (plan.view_index) {
						plan.priceView = currency_template;
						let toFixed = 2;
						if (currency_grapheme === '¥') {
							toFixed = 0;
						}
						plan.priceView = plan.priceView.replace('1', plan.price.toFixed(toFixed));
						plan.priceView = plan.priceView.replace('$', currency_grapheme);

						plan.extraPaymentPrice = '';
						plan.maxDailyCharge = '';

						if (plan.additional_payment_price) {
							plan.extraPaymentPrice = currency_template;
							plan.extraPaymentPrice = plan.extraPaymentPrice.replace('1', plan.additional_payment_price);
							plan.extraPaymentPrice = plan.extraPaymentPrice.replace('$', currency_grapheme);
						}

						if (plan.max_daily_charge) {
							plan.maxDailyCharge = currency_template;
							plan.maxDailyCharge = plan.maxDailyCharge.replace('1', plan.max_daily_charge);
							plan.maxDailyCharge = plan.maxDailyCharge.replace('$', currency_grapheme);
						}

						if (plan.key_id && plan.key_id.startsWith('main_')) {
							plan.key_id = plan.key_id.replace('main_', '');
						}
						let push = true;

						if (firstTimeNoPayments) {
							if (plan.key_id !== 'monthly') {
								//push = false;
								plan.highlighted = 0;
							} else {
								plan.highlighted = 1;
							}
						}

						if (push) {
							plans.push(plan);
						}
						
						if (plan.view_label === 'Semester') {
							haveSemester = true;
						}
						if (plan.key_id === 'monthly') {
							haveMonthly = true;
						}
					}
				});
				
				let showPromo = true;

				if (this.state.promo.id) {
					let newPlan = this.state.promo;
					newPlan.promo = true;
					plans.push(newPlan);
					showPromo = false;
				}
				console.log('plans', plans);
				
				this.setState({
					plans: plans,
					totalPlans: plans.length,
					haveSemester,
					haveMonthly,
					fetch: false,
					showPromo,
					promoActivated: !showPromo
				}, () => {
					if (showPromo) {
						if (Cookies.get('referral') !== undefined) {
							this.setState({
								showPromo: true,
								promoInput: Cookies.get('referral'),
					      error: '',
					      promoChanged: true,
					      promoSubmited: false,
					      submitDisabled: false
							}, () => {
								this.onPromoSubmit();
							});
						}
					}
				});
			} else {
				this.setState({
					fetch: false
				})
			}
		}).catch(error => {
			console.error(error);
			this.setState({
				fetch: false
			})
		});
  }
  onPlanChanged = (e) => {
  	this.setState({
      plan_id: parseInt(e.currentTarget.value, 10),
			promoActivated: false,
			showPromo: true,
			promo_id: null,
			promo: {
				id: null,
				priceView: null,
				view_label: null,
				promo: false
			}
    }, this.orderChanged);
  }
  
  addNewPlan = (promoPlan, promo_id) => {
		const { plans } = this.state;
		const freshPlans = [];
		plans.forEach(planObj => {
			if (planObj.promo === undefined) {
				freshPlans.push(planObj);
			}
		})
		freshPlans.push(promoPlan);
		this.setState({
			plans: freshPlans,
			plan_id: promoPlan.id,
			promo_id: promo_id,
			showPromo: false,
			promoActivated: true,
			promo: promoPlan
		}, this.orderChanged);
  }
  closeModal = () => {
  	Analytics('Close membership rules');
  	this.setState({
  		showModal: false
  	})
  }
  showModal = () => {
  	Analytics('Open membership rules');
  	this.setState({
  		showModal: true
  	})
  }
  orderChanged = (redirectNext = false) => {
		if (redirectNext) {
			const { promoChanged, promoSubmited } = this.state;
			if (promoChanged) {
				if (!promoSubmited) {
					this.onPromoSubmit(false, true);
					return;
				}
			}
			this.setState({
	  		disabledNextBtn: true
	  	});
		}
		const {
			promo,
			plan_id,
			promo_id,
			has_payment_methods,
			who_pays_for_electrons,
			request_payment_information
		} = this.state;

		const orderId = this.props.match.params.orderId;
		const body = {
			order: {
				promo,
				plan_id,
				promo_id
			},
			orderId: orderId
		};

		if (who_pays_for_electrons === 'users') {
			Analytics('Order has changed to ' + plan_id, body);
		} else {
			Analytics('Order has changed to FREE by organisation');
		}
		let doCheckout = false;
		if (who_pays_for_electrons === 'owner' && request_payment_information === 0) {
			doCheckout = true;
		}

		if (!doCheckout) {
			fetchJSON('/api/v1/subscribe/order/change', {
				method: 'post',
				body
			}).then(data => {
				if (redirectNext) {
					if (data.code === 200) {
						if (has_payment_methods === 0) {
							this.props.history.push(`/subscribe/${orderId}/payments`);
						} else {
							this.props.history.push(`/subscribe/${orderId}/review`);
						}
	        } else {
				  	this.props.history.push(`/subscribe`);
	        }
				}
			}).catch(error => {
				console.error(error);
				if (redirectNext) {
		   		this.props.history.push(`/subscribe`);
		   	}
	   	});
		} else {
			this.submitForFreeUse();
		}
  }
  submitForFreeUse = (auto = false) => {
  	const { orderId } = this.props.match.params;
  	const { sessionBookingId } = this.state;
		let body = {
			orderId: orderId
		};
		if (auto) {
			this.setState({
				fetch: true
			});
		}
		
		Analytics('submitForFreeUse');

  	fetchJSON('/api/v1/subscribe/checkout', {
			method: 'post',
			body
		}).then(data => {
			console.log(data);
			if (data.code === 200) {
				this.props.history.push(`/station/${sessionBookingId}/release`);
			} else {
				this.props.history.push(`/subscribe/${orderId}/fail`);
			}
		}).catch(error => {
			console.log(error);
			if (auto) {
				this.setState({
					fetch: false
				});
			}
			//this.serverFail('Server error catch', 'serverSide');
		});
  }
  renderPlans = () => {
  	const { plans, plan_id, promoActivated } = this.state;
  	if (plans.length) {
			return plans.map((plan, key) => {
				let show = true;
				if (plan.promo) {
					show = false;
					if (promoActivated) {
						show = true;
					}
				}
				let label = plan.view_label;
				switch (plan.view_label) {
					case 'Daily':
						label = 'For 1 day';
						break;
					case 'Monthly':
						label = 'For 1 month';
						break;
					case 'Annual':
						label = 'For 1 year';
						break;
					default:
				}
				return ( show ? <li className="sbOrder__item" key={key}>
						<input
							id={`plan-${plan.id}`}
							className="sbOrder__input"
							type="radio"
							name="plan"
							value={ plan.id }
							checked={ plan_id === plan.id }
							onChange={ this.onPlanChanged }
						/>
						<label htmlFor={`plan-${plan.id}`} className="sbOrder__label">
							<span className="sbOrder__icon"></span>
							<span className="sbOrder__title">{label}</span>
							<span className="sbOrder__price">{plan.priceView}</span>
						</label>
					</li> : null 
				);
			})
  	}

  	return null;
  }

  renderPlansV2 = () => {
  	const {
  		plans,
  		plan_id, 
  		promoActivated, 
  		free_option,
  		currency_grapheme,
  		currency_template,
  		// firstTimeNoPayments
		} = this.state;
  	if (plans.length) {
			return plans.map((plan, key) => {
				let show = true;
				let isPromo = false;
				if (plan.promo) {
					show = false;
					isPromo = true;
					if (promoActivated) {
						show = true;
					}
				}

				let label = plan.view_label;

				if (plan.view_label === "ONEDAYFREE" && free_option) {
					show = false;
				}

				// let dailyTitleRow = t('subscribe_order__daily_plan_title', { defaultValue:  'Daily Access <b>#PRICE#</b>' });
				// dailyTitleRow = dailyTitleRow.replace("#PRICE#", plan.priceView);

				let annualTitleRow = t('subscribe_order__annual_plan_title', { defaultValue:  'Annual Access <b>#PRICE#</b>' });
				annualTitleRow = annualTitleRow.replace("#PRICE#", plan.priceView);

				// let monthlyTitleRow = t('subscribe_order__monthly_plan_title_ver_2', { defaultValue:  'Monthly Access' });
				// monthlyTitleRow = monthlyTitleRow.replace("#PRICE#", plan.priceView);

				let semesterTitleRow = t('subscribe_order__semester_plan_title', { defaultValue:  'Semester for <b>#PRICE#</b>' });
				semesterTitleRow = semesterTitleRow.replace("#PRICE#", plan.priceView);
				let oldMonthlyPrice = currency_template;
				oldMonthlyPrice = oldMonthlyPrice.replace('1', '10');
				oldMonthlyPrice = oldMonthlyPrice.replace('$', currency_grapheme);
				
				let hourlyAdvantageOne = t('subscribe_order__hourly_advantage_one', { defaultValue:  '#PRICE# every additional hour.' });
				hourlyAdvantageOne = hourlyAdvantageOne.replace("#PRICE#", plan.extraPaymentPrice);
				let hourlyAdvantageTwo = t('subscribe_order__hourly_advantage_two', { defaultValue:  '#PRICE# maximum daily charge.' });
				hourlyAdvantageTwo = hourlyAdvantageTwo.replace("#PRICE#", plan.maxDailyCharge);
				
				let monthlyTitle = <Trans i18nKey="subscribe_order__monthly_plan_title_ver_2">Monthly Access</Trans>
				

				return (
					show ?
						<li className="sbOrderV2__item" key={key}>
							<input
								id={`plan-${plan.id}`}
								className="sbOrderV2__input"
								type="radio"
								name="plan"
								value={ plan.id }
								checked={ plan_id === plan.id }
								onChange={ this.onPlanChanged }
							/>
							{ plan.key_id === "hourly_rate" ?
								<label htmlFor={`plan-${plan.id}`} className={"sbOrderV2__label " + (plan.highlighted ? 'sbOrderV2__label--unlimited' : '')}>
									<span className="sbOrderV2__title-group">
										<span className="sbOrderV2__title">
											<Trans i18nKey="subscribe_order__hourly_rate_first_120">First 2 hours</Trans> <b>{plan.priceView}</b>
										</span>
										<span className="sbOrderV2__description-group">
											<ul className="sbOrderV2__descriptionUl">
												<li className="sbOrderV2__descriptionLi">{hourlyAdvantageOne}</li>
												<li className="sbOrderV2__descriptionLi">{hourlyAdvantageTwo}</li>
											</ul>
										</span>
									</span>
									<span className="sbOrderV2__icon-group">
										<span className="sbOrderV2__icon"></span>
									</span>
								</label>
							: null }
							{ plan.view_label === 'Daily' ?
								<label htmlFor={`plan-${plan.id}`} className={"sbOrderV2__label " + (plan.highlighted ? 'sbOrderV2__label--unlimited' : '')}>
									<span className="sbOrderV2__title-group">
										<span className="sbOrderV2__title">
											<Trans i18nKey="subscribe_order__daily_plan_title_v2">Daily Access</Trans> <b className={free_option ? "sbOrderV2__crossedOut" : null}>{plan.priceView}</b>
											{free_option ? <span className="sbOrderV2__freeOption"><Trans i18nKey="subscribe_order__sale_word_free">Free</Trans></span> : null}
										</span>
										<span className="sbOrderV2__description">
											{free_option ? 
												<Trans i18nKey="subscribe_order__daily_offer_free">Try it for free today!</Trans>
												: <Trans i18nKey="subscribe_order__daily_offer">Limited access for 1 day</Trans> }
											</span>
									</span>
									<span className="sbOrderV2__icon-group">
										<span className="sbOrderV2__icon"></span>
									</span>
								</label>
							: null }
							
							{ plan.view_label === 'Monthly'  ?
								<label htmlFor={`plan-${plan.id}`} className={"sbOrderV2__label " + (plan.highlighted ? 'sbOrderV2__label--unlimited' : '')}>
									<span className="sbOrderV2__title-group">
										<span className="sbOrderV2__title">{monthlyTitle}
											{plan.price < 7.99 ?
												<React.Fragment>
													<span className="sbOrderV2__oldPrice">{oldMonthlyPrice}</span>
													<span className="sbOrderV2__newPrice">{plan.priceView}</span>
												</React.Fragment>
											: <span className="sbOrderV2__newPrice">{plan.priceView}</span> }</span>
										<span className="sbOrderV2__description"><Trans i18nKey="subscribe_order__montly_offer_3">Grab, Go, Return Anywhere, Repeat.</Trans></span>
										<span className="sbOrderV2__description-group">
											<ul className="sbOrderV2__descriptionUl">
												<li className="sbOrderV2__descriptionLi"><Trans i18nKey="subscribe_order__monthly_offer_1">1 month with no limit of charges</Trans></li>
												<li className="sbOrderV2__descriptionLi"><Trans i18nKey="subscribe_order__monthly_offer_3">Fully refundable for 2 weeks</Trans></li>
											</ul>
										</span>
									</span>
									<span className="sbOrderV2__icon-group">
										<span className="sbOrderV2__icon"></span>
									</span>
								</label>
							: null }

							{ plan.view_label === 'Annual' ?
								<label htmlFor={`plan-${plan.id}`} className={"sbOrderV2__label " + (plan.highlighted ? 'sbOrderV2__label--unlimited' : '')}>
									<span className="sbOrderV2__title-group">
										<span className="sbOrderV2__title" dangerouslySetInnerHTML={{__html: annualTitleRow}}></span>
										<span className="sbOrderV2__description"><Trans i18nKey="subscribe_order__annual_offer">Unlimited access for 1 year</Trans></span>
									</span>
									<span className="sbOrderV2__icon-group">
										<span className="sbOrderV2__icon"></span>
									</span>
								</label>
							: null }
							
							{ plan.view_label === 'Semester' ?
								<label htmlFor={`plan-${plan.id}`} className={"sbOrderV2__label " + (plan.highlighted ? 'sbOrderV2__label--unlimited' : '')}>
									<span className="sbOrderV2__title-group">
										<span className="sbOrderV2__title"><span dangerouslySetInnerHTML={{__html: semesterTitleRow}}></span><span className="sbOrderV2__saleBundle">Save 50%</span></span>
										<span className="sbOrderV2__description"><Trans i18nKey="subscribe_order__semester_offer">Get 6 Month Unlimited Access</Trans></span>
										<span className="sbOrderV2__description-group">
											<ul className="sbOrderV2__descriptionUl">
												<li className="sbOrderV2__descriptionLi"><Trans i18nKey="subscribe_order__semester_offer_1">Fully refundable for 2 weeks</Trans></li>
												<li className="sbOrderV2__descriptionLi"><Trans i18nKey="subscribe_order__semester_offer_2">Unlimited amount of sessions</Trans></li>
											</ul>
										</span>
									</span>
									<span className="sbOrderV2__icon-group sbOrderV2__icon-group--top">
										<span className="sbOrderV2__icon"></span>
									</span>
								</label>
							: null }

							{ isPromo ? 
								<label htmlFor={`plan-${plan.id}`} className="sbOrderV2__label sbOrderV2__label--promo">
									<span className="sbOrderV2__title-group">
										<span className="sbOrderV2__title"><Trans i18nKey="subscribe_order__promo_plan_title">Promo access:</Trans> {plan.priceView}</span>
									</span>
									<span className="sbOrderV2__icon-group">
										<span className="sbOrderV2__icon"></span>
									</span>
								</label>
							: null }
						</li>
					: null 
				);
			})
  	}

  	return null;
  }

  onPromoChanged = (e) => {
		const promoInput = e.currentTarget.value;
  	this.setState({
      promoInput,
      error: '',
      promoChanged: promoInput.length ? true : false,
      promoSubmited: false,
      submitDisabled: promoInput.length ? false : true
    });
  }
  onPromoFocus = () => {
		this.setState({
      showLabel: false,
      showInput: true,
      error: ''
    });
  }
  onPromoBlur = () => {
  	const { promoInput } = this.state;
		this.setState({
      showLabel: true,
      showInput: promoInput.length ? true : false
    });
  }
  notify = (value, type = 'danger', timeout = 3000) => {

    this.setState({
      alert: {
        type: type,
        value: value,
        active: 1
      }
    });

    setTimeout(() => {
      this.setState({
        alert: {
          type: '',
          value: '',
          active: 0
        }
      });
    }, timeout)
  }
  onPromoSubmit = (e, doubleSubmit = false) => {
  	if (e) {
  		e.preventDefault();
  	}
		const { promoInput } = this.state;

		if (!promoInput.length) return false;

		this.setState({
      isFetching: true,
      promoSubmited: false
    });
		
		fetchJSON('/api/v1/subscribe/promocode', {
			method: 'post',
			body: {
				promocode_key: promoInput
			},
			headers: {
				Source: 'Order'
			}
		}).then(data => {
        console.log(data);
        if (data.code === 200) {
        	Analytics('The promo code applied', { Promo: promoInput });
          this.notify(t('subscribe_order__promo_applied', { defaultValue:  'The promo code applied' }), 'success', 3000);
					setTimeout(() => {
						this.setState(defaultStatePromo);
					}, 1000)
          this.addNewPlan(data.promo, data.promo_id);
          if (doubleSubmit) {
          	this.setState({
		          isFetching: false,
		          promoSubmited: true
		        }, this.orderChanged(true));
		        return;
        	}
        } else {
        	let errorMessage = t('subscribe_order__promo_error_1', { defaultValue:  'The promo code not found or not valid' });
        	Cookies.remove('referral');
        	switch (data.code) {
        		case 410:
							errorMessage = t('subscribe_order__promo_error_expired', { defaultValue:  'The promo code is expired' });
							break;
						case 412:
							errorMessage = t('subscribe_order__promo_error_expired', { defaultValue:  'The promo code is expired' });
							break;
						case 403:
							errorMessage = t('subscribe_order__promo_error_403', { defaultValue:  'You have already used this promo code' });
							break;
						case 405:
							errorMessage = t('subscribe_order__promo_error_405', { defaultValue:  'You can\'t use the referral promotional code more than 1 time' });
							break;
						default:
        	}
        	Analytics(errorMessage, {Promo: promoInput});
          this.notify(errorMessage, 'danger', 5000);
        }
        this.setState({
          isFetching: false,
          promoSubmited: true
        });
     }).catch(data => {
        this.setState({
          isFetching: false,
          promoSubmited: true
        });
        this.notify('Something went wrong');
     });
  }
	termsAgree = (e) => {
    if (e.currentTarget.checked) {
      Analytics('Agree to terms and condition');
    } else {
      Analytics('Disagree to terms and condition');
    }
    
    const orderId = this.props.match.params.orderId;
    const body = {
      orderId: orderId,
      user: {
        agree_to_terms: e.currentTarget.checked ? 1 : 0
      }
    };

    this.setState({
      termsAgree: e.currentTarget.checked,
      disabledNextBtn: !e.currentTarget.checked
    })

    fetchJSON('/api/v1/subscribe/order/change', {
      method: 'post',
      body: body 
    }).then(data => {
      console.log('Saved OK', data);
    }).catch(error => {
      console.error(error);
    });
  }
  render() {
  	const {
  		fetch,
  		showModal,
  		showPromo,
  		disabledNextBtn,
			promoInput,
			showLabel,
			showInput,
			submitDisabled,
			alert,
			who_pays_for_electrons,
			request_payment_information,
			venue,
			sessionBookingId,
			termsAgree,
			agree_to_terms,
			firstTimeNoPayments,
			totalPlans,
			haveMonthly
  	} = this.state;
  	const screenVersion = 2;
  	let subscribe_order__title = t("subscribe_order__title", { defaultValue:  'Choose charging<br/> option' });
		let titleStyle = { paddingBottom: 0 };

		if (firstTimeNoPayments) {
			subscribe_order__title = t("subscribe.firstTimeNoPayments.title", { defaultValue:  'Free yourself with Electron to Go' });
			titleStyle = {
				paddingBottom: '20px',
				paddingTop: '50px'
			}
		}
    // const orderId = this.props.match.params.orderId;
    let cover = false;
    if (typeof venue.photos === 'object') {
    	cover = venue.photos.length ? venue.photos[0] : false;
    }
    let containerStyle = {
      width: this.props.width,
      height: this.props.height
    }
		

    let agreeTermsText = t('subscribe__review_agree_terms_privacy', { defaultValue:  'I agree #TERMS_LINK_OPEN#Terms and Conditions#TERMS_LINK_CLOSE# and #PRIVACY_LINK_OPEN#Privacy Policy#PRIVACY_LINK_CLOSE#' });
    agreeTermsText = `<span class="sbReview__checkbox-icon"></span>${agreeTermsText}`
    agreeTermsText = agreeTermsText.replace("#TERMS_LINK_OPEN#", '<a href="https://electrontogo.com/terms" rel="noopener noreferrer" class="sbReview__link" target="_blank">');
    agreeTermsText = agreeTermsText.replace("#TERMS_LINK_CLOSE#", '</a>');
    agreeTermsText = agreeTermsText.replace("#PRIVACY_LINK_OPEN#", '<a href="https://electrontogo.com/privacy" class="sbReview__link" rel="noopener noreferrer" target="_blank">');
    agreeTermsText = agreeTermsText.replace("#PRIVACY_LINK_CLOSE#", '</a>');
		
		let buttonSubmitText = <Trans i18nKey="subscribe_order__continue_btn">Continue</Trans>;
	
		if (firstTimeNoPayments && totalPlans === 1 && haveMonthly) {
			buttonSubmitText = t('subscribe.firstTimeNoPayments.submit', { defaultValue:  'GET MONTHLY ACCESS' });
		}
		if (who_pays_for_electrons === 'owner') {
			buttonSubmitText = t('subscribe_order__get_free_charger', { defaultValue:  'GET A FREE CHARGER' });
		}
		const submitButton = <button type="button" disabled={disabledNextBtn} onClick={e => this.orderChanged(true)} className="sbOrder__action-btn">
		{buttonSubmitText}
    </button>;
			
		let labelPromoStyle = {
			zIndex: '-999',
			visibility: 'hidden',
			opacity: 0
		};
		let styleHead = {};
		
		if (showPromo && showLabel && !promoInput.length) {
			labelPromoStyle = {};
		}
		if (firstTimeNoPayments) {
			labelPromoStyle.paddingBottom = '20px';
			labelPromoStyle.textAlign = 'center';

			styleHead = {
				paddingLeft: 0,
				paddingRight: 0
			}
		}	

		const labelPromo = <label
  	className="sbOrderPromoV2__labelV2"
  	htmlFor="promo"
  	style={labelPromoStyle}
  	><Trans i18nKey="subscribe_order__have_promo">I have a promocode</Trans></label>;

    return (
    	<React.Fragment>
	    	<Loading loading = { fetch } className="fill-bg" />
	      <div className={"subscribe__container" + (who_pays_for_electrons === 'owner' ? " ownerScreen" : "")} style={who_pays_for_electrons === 'owner' ? containerStyle : {}}>
					<div className="subscribe__header" style={styleHead}>
						<Link to={`/station/${sessionBookingId}?redirect=no`} className="subscribe__back-btn">
							<img src={BackIcon} alt="back icon" />
						</Link>
						{ who_pays_for_electrons !== 'owner' ? <div className="subscribe__title" dangerouslySetInnerHTML={{__html: subscribe_order__title}} style={titleStyle}></div> : null }
					</div>
					<div className="subscribe__body">
						{ screenVersion === 2 ?
						<React.Fragment>
							{who_pays_for_electrons !== 'owner' ? <ul className="sbOrderV2__list">
								{this.renderPlansV2()}
								<li>
									{ firstTimeNoPayments ? labelPromo : null }
								</li>
								<PromoV2
									addNewPlan={this.addNewPlan}
									showPromo={showPromo}
									promo={promoInput}
									showLabel={showLabel}
									showInput={showInput}
									submitDisabled={submitDisabled}
									alert={alert}
									onSubmit={this.onPromoSubmit}
									onChanged={this.onPromoChanged}
									onFocus={this.onPromoFocus}
									onBlur={this.onPromoBlur}
									/>
							</ul> : null }
							{ who_pays_for_electrons === 'owner' ?
								<div className="subscribeByOwner">
									<div className="subscribeByOwner__title" style={{ fontWeight: firstTimeNoPayments ? 'normal' : '', fontSize: '26px'}}>
										<div>
											<Trans i18nKey="orderScreen.freeFirstTime.title">We are providing Electron first use for Free.</Trans>
											<br/><br/>
											<Trans i18nKey="orderScreen.freeFirstTime.desc">But we expect you to return it within 24 hours</Trans>
										</div>
									</div>
									{ false ? <div className="subscribeByOwner__imageWrap">
										{cover ? <img className="subscribeByOwner__image" src={cover} alt="venue" /> : null }
									</div> : null }
								</div>
							: null }	
							<div className={"sbOrder__action-row sbOrderV2__action-row" + (who_pays_for_electrons === 'owner' ? " stickToBottom" : "")}>
								{ who_pays_for_electrons === 'owner' && request_payment_information === 0 && !agree_to_terms ?
								<div className="sbReview__terms" style={{
									paddingBottom: '30px',
									textAlign: 'left',
									maxWwidth: '400px',
									margin: '0 auto'
								}}>
	                <input
	                  id="termsAgree"
	                  type="checkbox"
	                  name="terms"
	                  checked={termsAgree}
	                  onChange={this.termsAgree}
	                  className="sbReview__checkbox-input"
	                />
	                <label className="sbReview__terms-text" htmlFor="termsAgree" dangerouslySetInnerHTML={{__html: agreeTermsText}}></label>
	              </div>
	              : null }
									<React.Fragment>
										{submitButton}
										{ !firstTimeNoPayments ? labelPromo : null }
									</React.Fragment>
				      </div>
						</React.Fragment>
						: null }
						{ screenVersion === 1 ?
						<React.Fragment>
						<ul className="sbOrder__list">
							{this.renderPlans()}
							<Promo
								addNewPlan={this.addNewPlan}
								showPromo={showPromo}
								promo={promoInput}
								showLabel={showLabel}
								showInput={showInput}
								submitDisabled={submitDisabled}
								alert={alert}
								onSubmit={this.onPromoSubmit}
								onChanged={this.onPromoChanged}
								onFocus={this.onPromoFocus}
								onBlur={this.onPromoBlur}
								/>
						</ul>
						<div className="sbOrder__help-wrap noMargin">
				      <button type="button" onClick={this.showModal} className="sbOrder__help brightly">
				      <Trans i18nKey="subscribe_order__how_membership_works">How does membership work?</Trans>
				      </button>
			      </div>
			      <div className="sbOrder__action-row">
							<button type="button" disabled={disabledNextBtn} onClick={e => this.orderChanged(true)} className="sbOrder__action-btn">
							<Trans i18nKey="subscribe_order__next">Next</Trans>
				      </button>
			      </div>
			      </React.Fragment>
			      : null }
					</div>
	      </div>
	      { showModal ? <HowDoPlansWork closeModal={this.closeModal} /> : null }
      </React.Fragment>
    )
  }
};

	// { !firstTimeNoPayments ? <div>
	// 										<Trans i18nKey="orderScreen.freeUse.title">This location provides Electron®&nbsp;phone chargers for FREE.</Trans>
	// 										<br/><br/>
	// 										<Trans i18nKey="orderScreen.freeUse.descr"><span>Return Electron</span> within 2 hours.</Trans>
	// 									</div> :  }

export default windowDimensions()(SubscribeOrder);
