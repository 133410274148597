import * as Stickers from 'scenarios/components/Stickers';
import { t } from 'components/Translate/Translate';

const Supernova = {
	name: 'supernova',
	steps: [
		{
			action: 'message',
			type: 'text',
			value: 'Please type 6 digit code from manual near Supernova station',
			content: 'supernova-type-code',
			timeout: 500,
		},
		{
			action: 'message',
			type: 'image',
			value: Stickers.Supernova,
			timeout: 500,
			typing: false
		},
		{
			action: 'func',
			name: 'numberSupernova',
			typing: false,
			controller: {
				status: true,
				placeholder: t('supernova-type-code_input_placeholder', { defaultValue:  'Type code here' }),
				label: t('supernova-type-code_input_label_text', { defaultValue:  'Click here to type station code' }),
				value: '',
				type: 'tel'
			}
		}
	]
};

export default Supernova