import React from 'react';
import './styles.less';

import Picture from './photo_2018-03-05_01-20-47.jpg';
import closeIcon from 'components/images/close-icon.svg';

export default (props) => {
	
	class AdsPromoScreen extends React.Component {
		state = {
			elapsed: 5
		}
		closeAds = () => {
			props.propSetState({
				activeHeader: true,
				fullScreenStore: props.module ? props.module.prevScreen : null
			})
		}
		componentDidMount = () => {
			// componentDidMount is called by react when the component 
			// has been rendered on the page. We can set the interval here:
			this.timer = setInterval(this.tick, 1000);
		}
		componentWillUnmount = () => {
			// This method is called immediately before the component is removed
			// from the page and destroyed. We can clear the interval here:
			clearInterval(this.timer);
		}
		tick = () => {
			let elapsed = this.state.elapsed;
			elapsed = elapsed - 1;
			this.setState({
				elapsed
			});
			if (!elapsed) {
				clearInterval(this.timer);
			}
		}
		render() {
			return (
				<div className="modal__box" style={{display: 'block'}}>
					<div className="modal__backdrop"></div>
					<div className="modal__container">
						<div className="modal">
							<div className="reclame">
								<div className={"reclame__loader-bar " + (!this.state.elapsed ? 'hide' : '') }>
									<span>You can close in {this.state.elapsed} second...</span>
								</div>
								{ !this.state.elapsed ? <div className="reclame__close" onClick={this.closeAds}>
									<img className="reclame__icon" src={closeIcon} alt="close" />
								</div> : null }
								<div className="reclame__fullimg" style={{'backgroundImage':`url(${Picture})`}} ></div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	};
	props.propSetState({
		dialogState: 0,
		showUserController: false,
		activeHeader: false,
		fullScreenStore: <AdsPromoScreen />
	});
	
}