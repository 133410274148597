import React from 'react';

class Login extends React.Component {
	componentDidMount = () => {
		this.props.history.push('/?m=login');
  }
  render() {
    return null
  }
};

export default Login;