import React from 'react';
import moment from 'moment';
import fetchJSON from 'services/utils/fetchJSON';
import Settings from 'services/config/Settings';
import Loading from 'components/Loading';
import { Trans, t } from 'components/Translate/Translate';
import PointHistory from 'components/images/history-points.svg';

require("moment-duration-format");

class Charges extends React.Component {
	state = {
		loading: true,
		history: [],
		totalHours: 0
	}
	componentDidMount = () => {
    const pageTitle = t('pageTitles.myCharges', { defaultValue: 'My charges'});
		document.title = `${pageTitle} | ${Settings.title}`;
		this.getChargeHistory();
  }
	getChargeHistory = () => {
    fetchJSON('/api/v1/users/electrons/history', {
			method: 'get'
		}).then(data => {
			console.log(data);
			if (data.code === 200) {
				let totalHours = 0;
				if (data.history.length) {
					let opentime;
		  		let closetime;
		  		let ms;
		  		let dur;
		  		let hoursSpend;
					data.history.forEach(item => {
						opentime = moment.unix(item.opentime);
						closetime = item.closetime ? moment.unix(item.closetime) : moment();
						ms = closetime.diff(opentime);
						dur = moment.duration(ms);
						hoursSpend = parseInt((dur._milliseconds/(1000*60*60)), 10);
						totalHours = totalHours + hoursSpend;
					})
				}
				this.setState({
					loading: false,
					history: data.history,
					totalHours
				})
			}
     }).catch(error => {
				console.log(error);
				this.setState({
					loading: false
				});
     });
  }
  chargesHistory = () => {
  	if (this.state.history.length) {
  		let opentime;
  		let closetime;
  		let ms;
  		let dur;
  		let durationSession;
  		let timeStart;
  		return this.state.history.map((item, index) => {
				opentime = moment.unix(item.opentime);
				closetime = item.closetime ? moment.unix(item.closetime) : moment();
				ms = closetime.diff(opentime);
				dur = moment.duration(ms);
				durationSession = dur.format("h [h] m [min]");
				timeStart = moment(opentime).format("DD MMM YY, h:mm a");
  			return (
  				<div key={index} className="charges__colums">
            <div className="charges__colum-left">
              <div className="charges__information">
                <div className="charges__logo-point">
                	<img alt="" src={PointHistory} className="charges__logo-img" />
                </div>
                <div className="charges__accurate-info">{timeStart}</div>
              </div>
              <div className="charges__desc">
                <div className="charges__border-line"></div>
              </div>
              <div className="charges__desc-items">
                <div className="charges__item"><Trans i18nKey="my_charges_page__electron_id">Electron id</Trans>: {item.electron_id}</div>
              </div>
            </div>
            <div className="charges__colum-right">
              <div className="charges__duration">{durationSession}</div>
            </div>
          </div>
  			)
  		})
  	} else {
  		return (
  			<div className="wallet__sub-name"><Trans i18nKey="my_charges_page__no_charges_yet">You have no active charges.</Trans></div>
			);
  	}
  }
  render() {
    return (
     	<div className="wallet">
    		<Loading loading = {this.state.loading} className="fill-bg" />
      	<div className="wallet__container red-bg">
          <div className="wallet__header mgn-zero">
	          <div className="wallet__titlePage"><Trans i18nKey="my_charges_page__title">My charges</Trans></div>
	        </div>
          <div className="wallet__charges-wallet">
            <div className="wallet__box-group">
              <div className="wallet__info-box">
                <div className="wallet__box">
                  <div className="wallet__number">{ this.state.history ? this.state.history.length : 0 }</div>
                  <div className="wallet__meaning"><Trans i18nKey="my_charges_page__amount_charges_word">Charges</Trans></div>
                </div>
              </div>
              <div className="wallet__info-box">
                <div className="wallet__box">
                  <div className="wallet__number">{ this.state.totalHours || 0 }</div>
                  <div className="wallet__meaning"><Trans i18nKey="my_charges_page__amount_hours_word">Hours</Trans></div>
                </div>
              </div>
            </div>
          </div>
        </div>
				<div className="wallet__container">
					<div className="charges">
					  <div className="charges__detail">
					    <div className="charges__colum-group">
					      { this.chargesHistory() }
					    </div>
					  </div>
					</div>
				</div>
	    </div>
    )
  }
};

export default Charges;