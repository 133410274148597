/*
 * API for communicating with the server via socket.io
 * (both proactive and reactive)
 */
import io from 'socket.io-client';

import Notify from 'services/utils/Notify';
import Auth from 'services/utils/Auth';

// import static env
import Settings from 'services/config/Settings';

class Socket {
  constructor() {
    this.endpoint = Settings.api.endpoint;
    this.socket = io(Settings.api.socketUrl, {
      'query': 'token=' + Auth.getToken(),
      'timeout': '5000'
    });
    this.id = this.socket.id;
    this.socket.on('connect', () => {
      console.log(`Connected to socket.io with id ${this.socket.id}`);
    });
    
    this.socket.on('error', (err) => {
      console.log('error', err);
    });
    this.socket.on('notifyClient', (notification) => {
      Notify(notification.text, notification.type);
    });

  }
  emit(k, v) {
    this.socket.emit(k, v);
  }
}

export default Socket;
