import React from 'react';
import { Link } from 'react-router-dom';
import BackIcon from 'components/images/ic-back.svg';
import { Trans } from 'components/Translate/Translate';
import Loading from 'components/Loading';

class SubscribeProcess extends React.Component {
  componentDidMount = () => {

  }
  render() {
    const { screenKey, orderId } = this.props;
    return (
      <div className={"subscribe__container "+(screenKey === 'process' ? 'active' : 'hide')}>
        <div className="subscribe__header">
          <Link to={"/subscribe/"+orderId+"/review"} className="subscribe__back-btn">
            <img src={BackIcon} alt="back icon" />
          </Link>
          <div className="subscribe__title">
            <Trans i18nKey="subscribe-process-title">Payment</Trans>
          </div>
          <p className="subscribe__description"><Trans i18nKey="subscribe-process-description">Payment is being processed..</Trans></p>
        </div>
        <div className="subscribe__body sbReview__loading">
          <Loading loading = { true } full={false} />
        </div>
      </div>
    )
  }
};

export default SubscribeProcess;