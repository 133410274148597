import React from 'react';
import Cookies from 'js-cookie';
import listOfLanguages from 'components/Translate/dictionaries';
import getUserLocale from 'get-user-locale';
import 'font-awesome/css/font-awesome.css';
import createjs from 'preload-js';
import covidWarning from 'components/images/gifs/clean-your-electron-warning.gif';
import moment from 'moment-timezone';

class App extends React.Component {
	constructor() {
		let language = getUserLocale();
		if (language) { language = language.substr(0, 2); }

		const browserLanguage = listOfLanguages.includes(language) ? language : 'en';
		const savedLanguage = Cookies.get('app_language');
		if (!savedLanguage) {
			Cookies.set('app_language', browserLanguage, { expires: 365 });
			if (browserLanguage !== 'en') {
				window.location.reload(false);
			}
		}

		moment.locale(savedLanguage)
		super();

		const preloadContent = new createjs.LoadQueue();
	  const imagesList = [
	    covidWarning,
	  ];
	  imagesList.forEach((image) => {
	    preloadContent.loadFile(image);
	  });
	}
  render() {
    return (
			<React.Fragment>
				{ this.props.children }
			</React.Fragment>
    )
  }
};

export default App;