export default (props) => {
	props.propSetState({
		dialogState: 1
	})
	const userActions = {
		active: true,
		label: 'Choose station to get Juice!',
		buttons: [
			{
				text: 'Find on map',
				func: (e) => {
					// show ma
					props.propSetState({ 
						modalMap: true
					})
				}
			},
			{
				text: 'Near station? Enter code',
				func: (e) => {
					// select supernova
					props.userWriteToDialog('Enter station code');
					props.propSetState({ 
						userActions: {}
					}, () => {
						props.moveConversation({ name: 'supernova', step: 2 });
					});
				}
			}
		]
	}
	
	props.setState({ 
		userActions
	});
	
}