import React from 'react';
import Analytics from 'services/utils/Analytics';
import Settings from 'services/config/Settings';
import fetchJSON from 'services/utils/fetchJSON';
import GroupId from 'services/utils/GroupId';

class ExpressLink extends React.Component {
	componentDidMount = () => {
		if (this.props.match.params) {
			const groupId = this.props.match.params.groupId ? this.props.match.params.groupId : false;
			if (groupId) {
				Analytics('User used ExpressLink', { GroupId: groupId });
				fetchJSON(Settings.endpoindApi+'/api/v1/groups/get/'+groupId, {
					method: 'get',
					headers: {
						Source: 'express_link'
					}
				}).then(data => {
					if (data.code === 200) {
						Analytics('Сorrect group id from ExpressLink', { GroupId: groupId });
						GroupId.add(groupId);
						this.props.history.push(`/station?id=${groupId}`);
					} else {
						this.goToFrontPage();
					}
				})
				.catch(error => {
					console.log(error);
					this.goToFrontPage();
				});
			} else {
				this.goToFrontPage();
			}
		} else {
			this.goToFrontPage();
		}
  }
  goToFrontPage() {
  	Analytics('Group id from ExpressLink is invalid');
  	this.props.history.push('/');
  }
  render() {
  	return null;
  }
  
}


export default ExpressLink;