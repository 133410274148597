import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Settings from 'services/config/Settings';
import fetchJSON from 'services/utils/fetchJSON';
import Loading from 'components/Loading';
import { t, Trans } from 'components/Translate/Translate';

require("moment-duration-format");

class Payments extends React.Component {
	state = {
		loading: true,
		modalPayment: false,
		setState: this.propSetState,
  	propSetState: this.propSetState,
  	price: '1',
  	autoRenewChecked: true,
  	payment_methods: [],
  	user: {},
  	loadingChangeMethod: false,
  	billing: {},
  	plans: {}
	}
	componentDidMount = () => {
		const pageTitle = t('pageTitles.payments', { defaultValue: 'Payments'});
		document.title = `${pageTitle} | ${Settings.title}`;

		return this.getPlanList().then(plans => {
			const plansById = {};
			plans.forEach(plan => {
				plansById[plan.id] = plan.title;
			});
			this.setState({
				plans: plansById
			})
			this.welcomeInfo()
		});
		
  }

  welcomeInfo = () => {
  	fetchJSON('/api/v1/welcome', {
			method: 'post'
		}).then(data => {
				console.log('welcome', data);
				if (data.code === 200 && data.user) {
					this.setState({
						user: data.user,
						billing: data.user.billing
					}, () => {
						this.loadPaymentMethods();
					})
				} else {
					this.props.history.push('/');
				}
		 }).catch(error => {
				console.log('error welcome', error);
				// this.props.history.push('/');
		});
  }
  getPlanList() {
  	return new Promise((resolve, reject) => {
  		fetchJSON('/api/v1/braintree/plans/get', {
				method: 'get'
			}).then(data => {
				if (data.code === 200) {
					return resolve(data.plans);
				} else {
					reject(false);
				}
			}).catch(error => {
				console.error(error);
				return reject(false);
			});
  	})
  }
  loadPaymentMethods = () => {
  	fetchJSON('/api/v1/braintree/payment_methods', {
			method: 'get'
		}).then(data => {
			console.log(data);
			if (data.code === 200) {	
				this.setState({
					loading: false,
					payment_methods: data.payment_methods,
					loadingChangeMethod: false
				})
			}
     }).catch(error => {
				console.log(error);
				this.setState({
					loading: false
				});
     });
  }
  openCards = () => {
  	this.setState({
  		modalPayment: true
  	})
  }
  propSetState = (set, next = false) => {
  	this.setState(set, next)
  }
  sendMessageToDialog = (module) => {
  	console.log(module);
  }
  userWriteToDialog = (module) => {
  	console.log(module);
  }
  moveConversation = (module) => {
  	console.log(module);
  }
  AutoRenewSub = event => {
  	this.setState({
  		autoRenewChecked: event.target.checked
  	});
  }
  sendNewTokenPayment = (newToken, oldToken) => {
		fetchJSON('/api/v1/braintree/payment_methods/set_default', {
			method: 'post',
			body: {
				token: newToken
			}
		}).then(data => {
			const payment_methods = this.state.payment_methods;
			if (data.code === 200) {
				payment_methods.forEach(method => {
		  		if (method.token === newToken) {
		  			method.default = true;
		  		} else {
		  			method.default = false;
		  		}
		  	})
			} else {
				payment_methods.forEach(method => {
		  		if (method.token === oldToken) {
		  			method.default = true;
		  		} else {
		  			method.default = false;
		  		}
		  	})
			}
			this.setState({
	  		loadingChangeMethod: false,
	  		payment_methods
	  	})
     }).catch(error => {
				console.log(error);
				this.setState({
					loadingChangeMethod: false
				});
     });
  }
  changeDefaultMethod = event => {
		const payment_methods = this.state.payment_methods;
		let newToken;
		let oldToken;
  	payment_methods.forEach(method => {
  		if (method.token === event.target.value) {
  			newToken = method.token;
  		}
  		if (method.default) {
  			oldToken = method.token;
  		}
  	})
  	this.setState({
  		loadingChangeMethod: true
  	}, this.sendNewTokenPayment(newToken, oldToken));
  }
  removeMethod = (event) => {
  	/*eslint-disable */
  	if (confirm(t('payments_page__delete_payment_method_question', { defaultValue:  'Delete payment method?' }))) {
  	/*eslint-enable */
  		this.setState({
  			loadingChangeMethod: true
  		});
  		fetchJSON('/api/v1/braintree/payment_methods/remove', {
				method: 'post',
				body: {
					token: event.target.id
				}
			}).then(data => {
				console.log(data);
				if (data.code === 200) {
					this.loadPaymentMethods();
				}
	     }).catch(error => {
					console.log(error);
	     });
  	}
  }
  renderMethods = () => {
  	return this.state.payment_methods.map((method, index) => {
  		return (
  			<label key={index} className="payment__label">
	        <div className="payment__input-group">
	          <input
		          type="radio"
		          checked={method.default}
		          onChange={this.changeDefaultMethod}
		          name="payment"
		          value={method.token}
		          disabled={this.state.loadingChangeMethod}
		          className="payment__input"
	          />
	          <span className="payment__span icon-radio"></span>
	        </div>
	        <div className="payment__img-card">
	        	<img alt="" src={method.image_url} className="payment__img" />
	        </div>
	        <div className="payment__last-number">{method.display}</div>
	        { !method.default ? <button disabled={this.state.loadingChangeMethod} id={method.token} className="payment__icon-cross" onClick={this.removeMethod}></button> : null }
      </label>
      )
  	});
  }
  renderActiveSubscription = () => {
		let activePlan = false;
		const plan = this.state.billing ? this.state.billing.plan_status === 'Active' || this.state.billing.plan_status === 'Single' : false;
		if (this.state.user.billing && plan) {
			activePlan = true;
		}
		let expires = '0 days';
		let loading = 100;
		if (activePlan) {
			let paid_until_date = this.state.user.billing.paid_until_date ? moment.unix(this.state.user.billing.paid_until_date) : moment();
			let now = moment();
			let ms = paid_until_date.diff(now);
			let dur = moment.duration(ms);
			expires = dur.format("d [d] h [h] m [min]");
			let daysSpend = parseInt((dur._milliseconds/(1000*60*60)/24), 10);
			let hoursSpend = parseInt((dur._milliseconds/(1000*60*60)), 10);
			if (this.state.user.billing.plan_id === '1' && daysSpend) {
				loading = daysSpend / (365/100);
			}
			if (this.state.user.billing.plan_id === '2' && daysSpend) {
				loading = hoursSpend / (24/100);
			}
		}
		let planName = t('payments_page__currently_active_membership_promo', { defaultValue:  'Promo' });
		if (activePlan) {
			switch (this.state.plans[this.state.user.billing.plan_id]) {
	      case 'year':
	        planName = t('payments_page__currently_active_membership_annual', { defaultValue:  'Annual' });
	        break;
	      case 'day':
	        planName = t('payments_page__currently_active_membership_daily', { defaultValue:  'Daily' });
	        break;
        case 'month':
	        planName = t('payments_page__currently_active_membership_monthly', { defaultValue:  'Monthly' });
	        break;
	       case 'semester':
	        planName = t('payments_page__currently_active_membership_semester', { defaultValue:  'Semester' });
	        break;
	      case 'promo_3days':
	        planName = t('payments_page__currently_active_membership_promo', { defaultValue:  'Promo' });
	        break;
        case 'referral':
	        planName = t('payments_page__currently_active_membership_referral', { defaultValue:  'Referral' });
	        break;
				case 'hourly':
	        planName = t('payments_page__currently_active_membership_hourly', { defaultValue:  'Hourly' });
	        break;
	      default:
	    }
		}
  	return (
  		activePlan ? 
  		<div className="wallet__currently-info">
        <div className="wallet__currently-title"><Trans i18nKey="payments_page__currently_active_title">Currently active</Trans></div>
        <div className="wallet__sub-name">{ planName } <Trans i18nKey="payments_page__membership_word">membership</Trans></div>
        <div className="wallet__info-line">
          <div className="wallet__line-element">
            <div className="wallet__line-part" style={{width: loading+'%'}}></div>
          </div>
        </div>
        <div className="wallet__expires"><Trans i18nKey="payments_page__expires_in_period">Expires in</Trans> {expires}</div>
      </div>
      : null
  	)
  }
  render() {
  	const { can_refund } = this.state.billing;
    return (
      <div className="wallet">
      	<Loading loading = {this.state.loading} className="fill-bg" />
	      <div className="wallet__container">
	        <div className="wallet__header">
	          <div className="wallet__titlePage"><Trans i18nKey="payments_page__title">Payment Methods</Trans></div>
	        </div>
	        <div className="payment">
		        { this.renderActiveSubscription() }
		        <div className="wallet__items">
		        	{ false ? 
		        	<span className="wallet__items-title">
		        		Subscription auto-renew
		        		<input
		        			id="autoRenew"
		        			className="wallet__checkbox"
		        			type="checkbox"
		        			checked = {this.state.autoRenewChecked}
		        			onChange = {this.AutoRenewSub}
	        			/>
		        		<label htmlFor="autoRenew" className="wallet__checkbox-label"></label>
		        	</span> : null }
		        	{ can_refund ? <div className="wallet__items-title">
		        		<Trans i18nKey="payments_page__cancel_membership">Cancel membership</Trans>
		        		<Link to="/?m=refund" className="wallet__items-btn"><Trans i18nKey="payments_page__refund_btn">Refund</Trans></Link>
		        	</div> : null }
		        	<Link to="/payments/history" className="wallet__items-title">
		        		<Trans i18nKey="payments_page__payment_history_link">Payment history</Trans>
		        		<span className="wallet__items-arrow"></span>
		        	</Link>
		        	<div className="wallet__items-title">
		        		<Trans i18nKey="payments_page__payment_method_list_title">Payment Method</Trans>
		        	</div>
		        </div>
	          <div className="payment__info-group" style={{'paddingBottom': '15px'}}>
	          	{!this.state.loading ? this.renderMethods() : null }
	          </div>
	        </div> 
	      </div> 
	    </div>
    )
  }
};



export default Payments;