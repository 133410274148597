import React from 'react';
import Cookies from 'js-cookie';
import Analytics from 'services/utils/Analytics';
import { Trans } from 'components/Translate/Translate';
import fetchJSON from 'services/utils/fetchJSON';
import './styles.less';

export default (props) => {
	props.propSetState({
		dialogState: 0,
		headerState: 5,
		showUserController: false,
		background: 'light'
	});
	let userAgree = false;
	let showAgree = true;
	const cookieMemory = Cookies.get('exchange_rules');
	if (cookieMemory !== undefined) {
		if (cookieMemory) {
			userAgree = true;
			showAgree = false;
		}
	}
	class PitStopRules extends React.Component {
		state = {
			userAgree: userAgree,
			showAgree: showAgree
		}
		userAgree = (e) => {
			this.setState({
				userAgree: e.currentTarget.checked,
			});
			if (e.currentTarget.checked) {
				Cookies.set('exchange_rules', 1, { expires: 365 });
			}
			Analytics(`User ${e.currentTarget.checked ? 'agree' : 'not agree'} with change battery rules`);
		}
		goNext = () => {
			Analytics('Change battery - START');
			
			fetchJSON('/api/v1/sessions/start', {
				method: 'post',
				body: {
					settings: {
						exchangeChargerFlow: 1
					}
				}
			}).then(data => {
				console.log('start', data);
				if (data.code === 200) {
					Analytics('New station booking', { BookingId: data.booking.key_id });

					const userStatus = this.getUserStatus(data.user);
					if (userStatus === 'userSuspended' || userStatus === 'newUser') {
	          props.history.push('/');
	          return;
	        }
					props.history.push(`/station/${data.booking.key_id}`);
				} else {
					props.history.push('/');
				}
			}).catch(error => {
				console.error(error);
				props.history.push('/');
			});
		}
		getUserStatus = (user) => {
	    let activePlan = false;
		  let userStory = null;
		  let userSuspended = typeof user === 'object' && user.blocking_reason ? true : false;

		  if (!user) {
		    userStory = 'newUser';
		  } else if (!userSuspended) {
		    const plan = user.plan_status ? user.plan_status === 'Active' || user.plan_status === 'Single' : false;
		    if (user.subscription_id && plan) {
		      activePlan = true;
		    }

		    if (user.sessions.length) {
		      userStory = 'userWithBattery';
		    } else if (activePlan) {
		      userStory = 'userNoBattery';
		    } else {
		      userStory = 'userNoPlan';
		    }
		  } else {
		    userStory = 'userSuspended';
		  }
		  return userStory;
	  }
		render() {
			const { userAgree } = this.state;
			return (
				<div className="sessions__wrapper">
					<div className="sessions">
						<div className="pitStop__title">
							<Trans i18nKey="exchange_charger__title">Supernova is full?</Trans>
						</div>
						<div className="pitStop__desc">
							<Trans i18nKey="exchange_charger__description">Exchange your Electron with a new Electron by following these steps:</Trans>
						</div>
						<ul className="pitStop__steps">
							<li className="pitStop__step">
								<Trans i18nKey="exchange_charger__steps_1">1. Hit the “Exchange Battery” button & enter the Supernova station number</Trans>
								</li>
							<li className="pitStop__step">
								<Trans i18nKey="exchange_charger__steps_2">2. Take a new battery</Trans>
							</li>
							<li className="pitStop__step">
								<Trans i18nKey="exchange_charger__steps_3">3. When the nest closes, return the empty battery</Trans>
								</li>
						</ul>
					</div>
					<div className="sessions__controls">
						<div className="sessions__controls_inner">
							{showAgree ? <div className="sbReview__terms pitStop__terms visible">
                <input
                  id="userAgree"
                  type="checkbox"
                  name="terms"
                  checked={userAgree}
                  onChange={this.userAgree}
                  className="sbReview__checkbox-input"
                />
                <label className="sbReview__terms-text" htmlFor="userAgree">
                  <span className="sbReview__checkbox-icon"></span>
                  <Trans i18nKey="exchange_charger__understand_rules_check">I understand</Trans>
                </label>
              </div> : null }
							<button
								className="dialog-bottom__btn"
								onClick={this.goNext}
								disabled={!userAgree}
								><Trans i18nKey="exchange_charger__start_btn">Exchange Battery</Trans></button>
						</div>
					</div>
				</div>
			)
		}
	};
	
	props.propSetState({
		fullScreenStore: <PitStopRules />
	});
	
}