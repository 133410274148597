import React from 'react';
import Settings from 'services/config/Settings';

// /electron/lost
class LostElectron extends React.Component {
  componentDidMount = () => {
    document.title = `Lost Electron | ${Settings.title}`;
  }
  render() {
    return (
      <div className="wallet">
      	LostElectron
      </div>
    )
  }
};

export default LostElectron;