import React from 'react';
import windowDimensions from 'react-window-dimensions';
import Settings from 'services/config/Settings';
import Background from '../../Flow/components/Background';
//import StationPlacard from 'scenarios/components/Stickers/supernova.png';
import Analytics from 'services/utils/Analytics';
import Message from '../../Flow/components/Message';
import fetchJSON from 'services/utils/fetchJSON';
import { t, Trans } from 'components/Translate/Translate';
import Typing from '../../Flow/components/Dialog/components/Typing';
import _ from 'lodash';
import readUserStatus from '../components/readUserStatus';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import Loading from 'components/Loading';
import CoronaBanner from './CoronaBanner';
import qs from 'qs';
import PlacardClean from 'components/images/icon-placard-location-id.png';
import PlacardCleanJA from 'components/images/icon-placard-location-id-ja.png';
import Cookies from 'js-cookie';

class StationBooking extends React.Component {
  state = {
    group_id: '',
    cableType: '',
    fetching: false,
    showInput: true,
    dialogMessages: [],
    step: 1,
    cables: [],
    showDontAskCable: true,
    exchangeChargerFlow: 0,
    cable_by_default: '',
    cable_option_ask: '',
    fetchingPage: true,
    reportProblemOnce: {},
    corona_virus: 0,
    redirectIfCableSelected: true
  }
  componentDidMount = () => {
    const pageTitle = t('pageTitles.Station', { defaultValue: 'Station'});
    document.title = `${pageTitle} | ${Settings.title}`;

    const { bookingId } = this.props.match.params;
  
    

    if (bookingId) {
      this.sessionStart(bookingId);
    } else {
      this.props.history.push('/');
    }

  }
  sessionStart = (bookingId) => {
    fetchJSON(`/api/v1/sessions/process/${bookingId}`, {
      method: 'get'
    }).then(data => {
      if (data.code === 200) {
        console.log(data);

        if (data.booking.status === 'charger_taken') {
          this.props.history.push('/station/');
          return false;
        }

        const userStatus = readUserStatus(data.user);
        console.log('userStatus', userStatus);
        if (userStatus === 'userSuspended' || userStatus === 'newUser') {
          this.props.history.push('/');
          return;
        }

        if (userStatus === 'userWithBattery') {
          const exchangeChargerFlow = _.get(data, 'booking.settings.exchangeChargerFlow');
          if (!exchangeChargerFlow) {
            this.props.history.push('/');
            return;
          } else {
            this.setState({
              exchangeChargerFlow: 1
            })
          }
        }

        let paramFromUrl = {};
        if (window.location.search) {
          paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        }
        console.log('paramFromUrl', paramFromUrl);
        let redirectIfCableSelected = true;

        if (paramFromUrl.redirect && paramFromUrl.redirect === 'no') {
          redirectIfCableSelected = false;
        }

        const group_id = _.get(data, 'booking.settings.group_id');
        const cable_by_default = _.get(data, 'user.cable_by_default');
        const cable_option_ask = _.get(data, 'user.cable_option_ask');

        if (group_id) {
          this.setState({
            group_id,
            cable_by_default,
            cable_option_ask,
            redirectIfCableSelected,
            showDontAskCable: cable_option_ask
          }, this.sendToDialog);
          return;
        }

        this.setState({
          fetchingPage: false,
          cable_by_default,
          cable_option_ask,
          redirectIfCableSelected,
          showDontAskCable: cable_option_ask
        });
      } else {
        this.props.history.push('/station');
      }
    }).catch(error => {
      console.error(error);
      this.props.history.push('/');
    });
  }

  orderChanged = (redirectNext = false) => {
    const { group_id, cableType, cable_option_ask } = this.state;
    const { bookingId } = this.props.match.params;

    const body = {
      settings: {
        group_id,
        cableType,
        cable_option_ask
      },
      bookingId
    };

    if (redirectNext) {
      this.setState({
        fetching: true
      });
    }

    Analytics('Group Id has changed to '+group_id, body);

    fetchJSON('/api/v1/sessions/booking/change', {
      method: 'post',
      body
    }).then(data => {
      console.log('orderChanged OK', data);
      const userStatus = readUserStatus(data.user);

      if (userStatus === 'userSuspended' || userStatus === 'newUser') {
        this.props.history.push('/');
        return;
      }

      if (redirectNext) {
        if (userStatus === 'userNoPlan') {
          this.setState({
            step: 3
          }, this.startSubscription);
        } else {
          if (redirectNext) {
            this.goNextScreen();
          }
        }
      }
    }).catch(error => {
      console.error(error);
      this.props.history.push('/station');
   });
  }

  startSubscription = () => {
    const { group_id, cableType } = this.state;
    const { bookingId } = this.props.match.params;

    const settings = {
      group_id: group_id,
      cableType: cableType,
      sessionBookingId: bookingId
    };

    fetchJSON('/api/v1/subscribe/start', {
      method: 'post',
      body: {
        settings
      }
    }).then(data => {
      console.log('startSubscription', data);
      if (data.code === 200) {
        Analytics('New order', {OrderId: data.order.key_id});
        this.props.history.push(`/subscribe/${data.order.key_id}`);
      } else {
        this.props.history.push('/');
      }
    }).catch(error => {
      console.error(error);
      this.props.history.push('/');
    });
  }
  changeGroupId = (e) => {
    this.setState({
      group_id: e.currentTarget.value
    });
  }
  reportStationProblem = (group_id, message) => {
    const { reportProblemOnce } = this.state;
    if (Object.keys(reportProblemOnce).length && Object.keys(reportProblemOnce).indexOf(group_id) > -1) {
      if (reportProblemOnce[group_id] === message) return false;
    }
    reportProblemOnce[group_id] = message;
    this.setState({
      reportProblemOnce
    });

    fetchJSON(Settings.endpoindApi+'/api/v1/groups/report/', {
      method: 'post',
      body: {
        group_id,
        message
      }
    }).catch(error => {
      console.error(error);
    });
  }
  sendToDialog = (e = false) => {
    this.setState({
      showInput: false,
      fetching: true
    });
    const { exchangeChargerFlow } = this.state;

    let Source = 'robot';  
    if (e) {
      Source = 'user_input';
    }
    
    const value = this.state.group_id;
    if (!value) {
      Analytics('Choose group - empty value', { group_id: value });
      this.result({
        status: 'error',
        message: t("supernova-get-error", { defaultValue:  'Sorry but this Supernova is offline or that was incorrect code. Please try again.' })
      });
      return;
    }
    fetchJSON(Settings.endpoindApi+'/api/v1/groups/get/'+value, {
        method: 'get',
        headers: {
          Source
        }
      }).then(data => {
        console.log('venues get', data);
        if (data.code === 200) {
          Analytics('Choose group - status: success', { group_id: value });
          const { free_nests } = data;
          if (exchangeChargerFlow) {
            if (free_nests > 0) {
              Analytics('Station is not full', { group_id: value, free_nests: free_nests });
              this.reportStationProblem(value, 'exchangeChargerFlow: Station is not full');
              this.result({
                status: 'error',
                message: t("supernova-exchange-charger-reject", { defaultValue:  'This station has empty nests, please return your battery in any empty nest. Battery exchange is available only for full stations.' })
              });
              return;
            }
          }

          const getElectrons = data.venue.state.electrons.get;
          const cables = _.get(data, 'venue.state.cables');
          const status_venue = _.get(data, 'venue.status');
          const corona_virus = _.get(data, 'corona_virus');

          if (corona_virus === 1 && status_venue === 1) {
            this.setState({
              corona_virus
            });
          }

          if (status_venue === 2) {
              this.result({
                status: 'error',
                message: t("supernova-stations-disabled", { defaultValue:  'The station is temporarily disabled.' }),
                venue: data.venue,
              });
              return;
          }

          if (getElectrons) {
            this.result({
              status: 'success',
              message: false,
              venue: data.venue,
              group_id: value,
              cables: cables
            });
          } else {
            this.reportStationProblem(value, 'Group - No batteries found');
            Analytics('ERROR Group - No batteries found', { group_id: value });
            this.result({
              status: 'error',
              message: t( "supernova-no-available-electrons", { defaultValue:  'Sorry, in this venue there are no available Electron batteries.' }),
              venue: _.get(data, 'venue')
            });
          }
        } else {
          this.reportStationProblem(value, 'Supernova is offline or that was incorrect code');
          Analytics('Choose group - status: error', { group_id: value });
          this.result({
            status: 'error',
            message: t("supernova-get-error", { defaultValue:  'Sorry but this Supernova is offline or that was incorrect code. Please try again.' })
          });
        }
      }).catch(error => {
        console.log(error);
        this.reportStationProblem(value, 'Server error');
        this.result({
          status: 'error',
          message: t("supernova-get-error", { defaultValue:  'Sorry but this Supernova is offline or that was incorrect code. Please try again.' })
        });
      });
  }
  noCableThatNeeded = (cable) => {
    const { group_id } = this.state;
    Analytics(`No electrons available with cable type: ${cable}`, { group_id: group_id });
    this.reportStationProblem(group_id, `No electrons available with cable type: ${cable}`);
    
    this.sendMessageToDialog({ 'type': 'text', 'value': t("station-no-connector", { defaultValue:  'Oops! We\'re sorry, none of this station\'s Electrons has the connector you need. Please check our map for the nearest station with your type of connector.' }) });
    this.sendMessageToDialog({
      'type': 'choice',
      'value': [{
        title: t('have_charger_screen__find_on_map', { defaultValue:  'Find station on map' }),
        class: 'dialog-bottom__btn',
        func: (e) => {
          this.props.history.push("/map");
        },
      }]
    });
  }
  goPickCable = (cable) => {
    const { cables } = this.state;
    Analytics(`Cable selected: ${cable}`);
    if (cables.indexOf(cable) > -1) {
      this.setState({
        cableType: cable
      }, () => {
        this.orderChanged(true);
      });
    } else {
      this.noCableThatNeeded(cable);
    }
  }
  sendMessageToDialog = (props) => {
    const { dialogMessages } = this.state;
    dialogMessages.push({
      value: props.value,
      type: props.type,
      content: props.content,
      side: true
    });
    this.setState({
      dialogMessages
    }, () => {
      this.scrollToBottom();
    })
  }
  goNextScreen = () => {
    const { bookingId } = this.props.match.params;
    this.props.history.push(`/station/${bookingId}/release`);
  }
  selectDifferentStation = (e) => {
    const { fetching } = this.state;
    if (!fetching) {
      Analytics('Reset station choice');
      this.setState({
        dialogMessages: []
      }, () => {
        this.setState({
          showInput: true,
          step: 1,
          fetching: false,
          group_id: '',
          cables: '',
          corona_virus: 0
        }, () => {
          this.orderChanged();
        });
      });
    }
  }
  result = (result) => {
    switch (result.status) {
        case 'error': {
          this.setState({
            dialogMessages: []
          }, () => {
            if (result.venue) {
              this.sendMessageToDialog({
                'type': 'venue',
                'value': result.venue
              });
            }
            this.sendMessageToDialog({ 'type': 'error', 'value': result.message });
            this.setState({
              showInput: true,
              fetching: false,
              fetchingPage: false
            });
          })
          break;
        }
        case 'success': {

          const { cable_by_default, cable_option_ask, corona_virus, redirectIfCableSelected } = this.state;

          this.setState({
            dialogMessages: []
          }, () => {
            result.venue.resetBtn = {
              title: t("reset-station-choice", { defaultValue:  "I'm at a different location" }),
              class: 'dialog__collapse-btn--plainText',
              func: this.selectDifferentStation,
            };
            this.sendMessageToDialog({
              'type': 'venue',
              'value': result.venue
            });

            if (result.message) {
              this.sendMessageToDialog({ 'type': 'text', 'value': result.message });
            }

            if (cable_option_ask === 0 && cable_by_default && !corona_virus && redirectIfCableSelected) {
              if (result.cables.indexOf(cable_by_default) > -1) {
                this.setState({
                  step: 3,
                  fetching: true,
                  group_id: result.group_id,
                  cableType: cable_by_default,
                  fetchingPage: true
                }, () => {
                  setTimeout(() => {
                    this.orderChanged(true);
                  }, 500);
                });
                return;
              } else {
                this.noCableThatNeeded(cable_by_default);
              }
            }

            this.setState({
              step: 2,
              fetching: false,
              group_id: result.group_id,
              cables: result.cables,
              fetchingPage: false
            }, () => {
              this.orderChanged();
            });
          });
          break;
        }
        default: {
          this.setState({
            dialogMessages: []
          }, () => {
            this.sendMessageToDialog({
              'type': 'error',
              'value': 'nothing to send'
            });
            this.setState({
              showInput: true,
              fetching: false,
              fetchingPage: false
            });
          });
          break;
        }
      }
  }
  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.sendToDialog(e);
    }
  }
  renderDialog = () => {
    const { dialogMessages } = this.state;
    if (dialogMessages.length) {
      let List = [];
      dialogMessages.forEach((message, index) => {
        List.push(
          <Message key={index} value={message.value} type={message.type} content={message.content} side={message.side} flow = {this.props.flow} />
        )
      });
      return List;
    } else{
      return(<div></div>)
    }
  }
  dontAskCable = (e) => {
    if (e.currentTarget.checked) {
      Analytics('Selected don\'t ask cable again');
    } else {
      Analytics('Unselected don\'t ask cable again');
    }

    this.setState({
      cable_option_ask: !e.currentTarget.checked
    },  this.orderChanged);
  }
  scrollToBottom = () => {
    const { fetchingPage, corona_virus } = this.state;
    if (!fetchingPage && !corona_virus) {
      scrollIntoViewIfNeeded(this.messagesEnd, false, {
        duration: 150
      });
    }
  } 
  render() {
    let containerStyle = {
      width: this.props.width,
      height: this.props.height
    }
    const {
      group_id,
      fetching,
      fetchingPage,
      step,
      cable_option_ask,
      showDontAskCable,
      corona_virus
    } = this.state;
    const savedLanguage = Cookies.get('app_language');
    return (
      <React.Fragment>
        { fetchingPage ? 
          <Loading loading = {true} className="fill-bg" />
        : 
        <React.Fragment>
        
        {
          corona_virus ?
          <div className="app-container" style={containerStyle}>
            <div className="dialog-screen">
              <div className="dialog__container" style={{display: 'block'}}>
                <CoronaBanner/>
                <button
                  className='dialog__collapse-btn--plainText'
                  style={{
                    'margin': '0 auto',
                    'paddingTop': '30px'
                  }}
                  onClick={this.selectDifferentStation}>
                  {t("reset-station-choice", { defaultValue: "I'm at a different location" })}
                </button>
              </div>
            </div>
          </div>
          :
          <React.Fragment>

          <Background type="Dark" />
          <div className="app-container" style={containerStyle}>
            <div className="dialog-screen">
              <div className="dialog__container">
                <div className="dialog__window">
                  <div className="dialog__messages no-reverse">
                    { step === 1 ?  
                      <React.Fragment>
                        <div className="dialog__row left">
                          <span>
                          <div className="dialog__message">
                            <div style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                              <div style={{ marginRight: '15px'}}>
                                <img className="dialog__image" src={savedLanguage === 'ja' ? PlacardCleanJA : PlacardClean} alt="" style={{ width: '60px', borderRadius: '0', boxShadow: 'none' }}/>
                              </div>
                              <div>
                                <Trans i18nKey="station-scan-qr-code">Scan QR code near station or Type station ID (numeric code under QR code)</Trans>
                              </div>
                            </div>
                          </div>
                          </span>
                        </div>
                      </React.Fragment>
                    : null }
                    { this.renderDialog() }
                    { fetching ? <Typing /> : null }
                    <span ref={(el) => { this.messagesEnd = el; }} style={{paddingTop: '10px'}}></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="control__fixed">
              <div className={"control__tableRow blurred " + (this.state.showInput || this.state.showInput === 'showTab' ? '' : ' disabled' )}>
                  <div className="control__container">
                    <span className="control__label">
                      <Trans i18nKey="supernova-type-code_input_label_text">Click here to type station code</Trans>
                    </span>
                    <div className="control__group">
                      <input
                        className="control__controller"
                        type="tel"
                        name="controllerValue"
                        onChange={this.changeGroupId}
                        id="controllerValue"
                        placeholder={ t('supernova-type-code_input_placeholder', { defaultValue:  'Type code here' })}
                        value={group_id}
                        onKeyPress={this._handleKeyPress}
                      />
                      <button
                        className="control__button-send"
                        type="button"
                        onClick={this.sendToDialog}
                        disabled={fetching}>
                        <Trans i18nKey="input_controller_send_request">SEND</Trans>
                        </button>
                    </div>
                  </div>
              </div>
              <div>
                { step === 2 ? <div className="control__tableRow blurred showTab">
                  <div className="dialog-bottom__container">
                    <span className="control__label">
                      <Trans i18nKey="station-which-connector-you-need">Electrons have built-in cables.<br/>Which connector do you need?</Trans>
                    </span>
                    <div className="bookingCables__wrapper">
                      <div className="bookingCables__inner">
                        <button
                          className="booking__btn"
                          onClick={() => this.goPickCable('lightning')}
                          ><span className="booking__btn--inner ios lightning">
                            <Trans i18nKey="chooseCable.lightning">Apple<br/>Lightning</Trans>
                          </span></button>
                        <button
                          className="booking__btn"
                          onClick={() => this.goPickCable('usb_c')}
                          ><span className="booking__btn--inner android usb_c">
                            <Trans i18nKey="chooseCable.usb_c">USB-C</Trans>
                          </span></button>
                        <button
                          className="booking__btn"
                          onClick={() => this.goPickCable('micro_usb')}
                          ><span className="booking__btn--inner android micro_usb">
                            <Trans i18nKey="chooseCable.micro_usb">Micro USB</Trans>
                          </span></button>
                      </div>
                      { showDontAskCable ? <div className="bookingCables__bottom">
                         <div className="sbReview__terms visible">
                          <input
                            id="dontAskCable"
                            type="checkbox"
                            name="terms"
                            checked={!cable_option_ask}
                            onChange={this.dontAskCable}
                            className="sbReview__checkbox-input"
                          />
                          <label className="sbReview__terms-text" htmlFor="dontAskCable"><span className="sbReview__checkbox-icon"></span> <Trans i18nKey="station-dont-ask-me-cable-again">Don't ask me again.</Trans></label>
                        </div>
                      </div> : null }
                    </div>
                  </div>
                </div> : null }
              </div>
            </div>
          </div>
          </React.Fragment>
        }
        </React.Fragment> }
      </React.Fragment>
    )
  }
};



export default windowDimensions()(StationBooking);

