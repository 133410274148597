const dictionary = {
  "auth-dialog_write-number": "電話番号をご入力ください",
  "auth-dialog_request_code": "新しくコードを設定してください",
  "auth-dialog_request_edit_num": "番号を変更",
  "auth-dialog_what_is_num": "あなたの電話番号はなんですか？",
  "auth-dialog_get_new_code": "新しいコードを発行します",
  "auth-dialog_user-edit-num": "番号を変更",
  "lostBattery-yes-i-do": "はい、エレクトロンを紛失しました。",
  "lostBattery-no-i-check": "いいえ、もう一度確認します。",
  "lost_battery-are-you-sure": "探して返却出来る可能性が低いことは確かですか？もし難しければ基本金額として$60請求させて頂いています。返却とペナルティールールに関してお読みください。",
  "lostBattery-sorry-penalty": "申し訳ないですが、基本金額$40のお支払いをお願いいたします。",
  "block-user-screen": "アカウントは停止状態です。",
  "hello-user_userNoBattery": "あなたのチャージャーを手に入れましょう。",
  "hello-user_userNoBattery-lets-get-juice": "エレクトロンを手に入れましょう。",
  "supernova-type-code": "スーパーノバステーションのプラカード近くにある4つの番号コードを入力してください。",
  "supernova-get-success": "エレクトロンは使用可能になりました！",
  "take-battery-screen-label": "エレクトロンバッテリーをお取りください。",
  "take-battery-screen-take-btn": "手に取ってみてください！",
  "single-venue__get-direction-btn": "道案内機能へ切り替えましょう",
  "took-success-open-map-btn": "スポットを地図で探しましょう",
  "took-success-label": "Electron-to-Go コミュニティでは返却ルールは重要な意味を持っています。 <b>24時間未満の時間で返却をお願いします</b>",
  "supernova-help-screen_openMap": "スポットを地図で探しましょう",
  "single-venue_get-battery-button": "あなたのチャージャーを手に入れましょう",
  "took-success-message": "赤色のタブからElectronの充電ケーブルを引出しましょう。あとは携帯へさして、出掛けるだけです！",
  "refund-yes-doRefund": "はい",
  "refund-no-refund": "いいえ",
  "refund-weSorry": "とても申し訳ございません",
  "refund-inProgress": "返金処理のプロセス中です",
  "refund-question-really": "私たちのサービスを本当に解約されますか？",
  "refund-right-choice-back": "正しい選択でしょう！",
  "auth-dialog_wrong-num": "番号が違います。再度お試しください。",
  "header-btn-find-on-map": "地図上で探す",
  "hello-user_without-plan": "ようこそ！",
  "hello-user-click-for-annual": "年間契約 #PRICE#",
  "hello-user-click-for-daily": "試してみますか?<br/>ここをクリックしてください <span>1日アクセス権</span>",
  "community-rules_accept-btn": "コミュニティールールに関して了承してください",
  "community-rules_accept-message": "Electron-to-Go コミュニティでは返却ルールは重要な意味を持っています。 <b>24時間未満の時間で返却をお願いします</b>",
  "community-rules_read": "詳細を読む",
  "community-rules_returnPolicy": "レンタル終了時間と、返却ルール",
  "hello-user__userNoPlan-money-back": "1か月の返金保証",
  "return-policy-message-more-48": "許可されるのは<b>それから48時間</ b>です",
  "find-supernova-screen_btn-no": "いいえ",
  "find-supernova-screen_question": "あなたはスーパノヴァステーションの近くにいますか？",
  "promo-add-payment": "サービスの安全性の為、お支払い方法を選択いただく必要があります",
  "refund-done": "これで完了です！",
  "map-ask-user-share-location": "ナビゲーションを簡単にするために、現在地を使用しましょう。",
  "find-supernova-correct-venue": "これはあなたがいる場所ですか？",
  "take-battery-screen-sorry-didNotTake": "申し訳ありませんが、あなたはバッテリーを取ることが出来ませんでした。 やり直しますか？",
  "subscribe-order-title": "サブスクリプションサービスを選択する",
  "supernova-get-error": "申し訳ないですがこのスーパーノヴァステーションはオフラインか、コードが間違っています。もう一度やり直してみてください。",
  "take-battery-screen-sorry-no-free-batteries": "現在は自由に使えるバッテリーがないようです。",
  "subscribe-payments-title": "支払い方法を選択する",
  "subscribe-review-title": "支払い詳細をみる",
  "subscribe-process-title": "支払い方法について",
  "subscribe-fail-title": "支払いに失敗しました",
  "subscribe-purchase-title": "支払い方法について",
  "ReturnBatteryFail_return-soon-a-spossible": "可能であればバッテリーを一刻も早く返却してください",
  "take-battery-screen-sorry-there-queue": "申し訳ありませんが、キューがあります。 10秒待ちます",
  "hello-user_userWithBattery": "バッテリーを返却する",
  "welcome__item__2": "LightningおよびUSB-C <br/>コネクタが組み込まれています",
  "welcome__item__1": "共有可能な充電器への無制限のアクセス<br/>",
  "welcome__item__3": "支払い後、<br/>バッテリーのロックが解除されます",
  "welcome__item__4": "24時間以内に<br/>スーパーノヴァステーションのある位置へ戻る",
  "welcome__btn__first": "使用方法について",
  "welcome__item__btn__next": "次へ",
  "auth-dialog__type_phone_placeholder": "電話番号を入力",
  "auth-dialog__type_code_from_sms": "SMSからのコードを入力",
  "auth-dialog__type_code_placeholder": "コードを入力",
  "subscribe_order__title": "充電<br/>料金プランを選択してください",
  "subscribe_order__have_promo": "プロモーションコードを持っています",
  "subscribe_order__continue_btn": "続ける",
  "subscribe_order__1_month_plan_label": "1ヶ月",
  "subscribe_order__1_day_plan_label": "1日",
  "subscribe_order__daily_plan_title": "1日料金として #PRICE# を支払う",
  "subscribe_order__daily_offer": "1日間追加料金なく何度もご利用いただけます。",
  "subscribe_order__monthly_offer_1": "1ヶ月後の返却は適宜リマインドします",
  "subscribe_order__monthly_offer_2": "友人の1日間の無料アクセスをプレゼント",
  "subscribe_order__monthly_plan_title": "1ヶ月の無制限利用アクセス",
  "subscribe_order__promo_plan_title": "プロモコード",
  "subscribe_order__promo_input_placeholder": "プロモコードを入力する",
  "subscribe__payments_description_no_payment_methods": "便利な支払い方法を選択してください",
  "subscribe__payments_description_has_payment_methods": "保存した支払い方法を選択して、新しい支払い方法を追加します",
  "subscribe__payments_description_promo": "電子充電器のバッテリーリターンを制御するには、有効な支払い方法が必要です",
  "subscribe__payments_billing_info_title": "課金情報",
  "subscribe__payments_add_payment_method_btn": "新しい支払い方法を追加",
  "subscribe__payments_billing_post_code_placeholder": "郵便番号",
  "subscribe__payments_billing_email_placeholder": "Eメール",
  "subscribe_order__promo_error_1": "プロモーションコードが見つからないか無効です",
  "subscribe_order__promo_applied": "プロモーションコードが適用されました",
  "subscribe_order__promo_error_403": "このプロモーションコードは既に使用されています",
  "subscribe_order__promo_error_405": "紹介プロモーションコードは1回しか使用できません",
  "subscribe_order__promo_error_expired": "プロモーションコードの有効期限が切れています",
  "subscribe__review-description": "あなたの選択を確認してください",
  "subscribe__review_membership_title": "メンバーシップ",
  "subscribe__review_membership_valid_until": "メンバーシップの有効期限",
  "subscribe__review_payment_method_selected": "お支払い方法",
  "subscribe__review_promo_description": "プロモーションプランに料金を請求することはありませんが、確認のために支払い方法から1ドルをリクエストします。 お金はすぐに銀行口座に戻ります。",
  "subscribe__review_agree_terms_privacy": "#TERMS_LINK_OPEN#規約と条件#TERMS_LINK_CLOSE# と#PRIVACY_LINK_OPEN#個人情報保護方針#PRIVACY_LINK_CLOSE# に同意します",
  "subscribe-fail-help": "ヘルプが必要ですか？ お問い合わせ",
  "subscribe-fail-retry": "リトライ",
  "subscribe__pay_start_payment_btn": "支払い",
  "subscribe__pay_add_card_btn": "新しいカード情報を加える",
  "subscribe-purchase-description": "成功",
  "subscribe-purchase-got_it_btn": "了解しました",
  "subscribe-purchase-24-hours-rules": "Electron-to-Go コミュニティでは返却ルールは重要な意味を持っています。 <b>24時間未満の時間で返却をお願いします</b>",
  "hello_user_userNoBattery__select_plan_btn": "プランの選択",
  "supernova-type-code_input_label_text": "ここをクリックしてステーションコードを入力してください",
  "supernova-type-code_input_placeholder": "コードをここに入力してください",
  "take-battery-unlock-my-charger_btn": "チャージャーを解除する",
  "take-battery-screen-something-wrong": "何かがおかしいです。 5分後にもう一度お試しいただくか、support@electrontogo.com までご連絡ください",
  "took-success-having-problem-btn": "\n問題がありますか？",
  "exchange_charger__title": "スーパーノヴァはいっぱいですか？",
  "exchange_charger__description": "次の手順に従って、Electronを新しいElectronと交換します。",
  "exchange_charger__steps_1": "1.「バッテリー交換」ボタンを押して、Supernovaステーション番号を入力します",
  "exchange_charger__steps_2": "2.新しいバッテリーを取ります",
  "exchange_charger__steps_3": "3.巣が引っ込んだら、空のバッテリーを返します",
  "exchange_charger__understand_rules_check": "理解しました",
  "exchange_charger__start_btn": "バッテリー交換",
  "have_charger_screen__title": "チャージャーセッション中",
  "have_charger_screen__instruction_title": "エレクトロン返却方法",
  "have_charger_screen__instruction_steps_1": "1. Electronを空のネストに戻す",
  "have_charger_screen__instruction_steps_2": "\n2.返品確認とともにテキスト確認を受け取る必要があります",
  "have_charger_screen__instruction_steps_3": "3. Electronを新しい選挙に交換する場合は、返品確認のエクスプレスリンクに従ってください。",
  "have_charger_screen__find_on_map": "地図上でステーションを探す",
  "report__how_return_question": "バッテリーの返却方法は？",
  "report__how_return_answer": "\nバッテリーを使用可能なStationのネストに戻します。 ケーブルをバッテリーフレームに完全に挿入してください。",
  "report__how_battery_doesnt_work_question": "バッテリーが機能しない",
  "report__how_battery_doesnt_work_answer": "バッテリーをステーションに戻し、別のバッテリーを入手します。",
  "report__exchange_charger_question": "ステーションがいっぱいです：このステーションの他のエレクトロンとエレクトロンを交換します。",
  "report__cant_take_battery_question": "バッテリーが手に入らない",
  "report__supernova_doesnt_work_question": "ステーションが機能していません",
  "report__session_is_still_open_question": "私はすでにバッテリーを返却しましたが、私のセッションはまだ開いています",
  "report__other_question": "\nその他の問題",
  "report__title": "助けが必要？ いずれかのオプションを選択するか、質問を書いてください",
  "report__exchange_charger_answer": "ステーションに空き巣がない場合にのみ、交換バッテリーを使用できます。",
  "report__exchange_charger_btn_start": "バッテリー交換を開始",
  "report__find_battery_placeholder": "ステーション番号または場所",
  "report__find_battery_describe_field_placeholder": "問題を説明してください",
  "report__find_battery_provide_details": "次の詳細を入力してください：",
  "report__find_battery_label": "ステーション番号または場所",
  "report__find_battery_describe": "問題を説明してください",
  "report__find_battery_submit": "提出する",
  "report__custom_question": "あなたのメッセージ",
  "report__submit_question": "提出する",
  "referral__share_subject": "充電器のネットワークへの無料月間アクセスを取得",
  "referral__share_text": "招待コード #UNIQUE_COD# を使用すると、充電器のネットワークに無料で月にアクセスできます。https://e2g.app/r/#UNIQUE_CODE# で引き換えます",
  "referral__title": "#LOCAL_AMOUNT# をプレゼントして、#LOCAL_AMOUNT#を取得しよう",
  "referral__description": "友人にe2g.appを試してもらうと、電子充電器の使用を開始すると、2人とも #LOCAL_AMOUNT# の月間サブスクリプションを獲得できます。",
  "menu__referral": "無料で入手",
  "menu__payments": "お支払い",
  "menu__charges": "充電情報",
  "menu__map": "地図",
  "map__faq": "ヘルプ",
  "menu__about_etg": "Electron-to-Goについて",
  "menu__logout": "ログアウト",
  "payments_page__cancel_membership": "メンバーシップ退会",
  "payments_page__refund_btn": "払い戻し",
  "payments_page__title": "お支払い方法",
  "payments_page__payment_history_link": "お支払い履歴",
  "payments_page__payment_method_list_title": "お支払い方法",
  "payments_page__currently_active_membership_promo": "割引",
  "payments_page__currently_active_membership_annual": "年",
  "payments_page__currently_active_membership_daily": "日",
  "payments_page__currently_active_membership_monthly": "月",
  "payments_page__currently_active_membership_semester": "学期",
  "payments_page__currently_active_membership_referral": "推薦",
  "payments_page__currently_active_title": "直近の使用状況",
  "payments_page__membership_word": "メンバーシップ",
  "payments_page__expires_in_period": "〜までの貸出期限です",
  "payments_page__delete_payment_method_question": "お支払い方法について削除します",
  "payment_history_page__title": "お支払い履歴",
  "payment_history_page__transaction_word": "取引",
  "payment_history_page__status_paid_word": "お支払い完了",
  "payment_history_page__date": "日時",
  "payment_history_page__plan_status": "プラン状況",
  "payment_history_page__order_id": "オーダーID",
  "payment_history_page__order_status": "オーダー状況",
  "payment_history_page__order_amount": "オーダー量",
  "payment_history_page__payment_method": "お支払い方法",
  "payment_history_page__payment_status": "お支払い状況",
  "payment_history_page__no_transactions": "現在お取引中の物はありません",
  "my_charges_page__title": "私の充電について",
  "my_charges_page__amount_charges_word": "お支払い",
  "my_charges_page__amount_hours_word": "時間",
  "my_charges_page__no_charges_yet": "有効な請求はありません。",
  "my_charges_page__electron_id": "エレクトンID",
  "faq_page__title": "FAQ",
  "faq_page__where_can_return_answer": "<a href=\"https://electrontogo.app/map\">マップ</a>で見つけることができる返品可能な場所へ。",
  "faq_page__what_id_supernova_not_respond_answer": "しばらくしてからもう一度試してください。 それでも機能しない場合は、<a href=\"mailto:support@electrontogo.com\"> support@electrontogo.com </a>までご連絡ください。できるだけ早く返信いたします",
  "faq_page__how_do_i_get_refund_answer": "支払いページ（<a href=\"https://electrontogo.app/payments\"> https://electrontogo.app/payments </a>）でプランをキャンセルできます。 払い戻しを受けることができるのは初めての加入者のみです。",
  "faq_page__if_you_didnt_find_answer_question": "回答が見つからない場合は、<a href=\"https://electrontogo.app/contact\">お問い合わせください</a>。",
  "faq_page__what_is_supernova_question": "Supernovaとはなんですか？",
  "faq_page__what_is_supernova_answer": "それは私たちの\n充電ステーションです。",
  "faq_page__what_is_electron_question": "Electronとはなんですか？",
  "faq_page__what_is_electron_answer": "それは私たちのポータブル充電器です。",
  "faq_page__how_it_will_charge_question": "どのように携帯電話を充電しますか？",
  "faq_page__how_it_will_charge_answer": "各エレクトロンには、iPhoneとAndroid Type-Cの両方に内蔵ケーブルがあります（ただし、マイクロUSB用ではありません）。",
  "faq_page__is_it_wireless_question": "ワイヤレス充電ですか？",
  "faq_page__is_it_wireless_answer": "いいえ。現在のワイヤレス充電プロトコルは重要であり、スマートフォンのバッテリーセルを短くします。",
  "faq_page__what_type_of_phone_can_change_question": "電子チャージできる電話の種類は何ですか？",
  "faq_page__what_type_of_phone_can_change_answer": "iPhone 5モデルのApple iPhoneおよびUSB Type-Cコネクタを備えたAndroidフォン。",
  "faq_page__how_fast_it_question": "携帯電話をどれくらい速く充電できますか？",
  "faq_page__how_fast_it_answer": "電話の機能に依存します。 Electronsはあなたの電話を2Aまで充電できます。これはほとんどの電話で毎分1％です。",
  "faq_page__how_much_charge_I_can_get_question": "どれくらいの料金がかかりますか？",
  "faq_page__how_much_charge_I_can_get_answer": "お使いの携帯電話のバッテリーのサイズに依存します。 電子容量は2700mAhで、ほとんどの電話で約70〜100％の充電です。",
  "faq_page__how_do_i_know_electron_charged_question": "電子が帯電しているかどうかはどうすればわかりますか？",
  "faq_page__how_do_i_know_electron_charged_answer": "Electronの赤いボタンを押すと、Electronの背面にLEDインジケータが表示されます。 3個または4個のLEDが点灯している場合は、電子が完全に充電されています。",
  "faq_page__where_can_return_question": "充電器はどこに返却できますか？",
  "faq_page__what_happend_if_not_return_on_time_question": "Electronを時間内に返さないとどうなりますか？",
  "faq_page__what_happend_if_not_return_on_time_answer": "毎日のユーザーの場合、1日1ドルを請求し、3日後にサブスクリプションを月額に引き上げ、10ドルを請求します。 毎月の購読者の場合、Electronを返却するまでに最大7日間があります。その後、購読を年間に引き上げて60ドルを請求します。 年間ユーザーの場合は、最大7日間返品できます。その後、Electronを返品するまでアカウントを一時停止します。",
  "faq_page__what_if_lost_question": "電子が失われるとどうなりますか？",
  "faq_page__what_if_lost_answer": "サブスクリプションは1年にアップグレードされ、60.00ドルが請求されます。 年間加入者には50.00ドルが請求されます",
  "faq_page__what_if_break_question": "電子を破損または損傷した場合はどうなりますか？",
  "faq_page__what_if_break_answer": "\n損傷に応じて、60.00ドルを超えない料金を請求します。",
  "faq_page__what_id_supernova_not_respond_question": "Supernovaが反応しない場合はどうすればよいですか？",
  "faq_page__electron_doesnt_work_what_to_do_question": "Electronは動作しません。どうすればいいですか？",
  "faq_page__electron_doesnt_work_what_to_do_answer": "Electronをステーションに戻し、別のElectronを取得します。 これがステーション上の唯一のエレクトロンである場合、それを返して再度入手してください。 それでも機能しない場合は、<a href=\"https://electrontogo.app/map\">マップ</a>でElectronを取得する別の場所を見つけてください。 不便でごめんなさい。 電子IDで問題を報告して、評価のボーナスポイントを獲得してください。",
  "faq_page__what_if_i_am_in_other_city_question": "別の都市にいて、Electronを返せない場合はどうなりますか？",
  "faq_page__what_if_i_am_in_other_city_answer": "ご連絡ください：support@electrontogo.com",
  "faq_page__what_is_juize_inc_question": "Juize Incとはなんですか？",
  "faq_page__what_is_juize_inc_answer": "Juize Incは当社の正式名称です。",
  "faq_page__electron_too_hot_question": "エレクトロンが熱くなりすぎました。 私は何をすべきか？",
  "faq_page__electron_too_hot_answer": "プラグを抜いて数分待ってから、再び差し込みます。ズボンのポケットに携帯電話を充電することはお勧めしません。",
  "faq_page__how_many_electrons_i_can_get_question": "Electronバッテリーはいくつ入手できますか？",
  "faq_page__how_many_electrons_i_can_get_answer": "1つだけですが、好きなだけ返却と借用が有効時間の中で可能です",
  "faq_page__how_log_i_kan_keep_charger_question": "充電器はどのくらいの期間保管できますか？",
  "faq_page__how_log_i_kan_keep_charger_answer": "24時間以内に返品してください。 プランの詳細については、ユーザー規約をご覧ください",
  "faq_page__why_do_i_need_add_payment_when_promo_question": "プロモーションコードを持っているときに支払い方法を追加する必要があるのはなぜですか？",
  "faq_page__why_do_i_need_add_payment_when_promo_answer": "Electron™バッテリーをステーションに確実に戻すには",
  "faq_page__does_plans_auto-renews_question": "私のプランは自動更新されますか？",
  "faq_page__does_plans_auto-renews_answer": "いいえ、毎回手動で更新する必要があります",
  "faq_page__what_if_i_dont_like_question": "このサービスが気に入らない場合はどうすればよいですか？",
  "faq_page__what_if_i_dont_like_answer": "毎月、半期、または年間サブスクリプションの全額払い戻しを受ける最初の2週間があります。",
  "faq_page__how_do_i_get_refund_question": "返金を受けるにはどうすればよいですか？",
  "about_page__contact_us": "お問い合わせ",
  "about_page__about_us": "私たちについて",
  "about_page__help": "ヘルプ",
  "about_page__terms_and_conditions": "利用規約",
  "about_page__privacy_policy": "プライバシーポリシー",
  "about_page__become_venue": "設置したい方",
  "contact_us_page__title": "お問い合わせ",
  "contact_us_page__help_is_needed": "ヘルプが必要ですか？",
  "contact_us_page__we_recommend_faq": "よくある質問をご覧になることをお勧めします。",
  "contact_us_page__go_to_faq": "FAQへ",
  "contact_us_page__any_other_by_email": "サポートについてご質問がある場合は、メールでお問い合わせください。",
  "about_page__refund_policy": "QRコードをスキャンするか、ブラウザでリンクを開きます",
  "supernova-no-available-electrons": "ここから始める",
  "subscribe_order__1_year_plan_label": "エレクトロンが出されました。",
  "subscribe_order__annual_offer": "エレクトンを取得し、充電してお出かけください。",
  "subscribe-process-description": "現在支払い手続きをしています",
  "welcome__item__btn__next_try_for_free": "無料でお試しください",
  "welcome__item__5": "エレクトロンチャージャー持って出掛けましょう. 返却し繰り返しご利用ください",
  "subscribe_order__semester_offer": "6ヶ月間の無制限利用を取得",
  "subscribe_order__montly_offer_3": "持って、お出かけ、繰り返し。何度でも。",
  "subscribe_order__semester_plan_title": "セメスター ",
  "subscribe_order__monthly_plan_title_ver_2": "月間プラン",
  "subscribe_order__semester_plan_title_ver_2": "セメスター は#PRICE#",
  "subscribe_order__semester_offer_2": "利用は無制限",
  "subscribe_order__semester_offer_1": "セメスター は#PRICE#",
  "subscribe_order__daily_offer_free": "1日無料でお試しください",
  "subscribe_order__daily_plan_title_v2": "1日プラン",
  "subscribe_order__sale_word_free": "無料",
  "subscribe-payments-title-add-payment-method": "支払い方法の追加",
  "subscribe-payments-title-promo": "セキュリティ上の理由から、支払い方法を追加",
  "welcome__item__btn__next_try_not": "今すぐ試す",
  "find-supernova-screen_btn-yes": "はい",
  "reset-station-before-release": "別のステーションを選択します。",
  "station-scan-qr-code": "QRコードをスキャン、またはIDを入力してください。",
  "reset-station-choice": "私は別の場所にいます",
  "station-dont-ask-me-cable-again": "繰り返し聞かないようする",
  "take-battery-screen-offline": "申し訳ありませんが、このスーパーノヴァステーションはオフラインです。5分後にもう一度お試しいただくか、support@electrontogo.com までご連絡ください。",
  "station-which-connector-you-need": "エレクトロンチャージャーにはケーブルが内蔵されています。<br /> どのコネクタが必要ですか？",
  "station-no-connector": "申し訳ありませんが、この場所にはあなたが必要とするコネクターがありません。あなたの必要なコネクターを持つ最寄りの駅を地図で確認してください。",
  "reset-cable-preference": "ケーブル環境設定の変更",
  "supernova-exchange-charger-reject": "エレクトロンチャージャーを返却ください、どの空きスペースに返却頂いても大丈夫です。チャージャー交換は空きスペースがない場合のみ有効です。",
  "subscribe_order__monthly_offer_3": "新規ご利用から2週間以内は何かあれば全額返金対応致します。",
  "subscribe_order__how_membership_works": "会員制の仕組みについて",
  "input_controller_send_request": "送る",
  "map_preffered_cable_filter": "ケーブルの種類",
  "map_get_electron": "エレクトロンチャージャーを取得する",
  "map_return_electron": "返却",
  "take-battery-look-at-supernovas": "スーパーノヴァステーションを見てください あなたのエレクトロンチャージャーは光に包まれています、もうすぐ出てきます",
  "subscribe_order__annual_plan_title": "年間プラン",
  "orderScreen.freeFirstTime.title": "エレクトロン チャージャーを無料でレンタルしています。",
  "orderScreen.freeFirstTime.desc": "24時間以内のご返却をお願いいたします",
  "subscribe_order__get_free_charger": "無料のチャージャーを借りる！",
  "delayFee.page.title": "遅延料金の概要",
  "menu__delay_fees": "遅延料金",
  "delayFee.countries.jp": "日本",
  "delayFee.countries.us": "アメリカ",
  "delayFee.countries.gb": "UK",
  "delayFee.switch.private": "プライベートロケーション",
  "delayFee.switch.public": "公共の場所",
  "delayFee.public.title": "公共の場所の場合",
  "delayFee.page.description": "遅延損害金は、当社にとってもお客様にとっても楽しいものではありません。しかし、Electron®を紛失するたびに、ネットワークをスムーズに稼動させるために代替品を発送する必要があります。",
  "delayFee.freeWithPayment.title": "お支払方法の入力が求められた場合",
  "delayFee.freeWithPayment.rule_1": "セッションは無料です。エレクトロン チャージャーの返却が必要です。",
  "delayFee.freeWithPayment.rule_2": "10日目には、#PRICE#の損切り手数料が発生します。",
  "delayFee.noPayment.title": "お支払方法の入力が求められなかった場合",
  "delayFee.noPayment.rule_1": "セッションは無料です。エレクトロン チャージャーの返却が必要です。",
  "delayFee.noPayment.rule_2": "設置会社が、登録した電話またはメールで連絡を取る権利を有することに同意したものとします。",
  "haveBattery.dateFormat.days": "日",
  "haveBattery.dateFormat.hours": "時間",
  "haveBattery.dateFormat.min": "分",
  "haveBattery.helpText": "ヘルプ",
  "haveBattery.returnSoOtherCanUse": "他の人にも使ってもらえるように、エレクトロン チャージャーを返却してください。",
  "haveBattery.timeLeftToReturn": "返却期限まで残り{{ duration }}です",
  "orderScreen.freeUse.descr": "2時間以内にエレクトロン チャージャーを返却してください。",
  "report__custom_type_your_message": "メッセージを入力してください",
  "subscribe.firstTimeNoPayments.title": "エレクトロン チャージャーによって充電の悩みから自分を解放しましょう",
  "subscribe.firstTimeNoPayments.submit": "月間プランへのアクセス",
  "subscribe__review_rental_period_valid_until": "レンタル期間は以下の通りです。",
  "subscribe__pay_preparing_payment": "お支払いの準備",
  "subscribe__review_payment_method_edit": "編集",
  "subscribe_order__hourly_rate_first_120": "最初の120分",
  "subscribe_order__hourly_advantage_one": "1時間ごとに#PRICE#が追加されます。",
  "subscribe_order__hourly_advantage_two": "#PRICE#1日の最大料金です。",
  "paymentScreen.freeUse.explanation": "当社では、お客様の検証済みのお支払い方法を使用してElectronバッテリーの返却を保証しています。プロモプランの料金は表示されません;ちょうど24時間以内にあなたのデバイスを返します。",
  "paymentScreen.paymentMethods.ApplePay": "アップルペイ",
  "paymentScreen.paymentMethods.creditCard": "クレジットカード",
  "paymentScreen.paymentMethods.PayPal": "ペイパル",
  "paymentScreen.paymentMethods.Venmo": "Venmo",
  "addCardForm.CardNumber": "カード番号",
  "addCardForm.CVV": "CVV",
  "addCardForm.ExpirationDate": "有効期限",
  "delayFee.hourly.title": "1時間毎プラン",
  "delayFee.hourly.rule_1": "1時間ごとのプランはは、2時間のレンタル期間を想定しています。",
  "delayFee.hourly.rule_2": "1日目の1時間ごとに #PRICE# ",
  "delayFee.hourly.rule_3": "#価格#1日あたりの最大料金",
  "delayFee.hourly.rule_4": "2日目からElectron®が返却されるまでの間、#PRICE#は日割りで請求されます。",
  "delayFee.daily.title": "1日プラン",
  "delayFee.daily.rule_1": "24時間無制限にご利用いただけます。",
  "delayFee.daily.rule_2": "1日の料金は24時間ごとに再課金され、最大10日間まで再課金されます。",
  "delayFee.promo.title": "初回は無料です",
  "delayFee.promo.rule_1": "24時間無制限にご利用いただけます。",
  "delayFee.promo.rule_2": "2日目からエレクトロンチャージャーが返却されるまでの間、#250円#は毎日請求されます。",
  "delayFee.monthly.title": "月間プラン",
  "delayFee.monthly.rule_1": "各エレクトロンチャージャーの返却まで最大10日間の猶予がある、無制限にご利用いただけるプランです。",
  "delayFee.monthly.rule_2": "11日目には、アカウントを年間会員にアップグレードするための#5000円#が課金されます。",
  "delayFee.monthly.rule_3": "新規の年会費は請求日に始まります。",
  "delayFee.annual.title": "年間プラン",
  "delayFee.annual.rule_1": "年間購読には、1回目の無料紛失クレジットが含まれています。",
  "delayFee.annual.rule_2": "Electrons®の2回目または追加の損失は、10日目に#PRICE#の損失手数料が発生します。",
  "delayFee.general.title": "一般原則",
  "delayFee.general.rule_1": "レンタル期間中やセッション中にエレクトロンチャージャーを紛失した場合は、遅延・紛失料として#PRICE#の合計金額が発生します。",
  "delayFee.general.rule_2": "紛失した場合、#PRICE#をお支払い後20日以内にエレクトロンチャージャーをご返却いただくと、#PRICE1#ユーザークレジットが付与されます。",
  "delayFee.general.rule_3": "それ以外のすべての紛失したエレクトロンチャージャーの返品は、返却者に#PRICE#ユーザークレジットを付与することになります。",
  "delayFee.general.rule_4": "<b>  返却の場合の連絡先 </b> Eメール support@electrontogo.com ",
  "delayFee.general.rule_maximum": "遅延料金の総額は最大#PRICE#円までです。",
  "addCardForm.PurchaseButton": "購入",
  "report__find_battery_enter_station_number": "バッテリーを返却したステーションの番号を入力し、バッテリーを返却した日時を記述してください。",
  "orderScreen.freeUse.title": "このロケーションはエレクトロンチャージャーは無料でレンタルしています。",
  "delayFee.termsAndConditions": "規約と条件",
  "appSettings.pageTitle": "持ち運びできるスマホ充電器",
  "surveyPage.title": "パイロットを使用しているユーザー利便性向上の為の調査として、以下の質問へのご協力をお願いしています。",
  "surveyPage.gender.title": "性別をお選びください。",
  "surveyPage.gender.female": "女性",
  "surveyPage.gender.male": "男性",
  "surveyPage.gender.nonbinary": "非バイナリ",
  "surveyPage.age.title": "年齢についてお教えください(年代でも可)",
  "report__report_submitted": "ありがとうございます。追ってご連絡させていただきます。",
  "surveyPage.error": "このフィールドは必須です。",
  "surveyPage.email.title": "Please provide your email",
  "surveyPage.worry_your_phone_dying.somewhat": "①少し心配",
  "surveyPage.worry_your_phone_dying.worry": "②心配",
  "surveyPage.worry_your_phone_dying.stressed": "3,不安",
  "surveyPage.worry_your_phone_dying.title": "外出先であなたのスマホのバッテリーは大丈夫ですか？",
  "covidWarning.title": "チャージャーは48時間以内に返却されたものです",
  "covidWarning.gotIt": "わかりました",
  "covidWarning.text_one": "外側は、水または70％アルコール液で湿らせた柔らかい布、または電子機器に安全な抗菌スプレーで安全に拭くことができます",
  "covidWarning.text_two": "<b> 注意 </b> 過剰な量の液体の使用や、チャージャーを液体に沈めることは避けてください",
  "covidWarning.text_three": "<b> 注意 </b> 製品が完全に乾いたことを確認し、コネクタを差し込んでください",
  "surveyPage.s_paid_services.title": "将来有料サービスにした場合に 利用料金についてご質問致します。",
  "surveyPage.s_paid_services.24hours250": "①24時間 ¥250",
  "surveyPage.s_paid_services.24hours300": "②24時間 ¥300",
  "surveyPage.s_paid_services.monthly800": "③月額利用料金 ¥800 使い放題",
  "surveyPage.pleaseSelect": "選んでください",
  "map.marker.label.privateLocation": "プライベートなロケーション",
  "surveyPage.nps_score.title": "当社のサービスを友人や同僚に勧める可能性はどの程度ありますか？",
  "surveyPage.title.shareExperience": "フィードバックを共有してください"
};
export default dictionary;