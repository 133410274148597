const PitStopRules = {
	name: 'PitStopRules',
	steps: [
		{
			action: 'func',
			name: 'PitStopRulesScreen'
		}
	]
};

export default PitStopRules;