import React from 'react';
import Settings from 'services/config/Settings';
import { Trans, t } from 'components/Translate/Translate';
import Cookies from 'js-cookie';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appLanguage: 'en'
    }
  }
	componentDidMount = () => {
    const pageTitle = t('pageTitles.about', { defaultValue: 'About'});
		document.title = `${pageTitle} | ${Settings.title}`;
    const appLanguage = Cookies.get('app_language');
    this.setState({
      appLanguage
    });
  }

  render() {
    const { appLanguage } = this.state;
    return (
      <div className="wallet">
	      <div className="wallet__redbg">
	        <div className="wallet__container">
            <div className="home">
              <div className="account_about__link-group">
	              <a href="mailto:support@electrontogo.com" target="_blank" rel="noopener noreferrer" className="account_about__link-item"><Trans i18nKey="about_page__contact_us">CONTACT US</Trans></a>
	              <a href="https://electrontogo.com" target="_blank" rel="noopener noreferrer" className="account_about__link-item"><Trans i18nKey="about_page__about_us">ABOUT US</Trans></a>
              </div>
            </div>
          </div>
        </div>
        <div className="wallet__container">
          <div className="wallet__items wallet__items--home-items-margin">
          	<a href="/faq" rel="noopener noreferrer" className="wallet__items-title"><Trans i18nKey="about_page__help">Help</Trans><span className="wallet__items-arrow"></span></a>
          	<a href={`https://electrontogo.com/terms?lng=${appLanguage}`} target="_blank" rel="noopener noreferrer" className="wallet__items-title"><Trans i18nKey="about_page__terms_and_conditions">Terms of service</Trans><span className="wallet__items-arrow"></span></a>
            <a href={`https://electrontogo.com/privacy?lng=${appLanguage}`} target="_blank" rel="noopener noreferrer" className="wallet__items-title"><Trans i18nKey="about_page__privacy_policy">Privacy Policy</Trans><span className="wallet__items-arrow"></span></a>
            <a href={`https://electrontogo.com/refund?lng=${appLanguage}`} target="_blank" rel="noopener noreferrer" className="wallet__items-title"><Trans i18nKey="about_page__refund_policy">Refund Policy</Trans><span className="wallet__items-arrow"></span></a>
          	<a href={`https://electrontogo.com?lng=${appLanguage}`} target="_blank" rel="noopener noreferrer" className="wallet__items-title"><Trans i18nKey="about_page__become_venue">Became a venue</Trans><span className="wallet__items-arrow"></span></a>
        	</div>
          <div className="home">
            <div className="account_about__social-address">
              <div className="account_about__address">Juize Inc</div>
              <div className="account_about__address">
              <a href="mailto:support@electrontogo.com" className="account_about__link">support@electrontogo.com</a>
              </div>
              <div className="account_about__address">
                <a href="https://electrontogo.com" className="account_about__link">
                  electrontogo.com
                </a>
              </div>
            </div>
          </div>
        </div>
	    </div>
    )
  }
};

export default About;