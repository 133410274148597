import * as Stickers from 'scenarios/components/Stickers';
import { t } from 'components/Translate/Translate';

const Refund = {
	name: 'Refund',
	steps: [
		{
			action: 'message',
			type: 'text',
			value: t("refund-question-really", { defaultValue:  'Are you really leaving us?' }),
			timeout: 500,
			typing: false
		},
		{
			action: 'message',
			type: 'sticker',
			value: Stickers.SoSad,
			timeout: 1000,
			typing: false
		},		
		{
			action: 'func',
			name: 'Refund'
		}
	]
};

export default Refund;