import * as Stickers from 'scenarios/components/Stickers';

const Payment = {
	name: 'payment',
	steps: [
		{
			action: 'func',
			attempts: 5,
			name: 'paymentChoice',
			type: 'sticker',
			value: Stickers.Whatareyougoingdo,
			controller: {
				status: false,
				placeholder: 'Type your name',
				value: '',
				type: 'text'
			}
		},
		{
			action: 'func',
			name: 'SuccessPaymentScreen'
		},
		{
			action: 'func',
			name: 'CommunityRulesScreen'
		},
		{
			action: 'message',
			type: 'sticker',
			value: Stickers.SupernovaSuccess,
			timeout: 500,
			typing: false
		}
	]
};

export default Payment;