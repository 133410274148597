import React from 'react';
//import {observer} from "mobx-react"
import './styles.less';
import './components/slick/slick.less';

import RenderScreen from './components/RenderScreen';

//@observer
class FullScreenAction extends React.Component {
	render() {
		return (
		 this.props.flow.fullScreenStore ?
			<RenderScreen key={Math.random()} history={this.props.history} screen={this.props.flow.fullScreenStore} /> : 
			null
		)
	}
}
export default FullScreenAction;