import React from 'react';
import './styles.less';
import Typing from './components/Typing';
import Message from '../Message';
import {observer} from "mobx-react";
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

@observer
class Dialog extends React.Component {
	scrollToBottom = () => {
		scrollIntoViewIfNeeded(this.messagesEnd, false, {
			duration: 150
		});
	}	
	componentDidMount() {
		this.scrollToBottom();
	}
	componentDidUpdate() {
		this.scrollToBottom();
	}
	preRender = () => {
		const dialogMessages = this.props.dialogMessages;
		if (dialogMessages.length) {
			let List = [];
			dialogMessages.forEach((message, index) => {
				List.push(
					<Message key={index} value={message.value} type={message.type} content={message.content} side={message.side} flow = {this.props.flow} />
				)
			});
			return List;
		} else{
			return(<div></div>)
		}
	}
  render() {
		let renderList = this.preRender();
    return (
			<div className='dialog__wrapper'>
				<div className='dialog__container'>
					<div className='dialog__window'>
						<div className='dialog__messages'>
							{ renderList }
							{ this.props.typingText ? <Typing /> : null }
						</div>
					</div>
					<span ref={(el) => { this.messagesEnd = el; }}></span>
				</div>
			</div>
    )
  }
};

export default Dialog;