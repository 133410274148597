import Scenario from 'scenarios/scenario-one';
import Analytics from 'services/utils/Analytics';
import dictionary from 'components/Translate/dictionary';
// import moment from 'moment';
import fetchJSON from 'services/utils/fetchJSON';
import Settings from 'services/config/Settings';
import { TrackJS } from 'trackjs';

// require("moment-duration-format");

class UserJourney {
	constructor(props) {
    this.props = props;
  }
  getStarted() {
		this.props.setState({
			dictionary
		});
		this.userState();
  }
  userState() {
  	let user = this.props.state.user;
  	let activePlan = false;
  	let userStory = null;
  	//let getPlans = false;
  	let userSuspended = typeof user === 'object' && user.blocking_reason ? true : false;

  	if (!user) {
  		userStory = 'newUser';
  	} else if (!userSuspended) {
			const plan = user.billing ? user.billing.plan_status === 'Active' || user.billing.plan_status === 'Single' : false;
			if (user.billing && plan) {
				activePlan = true;
			}
			if (user.sessions.length) {
				userStory = 'userWithBattery';
			} else if (activePlan) {
				userStory = 'userNoBattery';
			} else {
				userStory = 'userNoPlan';
			}
  	} else {
  		userStory = 'userSuspended';
  	}

  	console.log('userStory', userStory, user ? user : false);

  	if (user) {
  		if (Settings.analytics.heap !== 'null') {
	      /* eslint-disable */
	      try {
	        heap.identify(user.id);
	        if (Settings.trackJsEnable) {
						TrackJS.configure({
							userId: user.id.toString()
						});
					}
	      } catch(e) { }
	      /* eslint-enable */
	    }
  	}
  	
  	this.props.state.plans = [];

  	return this.start(activePlan, userStory);
  }
  start(activePlan, userStory) {
		let user = this.props.state.user;
		this.props.state.loading = false;

		// const hasPaymentMethods = typeof user === 'object' && user.has_payment_methods ? true : false;

		let groupIdSelected = false;
		let showLoginPage = false;
		if (this.props.moduleName) {
			switch (this.props.moduleName) {
				case 'refund': {
					if (user) {
						const scenario = Scenario('en', ['Refund']);
						this.props.propSetState({
							scenario: scenario,
							user: {},
							loading: false
						}, () => {
							this.props.moveConversation();
						});
					return;
					}
					break;
				}
				case 'groupIdRegistred': {
					if (this.props.state.groupId) {
						groupIdSelected = true;
					}
					break;
				}
				case 'login': {
					showLoginPage = true;
					break;
				}
				case 'referral': {
					if (user) {
						if (userStory === 'userNoPlan') {
							this.props.history.push("/subscribe");
							return;
						} else if (userStory !== 'newUser')  {
							this.props.history.push("/");
						}
					}
					break;
				}
				default:
			}
		}
		
		console.log('user', user);
		let scenario;
		let hoursSpend = 0;
		let durationSession = '';
		let headerState = 2;
		let doNext = () => {
			this.props.propSetState({
				loading: false
			}, () => {
				this.props.moveConversation();
			});
		}

		if (this.props.moduleName === 'turnOnMap') {
			this.props.propSetState({
				modalMap: true
			});
		}

		switch (userStory) {
			case 'newUser': {
				Analytics('userStory - newUser');
			  scenario = Scenario('en', ['Welcome', 'Auth', 'HelloUser', 'Supernova', 'TakeBattery']);

				headerState = 1;
				user = {};
				break;
			}
			case 'userWithBattery': {
				Analytics('userStory - userWithBattery');
				
				// const sessionOpen = moment.unix(user.electron.session_open);
				// const finished = moment();
				// const ms = finished.diff(sessionOpen);
				// const dur = moment.duration(ms);
				// durationSession = dur.format("d [d] h [h] m [min]");
				// hoursSpend = parseInt((dur._milliseconds/(1000*60*60)), 10);
				// scenario = Scenario('en', ['ReturnBatteryNormal', 'LostBattery']);
				const { can_do_pit_stop } = user;
				if (can_do_pit_stop) {
					scenario = Scenario('en', ['HaveBattery', 'PitStopRules', 'Supernova', 'TakeBattery']);
				} else {
					scenario = Scenario('en', ['HaveBattery'])
				}
				
				break;
				
			}
			case 'userNoBattery': {
				Analytics('userStory - userNoBattery');

				headerState = 3;
				if (this.props.state.tokenSupernova) {
					scenario = Scenario('en', ['HelloUser', 'TakeBattery'])
				} else {
					scenario = Scenario('en', ['HelloUser', 'Supernova', 'TakeBattery'])
				}

				break;
			}
			case 'userNoPlan': {
				Analytics('userStory - userNoPlan');
				scenario = Scenario('en', ['HelloUser', 'Payment', 'Supernova', 'TakeBattery']);
				break;
			}
			case 'userSuspended': {
				Analytics('userStory - userSuspended');
				this.props.state.loading = false;
				this.props.doParticipantAction(null, { name: 'BlockUserScreen' });
				return;
			}
			default: {
				headerState = 1;
				console.log('no user story');
				break;
			}
		}
		const FinishThis = () => {
			this.props.propSetState({
				scenario: scenario,
				activeModule: 0,
				activeStep: 0,
				dialogMessages: [],
				dialogState: 0,
				headerState,
				userStory,
				user,
				hoursSpend,
				durationSession,
				showLoginPage
			}, doNext);
		}
		if (groupIdSelected) {
			fetchJSON('/api/v1/groups/get/'+this.props.state.groupId, {
				method: 'get',
				headers: {
					Source: 'flow'
				}
			}).then(data => {
				console.log('venues get', data);
				if (data.code === 200) {
					const { supernovasOnline } = data;

					let params = {};
					if (supernovasOnline > 0) {
						params = {
							selectedVenue: data.venue,
							headerState: 4
						};
					} else {
						this.props.history.push('/');
						params = {
							groupId: ""
						};
					}
					this.props.propSetState(params, () => {
						FinishThis();
					});
				} else {
					this.props.propSetState({
						groupId: ""
					}, () => {
						FinishThis();
					});
				}
			})
			.catch(error => {
				console.log(error);
				this.props.propSetState({
					groupId: ""
				});
				FinishThis();
			});
		} else {
			FinishThis()
		}
		
	}
}

export default UserJourney;