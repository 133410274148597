export default (props) => {
	let message = 'You took the battery <b>on Wednesday (Aug 23, 2017) at 17:23 from Pasadena Cafe, St. Lord st. 48, 758890</b>';
	props.sendMessageToDialog({ 'type': 'text', 'value': message });
	
	props.propSetState({
		activeStep: props.state.activeStep + 1,
	}, () => {
		setTimeout(() => {
			props.moveConversation();
		}, 2500)
	});

}