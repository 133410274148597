import React from 'react';
import { TranslateString } from 'components/Translate/Translate';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { FacebookButton, TwitterButton } from "react-social";
import Settings from 'services/config/Settings';
import {observer} from "mobx-react"
import './styles.less';
import _ from 'lodash';

@observer
class Message extends React.Component {
  renderMessage = () => {
    if (!this.props.type || this.props.type === 'text') {
      let value = this.props.value;
      if (this.props.content) {
        value = TranslateString(value, this.props.content);
      }
      return (
        <div className='dialog__message' dangerouslySetInnerHTML={{__html : value }}></div>
      );
    }
    if (this.props.type === 'text_resetBtn') {
      let value = _.get(this.props.value, 'text');
      if (this.props.content) {
        value = TranslateString(value, this.props.content);
      }

      const resetBtn = _.get(this.props.value, 'resetBtn');
      return (
        <div className='dialog__message'><span dangerouslySetInnerHTML={{__html : value }}></span> {resetBtn ? <span className={"dialog__collapse-btn" + (resetBtn.class ? ' '+resetBtn.class : '' )} onClick={resetBtn.func} dangerouslySetInnerHTML={{__html : resetBtn.title }}></span> : null}</div>
      );
    }
    if (!this.props.type || this.props.type === 'error') {
      let value = this.props.value;
      if (this.props.content) {
        value = TranslateString(value, this.props.content);
      }
      return (
        <div className='dialog__message dialog__message--error' dangerouslySetInnerHTML={{__html : value }}></div>
      );
    }
    if (!this.props.type || this.props.type === 'debug') {
      return (
        <div className='dialog__message dialog__message--debug' dangerouslySetInnerHTML={{__html : this.props.value }}></div>
      );
    }
	  if (!this.props.type || this.props.type === 'choice') {
      let choice = [];
      this.props.value.forEach((obj, index) => {
        choice.push(
          <button className={"dialog__collapse-btn" + (obj.class ? ' '+obj.class : '' )} key={index} onClick={obj.func} dangerouslySetInnerHTML={{__html : obj.title }}></button>
        );
      });
      return (
        <div className="dialog__collapse">
          {choice}
        </div>
      );
    }		
		if (!this.props.type || this.props.type === 'social-btn') {
			const url = "https://app.getjuize.com";
			const appId = Settings.facebookAppId;
			return(
				<div key={Math.random()} className="dialog__share">
					<FacebookButton url={url} appId={appId} className="dialog__share-btn dialog__share-btn--fb">
						<i className="fa fa-facebook-square" aria-hidden="true"></i><span className="share__content">share on facebook</span>
					</FacebookButton>
					<TwitterButton url={url} className="dialog__share-btn dialog__share-btn--twitter">
						<i className="fa fa-twitter-square" aria-hidden="true"></i><span className="share__content">share on Twitter</span>
					</TwitterButton>
				</div>
			);
		}
		if (!this.props.type || this.props.type === 'choice-v2') {
      let choice = [];
			let variants = this.props.value.values ? this.props.value.values.slice() : [];
			if (variants) {
				variants.forEach((obj, index) => {
					choice.push(
						<button className={"dialog__payments-btn" + (obj.class ? ' '+obj.class : '' )} key={index} onClick={obj.func} dangerouslySetInnerHTML={{__html : obj.title }}></button>
					);
				});
			}
      return (
        <div className="dialog__payments">
					{this.props.value.message ? <div className="dialog__payments-btn dialog__payments--message"  dangerouslySetInnerHTML={{__html : this.props.value.message }}></div>: ''}
          {choice}
        </div>
      );
    }
		if (!this.props.type || this.props.type === 'choice-v3') {
      let choice = [];
			let variants = this.props.value.values ? this.props.value.values.slice() : [];
			if (variants) {
				variants.forEach((obj, index) => {
					choice.push(
						<button className={"dialog__payments-btn" + (obj.class ? ' '+obj.class : '' )} key={index} onClick={obj.func} dangerouslySetInnerHTML={{__html : obj.title }}></button>
					);
				});
			}
      return (
        <div className="dialog__payments">
					{this.props.value.message ? <div className="dialog__payments-btn dialog__payments--message">{this.props.value.message}</div>: ''}
          {choice}
        </div>
      );
    }
		if (!this.props.type || this.props.type === 'agreement-button') {
			return (
        <div className="dialog__agreement">
					<div className="dialog__agreement-text" dangerouslySetInnerHTML={{__html : this.props.value.message }}></div>
          <button className={(this.props.value.class ? ' '+this.props.value.class : '' )} onClick={this.props.value.func} dangerouslySetInnerHTML={{__html : this.props.value.title }}></button>
        </div>
      );
		}
    
    if (this.props.type === 'sticker') {
      return (
        <img className='dialog__sticker' src={this.props.value} alt="" />
      );
    }
    if (this.props.type === 'image') {
      return (
        <img className='dialog__image' src={this.props.value} alt="" />
      );
    }
		if (this.props.type === 'payment') {
			return (
				<div className="dialog__collapse">
					<button className={(this.props.value.class ? ' '+this.props.value.class : '' )} onClick={this.props.value.func} dangerouslySetInnerHTML={{__html : this.props.value.title }}></button>
				</div>
			)
		}
		if (this.props.type === 'venue') {
      const dialogVenueStyle = {};
      let cover = false;
      if (this.props.value) {
        if (typeof this.props.value.photos === 'object') {
          cover = this.props.value.photos.length ? this.props.value.photos[0] : false;
        }
      }
      if (!cover) dialogVenueStyle.minHeight = '1px';
      const resetBtn = _.get(this.props.value, 'resetBtn');
      let style = {};
      if (cover) {
        style = { 
          'backgroundImage': 'url('+cover+')'
        };
      }
      return (
        <div className="dialog__venue" style={dialogVenueStyle}>
          <div className={"dialog__venue-top" + (!cover ? " empty" : "") } style={style}></div>
          <div className="dialog__venue-bottom">
						<b>{this.props.value.title}</b> {this.props.value.address} {resetBtn ? <span className={"dialog__collapse-btn" + (resetBtn.class ? ' '+resetBtn.class : '' )} onClick={resetBtn.func} dangerouslySetInnerHTML={{__html : resetBtn.title }}></span> : null}
					</div>
        </div>
      );
    }
    
  }
  render() {
    return (
      <div className={'dialog__row' + (this.props.side ? ' left' : ' right')}>
        <ReactCSSTransitionGroup
				transitionName="smoothies"
        transitionAppear={this.props.side ? true : false}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
        {this.renderMessage()}
        </ReactCSSTransitionGroup>
      </div>
    )
  }
};

export default Message;