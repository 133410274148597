import React from 'react';
import { Link } from 'react-router-dom';
import enhanceWithClickOutside from 'react-click-outside';
import Auth from 'services/utils/Auth';
import Cookies from 'js-cookie';
import { t } from 'components/Translate/Translate';
import listOfLanguages, { listLangNames } from 'components/Translate/dictionaries';
import LanguageDropDown from './LanguageDropDown';
import fetchJSON from 'services/utils/fetchJSON';
// import { withI18n } from 'react-i18next'; 
// import i18n from 'i18next';

import './styles.less';
import closeIcon from 'components/images/close-icon.svg';

class DropdownMenu extends React.Component {
	state = {
		appLanguage: 'en',
		showLanguageMenu: false
	}
	componentDidMount = () => {
		const appLanguage = Cookies.get('app_language') || 'en';
		this.setState({
			appLanguage
		});
	}
	renderMenu = () => {
		//const { t } = this.props;
		//t('shareable.enterTitlePlease', { defaultValue:  'Please enter a title' }) }
		const menuDropdown = [
			// {
			// 	title: t('menu__referral', { defaultValue:  'Get for Free' }),
			// 	type: 'link',
			// 	value: '/referral'
			// },
			{
				title: t('menu__payments', { defaultValue:  'My Payments' }),
				type: 'link',
				value: '/payments'
			},
			{
				title: t('menu__charges', { defaultValue:  'My Charges' }),
				type: 'link',
				value: '/charges'
			},
			{
				title: t('menu__map', { defaultValue:  'Map' }),
				type: 'link',
				value: '/map'
			},
			{
				title: t('map__faq', { defaultValue:  'Help' }),
				type: 'link',
				value: '/faq'
			},
			{
				title: t('menu__delay_fees', { defaultValue:  'Delay Fees' }),
				type: 'link',
				value: '/delay'
			},
			{
				title: t('menu__about_etg', { defaultValue:  'About Electron-to-Go' }),
				type: 'link',
				value: '/about'
			},
			{
				title: t('menu__logout', { defaultValue:  'Logout' }),
				type: 'link',
				value: '/logout'
			}
		]
		return menuDropdown.map((obj, index) => {
			if (obj.type === 'link') {
				return (
					obj.value === '/map' ?
					<a className="slideMenu__menuItem" href={obj.value} key={index} onClick={this.props.closeMenu}>{obj.title}</a>
					: 
					<Link className="slideMenu__menuItem" to={obj.value} key={index} onClick={this.props.closeMenu}>{obj.title}</Link>
				)
			} else {
				return (
					<span className="slideMenu__menuItem" key={index} onClick={obj.value}>{obj.title}</span>
				)
			}
		})
	}
	renderPlainMenu = () => {
		const menuDropdown = [
			{
				title: t('menu__map', { defaultValue:  'Map' }),
				type: 'link',
				value: '/map'
			},
			{
				title: t('map__faq', { defaultValue:  'Help' }),
				type: 'link',
				value: '/faq'
			},
			{
				title: t('menu__about_etg', { defaultValue:  'About Electron-to-Go' }),
				type: 'link',
				value: '/about'
			}
		]
		return menuDropdown.map((obj, index) => {
			if (obj.type === 'link') {
				return (
					<Link className="slideMenu__menuItem" to={obj.value} key={index}>{obj.title}</Link>
				)
			} else {
				return (
					<span className="slideMenu__menuItem" key={index} onClick={obj.value}>{obj.title}</span>
				)
			}
		})
	}
	handleClickOutside() {
		if (this.props.opened) {
			this.closeLang();
			this.props.closeMenu();
		}
  }
  openMap = () => {
  	this.props.closeMenu();
  	this.props.flow.headerState = 3;
		this.props.flow.modalMap = true;
  }
  openTerms = () => {
  	this.props.closeMenu();
		this.props.flow.modalTerms = true;
  }
  showLanguagesMenu = () => {
  	const { showLanguageMenu } = this.state;
  	this.setState({
			showLanguageMenu: !showLanguageMenu
		});
  }
  closeLang = () => {
  	this.setState({
			showLanguageMenu: !1
		});
  }
  changeAppLang = (lang) => {
		if (listOfLanguages.indexOf(lang) > -1) {
			Cookies.set('app_language', lang, { expires: 365 });
			//i18n.changeLanguage(lang);
			fetchJSON('/api/v1/users/language', {
				method: 'post',
				body: {
					appLanguage: lang
				}
			}).then(data => {
				this.setState({
					appLanguage: lang,
					showLanguageMenu: !1
				}, () => {
					setTimeout(() => {
						window.location.reload(true);
					}, 500);
				});
			})
			.catch(error => {
				console.error('error language', error);
			});
		}
  }
  render() {
  	const { appLanguage, showLanguageMenu } = this.state;
  	const isUserAuthenticated = Auth.isUserAuthenticated();
    return (
			<div className={"slideMenu" + ( this.props.opened ? " opened" : " closed" )}>
				<button className="slideMenu__lang"
								onClick={this.showLanguagesMenu}>{listLangNames[appLanguage]}</button>
				<LanguageDropDown
					showLanguageMenu={showLanguageMenu}
					listLangNames={listLangNames}
					appLanguage={appLanguage}
					changeAppLang={this.changeAppLang}
					closeLang={this.closeLang}
				/>
				<div className="slideMenu__close" onClick={this.props.closeMenu}>
					<img className="slideMenu__icon" src={closeIcon} alt="close" />
				</div>
				{ isUserAuthenticated ? this.renderMenu() : this.renderPlainMenu() }
				<div className="slideMenu__footer">
					<a href="mailto:support@electrontogo.com" target="_blank" rel="noopener noreferrer" className="slideMenu__smallLink">support@electrontogo.com</a>
				</div>
			</div>
    )
  }
};

//export default withI18n()(enhanceWithClickOutside(DropdownMenu));

export default enhanceWithClickOutside(DropdownMenu);
