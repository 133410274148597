import React from 'react';
import moment from 'moment';
import fetchJSON from 'services/utils/fetchJSON';
import { Link } from 'react-router-dom';
import Settings from 'services/config/Settings';
import Loading from 'components/Loading';
import { t, Trans } from 'components/Translate/Translate';

class Charges extends React.Component {
	state = {
		loading: true,
		transactions: []
	}
	componentDidMount = () => {
    const pageTitle = t('pageTitles.PaymentHistory', { defaultValue: 'Payment History'});
		document.title = `${pageTitle} | ${Settings.title}`;
		this.getChargeHistory();
  }
	getChargeHistory = () => {
    fetchJSON('/api/v1/braintree/transactions', {
			method: 'get'
		}).then(data => {
			console.log(data);
			if (data.code === 200) {
				this.setState({
					loading: false,
					transactions: data.transactions
				})
			} else {
				this.props.history.push('/');
			}
     }).catch(error => {
				console.log(error);
				this.setState({
					loading: false
				});
     });
  }
  transactionsHistory = () => {
  	if (this.state.transactions.length) {
  		return this.state.transactions.map((item, index) => {
  			let type = item.payment_instrument_type.replace('_',' ');
  			type = type.charAt(0).toUpperCase() + type.slice(1);
  			let time = moment(item.created_at).format("DD MMM YY, h:mm a");
        let transactionPurpose = t('payment_history_page__transaction_word', { defaultValue:  'Transaction' });
        let status = item.status;
        const goodStatuses = ['authorized','submitted_for_settlement','settling','settlement_pending','settled'];
        if (goodStatuses.indexOf(status) > -1) {
          status = t('payment_history_page__status_paid_word', { defaultValue:  'Paid' });
        }

        if (item.plan) {
          transactionPurpose = `${item.plan.view_label} `;
          if (item.promo) {
            transactionPurpose += 'promo'
          } else {
            transactionPurpose += 'plan'
          }
        }
        let orderStatus = false;
        if (item.order_id) {
          if (item.order) {
            orderStatus = item.order.status;
          }
        }
        let planStatus = false;
        if (item.subscription) {
          planStatus = item.subscription.status;
          if (planStatus === 'Single') planStatus = 'Active';
        }
        let paymentOrderStatus = status;
        if (orderStatus) {
          if (orderStatus === 'paid') {
            if (status !== 'Paid' && planStatus !== 'Refunded') {
              if (status === 'voided') {
                paymentOrderStatus = `verified ($${item.amount})`;
              }
            }
          }          
        }

				return (
					<div key={ index } className="payment-history__history-info">
            <div className="payment-history__colum-left">
              <div className="payment-history__title">{ transactionPurpose }</div>
              <div className="payment-history__text"><Trans i18nKey="payment_history_page__date">Date</Trans>: {time}</div>
              {planStatus ? <div className="payment-history__text"><Trans i18nKey="payment_history_page__plan_status">Plan status</Trans>: {planStatus}</div> : null}
              {item.order_id ? <div className="payment-history__text"><Trans i18nKey="payment_history_page__order_id">Order ID</Trans>: {item.order_id}</div> : null}
              {orderStatus ? <div className="payment-history__text"><Trans i18nKey="payment_history_page__order_status">Order status</Trans>: {orderStatus}</div> : null}
              <div className="payment-history__text"><Trans i18nKey="payment_history_page__order_amount">Order amount</Trans>: { !item.promo ? `${item.amount} ${item.currency}` : '0' }</div>
              <div className="payment-history__text"><Trans i18nKey="payment_history_page__payment_method">Payment method</Trans>: {type} ({ item.last_4 })</div>
              {!orderStatus ? 
                <div className="payment-history__text"><Trans i18nKey="payment_history_page__payment_status">Payment status</Trans>: { status }</div>
              : <div className="payment-history__text"><Trans i18nKey="payment_history_page__payment_status">Payment status</Trans>: { paymentOrderStatus }</div> }
            </div>
          </div>
  			)
  		})
  	} else {
  		return (
  			<div className="wallet__sub-name" style={{'paddingTop': '20px'}}><Trans i18nKey="payment_history_page__no_transactions">You have no active transactions.</Trans></div>
			);
  	}
  }
  render() {
    return (
     	<div className="wallet">
    		<Loading loading = {this.state.loading} className="fill-bg" />
    		<div className="wallet__redbg">
        	<div className="wallet__container">
	          <div className="wallet__header-info">
	            <div className="wallet__header wallet__header--account-main-margin">
	              <Link to="/payments" className="wallet__btn-back wallet__btn-back--btn-back-white"></Link>
	              <div className="wallet__title wallet__title--color-charges"><Trans i18nKey="payment_history_page__title">Payment History</Trans></div>
	            </div>
	          </div>
          </div>
         </div>
         <div className="wallet__container">
         	<div className="payment-history">
         		{ this.transactionsHistory() }
        	</div>
        </div>
	    </div>
    )
  }
};

export default Charges;