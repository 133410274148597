import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

class PreferredCablesFilter extends React.Component {
  handleClickOutside() {
    this.props.hide();
  }
  render() {
    const { activeOptions } = this.props;
    return (
      <div className={"maps__cables-modal "+(this.props.class || '' )}>
      <div className="bookingCables__inner">
        <button
          className="booking__btn"
          onClick={() => this.props.onChange('lightning')}
        ><span className={"booking__btn--inner lightning " + (activeOptions.indexOf('lightning') > -1 ? 'filterOn' : 'filterOff')}>Apple<br/>Lightning</span></button>
        <button
          className="booking__btn"
          onClick={() => this.props.onChange('usb_c')}
        ><span className={"booking__btn--inner usb_c " + (activeOptions.indexOf('usb_c') > -1 ? 'filterOn' : 'filterOff')}>USB-C</span></button>
        <button
          className="booking__btn"
          onClick={() => this.props.onChange('micro_usb')}
        ><span className={"booking__btn--inner micro_usb " + (activeOptions.indexOf('micro_usb') > -1 ? 'filterOn' : 'filterOff')}>Micro USB</span></button>
      </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(PreferredCablesFilter);