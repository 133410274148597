import fetchJSON from 'services/utils/fetchJSON';
import GroupId from 'services/utils/GroupId';
import Cookies from 'js-cookie';
import storeFlow from '../config';
//import i18n from 'i18next';

export default (props) => {
	const appLanguage = Cookies.get('app_language');
	fetchJSON('/api/v1/welcome', {
		method: 'post',
		body: {
			appLanguage
		}
	}).then(data => {
			console.log('welcome', data);
			if (data.code === 200) {
				let moduleName = null;
				let groupId = '';
				if (props.module && props.module.m) {
					moduleName = props.module.m;
					if (props.module.groupId) {
						groupId = props.module.groupId;
						GroupId.add(groupId);
					}
				}
				let freeFirstDay = false;
				if (data.freeFirstDay) {
					freeFirstDay = true;
				}
				props.propSetState({
					...storeFlow,
					user: data.user ? data.user : false,
					location: data.location,
					groupId,
					freeFirstDay
				}, () => {
					props.doParticipantAction(moduleName, { name: 'UserJourney' });
				});
			}
			if (data.code === 403) {
				document.documentElement.innerHTML = '';
			}
			if (data.user.language) {
				//if (data.user.language !== appLanguage || i18n.language !== appLanguage) {
				if (data.user.language !== appLanguage) {
					Cookies.set('app_language', data.user.language, { expires: 365 });
					//i18n.changeLanguage(data.user.language);
					setTimeout(() => {
						window.location.reload(true);
					}, 500);
				}
			}
	 }).catch(error => {
			//console.log('error welcome');
	});
}