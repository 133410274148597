import React from 'react';
import Cookies from 'js-cookie';
import TranslateController from './TranslateController';
import {observer} from "mobx-react";
// import fullDictionary from './dictionary';
import listOfLanguages from './dictionaries';
import reactElementToJSXString from 'react-element-to-jsx-string';
import ReactHtmlParser from 'react-html-parser';

const translateGateway = new TranslateController();

const getDictionary = () => {
	let appLanguage = Cookies.get('app_language') || 'en';
	if (listOfLanguages.indexOf(appLanguage) === -1) {
		appLanguage = 'en';
	}
	const dictionaryObj = require(`./dictionaries/${appLanguage}.js`).default;

	return dictionaryObj;
}
export const TranslateString = (value, content, dictionary = {}) => {	
	const dictionaryObj = getDictionary();
	if (dictionaryObj[content]) {
		return dictionaryObj[content];
	} else {
		dictionary[content] = value;
		dictionaryObj[content] = value;
		const text = translateGateway.put({
			key: content,
			value: value
		});
		return text;
	}
};
export const t = (key, params) => {
	return TranslateString(params.defaultValue, key);
};

@observer
class Translate extends React.Component {
	renderTranslate = () => {
		const dictionaryObj = getDictionary();
		const key = this.props.content || this.props.i18nKey;
		if (dictionaryObj[key]) {
			return ReactHtmlParser(dictionaryObj[key]);
		} else {
			let value = this.props.children;
			if (typeof value !== 'string') {
				value = '';
				this.props.children.forEach((elem) => {
					if (typeof elem === 'string') {
						value += elem + ' ';
					} else {
						value += reactElementToJSXString(elem) + ' ';
					}
				});
				value = value.replace(/(\r\n|\n|\r)/gm, "");
			}
			dictionaryObj[key] = value;
			//console.log('fullDictionary', fullDictionary);
			const text = translateGateway.put({
				key: key,
				value: value
			});
			return ReactHtmlParser(text);
		}
	}
  render() {
    return (
    	<React.Fragment>
    	{ this.renderTranslate() }
    	</React.Fragment>
    )
  }
};
export const Trans = Translate;

export default Translate;