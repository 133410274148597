import { t } from 'components/Translate/Translate';

const Auth = {
  name: 'phone',
  steps: [
    {
      action: 'message',
      type: 'text',
      content: '',
      value: t("auth-dialog_write-number", { defaultValue:  'To start get Juice, please, type your phone number' }),
      timeout: 500,
      typing: false
    },
    {
      action: 'func',
      attempts: 9,
      name: 'phoneAuth',
      controller: {
        status: true,
        placeholder: t("auth-dialog__type_phone_placeholder", { defaultValue:  'Type phone' }),
        label: '',
        value: '',
        type: 'tel',
        name: 'phone'
      }
    }
  ]
};

export default Auth;