import React from 'react';
import Settings from 'services/config/Settings';
import { t, Trans } from 'components/Translate/Translate';
import CountryDropDown from './components/CountryDropDown';
import fetchJSON from 'services/utils/fetchJSON';
import Cookies from 'js-cookie';

import './style.less';

class DelayFees extends React.Component {
  constructor() {
    super();
    const emptyPrices = {
      hourly_rate: {
        additional_payment_price: '',
        max_daily_charge: '',
        penalty_price: '',
        delay_return_fee: ''
      },
      daily: {
        delay_return_fee: ''
      },
      promo: {
        penalty_price: '',
        delay_return_fee: ''
      },
      monthly: {
        annual: ''
      },
      annual: {
        delay_return_fee: ''
      },
      general: {
        delay_return_fee: '',
        refundWithin20DelayFree: '',
        otherReturn: ''
      }
    };
    this.state = {
      showCountryMenu: false,
      activeCountry: 'US',
      venueType: 'public',
      loading: false,
      pricing: {
        US: emptyPrices,
        JP: emptyPrices
      },
      user: false,
      showPlan: 'all',
      fetching: false,
      savedLanguage: 'en'
    }
  }
  componentDidMount = () => {
    const title = t("delayFee.page.title", { defaultValue:  'Delay Fee Summary' });
    document.title = `${title} | ${Settings.title}`;
    this.getDefaultPrices();

    const savedLanguage = Cookies.get('app_language');
    if (savedLanguage) {
      let activeCountry = 'US';
      if (savedLanguage === 'ja') {
        activeCountry = 'JP';
      }
      this.setState({
        savedLanguage,
        activeCountry
      });
    }
  }
  getDefaultPrices = () => {
    this.setState({
      fetching: true
    });
    fetchJSON('/api/v1/pricing_list', {
      method: 'get'
    }).then(response => {
      console.log(response);
      const setObj = { fetching: false };
      if (response.code === 200) {
        const { pricing, user } = response.data;
        if (pricing) {
          setObj.pricing = pricing;
        }
        if (user) {
          setObj.user = user;
          if (user.active_plan && ['daily', 'promo', 'annual', 'monthly', 'hourly_rate', "free_use_no_penalty", "free_use_with_penalty"].indexOf(user.active_plan) > -1) {
            setObj.showPlan = user.active_plan;
            
            let venueType = 'public';

            if (user.active_plan.startsWith('free_use')) {
              venueType = 'private';
            }
            setObj.venueType = venueType;
          }
        }
      }
      this.setState(setObj);
     }).catch(error => {
        this.setState({
          fetching: false
        });
        console.log(error);
     });
  }
  showLanguagesMenu = () => {
    const { showCountryMenu } = this.state;
    this.setState({
      showCountryMenu: !showCountryMenu
    });
  }
  closeCountry = () => {
    this.setState({
      showCountryMenu: !1
    });
  }
  changeCountryLang = (country) => {
    this.setState({
      activeCountry: country,
      showCountryMenu: !1
    });
  }
  venueType = (e) => {
    this.setState({
      venueType: e.target.value
    });
  }
  fillPrice = (string, price, price1 = false) => {
    let source = string;

    source = source.replace("#PRICE#", price);
    if (price1) {
      source = source.replace("#PRICE1#", price1);
    }
    return source;
  }
  render() {
    const {
      showCountryMenu,
      activeCountry,
      venueType,
      pricing,
      showPlan,
      fetching,
      savedLanguage
    } = this.state;

    const listCountryNames = {
      'US': <Trans i18nKey="delayFee.countries.us">USA</Trans>,
      'JP': <Trans i18nKey="delayFee.countries.jp">Japan</Trans>,
      'GB': <Trans i18nKey="delayFee.countries.gb">UK</Trans>,
    };
    let pricinList = {};

    try {
      pricinList = pricing[activeCountry];
    } catch(err) {
      pricinList = pricing['US'];
    }
  
    return (
      <div className="delayFee">
          <div className="wallet__container">
            <div className="delayFeeMenu">
              
              <div className="delayFeeMenu__column" style={{ width: '40%' }}>
                <div className="delayFeeMenu__switch">
                  <button className="slideMenu__lang delayFeeMenu__switchHandler"
                  onClick={this.showLanguagesMenu}>{listCountryNames[activeCountry]}</button>
                  <CountryDropDown
                    showCountryMenu={showCountryMenu}
                    listCountryNames={listCountryNames}
                    activeCountry={activeCountry}
                    changeCountryLang={this.changeCountryLang}
                    closeCountry={this.closeCountry}
                  />
                </div>
              </div>
              <div className="delayFeeMenu__column" style={{ width: '60%' }}>
                { showPlan === 'all' ? <select className="delayFeeMenu__locType" value={venueType} onChange={this.venueType}>
                  <option value="public">{t("delayFee.switch.public", { defaultValue:  'Public Location' })}</option>
                  <option value="private">{t("delayFee.switch.private", { defaultValue:  'Private Location' })}</option>
                </select> : null }
              </div>
            </div>
            <div className="delayFeeHeader">
              <div className="delayFeeHeader__title">
                <Trans i18nKey="delayFee.page.title">Delay Fee Summary</Trans>
              </div>
              <div className="delayFeeHeader__descr">
                <Trans i18nKey="delayFee.page.description">
                  Late fees are no fun, for us or for you. But each lost Electron® requires that we ship a replacement to keep the network running smoothly.
                </Trans>
              </div>
            </div>
            { !fetching ? <div className="delayFeeBody">
              { venueType === 'public' ? <div>
                { showPlan === 'all' || showPlan === 'hourly_rate' ? <div className="delayFeeCard">
                  <div className="delayFeeCard__title">
                    <Trans i18nKey="delayFee.hourly.title">Hourly</Trans>
                  </div>
                  <ul className="delayFeeCard__list">
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.hourly.rule_1">Location’s Hourly Rate provides a 2-hour rental period</Trans>
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.hourly.rule_2", { defaultValue:  '#PRICE# every additional hour on Day 1' }), pricinList.hourly_rate.additional_payment_price)}
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.hourly.rule_3", { defaultValue:  '#PRICE# maximum daily charge' }), pricinList.hourly_rate.max_daily_charge)}
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.hourly.rule_4", { defaultValue:  '#PRICE# charged daily from Day 2 until the Electron® is returned' }), pricinList.hourly_rate.penalty_price)}
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.general.rule_maximum", { defaultValue:  'Total delay fees capped at #PRICE# maximum' }), pricinList.hourly_rate.delay_return_fee)}
                    </li>
                  </ul>
                </div> : null }
                
                { showPlan === 'all' || showPlan === 'daily' ? <div className="delayFeeCard">
                  <div className="delayFeeCard__title">
                    <Trans i18nKey="delayFee.daily.title">Daily</Trans>
                  </div>
                  <ul className="delayFeeCard__list">
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.daily.rule_1">Location’s Daily Rate provides unlimited sessions for 24 hours</Trans>
                    </li>
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.daily.rule_2">Daily Rate charged again every 24 hours, up to 10 days</Trans>
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.general.rule_maximum", { defaultValue:  'Total delay fees capped at #PRICE# maximum' }), pricinList.daily.delay_return_fee)}
                    </li>
                  </ul>
                </div> : null }
                

                { showPlan === 'all' || showPlan === 'promo' ? <div className="delayFeeCard">
                  <div className="delayFeeCard__title">
                    <Trans i18nKey="delayFee.promo.title">1st-Time-Free or Promo</Trans>
                  </div>
                  <ul className="delayFeeCard__list">
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.promo.rule_1">Free unlimited sessions for 24 hours</Trans>
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.promo.rule_2", { defaultValue:  '#PRICE# charged daily from Day 2 until the Electron is returned' }), pricinList.promo.penalty_price)}
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.general.rule_maximum", { defaultValue:  'Total delay fees capped at #PRICE# maximum' }), pricinList.promo.delay_return_fee)}
                    </li>
                  </ul>
                </div> : null }

                { showPlan === 'all' || showPlan === 'monthly' ? <div className="delayFeeCard">
                  <div className="delayFeeCard__title">
                    <Trans i18nKey="delayFee.monthly.title">Monthly</Trans>
                  </div>
                  <ul className="delayFeeCard__list">
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.monthly.rule_1">Maximum of 10 days to return each Electron</Trans>
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.monthly.rule_2", { defaultValue:  'On Day 11, you will be charged #PRICE# to upgrade your account to an Annual Subscription' }), pricinList.monthly.annual)}
                    </li>
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.monthly.rule_3">Your new Annual subscription starts on the date of the charge.</Trans>
                    </li>
                  </ul>
                </div> : null }
                

                { showPlan === 'all' || showPlan === 'annual' ?  <div className="delayFeeCard">
                  <div className="delayFeeCard__title">
                    <Trans i18nKey="delayFee.annual.title">Annual</Trans>
                  </div>
                  <ul className="delayFeeCard__list">
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.monthly.rule_1">Maximum of 10 days to return each Electron</Trans>
                    </li>
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.annual.rule_1">Annual Subscription includes 1 free loss credit - you will not be charged anything for your first lost Electron. </Trans>
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.annual.rule_2", { defaultValue:  'A second or additional loss of Electrons® will incur a loss fee of #PRICE# on Day 10.' }), pricinList.annual.delay_return_fee)}
                    </li>
                  </ul>
                </div> : null }
              
                { showPlan === 'all' ? <div className="delayFeeCard">
                  <div className="delayFeeCard__title">
                    <Trans i18nKey="delayFee.general.title">General</Trans>
                  </div>
                  <ul className="delayFeeCard__list">
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.general.rule_1", { defaultValue:  'Electrons lost during any rental period or session will incur delay/loss fees totaling #PRICE#' }), pricinList.general.delay_return_fee)}
                    </li>
                    <li className="delayFeeCard__item">
                      {this.fillPrice(t("delayFee.general.rule_2", { defaultValue:  'If you return your Electron within 20 days after paying #PRICE# for a loss, you will receive a #PRICE1# User Credit.' }), pricinList.general.delay_return_fee, pricinList.general.refundWithin20DelayFree)}
                    </li>
                    <li className="delayFeeCard__item">
                    {this.fillPrice(t("delayFee.general.rule_3", { defaultValue:  'All other returns of lost Electrons will result in a #PRICE# User Credit to the returning party.' }), pricinList.general.otherReturn)}
                    </li>
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.general.rule_4"><b>Address to return:</b> P.O. Box 505 Murrieta, CA. 92562 or email support@electrontogo.com</Trans>
                    </li>
                  </ul>
                </div> : null }
                </div> : null }
                { venueType === 'private' ? <div>
                
                { showPlan === 'all' || showPlan === 'free_use_with_penalty' ? <div className="delayFeeCard">
                  <div className="delayFeeCard__title">
                    <Trans i18nKey="delayFee.freeWithPayment.title">If payment method is requested:</Trans>
                  </div>
                  <ul className="delayFeeCard__list">
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.freeWithPayment.rule_1">Your session is free. You still need to return your Electron.</Trans>
                    </li>
                    <li className="delayFeeCard__item">
                    {this.fillPrice(t("delayFee.freeWithPayment.rule_2", { defaultValue:  'On Day 10, you will be charged a loss fee of #PRICE#' }), pricinList.general.delay_return_fee)}
                    </li>
                  </ul>
                </div> : null }
                
                { showPlan === 'all' || showPlan === 'free_use_no_penalty' ? <div className="delayFeeCard">
                  <div className="delayFeeCard__title">
                    <Trans i18nKey="delayFee.noPayment.title">If no payment method is requested:</Trans>
                  </div>
                  <ul className="delayFeeCard__list">
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.noPayment.rule_1">Your session is free. You still need to return your Electron.</Trans>
                    </li>
                    <li className="delayFeeCard__item">
                      <Trans i18nKey="delayFee.noPayment.rule_2">You consent that the venue sponsor has the right to contact you via your registered phone number or email.</Trans>
                    </li>
                  </ul>
                </div> : null }
                </div> : null }
                <div className="delayFeeBody__terms">
                  <a className="delayFeeBody__termsLink" href={`https://electrontogo.com/terms?lng=${savedLanguage}`}>
                    <Trans i18nKey="delayFee.termsAndConditions">Terms and Conditions</Trans>
                  </a>
                </div>
            </div> : null }
        </div>
      </div>
    )
  }
};

export default DelayFees;