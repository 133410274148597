const store = {
	scenario: [],
	activeModule: 0,
	activeStep: 0,
	loading: true,
	dictionary: {},
	user: {},
	userPhone: '',
	debug: false,
	activeMessageFromUser: null,
	activePhoneCode: null,
	activePhoneState: 0,
	controller: {
		status: false,
		placeholder: 'Type a message',
		value: '',
		type: 'text'
	},
	dialogMessages: [],
	typingText: false,
	modalPayment: false,
	applePayPayment: false,
	applePayInstance: false,
	venmoInstance: false,
	vemnoPayment: false,
	paypalCheckoutInstance: false,
	paypalPayment: false,
	paymentApi: '/api/v1/braintree/checkout',
	paypalReady: false,
	deviceData: '',
	paymentType: 'checkout',
	plan: false,
	userActions: [],
	hoursSpend: 0,
	durationSession: '',
	groupId: '',
	modalTerms: false,
	modalMap: false,
	modalFaq: false,
	/*
	* headerState
	* 1 - only logo
	* 2 - logo && menu
	* 3 - menu && venue search field
	* 4 - menu && selected venue field
	*/
	headerState: 1,
	/*
	* Show dialog view 1
	* Show full screen step view 0
	*/
	dialogState: 0,
	/*
	* react container for full screen view
	*/
	fullScreenStore: null,
	/*
	* Parameter for user HelloUser screen
	* - userWithBattery
	* - userNoBattery
	* - userNoPlan
	*/
	userStory: '',
	showUserController: false,
	// selected venue object
	selectedVenue: {
		title: ''
	},
	paymentProccess: 'normal',
	activeHeader: true,
	// list of plans for user subscription
	plans: {},
	location: false,
	background: 'dark',
	showLoginPage: false,
	showReport: false,
	reportItem: '',
	reportItemId: '',
	canExchangeBattery: false,
	exchangeBatteryFlow: false,
	freeFirstDay: false
}

export default store;
