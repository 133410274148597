//import fetchJSON from 'services/utils/fetchJSON';
import Scenario from 'scenarios/scenario-one';
import { t } from 'components/Translate/Translate';

export default (props) => {
	// const resetFlow = () => {
	// 	props.propSetState({
	// 		userActions: {},
	// 		typingText: true,
	// 		dialogMessages: []
	// 	}, () => {
	// 		props.doParticipantAction(null, { name: 'Welcome' });
	// 	});
	// }
	const userActions = {
		active: true,
		label: false ? 'Choose station to get Juice!' : null,
		buttons: [
			// {
			// 	text: 'Return battery',
			// 	class: 'btn-debug',
			// 	func: (e) => {
			// 		// select supernova
			// 		fetchJSON('/api/v1/electrons/back', {
			// 			method: 'post',
			// 			body: {
			// 				supernova_id: 1
			// 			}
			// 		}).then(data => {
			// 			console.log(data);
			// 			resetFlow();
			// 		}).catch(error => {
			// 				console.log(error);
			// 	 });
			// 	}
			// },
			{
				text: t("returnBattery-openMap-btn", { defaultValue:  'Find station on map' }),
				func: (e) => {
					// show ma
					props.propSetState({
						modalMap: true
					})
				}
			},
			{
				text: t("returnBattery-lostBattery-btn", { defaultValue:  'I lost a battery. What do I need to do?' }),
				class: 'btn-like-link',
				func: (e) => {
					// select supernova
					let scenario = Scenario('en', ['LostBattery']);
					props.setState({
						scenario,
						userActions: {},
						activeModule: 0,
						activeStep: 0,
					}, () => {
						props.moveConversation();
					});
				}
			}
		]
	}
	
	props.setState({ 
		userActions
	});
	
}