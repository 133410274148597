import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

class LanguageDropDown extends React.Component {
	handleClickOutside() {
		const { showLanguageMenu, closeLang } = this.props;
		if (showLanguageMenu) {
			closeLang();
		}
  }
	render() {
		const { showLanguageMenu, listLangNames, appLanguage, changeAppLang } = this.props;
		return (
			<div className={"slideMenuLang__list" + (showLanguageMenu ? ' active' : ' closed') }>
					<ul className="slideMenuLang__listWrapper">
						{ Object.keys(listLangNames).map((lang, key) =>
							<li key={key}
									className={"slideMenuLang__listItem" + (lang === appLanguage ? ' active' : '')}
									onClick={() => changeAppLang(lang)}>{listLangNames[lang]}</li>
						) }
					</ul>
				</div>
			)
	}
}

export default enhanceWithClickOutside(LanguageDropDown);