export default (props) => {
	const userActions = {
		active: true,
		message: props.activeStep.type === 'withText' ? props.activeStep.value.text : '',
		label: props.activeStep.label,
		buttons: [
			{
				text: props.activeStep.value,
				class: props.activeStep.skipToSignUp ? 'passive' : '',
				func: (e) => {
					const next = props.state.activeStep + 1;
					props.userWriteToDialog(props.activeStep.value);
					props.propSetState({ 
						typingText: true,
						activeStep: next,
						userActions: {}
					}, () => {
						props.moveConversation();
					});
				}
			}
		]
	}
	if (props.activeStep.skipToSignUp) {
		userActions.buttons.push(
			{
				text: 'Sign up to get free month',
				class: 'active',
				func: (e) => {
					const next = props.state.activeStep + 1;
					props.userWriteToDialog('Sign up to get free month');
					props.propSetState({ 
						typingText: true,
						activeStep: next,
						userActions: {}
					}, () => {
						props.moveConversation({name: 'phone', step: 0});
					});
				}
			}
		)
	}
	
	props.setState({ 
		userActions
	});
}