import * as Stickers from 'scenarios/components/Stickers';

const TakeBattery = {
	name: 'TakeBattery',
	steps: [
		{
			action: 'func',
			name: 'TakeBattery'
		},
		{
			action: 'func',
			name: 'TookBattery'
		},
		{
			action: 'message',
			type: 'sticker',
			value: Stickers.Party,
			timeout: 1500,
			typing: false
		},
	]
};

export default TakeBattery;