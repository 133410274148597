import React from 'react';
import {observer} from "mobx-react"
import enhanceWithClickOutside from 'react-click-outside';
import IconGeo from '../images/marker.png';
import IconGeoPrivate from '../images/ic-map-marker-private.png';
import { t } from 'components/Translate/Translate';

import './styles.less';


@observer
class MapMarker extends React.Component {
	state = {
		showCloud: false
	}
	clickOn = () => {
		this.props.flow.dialogState = 0;
		this.props.flow.modalMap = false;
		this.props.flow.headerState = 4;
		this.props.flow.selectedVenue = this.props.venue;
		this.props.doParticipantAction(false, { name: 'VenueSelectScreen' }, {
			title: this.props.title,
			description: this.props.description,
			venue: this.props.venue
		});
	}
	showCloud = () => {
		this.setState({
			showCloud: true
		});
	}
	handleClickOutside() {
		if (this.state.showCloud) {
			this.setState({
				showCloud: false
			});
		}
  }
  render() {
  	const { showCloud } = this.state;
  	const { cablesAvailable, cablesToShow, is_public } = this.props;

		let hidden = true;
		if (this.props.electrons[this.props.typeToShow]) {
			hidden = false;
		}
		if (this.props.typeToShow === 'get') {
			let haveCable = false;
			if (cablesToShow && cablesAvailable) {
				if (cablesToShow.length && cablesAvailable.length) {
					cablesToShow.forEach((cable) => {
						if (cablesAvailable.indexOf(cable) > -1) {
							haveCable = true;
						}
					});
				}
			}
			if (!haveCable) {
				hidden = true;
			}
		}

		const markerShow = {
			'display': hidden ? 'none' : 'block'
		};
		//Private location
		const privateLocationLabel = t("delayFee.switch.private", { defaultValue:  'Private Location' });
		const hover = this.props.$hover || showCloud ? 'block' : 'none';
    return (
			<div className="marker" style={markerShow}>
				<img src={is_public ? IconGeo : IconGeoPrivate } alt="" className="marker__icon" onClick={this.showCloud} />
				<div className={"marker__window " + (!is_public ? "private" : '') } style={{'display': hover}} onClick={this.clickOn}>
					<div className="marker__inner">
						{ !is_public ? 
							<div className="marker__label">{privateLocationLabel}</div>
						: null }
						<div className="marker__title">{this.props.title}</div>
						<div className="marker__description">{this.props.address}</div>
					</div>
				</div>
			</div>
    );
  }
}


export default enhanceWithClickOutside(MapMarker);