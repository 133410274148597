import React from 'react';
import Button from 'components/Button/Button';
import { t, Trans } from 'components/Translate/Translate';

export default (props) => {	
	class SupernovaFindScreen extends React.Component {
		goForward = () => {
			props.propSetState({
				activeStep: props.state.activeStep + 1,
			}, () => {
				props.moveConversation();
			});
		}
		findVenue = () => {
			props.doParticipantAction(false, { name: 'SupernovaHelpScreen' });
		}
		enterStationCode = () => {
			props.sendMessageToDialog({ 
				'type': 'text',
				'value': t("find-supernova-screen_enterCode", { defaultValue:  'Enter station code' })
			});
			props.propSetState({ 
				userActions: {},
				dialogState: 1,
				headerState: 2
			}, () => {
				props.moveConversation({ name: 'supernova', step: 0 });
			});
		}
		render() {
			return (
				<div className="app-container">
					<div className="dialog-screen">
						<div className="dialog__container">
							<div className="dialog__window">
								<div className="dialog__messages no-reverse">
									<div className="dialog__row left">
										<span>
											<div className="dialog__message"><Trans i18nKey="find-supernova-screen_question" >Are you near battery station?</Trans></div>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="dialog-bottom__container ">
							<div>
								<Button
									onClick={this.enterStationCode}
									text={t("find-supernova-screen_btn-yes", { defaultValue:  'Yes' })}  />
							</div>
							<Button
								addClass="white-btn"
								onClick={this.findVenue}
								text={t("find-supernova-screen_btn-no", { defaultValue:  'No' })} />
						</div>
					</div>
				</div>
			)
		}
	};
	class SupernovaApproveVenue extends React.Component {
		goForward = () => {
			props.propSetState({
				activeStep: props.state.activeStep + 1,
			}, () => {
				props.moveConversation();
			});
		}
		takeBattery = () => {
			props.propSetState({
				userActions: {},
				dialogState: 1
			}, () => {
				props.sendMessageToDialog({ 'type': 'venue', 'value': props.state.selectedVenue });
				props.sendMessageToDialog({ 'type': 'text', 'value': t( "supernova-get-success", { defaultValue:  'Whoa, Electron is available!' }) });
				props.moveConversation({ name: 'TakeBattery', step: 0 });
			});
		}
		enterStationCode = () => {
			props.sendMessageToDialog({ 
				'type': 'text',
				'value': t("find-supernova-screen_enterCode", { defaultValue:  'Enter station code' })
			});
			props.propSetState({ 
				userActions: {},
				dialogState: 1,
				headerState: 2
			}, () => {
				props.moveConversation({ name: 'supernova', step: 0 });
			});
		}
		render() {
			let style = {};
			const cover = props.state.selectedVenue.photos[0];
	    if (cover) {
	      style = { 
	        'backgroundImage': 'url('+cover+')'
	      };
	    }
			return (
				<div className="app-container">
					<div className="dialog-screen">
						<div className="dialog__container">
							<div className="dialog__window">
								<div className="dialog__messages no-reverse">
									<div className="dialog__row left">
										<span>
											<div className="dialog__message"><Trans i18nKey="find-supernova-correct-venue" >Is this the right venue where you are?</Trans></div>
										</span>
									</div>
									<div className="dialog__row left">
										<span>
											<div className="dialog__venue">
												<div className={"dialog__venue-top" + (!cover ? " empty" : "") } style={style}></div>
												<div className="dialog__venue-bottom"><b>{props.state.selectedVenue.title}</b> {props.state.selectedVenue.address}</div>
											</div>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="dialog-bottom__container ">
							<div>
								<Button
									onClick={this.takeBattery}
									text={t("find-supernova-screen_btn-yes", { defaultValue:  'Yes' })}  />
							</div>
							<Button
								addClass="white-btn"
								onClick={this.enterStationCode}
								text={t("find-supernova-screen_btn-no", { defaultValue:  'No' })} />
						</div>
					</div>
				</div>
			)
		}
	};
	if (props.state.groupId) {
		props.propSetState({
			fullScreenStore: <SupernovaApproveVenue />
		});
	} else {
		props.propSetState({
			fullScreenStore: <SupernovaFindScreen />,
			headerState: 3
		});
	}	
}