import React from 'react';
import { Trans } from 'components/Translate/Translate';

class AddCardForm extends React.Component {
  componentDidMount = () => {

  }
  render() {
    const { visible, onSubmit, cardValidateError } = this.props;
    return (
      <div className={"sbCardForm "+(visible ? 'visible' : 'hide')}>  
        <form onSubmit={onSubmit} action="/" method="post" id="cardForm" >
          <label className="hosted-fields--label" htmlFor="card-number">
            <Trans i18nKey="addCardForm.CardNumber">Card Number</Trans>
          </label>
          <div id="card-number" className="hosted-field"></div>

          <label className="hosted-fields--label" htmlFor="expiration-date">
            <Trans i18nKey="addCardForm.ExpirationDate">Expiration Date</Trans></label>
          <div id="expiration-date" className="hosted-field"></div>

          <label className="hosted-fields--label" htmlFor="cvv">
          <Trans i18nKey="addCardForm.CVV">CVV</Trans></label>
          <div id="cvv" className="hosted-field"></div>
          {cardValidateError ? <div className="sbCardForm__error">{cardValidateError}</div> : null }
          <div className="button-container">
            <button type="submit" className="sbOrder__action-btn" id="submit" >
              <Trans i18nKey="addCardForm.PurchaseButton">Purchase</Trans>
            </button>
          </div>
        </form>
      </div>
    )
  }
};

export default AddCardForm;