import React from 'react';
import { Link } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';
import Loading from 'components/Loading';
import Settings from 'services/config/Settings';
import { t, Trans } from 'components/Translate/Translate';
import BackIcon from 'components/images/ic-back.svg';
import Analytics from 'services/utils/Analytics';
import _ from 'lodash';

class SubscribePayments extends React.Component {
  state = {
    payment_methods: [
      {
        label: 'card',
        viewLabel: t('paymentScreen.paymentMethods.creditCard', { defaultValue:  'Credit card' }),
        token: 'card',
        newMethod: true
      },
      {
        label: 'paypal',
        viewLabel: t('paymentScreen.paymentMethods.PayPal', { defaultValue:  'PayPal' }),
        token: 'paypal',
        newMethod: true
      },
      {
        label: 'venmo',
        viewLabel: t('paymentScreen.paymentMethods.Venmo', { defaultValue:  'Venmo' }),
        token: 'venmo',
        newMethod: true
      },
      { 
        label: 'applePay',
        viewLabel: t('paymentScreen.paymentMethods.ApplePay', { defaultValue:  'Apple Pay' }),
        token: 'applePay',
        newMethod: true
      }
    ],
    cardActive: true,
    paypalActive: true,
    venmoActive: false,
    applePayActive: false,
    fetch: true,
    paymentMethod: null,
    promo_id: null,
    order: {},
    disabledNextBtn: false,
    clientToken: '',
    selectedMethod: null,
    showNewMethods: true,
    userEmail: '',
    userZip: '',
    showEmailField: true,
    errorEmail: '',
    errorZip: '',
    has_payment_methods: 0,
    currency: 'USD'
  }
  componentDidMount = () => {
    const pageTitle = t('pageTitles.Payments', { defaultValue: 'Payments'});
    document.title = `${pageTitle} | ${Settings.title}`;
    const orderId = this.props.match.params.orderId;
    if (orderId) {
      this.subscribeStart(orderId);
    } else {
      this.props.history.push('/');
    }
  }
  subscribeStart = (orderId) => {
    fetchJSON(`/api/v1/subscribe/process/${orderId}`, {
      method: 'get'
    }).then(data => {
      if (data.code === 200) {
        console.log(data);
        if (data.order.status === 'paid') {
          this.props.history.push(`/subscribe/${orderId}/purchase`);
          return false;
        }
        const order = data.order.order;

        const currency = _.get(data, 'order.currency');

        this.setState({
          promo_id: order.promo_id,
          paymentMethod: order.paymentMethod,
          order,
          selectedMethod: order.selectedMethod || null,
          has_payment_methods: data.user.has_payment_methods,
          currency
        }, () => {
          if (data.user.has_payment_methods) {
            this.getPaymentMethods();
          } else {
            this.setState({
              fetch: false,
              selectedMethod: this.state.payment_methods[0]
            })
          }
        });

        const toState = {}
        if (data.user.email && data.user.email_status) {
          toState.showEmailField = false
        }
        if (data.user.email) {
          toState.userEmail = data.user.email;
        }
        if (data.user.zip) {
          toState.userZip = data.user.zip;
        }

        this.setState(toState);
        this.applePayAvailable();
        this.initBraintree();
      } else {
        this.props.history.push(`/subscribe`);
      }
    }).catch(error => {
      console.error(error);
      this.props.history.push('/');
    });
  }
  initBraintree() {
    if (!document.getElementById('braintreeScript')) {
      this.addScriptHead('braintreeScript', 'client.min.js', 'braintree', this.braintreeReady);
    }
  }
  addScriptHead = (name, url, base = 'braintree', onload = {}) => {
    const braintreeScript = document.createElement('script');
    braintreeScript.setAttribute('id', name);
    let source = '';
    if (base === 'braintree') {
      source = 'https://js.braintreegateway.com/web/3.42.0/js/';
    }
    braintreeScript.onload = onload;
    braintreeScript.setAttribute('src', `${source}${url}`);
    document.head.appendChild(braintreeScript);
  }
  braintreeReady = () => {
    this.addScriptHead('braintreeVenmo', 'venmo.min.js', 'braintree', this.setupVenmo);
  }
  setupVenmo = () => {
    try {
      if (braintree.venmo.isBrowserSupported({ // eslint-disable-line
        allowNewBrowserTab: false
      })) {
        this.venmoAvailable();
      } else {
        Analytics('Venmo is not available');
      }
    } catch (err) {
      console.error(err);
    }
  }
  getPaymentMethods = () => {
    fetchJSON('/api/v1/braintree/payment_methods', {
      method: 'get'
    }).then(data => {
      if (data.code === 200) {
        
        const toState = {
          fetch: false
        };
        if (data.payment_methods.length) {
           // data.payment_methods
          const { payment_methods, selectedMethod } = this.state
          const usersMethods = data['payment_methods'];
          const newList = [];
          console.log('payment_methods', data.payment_methods);
          let addMethod = '';
          usersMethods.forEach(method => {
            addMethod = {
              label: method.label,
              viewLabel: method.display,
              token: method.token,
              newMethod: false
            };
            newList.push(addMethod);
            if (!selectedMethod && method.default) {
              toState.paymentMethod = method.token;
              toState.selectedMethod = addMethod;
            }
          });
          toState.showNewMethods = false;
          if (selectedMethod) {
            if (selectedMethod.newMethod) {
              toState.showNewMethods = true;
            }
          }
          
          toState.payment_methods = newList.concat(payment_methods);
        }
        console.log(toState);
        this.setState(toState)
      } else {
        this.setState({
          fetch: false
        })
      }
    }).catch(error => {
      console.error(error);
      this.setState({
        fetch: false
      })
    });
  }
  venmoAvailable = () => {
    const { currency } = this.state;
    if (currency === 'USD') {
      this.setState({
        venmoActive: true
      });
      Analytics('Venmo available');
    }
  }
  applePayAvailable = () => {
    if (window.ApplePaySession) {
      try {
        /*eslint-disable */
        if (ApplePaySession.canMakePayments()) {
          const toState = {
            applePayActive: true
          };
          Analytics('Apple pay avaliable');
          this.setState(toState)
        }
        /*eslint-enable */
      } catch (e) {
        // console.log(e);
      }
    }
  }
  onPaymentChanged = (method) => {
    this.setState({
      selectedMethod: method,
      paymentMethod: method.token
    }, this.orderChanged);
  }
  changeUserInfo = (e) => {
    const keyName = `user${e.currentTarget.name}`;
    this.setState({
      [keyName]: e.currentTarget.value,
      [`error${e.currentTarget.name}`]: ''
    });

  }
  orderChanged = (redirectNext = false) => {
    const {
      showEmailField,
      userEmail,
      userZip
    } = this.state;
    if (redirectNext) {
      let errors = 0;
      const toState = {};

      if (showEmailField) {
        if (!userEmail) {
          errors += 1;
          toState.errorEmail = 'We need your email to send you a receipt';
        }
        const expression = /\S+@\S+/
        const testEmail = expression.test(String(userEmail).toLowerCase());
        if (!testEmail) {
          errors += 1;
          toState.errorEmail = 'Please provide valid email';
        }
        if (errors) {
          Analytics('User did not enter email');
        }
      } 
      if (!userZip) {
        errors += 1;
        toState.errorZip = 'Please fill in your zip';
        Analytics('User did not enter ZIP');
      }

      if (errors) {
        this.setState(toState);
        return false;
      } else {
        toState.errorEmail = '';
        toState.errorZip = '';
        toState.disabledNextBtn = true;
        this.setState(toState);
      }
    }

    

    const { paymentMethod, selectedMethod } = this.state;
    const orderId = this.props.match.params.orderId;
    const body = {
      order: {
        paymentMethod,
        selectedMethod
      },
      orderId: orderId
    };
    console.log('body', body);

    Analytics('Payment method has changed', { Order: body });

    if (redirectNext) {
      const user = {
        zip: userZip
      }
      if (showEmailField) {
        user.email = userEmail;
      }
      body.user = user;
    }
    fetchJSON('/api/v1/subscribe/order/change', {
      method: 'post',
      body: body 
    }).then(data => {
      if (redirectNext) {
        if (data.code === 409) {
          this.setState({
            errorEmail: data.message,
            disabledNextBtn: false
          })
          return false;
        } else if (data.code === 200) {
          window.location.href = `/subscribe/${orderId}/review`;
        } else {
          this.props.history.push(`/subscribe`);
        }
      }
    }).catch(error => {
      console.error(error);
      if (redirectNext) {
        this.props.history.push(`/subscribe`);
      }
   });
  }
  renderPaymentMethods = () => {
    const { payment_methods, paymentMethod, showNewMethods } = this.state;
    if (payment_methods.length) {
      return payment_methods.map((method, key) => {
        let visible = true;
        if (method.newMethod) {
          const stateName = method.label+'Active';
          visible = this.state[stateName];
          if (method.newMethod && !showNewMethods) {
            visible = false;
          }
        }
        return (<li className={"sbOrder__item sbOrder__methods "+(visible ? 'active' : 'hide')} key={key}>
            <input
              id={`paymentMethod-${method.token}`}
              className="sbOrder__input"
              type="radio"
              name="paymentMethod"
              value={ method.token }
              checked={ paymentMethod === method.token }
              onChange={ e => this.onPaymentChanged(method) }
            />
            <label htmlFor={`paymentMethod-${method.token}`} className="sbOrder__label">
              <span className="sbOrder__icon"></span>
              <span className={`sbOrder__platformIcon ${method.label}`}></span>
              <span className={"sbOrder__title paymentTitle "+(method.newMethod ? 'new' : 'old')}>{method.viewLabel}</span>
            </label>
          </li>
        );
      })
    }

    return null;
  }
  showNewMethods = () => {
    this.setState({
      showNewMethods: true
    })
  }
  submitForm = (e) => {
    e.preventDefault();
    this.orderChanged(true);
  }
  render() {
    const {
      fetch,
      disabledNextBtn,
      promo_id,
      showNewMethods,
      showEmailField,
      userEmail,
      userZip,
      errorEmail,
      errorZip,
      has_payment_methods
    } = this.state;
    const orderId = this.props.match.params.orderId;
    return (
      <React.Fragment>
        <Loading loading = { fetch } className="fill-bg" />
        <div className="subscribe__container">
          <div className="subscribe__header">
            <Link to={"/subscribe/"+orderId} className="subscribe__back-btn">
              <img src={BackIcon} alt="back icon" />
            </Link>
            <h1 className="subscribe__title">
              { has_payment_methods ? 
                <Trans i18nKey="subscribe-payments-title">Select a Payment Method</Trans> :
                <Trans i18nKey="subscribe-payments-title-add-payment-method">Add a payment method</Trans>
              }
            </h1>
            <p className="subscribe__description">
              { promo_id ?
                <React.Fragment>
                  <Trans i18nKey="paymentScreen.freeUse.explanation">
                    We insure Electron battery return using your verified payment method. You will not see any charges for a promo plan; just return your device within 24 hours.
                  </Trans></React.Fragment>
                :
                <React.Fragment>
                { has_payment_methods ? 
                  <React.Fragment><Trans i18nKey="subscribe__payments_description_has_payment_methods">Select your saved payment method and add a new one</Trans></React.Fragment> :
                  <React.Fragment><Trans i18nKey="subscribe__payments_description_no_payment_methods">Choose a convenient payment method for you</Trans></React.Fragment> }
                </React.Fragment>
              }
            </p>
          </div>
          <form onSubmit={this.submitForm} className="subscribe__body">
            <ul className="sbOrder__list">
              {this.renderPaymentMethods()}
            </ul>
            { !showNewMethods ? <div className="sbOrder__help-wrap">
              <button type="button" onClick={this.showNewMethods} className="sbOrder__help black">
                <Trans i18nKey="subscribe__payments_add_payment_method_btn">Add new payment method</Trans>
              </button>
            </div> : null }
            <div className="sbBilling">
              <h2 className="sbBilling__title"><Trans i18nKey="subscribe__payments_billing_info_title">Billing information</Trans></h2>
              { showEmailField ? <div className="sbBilling__input-group">
                <div className="sbBilling__icon email">
                  <input
                    type="email"
                    required
                    placeholder={t('subscribe__payments_billing_email_placeholder', { defaultValue:  'Email' })}
                    name="Email"
                    className={"sbBilling__input " + (errorEmail ? 'error' : '') }
                    value = {userEmail}
                    onChange = {this.changeUserInfo}
                  />
                </div>
                { errorEmail ? <div className={"sbOrderPromo__message danger"}>{errorEmail}</div> : null }
              </div> : null }
              <div className="sbBilling__input-group ">
                <div className="sbBilling__icon zip">
                  <input
                    type="text"
                    required
                    placeholder={t('subscribe__payments_billing_post_code_placeholder', { defaultValue:  'ZIP' })}
                    name="Zip"
                    className={"sbBilling__input " + (errorZip ? 'error' : '') }
                    value = {userZip}
                    onChange = {this.changeUserInfo}
                  />
                </div>
                { errorZip ? <div className={"sbOrderPromo__message danger"}>{errorZip}</div> : null }
              </div>
            </div>
            <div className="sbOrder__action-row">
              <button type="submit" disabled={disabledNextBtn} className="sbOrder__action-btn">
               <Trans i18nKey="welcome__item__btn__next">Next</Trans>
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    )
  }
};

export default SubscribePayments;