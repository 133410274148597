import fetchJSON from 'services/utils/fetchJSON';
import Scenario from 'scenarios/scenario-one';
import { t } from 'components/Translate/Translate';


export default (props) => {
	const lostBattery = () => {
		const ReturnBatteryStep = {
			name: 'ChargeMoney',
			steps: [
				{
					action: 'message',
					type: 'error',
					value: t("lostBattery-sorry-penalty", { defaultValue:  'Unfortunately, we have to charge you for 30$ as a Penalty' }),
					typing: false,
					timeout: 2500,
				}
			]
		};
		const scenario = Scenario('en', []);
		scenario.unshift(ReturnBatteryStep);
		//console.log(scenario);
		props.propSetState({
			typingText: true,
			scenario,
			activeModule: 0,
			activeStep: 0
		}, () => {
			props.moveConversation();
			setTimeout(() => {
				props.doParticipantAction(null, { name: 'Welcome' });
			}, 3000)
		})
	}
	const userActions = {
		active: true,
		label: false,
		buttons: [
			{
				text: t("lostBattery-yes-i-do", { defaultValue:  'Yes, I lost a battery' }),
				func: (e) => {
					props.propSetState({
						userActions: {}
					})
					fetchJSON('/api/v1/electrons/lost', {
						method: 'post'
					}).then(data => {
						console.log(data);
						lostBattery();
					}).catch(error => {
						console.log(error);
				 });
				}
			},
			{
				text: t("lostBattery-no-i-check", { defaultValue:  'NO, I will check again' }),
				func: (e) => {
					const ReturnBatteryStep = {
						name: 'ReturnBattery',
						steps: [
							{
								action: 'func',
								name: 'ReturnBattery',
								typing: false
							}
						]
					};
					const scenario = props.state.scenario ? props.state.scenario.slice() : [];
					scenario.push(ReturnBatteryStep);
					
					props.propSetState({
						userActions: {},
						typingText: false,
						scenario
					}, () => {
						props.moveConversation({name: 'ReturnBattery', step: 0});
					});
				}
			},
		]
	}
	const next = props.state.activeStep + 1;
	props.setState({ 
		userActions,
		activeStep: next,
	}, () => {
		props.moveConversation();
	});
	
}