import Socket from 'services/middleware/Socket';
import { t } from 'components/Translate/Translate';
//import fetchJSON from 'services/utils/fetchJSON';
import Analytics from 'services/utils/Analytics';
import qs from 'qs';
import GroupId from 'services/utils/GroupId';

export default (props) => {
	const gifPreload = new Image();
	gifPreload.src = 'https://getjuice.nyc3.cdn.digitaloceanspaces.com/etg-hiw.gif';
	console.log(gifPreload);
	
	const Api = new Socket();

	if (props.state.userStory === 'userNoPlan') {
		window.location.href = "/station";
	}
	
	let cableType = GroupId.getCableType();
	let paramFromUrl;
	if (window.location.search) {
		paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
	}
	if (paramFromUrl && paramFromUrl.cableType) {
		cableType = paramFromUrl.cableType;
	}

	const userActions = {
		active: true,
		label: false,
		buttons: [
			{
				text: t('take-battery-unlock-my-charger_btn', { defaultValue:  'Unlock my charger' }),
				func: (e) => { // take battery
					props.sendMessageToDialog({ 'type': 'text',
						'value': t("take-battery-look-at-supernovas", { defaultValue:  'Please look at Supernovas station your Electron battery is surrounded by light and it’s coming out soon' })
					});
					props.propSetState({
						userActions: {},
						typingText: true
					})					
					Api.emit('get_electron', { groupId: props.state.groupId, cableType: cableType });
				}
			},
		]
	}

	Api.socket.on('electron', (response) => {
		console.log('electron feedback', response);
		if (response.code === 200) {
			Analytics('Take battery - User has taken the battery');
			setTimeout(() => {
				props.propSetState({
					activeStep: props.state.activeStep + 1,
					attempts: 0,
					typingText: false
				}, () => {
					props.moveConversation();
				});
			}, 500)
			return false;
		}
		// if user not first in queue
		if (response.code === 301) {
			Analytics('Take battery, ERROR - there\'s a queue');
			props.sendMessageToDialog({ 'type': 'text',
				'value': t("take-battery-screen-sorry-there-queue", { defaultValue:  'Sorry, there\'s a queue. Wait 10 seconds' })
			});
			props.propSetState({
				typingText: true
			})
			setTimeout(() => {
				props.setState({ 
					userActions,
					typingText: false
				});
			}, 10000);
			return false;
		}
		// if user did't take a battery
		if (response.code === 302) {
			Analytics('Take battery ERROR - User did not take your battery');
			setTimeout(() => {
				props.sendMessageToDialog({ 'type': 'text',
					'value': t("take-battery-screen-sorry-didNotTake", { defaultValue:  'Sorry, you did not take your battery. Repeat?' })
				});
				props.propSetState({
					userActions
				})
			})
			return false;
		}
		// if battery is not available
		if (response.code === 303) {
			Analytics('Take battery ERROR - Station not ready for battery release');
			setTimeout(() => {
				props.sendMessageToDialog({ 'type': 'text',
					'value': t("take-battery-screen-something-wrong", { defaultValue:  'Something went wrong. Please try again in 5 minutes or contact us at support@electrontogo.com' })
				});
				props.propSetState({
					userActions
				})
			})
			return false;
		}

		Analytics('Take battery ERROR - something went wrong');
		// if not found
		props.sendMessageToDialog({ 'type': 'text',
			'value': t("take-battery-screen-something-wrong", { defaultValue:  'Something went wrong. Please try again in 5 minutes or contact us at support@electrontogo.com' })
		});
		props.propSetState({
			userActions,
			typingText: false
		})
	});
	
	props.setState({ 
		userActions
	});
	
}