import React from 'react';
// import BackIcon from 'components/images/ic-back.svg';
// import { Trans } from 'components/Translate/Translate';
import { t, Trans } from 'components/Translate/Translate';
import Button from 'components/Button/Button';
import fetchJSON from 'services/utils/fetchJSON';

class Survey extends React.Component {
  state = {
    gender: '',
    age: '',
    email: '',
    worry_your_phone_dying: '',
    genderError: 0,
    ageError: 0,
    emailError: 0,
    worryError: 0,
    s_paid_services: '',
    s_paid_servicesError: 0,
    nps_score: '',
    nps_scoreError: 0
  }
  changeAge = (e) => {
    this.setState({
      ageError: 0,
      age: e.target.value
    });
  }
  changeField = (e, name) => {
    let errorName = 'Error';
    if (name === 'worry_your_phone_dying') {
      errorName = 'worryError';
    }
    this.setState({
      [errorName]: 0,
      [name]: e.target.value
    });
  }
  
  changeGender = (e) => {
    this.setState({
      genderError: 0,
      gender: e.target.value
    });
  }
  changeEmail = (e) => {
    this.setState({
      emailError: 0,
      email: e.target.value
    });
  }
  submitSurvey = () => {
    const {
      age,
      gender,
      email,
      worry_your_phone_dying,
      s_paid_services,
      nps_score
    } = this.state;

    const {
      survey_hide_fields,
      survey_step
    } = this.props;

    this.setState({
      genderError: 0,
      ageError: 0,
      emailError: 0,
      worryError: 0,
      s_paid_servicesError: 0,
      nps_scoreError: 0
    });

    const fields = {};
    const setState = {};

    if (survey_step === 1) {
      if (!age) {
        setState.ageError = 1;
      } else {
        fields.age = age;
      }

      if (!gender) {
        setState.genderError = 1;
      } else {
        fields.gender = gender;
      }

      let hide_email_field = 0;
      if (survey_hide_fields && Array.isArray(survey_hide_fields) && survey_hide_fields.length) {
        if (survey_hide_fields.indexOf('email') > -1) {
          hide_email_field = 1;
        }
      }

      if (!hide_email_field) {
        if (!email) {
          setState.emailError = 1;
        } else {
          var re = /\S+@\S+\.\S+/;
          if (!re.test(email)) {
            setState.emailError = 1;
          } else {
            fields.email = email;
          }
        }
      }

      if (!worry_your_phone_dying) {
        setState.worryError = 1;
      } else {
        fields.worry_your_phone_dying = worry_your_phone_dying;
      }
    }

    if (survey_step === 2) {
      if (!s_paid_services) {
        setState.s_paid_servicesError = 1;
      } else {
        fields.s_paid_services = s_paid_services;
      }
    }

    if (survey_step === 3) {
      if (!nps_score) {
        setState.nps_scoreError = 1;
      } else {
        fields.nps_score = nps_score;
      }
    }
    
    
    if (Object.keys(setState).length) {
      this.setState(setState)
      return false;
    }


    fetchJSON('/api/v1/users/updateUser', {
      method: 'post',
      body: {
        fields
      } 
    }).then(data => {
      console.log('data', data);
      this.props.endOfSurvey();
    }).catch(error => {
      console.error(error);
   });

  
  }
  render() {
    const { containerStyle, survey_hide_fields, survey_step } = this.props;
    const {
      age,
      gender,
      email,
      worry_your_phone_dying,
      genderError,
      ageError,
      emailError,
      worryError,
      s_paid_servicesError,
      s_paid_services,
      nps_score,
      nps_scoreError
    } = this.state;

    let hide_email_field = 0;
    if (survey_hide_fields && Array.isArray(survey_hide_fields) && survey_hide_fields.length) {
      if (survey_hide_fields.indexOf('email') > -1) {
        hide_email_field = 1;
      }
    }
    console.log('survey_step', survey_step);

    const buttonSubmitText = t('subscribe_order__get_free_charger', { defaultValue:  'GET A FREE CHARGER' });
    const ages = [
      '18-20',
      '20-25',
      '25-30',
      '30-35',
      '35-40',
      '40-50',
      '50-60',
      '60-70',
      '70-80',
      '80-100'
    ];
    const NPS = [10,9,8,7,6,5,4,3,2,1];
    return (
      <div className="app-container" style={containerStyle}>
          <div className="dialog-screen">
            <div className="dialog__container">
              <div className="subscribe__header">
                <div className="subscribe__title" style={{ paddingTop: '0px', fontSize: '18px', fontWeight: '500', paddingBottom: '5px' }}>
                  { survey_step !== 3 &&  <Trans i18nKey="surveyPage.title">
                    To understand more about users during the pilot we would like you to answer these questions
                  </Trans> }
                  { survey_step === 3 &&  <Trans i18nKey="surveyPage.title.shareExperience">
                    Please share your experience
                  </Trans> }
                </div>

                
              </div> 
              <div className="subscribe__body" style={{ padding: '0 30px' }}>
                <div className="surveyPage">
                  { survey_step === 1 &&
                  <React.Fragment>
                    <div className="subscribe__title" style={{ fontSize: '16px', padding: 0, paddingBottom: '5px' }}>
                      <Trans i18nKey="surveyPage.gender.title">
                        Please choose your gender:
                      </Trans>
                    </div>
                    {genderError ? <div className="surveyPage__alert">
                      <Trans i18nKey="surveyPage.error">This field is required.</Trans>
                    </div> : null }
                    <ul className="surveyPage__genderList">
                      <li className="surveyPage__genderItem">
                        <input className="surveyPage__genderItem-radio" onChange={this.changeGender} checked={gender === 'female'} id="female" type="radio" name="gender" value="female"/>
                        <label className="surveyPage__genderItem-label" htmlFor="female">
                          <span className="surveyPage__genderItem-icon surveyPage__genderItem-icon--female"></span>
                          <span className="surveyPage__genderItem-text">
                            <Trans i18nKey="surveyPage.gender.female">
                              Female
                            </Trans>
                          </span>
                        </label>
                      </li>
                      <li className="surveyPage__genderItem">
                        <input className="surveyPage__genderItem-radio" onChange={this.changeGender} checked={gender === 'male'} id="male" type="radio" name="gender" value="male"/>
                        <label className="surveyPage__genderItem-label" htmlFor="male">
                          <span className="surveyPage__genderItem-icon surveyPage__genderItem-icon--male"></span>
                          <span className="surveyPage__genderItem-text">
                            <Trans i18nKey="surveyPage.gender.male">
                              Male
                            </Trans>
                          </span>
                        </label>
                      </li>
                      <li className="surveyPage__genderItem">
                        <input className="surveyPage__genderItem-radio" onChange={this.changeGender} checked={gender === 'non_binary'} id="non_binary" type="radio" name="gender" value="non_binary"/>
                        <label className="surveyPage__genderItem-label" htmlFor="non_binary">
                          <span className="surveyPage__genderItem-icon surveyPage__genderItem-icon--non_binary"></span>
                          <span className="surveyPage__genderItem-text">
                            <Trans i18nKey="surveyPage.gender.nonbinary">
                              Non binary
                            </Trans>
                          </span>
                        </label>
                      </li>
                    </ul>
                  
                    <div className="subscribe__title" style={{ fontSize: '16px', paddingTop: '14px', paddingBottom: '5px' }}>
                      <Trans i18nKey="surveyPage.age.title">
                        How old are you?
                      </Trans>
                    </div>
                    {ageError ? <div className="surveyPage__alert">
                      <Trans i18nKey="surveyPage.error">This field is required.</Trans>
                    </div> : null }
                    <div>
                      <select className="surveyPage__select" onChange={this.changeAge} value={age}>
                        {!age ? <option>- {t('surveyPage.pleaseSelect', { defaultValue:  'Please Select' })} -</option> : null }
                        {ages.map((value, index) => {
                          return <option key={index} value={value}>{value}</option>
                        })}
                      </select>
                    </div>


                    <div className="subscribe__title" style={{ fontSize: '16px', paddingTop: '14px', paddingBottom: '5px' }}>
                      <Trans i18nKey="surveyPage.worry_your_phone_dying.title">
                        Do you worry about your phone dying when you are out and about?
                      </Trans>
                    </div>
                    {worryError ? <div className="surveyPage__alert">
                      <Trans i18nKey="surveyPage.error">This field is required.</Trans>
                    </div> : null }
                    <div>
                      <select className="surveyPage__select" onChange={(e) => this.changeField(e, 'worry_your_phone_dying')} value={worry_your_phone_dying}>
                        {!worry_your_phone_dying ? <option>- {t('surveyPage.pleaseSelect', { defaultValue:  'Please Select' })} -</option> : null }
                        <option value="somewhat worry">{t('surveyPage.worry_your_phone_dying.somewhat', { defaultValue:  'Somewhat worry' })}</option>
                        <option value="worry">{t('surveyPage.worry_your_phone_dying.worry', { defaultValue:  'Worry' })}</option>
                        <option value="stressed">{t('surveyPage.worry_your_phone_dying.stressed', { defaultValue:  'Stressed' })}</option>
                      </select>
                    </div>                  
                    { !hide_email_field ? 
                    <React.Fragment>
                      <div className="subscribe__title" style={{ fontSize: '16px', paddingTop: '14px', paddingBottom: '5px' }}>
                        <Trans i18nKey="surveyPage.email.title">
                          Please provide your email
                        </Trans>
                      </div>
                      {emailError ? <div className="surveyPage__alert">
                        <Trans i18nKey="surveyPage.error">This field is required.</Trans>
                      </div> : null }
                      <div>
                        <input
                          className="surveyPage__input"
                          placeholder={t('subscribe__payments_billing_email_placeholder', { defaultValue:  'Email' })}
                          type="email" name="email" value={email} onChange={this.changeEmail} />
                      </div>
                    </React.Fragment>
                    : null }
                  </React.Fragment>
                  }
                  { survey_step === 2 &&
                  <React.Fragment>
                    <div className="subscribe__title" style={{ fontSize: '16px', padding: 0, paddingBottom: '5px' }}>
                      <Trans i18nKey="surveyPage.s_paid_services.title">
                        If our solution become paid service which plan you prefer?
                      </Trans>
                      <br/>
                    </div>
                    {s_paid_servicesError ? <div className="surveyPage__alert">
                      <Trans i18nKey="surveyPage.error">This field is required.</Trans>
                    </div> : null }
                    <div>
                      <select className="surveyPage__select" onChange={(e) => this.changeField(e, 's_paid_services')} value={s_paid_services} style={{ maxWidth: '100%' }}>
                        {!s_paid_services ? <option>- {t('surveyPage.pleaseSelect', { defaultValue:  'Please Select' })} -</option> : null }
                        <option value="24hours_250">{t('surveyPage.s_paid_services.24hours250', { defaultValue:  '24 hours usage fee ¥250' })}</option>
                        <option value="24hours_300">{t('surveyPage.s_paid_services.24hours300', { defaultValue:  '24-hour usage fee ¥300' })}</option>
                        <option value="monthly_800">{t('surveyPage.s_paid_services.monthly800', { defaultValue:  'Monthly subscription Unlimited use fee ¥800' })}</option>
                      </select>
                    </div>
                  </React.Fragment>
                  }
                  { survey_step === 3 &&
                  <React.Fragment>
                    <div className="subscribe__title" style={{ fontSize: '16px', padding: 0, paddingBottom: '5px' }}>
                      <Trans i18nKey="surveyPage.nps_score.title">
                        How likely are you to recommend our service to a friend or colleague?
                      </Trans>
                      <br/>
                    </div>
                    {nps_scoreError ? <div className="surveyPage__alert">
                      <Trans i18nKey="surveyPage.error">This field is required.</Trans>
                    </div> : null }
                    <div>
                      <select className="surveyPage__select" onChange={(e) => this.changeField(e, 'nps_score')} value={nps_score} style={{ maxWidth: '100%' }}>
                        {!nps_score ? <option>- {t('surveyPage.pleaseSelect', { defaultValue:  'Please Select' })} -</option> : null }
                        {NPS.map((value, index) => {
                          return <option key={index} value={value}>{value}</option>
                        })}
                      </select>
                    </div>
                  </React.Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="control__fixed">
            <div>
              <div className="control__tableRow blurred showTab">
                <div className="dialog-bottom__container">
                  <Button
                    onClick={this.submitSurvey}
                    text={buttonSubmitText} />
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
};

export default Survey;