import React from 'react';
import { Trans } from 'components/Translate/Translate';


export default (props) => {
	props.propSetState({
		dialogState: 0,
		showUserController: false
	})
	class BlockUserScreen extends React.Component {
		render() {
			return (
				<div className="app-container">
					<div className="dialog-screen">
						<div className="dialog-screen__container">
							<div className="dialog-screen__message">
								<Trans i18nKey="block-user-screen" dictionary={props.state.dictionary}>Account has been suspended.</Trans><br/><br/>
								<span role="img" aria-label="Stop">🛑 </span>
							</div>
						</div>
					</div>
				</div>
			)
		}
	};
	
	props.propSetState({
		fullScreenStore: <BlockUserScreen />
	});
	
}