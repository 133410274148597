import React from 'react';
import _ from 'lodash';
import {observer} from "mobx-react"
import {observable} from "mobx"
import windowDimensions from 'react-window-dimensions';
import qs from 'qs';
import storeFlow from './config';
import Settings from 'services/config/Settings';
import ParticipantAction from './controllers/ParticipantAction';
import Loading from 'components/Loading';
import Background from './components/Background';
import UserController from './components/UserController';
import Dialog from './components/Dialog';
import Header from 'components/Header/Header';
import ModalMap from './components/ModalMap';
import FullScreenAction from './components/FullScreenAction/FullScreenAction';
import Analytics from 'services/utils/Analytics';
import Report from 'components/Report/Report';

@observer
class Flow extends React.Component {
	@observable flow = storeFlow;
	componentDidMount = () => {
		document.title = `${Settings.title}`;
		this.flow.typingText = true;
		let paramFromUrl;

		if (window.location.search) {
			paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
		}
		const { match: { params, path } } = this.props;
		if (params.groupId) {
			paramFromUrl = { m: 'groupIdRegistred', 'groupId': params.groupId.replace(/\D+/g, '') }
		}
		if (path === '/map') {
			paramFromUrl = { m: 'turnOnMap' }
		}
		if (paramFromUrl) {
			Analytics('Flow start', paramFromUrl);
		}
		this.doParticipantAction(null, { name: 'Welcome' }, paramFromUrl);
  }
	moveConversation = (module) => {
		if (module) {
			let scenario = this.flow.scenario;
			let newModule = _.findIndex(scenario, { name: module.name });
			if (newModule) {
				console.log(newModule, module.step);
				this.flow.activeModule = newModule;
				this.flow.activeStep = module.step ? module.step : 0;
				this.doParticipantAction(null, { name: 'MoveConversation'});
				return;
			}
		}
		
		this.doParticipantAction(null, {name: 'MoveConversation'});
	}
	doParticipantAction = (moduleName, activeStep, module = null) => {
		const listOpts = {
			state: this.flow,
			doParticipantAction: this.doParticipantAction,
			setState: this.propSetState,
			propSetState: this.propSetState,
			sendMessageToDialog: this.sendMessageToDialog,
			moveConversation: this.moveConversation,
			userWriteToDialog: this.userWriteToDialog,
			params: this.props.match.params,
			history: this.props.history,
			failPayment: this.failPayment,
			successPayment: this.successPayment,
			module,
			moduleName,
			activeStep
		}
		ParticipantAction(listOpts);
	}
	sendMessageToDialog = (module) => {
		this.doParticipantAction(null, {name: 'SendMessageToDialog'}, module);
	}
	showUserController = (module) => {
		this.doParticipantAction(null, {name: 'ShowUserController'}, module);
	}
	propSetState = (set, next = false) => {
		Object.keys(set).forEach((key, index) => {
			this.flow[key] = set[key];
		});
		if (next) {
			next();
		}
  }
	userWriteToDialog = (value) => {
		this.doParticipantAction(null, {name: 'UserWriteToDialog'}, value);
	}

	renderDialogWindow = () => {
		switch (this.flow.dialogState) {
			case 1: {
				return (
					<React.Fragment>
						<Dialog
							dialogMessages={this.flow.dialogMessages ? this.flow.dialogMessages.slice() : []}
							propSetState={this.propSetState}
							typingText = {this.flow.typingText}
							flow = {this.flow}
						/>
						<UserController
							state = {this.flow}
							controller={this.flow.controller}
							propSetState={this.propSetState}
							moveConversation={this.moveConversation}
							userWriteToDialog={this.userWriteToDialog}
							userActions={this.flow.userActions}
						/>
					</React.Fragment>
				)
			}
			case 0: {
				return (
					<React.Fragment>
						<FullScreenAction
							flow = {this.flow}
							history={this.props.history}
						/>
						{this.flow.showUserController ?
						<UserController
							state = {this.flow}
							controller={this.flow.controller}
							propSetState={this.propSetState}
							moveConversation={this.moveConversation}
							userWriteToDialog={this.userWriteToDialog}
							userActions={this.flow.userActions}
						/>
						: null}
					</React.Fragment>
				)
			}
			default: {
				return (
					<React.Fragment>
						loading
					</React.Fragment>
				)
			}
		}
	}
	hideHelp = () => {
		this.propSetState({
			showReport: false,
			canExchangeBattery: false
		});
	}
	goExchangeBattery = () => {
		this.propSetState({
			fullScreenStore: null,
			background: 'dark',
			activeStep: this.flow.activeStep + 1,
			showReport: false,
			canExchangeBattery: false,
			exchangeBatteryFlow: true
		}, () => {
			this.moveConversation();
		});
	}
	// from header this.flow.activeHeader || !this.flow.loading 
  render() {
  	let containerStyle = {
			width: this.props.width,
			height: this.props.height
		}
		const { reportItem, reportItemId, userStory, showReport, canExchangeBattery } = this.flow;
		let loadingStateFullScreen = !this.flow.fullScreenStore && this.flow.dialogState === 0;
    return (
			<React.Fragment>
				<Background type={this.flow.background} />
				<Loading loading = {this.flow.loading} />
				<div className={ "app-container " + (this.flow.loading || loadingStateFullScreen ? "hide" : "active")} style={containerStyle}>
					<Header />
					{ this.renderDialogWindow() }
					{ this.flow.modalMap ? <ModalMap
						state={this.flow}
						setState={this.propSetState}
						history={this.props.history}
						sendMessageToDialog={this.sendMessageToDialog}
						moveConversation={this.moveConversation}
						userWriteToDialog={this.userWriteToDialog}
						doParticipantAction={this.doParticipantAction}
						/> : null }
					</div>
					{ showReport ?
						<Report
							modal={true}
							visible={showReport}
							userStory={userStory}
							canExchangeBattery={canExchangeBattery}
							item={reportItem}
							itemId={reportItemId}
							hideIt={this.hideHelp}
							goExchangeBattery={this.goExchangeBattery}
						/> : null }
			</React.Fragment>
    )
  }
};

export default windowDimensions()(Flow);