const ChooseStation = {
	name: 'ChooseStation',
	steps: [
		{
			action: 'func',
			name: 'SupernovaFindScreen'
		},
	]
};

export default ChooseStation;