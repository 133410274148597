import React from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import Settings from 'services/config/Settings';
import { t, Trans } from 'components/Translate/Translate';
import paymentSuccess from 'components/images/payment-success.svg';
import fetchJSON from 'services/utils/fetchJSON';
import _ from 'lodash';
import Loading from 'components/Loading';

class SubscribePurchase extends React.Component {
  state = {
    fetching: false,
    sessionBookingId: ''
  }
  componentDidMount = () => {
    const pageTitle = t('pageTitles.Purchase', { defaultValue: 'Purchase'});
    document.title = `${pageTitle} | ${Settings.title}`;
    
    if (Cookies.get('referral') !== undefined) {
      Cookies.remove('referral');
    }
    this.getInfo()
  }
  getInfo = () => {
    this.setState({
      fetching: true
    });

    const { orderId } = this.props.match.params;
    fetchJSON(`/api/v1/subscribe/process/${orderId}`, {
      method: 'get'
    }).then(data => {
      if (data.code === 200) {
        //console.log(data);
        if (data.order.status !== 'paid') {
          this.props.history.push(`/subscribe/${orderId}`);
          return false;
        }
        const sessionBookingId = _.get(data, 'order.order.sessionBookingId');
        if (!sessionBookingId) {
          this.props.history.push('/station');
          return false;
        }

        this.props.history.push(`/station/${sessionBookingId}/release`);

        // this.setState({
        //   sessionBookingId,
        //   fetching: false
        // });

      } else {
        this.props.history.push(`/subscribe`);
      }
    }).catch(error => {
      console.error(error);
      this.props.history.push('/');
    });
  }

  render() {
    const { sessionBookingId, fetching } = this.state;
    const rulesText = t('subscribe-purchase-24-hours-rules', { defaultValue:  'It is very important for our Community that everyone return their used Electron <b>within 24 hours</b>.' });
    return (
      <React.Fragment>
      {
        fetching ? 
        <Loading loading={true} className="fill-bg" />
        :
        <div className="subscribe__container">
          <div className="subscribe__header">
            <div className="subscribe__title">
              <Trans i18nKey="subscribe-purchase-title">Payment</Trans>
            </div>
            <p className="subscribe__description success"><Trans i18nKey="subscribe-purchase-description">Success</Trans></p>
          </div>
          <div className="subscribe__body sbReviewSuccess">
            <img src={paymentSuccess} alt="payment Success" />
            <div className="sbReviewSuccess__rules" dangerouslySetInnerHTML={{__html: rulesText}}></div>
          </div>
          <div className="sbOrder__action-row">
            <Link to={`/station/${sessionBookingId}${sessionBookingId ? '/release' : ''}`} className="sbOrder__action-btn">
              <Trans i18nKey="subscribe-purchase-got_it_btn">Got it</Trans>
            </Link>
          </div>
        </div>
      }
      </React.Fragment>
    )
  }
};

export default SubscribePurchase;