import React from 'react';
import fetchJSON from 'services/utils/fetchJSON';
import Settings from 'services/config/Settings';
import AppleIcon from 'components/images/apple_pay.svg';
import VenmoIcon from 'components/images/venmo_pay.svg';
import PaypalIcon from 'components/images/paypal_pay.svg';
import AddCardForm from './AddCardForm';
import { t } from 'components/Translate/Translate';
import Cookies from 'js-cookie';

let paypalInstanceObject = {};
let applePayInstanceObject = {};
let venmoInstanceObject = {};
let hostedFieldsInstanceObject = {};

class OrderPay extends React.Component {
	state = {
		clientToken: '',
		deviceData: '',
		paypalReady: false,
		applePayReady: false,
		venmoReady: false,
		paymentApi: '/api/v1/subscribe/checkout',
		failStatus: null,
		failMethod: null,
		paymentLoading: true,
		addCardForm: false,
		submitVisible: true,
		cardValidateError: ''
	}
	componentDidMount = () => {
		const { newMethod } = this.props;
		if (newMethod) {
			this.initBraintree();
		} else {
			this.setState({
				paypalReady: true,
				applePayReady: true,
				venmoReady: true,
				paymentLoading: false
			});
		}
	}
	initBraintree() {
		const { methodLabel } = this.props;
		if (!document.getElementById('braintreeScript')) {
			this.addScriptHead('braintreeScript', 'client.min.js', 'braintree', this.setupBraintree);
			this.addScriptHead('braintreeScript', 'data-collector.min.js');
			switch (methodLabel) {
				case 'card': 
					this.addScriptHead('braintreeCard', 'hosted-fields.min.js', 'braintree');
					break;
				case 'paypal': 
					this.addScriptHead('braintreePaypal', 'paypal-checkout.min.js', 'braintree', this.initPaypalScripts);
					break;
				case 'venmo':
					this.addScriptHead('braintreeVenmo', 'venmo.min.js', 'braintree');
					break;
				case 'applePay': 
					this.addScriptHead('braintreeApplePay', 'apple-pay.min.js', 'braintree');
					break;
				default:
			}
		}
	}
	initPaypalScripts = () => {
		if (!document.getElementById('paypalScript')) {
			const paypalUrl = 'https://www.paypalobjects.com/api/checkout.js';
			this.addScriptHead('paypalScript', paypalUrl, 'paypal', this.setupPayPal);
		}
	}
	addScriptHead = (name, url, base = 'braintree', onload = {}) => {
		const braintreeScript = document.createElement('script');
		braintreeScript.setAttribute('id', name);
		let source = '';
		if (base === 'braintree') {
			source = 'https://js.braintreegateway.com/web/3.42.0/js/';
		}
		braintreeScript.onload = onload;
		braintreeScript.setAttribute('src', `${source}${url}`);
		document.head.appendChild(braintreeScript);
	}
	setupBraintree = () => {
		fetchJSON('/api/v1/braintree/client_token/generate', {
			method: 'post'
		}).then(data => {
			if (data.code === 200) {
				this.setState({
					clientToken: data.client_token
				})
				this.initPayments();
			}
		 }).catch(error => {
				console.log(error);
		 });
	}
	initPayments = () => {
		braintree.client.create({ // eslint-disable-line
			authorization: this.state.clientToken
		}, (clientErr, clientInstance) => {
			if (clientErr) {
				console.log('Error creating client:', clientErr);
				return;
			}
			const { methodLabel } = this.props;
			this.getDeviceDataAndStart(clientInstance, methodLabel);			
		});
	}
	getDeviceDataAndStart = (clientInstance, methodLabel) => {
		braintree.dataCollector.create({ // eslint-disable-line
			client: clientInstance,
			paypal: true
		}, (err, dataCollectorInstance) => {
			if (err) {
				// Handle error
				return;
			}

			const myDeviceData = dataCollectorInstance.deviceData;

			this.setState({
				deviceData: myDeviceData
			});

			switch (methodLabel) {
				case 'card':
					this.initializeCard(clientInstance);
					break;
				case 'paypal': 
					this.initializePayPal(clientInstance);
					break;
				case 'venmo': 
					this.initializeVenmo(clientInstance);
					break;
				case 'applePay': 
					this.initializeApplePay(clientInstance);
					break;
				default:
			}

		});
	}
	initializeCard = (clientInstance) => {
		this.setState({
			paymentLoading: false
		});
		const options = {
		  client: clientInstance,
		  styles: {
	      'input': {
	        'font-size': '16px',
	        'font-family': 'courier, monospace',
	        'font-weight': 'lighter',
	        'color': '#ccc'
	      },
	      ':focus': {
	        'color': 'black'
	      },
	      '.valid': {
	        'color': '#8bdda8'
	      }
	    },
	    fields: {
	      number: {
	        selector: '#card-number',
	        placeholder: '4111 1111 1111 1111'
	      },
	      cvv: {
	        selector: '#cvv',
	        placeholder: '123'
	      },
	      expirationDate: {
	        selector: '#expiration-date',
	        placeholder: t('addCardForm.expirationDate', { defaultValue:  'MM/YYYY' })
	      }
	    }
		};
		braintree.hostedFields.create(options, (hostedFieldsErr, hostedFieldsInstance) => { // eslint-disable-line
	    if (hostedFieldsErr) {
	    	console.log('hostedFieldsErr', hostedFieldsErr);
	      // Handle error in Hosted Fields creation
	      return;
	    }
	    hostedFieldsInstanceObject = hostedFieldsInstance;
	    hostedFieldsInstance.on('validityChange', (event) => {
		    var field = event.fields[event.emittedBy];
		    if (field.isValid) {
		      this.setState({
		    		cardValidateError: ''
		    	})
		    } else if (field.isPotentiallyValid) {
		      // console.log(event.emittedBy, 'is potentially valid');
		    } else {
		      this.setState({
		    		cardValidateError: t('addCardForm.cardValidationError', { defaultValue:  'Card validation error, check your card' })
		    	})
		    }
		  });
	  });
	}
	initializePayPal = (clientInstance) => {
	  braintree.paypalCheckout.create({ // eslint-disable-line
	    client: clientInstance
	  }, (paypalCheckoutErr, paypalCheckoutInstance) => {
	    // Stop if there was a problem creating PayPal Checkout.
	    // This could happen if there was a network error or if it's incorrectly
	    // configured.
	    if (paypalCheckoutErr) {
	      console.log('Error creating PayPal Checkout:', paypalCheckoutErr);
	      this.props.changeFailMessage(t('addPaymentMethod.paypal.fail', { defaultValue:  'PayPal fails, try again later' }));
				this.closeFail(Object.assign({'failReason':'PayPal error'}, paypalCheckoutErr), 'PayPal-paypalErr');
	      // TODO ERROR
	      return;
	    }
	    paypalInstanceObject = paypalCheckoutInstance;
	  });
	}
	initializeVenmo = (clientInstance) => {
		braintree.venmo.create({ // eslint-disable-line
			client: clientInstance,
			// Add allowNewBrowserTab: false if your checkout page does not support
			// relaunching in a new tab when returning from the Venmo app. This can
			// be omitted otherwise.
			allowNewBrowserTab: false
		}, (venmoErr, venmoInstance) => {
			if (venmoErr) {
				console.log('Error creating Venmo:', venmoErr);
				this.props.changeFailMessage(t('addPaymentMethod.venmo.fail', { defaultValue:  'Venmo fails, try again later' }));
				this.closeFail(Object.assign({'failReason':'Venmo error'}, venmoErr), 'Venmo-venmoErr');
				return;
			}
			// Verify browser support before proceeding.
			if (!venmoInstance.isBrowserSupported()) {
				this.props.changeFailMessage(t('addPaymentMethod.venmo.noSupported', { defaultValue:  'Venmo is not supported' }));
				this.closeFail('Venmo is not support', 'Venmo-isBrowserSupported');
				return;
			}
			venmoInstanceObject = venmoInstance;
			this.setState({
				venmoReady: true,
				paymentLoading: false
			});
			//console.log('venmoInstance', venmoInstance.hasTokenizationResult() )
			if (venmoInstance.hasTokenizationResult()) {
				venmoInstance.tokenize((tokenizeErr, payload) => {
					this.startPayVenmo(tokenizeErr, payload);
				});
				return;
			}
		})
	}
	initializeApplePay = (clientInstance) => {
		console.log('setupApplePay')
		braintree.applePay.create({ // eslint-disable-line
			client: clientInstance
		}, (applePayErr, applePayInstance) => {
			if (applePayErr) {
				console.log('Error creating applePayInstance:', applePayErr);
				this.props.changeFailMessage(t('addPaymentMethod.applePay.fail', { defaultValue: 'Apple Pay fails, try again later' }));
				this.closeFail(Object.assign({'failReason':'Apple Pay error'}, applePayErr), 'ApplePay-applePayErr');
				return;
			}
			if (window.ApplePaySession) {
				try {
					/*eslint-disable */
					if (ApplePaySession.canMakePayments()) {
						applePayInstanceObject = applePayInstance;
						this.setState({
							applePayReady: true,
							paymentLoading: false
						});
						return;
					}
					/*eslint-enable */
				} catch (e) {
					// console.log("ApplePaySession don't work here");
					//no	
				}
			}

			this.props.changeFailMessage(t('addPaymentMethod.applePay.noSupported', { defaultValue: 'Apple Pay is not supported' }));
			this.closeFail('Apple Pay is not available', 'ApplePay-initializeApplePay');
		})
	}
	closeFail = (failStatus, failMethod) => {
		console.log('closeFail', failStatus, failMethod);
		const { orderId } = this.props;
		this.setState({
			failStatus,
			failMethod
		});
		this.props.history.push(`/subscribe/${orderId}/fail`);
		fetchJSON('/api/v1/subscribe/order/status', {
      method: 'post',
      body: {
      	orderId,
      	status: 'fail',
      	order: {
      		failStatus,
      		failMethod
      	}
      }
    }).then(data => {
      //console.log('status', data);
    }).catch(error => {
      console.error(error);
   });
	}
	serverFail = (failServer) => {
		const { orderId } = this.props;
		console.log('serverFail!')
		this.props.history.push(`/subscribe/${orderId}/fail`);
		fetchJSON('/api/v1/subscribe/order/status', {
      method: 'post',
      body: {
      	orderId,
      	status: 'fail',
      	order: {
      		failServer
      	}
      }
    }).then(data => {
      //console.log('status', data);
    }).catch(error => {
      console.error(error);
   });
	}
	successPayment = () => {
		const { orderId } = this.props;
		console.log('successPayment!');
		this.props.history.push(`/subscribe/${orderId}/purchase`);
	}
	handlePaymentMethod = (payload = false) => {
		const { deviceData } = this.state;
		const { newMethod } = this.props;
		const { orderId, methodLabel } = this.props;
		let body = {
			source: methodLabel,
			orderId: orderId
		};
		if (deviceData) {
			body.device_data = deviceData;
		};
		
		if (newMethod) {
			body.payment_method_nonce = payload.nonce;
		}

		fetchJSON(this.state.paymentApi, {
			method: 'post',
			body
		}).then(data => {
			console.log(data);
			if (data.code === 200) {
				this.successPayment();
			} else {
				this.props.changeFailMessage(data.message);
				this.serverFail(data.message, 'serverSide');
			}
		}).catch(error => {
			console.log(error);
			this.serverFail('Server error catch', 'serverSide');
		});
	}
	setupPayPal = () => {
		const { price, paymentPurpose, orderId } = this.props;
		const elementExists = !!document.getElementById('paypal-button');
		if (typeof paypal !== 'undefined') {
			if (elementExists) {
				paypal.Button.render({ // eslint-disable-line
		      env: process.env.NODE_ENV !== "development" ? 'production' : 'sandbox',
		      payment: () => {
		      	console.log('paypal payment');
		      	this.props.history.push(`/subscribe/${orderId}/process`);
		      	const paypalOptions = {
		          flow: 'vault',
		          amount: price,
		          displayName: Settings.title,
		          billingAgreementDescription: paymentPurpose,
		          enableShippingAddress: false,
		          shippingAddressEditable: false,
		        };
						
						const savedLanguage = Cookies.get('app_language');
				    if (savedLanguage && savedLanguage === 'ja') {
				      paypalOptions.locale = 'ja_JP';
				    }
		        return paypalInstanceObject.createPayment(paypalOptions);
		      },

		      onAuthorize: (data, actions) => {
		      	console.log('onAuthorize');
		        return paypalInstanceObject.tokenizePayment(data)
	          .then((payload) => {
	          	this.handlePaymentMethod(payload);
	          });
		      },

		      onCancel: (data) => {
		      	this.props.changeFailMessage(t('addPaymentMethod.paypal.userCanceled', { defaultValue: 'User canceled PayPal flow.' }));
		        //console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
		        this.closeFail(Object.assign({'failReason':'User canceled PayPal flow.'}, data), 'PayPal-onCancel');
		      },

		      onError: (err) => {
		        console.error('checkout.js error', err);
		        this.props.changeFailMessage(t('addPaymentMethod.paypal.failToLoad', { defaultValue: 'PayPal failed to load.' }));
		        this.closeFail(Object.assign({'failReason':'PayPal failed to load.'}, err), 'PayPal-onError');
		      }
		    }, '#paypal-button').then(() => {
		    	console.log('paypalReady');
		    	this.setState({
		    		paypalReady: true,
		    		paymentLoading: false
		    	})
		    });
			}
		}
	}
	doPaymentNow = (payload = false) => {
		const { orderId } = this.props;
		this.props.history.push(`/subscribe/${orderId}/process`);
		setTimeout(() => {
			this.handlePaymentMethod(payload);
		}, 100)
	}
	payWithCard = () => {
		const { newMethod } = this.props;
		if (newMethod) {
			this.setState({
				addCardForm: true,
				submitVisible: false
			})
			this.props.hideTerms();
		} else {
			this.doPaymentNow();
		}
		console.log('payWithCard NOW');
	}
	startPayCard = (e) => {
		e.preventDefault();
		const state = hostedFieldsInstanceObject.getState();
    const formValid = Object.keys(state.fields).every(function (key) {
      return state.fields[key].isValid;
    });

    if (formValid) {
      hostedFieldsInstanceObject.tokenize((tokenizeErr, payload) => {
	      if (tokenizeErr) {
	        console.error(tokenizeErr);
	        return;
	      }
	      this.doPaymentNow(payload);
	    });
    }
	}
	payWithApplePay = () => {
		const { newMethod } = this.props;
		console.log('payWithApplePay NOW');
		if (newMethod) {
			this.startPayApplePay();
		} else {
			this.doPaymentNow();
		}
	}
	payWithVenmo = () => {
		console.log('payWithVenmo NOW')
		const { newMethod } = this.props;
		if (newMethod) {
			setTimeout(() => {
				venmoInstanceObject.tokenize((tokenizeErr, payload) => {
					this.startPayVenmo(tokenizeErr, payload);
				});
			}, 200);
		} else {
			this.doPaymentNow();
		}
	}
	payWithPayPal = () => {
		console.log('payWithPayPal NOW');
		this.doPaymentNow();
	}
	startPayApplePay = () => {
		/*eslint-disable */
		const { price, paymentPurpose } = this.props;

		console.log('price', price);
		var paymentRequest = applePayInstanceObject.createPaymentRequest({
			total: {
				label: `${paymentPurpose} - ${Settings.title}`,
				amount: price
			}
		});
		console.log(paymentRequest);

		var session = new ApplePaySession(1, paymentRequest);
		session.onvalidatemerchant = (event) => {
			console.log(event);
			console.log('applePayInstanceObject', applePayInstanceObject);
			applePayInstanceObject.performValidation({
				validationURL: event.validationURL,
				displayName: Settings.title
			}, (validationErr, merchantSession) => {
				if (validationErr) {
					// You should show an error to the user, e.g. 'Apple Pay failed to load.'
					console.log('Error validating merchant:', validationErr);
					this.props.changeFailMessage(t('addPaymentMethod.applePay.failToLoad', { defaultValue: 'Apple Pay failed to load.' }));
					this.closeFail(validationErr, 'ApplePay-validationErr');
					session.abort();
					return;
				}
				console.log(merchantSession);
				session.completeMerchantValidation(merchantSession);
			});
		};
		session.oncancel = (event) => {
			console.log('Apple Pay flow canceled', event)
			this.closeFail('Apple Pay flow canceled', 'ApplePay-OnCancel');
			session.abort();
		}
		session.onpaymentauthorized = (event) => {
			console.log('Your shipping address is:', event.payment.shippingContact);
			applePayInstanceObject.tokenize({
				token: event.payment.token
			}, (tokenizeErr, payload) => {
				if (tokenizeErr) {
					this.props.changeFailMessage(t('addPaymentMethod.applePay.failInProccess', { defaultValue: 'Failed in the process of payment Apple Pay' }));
					console.error('Error tokenizing Apple Pay:', tokenizeErr);
					session.completePayment(ApplePaySession.STATUS_FAILURE);
					return;
				}
				session.completePayment(ApplePaySession.STATUS_SUCCESS);
				console.log(payload);
				this.handlePaymentMethod(payload);
				// Send payload.nonce to your server.
			});
		};
		//console.log(session);
		session.begin();
		/*eslint-enable */
	}
	startPayVenmo = (tokenizeErr, payload) => {
		if (tokenizeErr) {
			if (tokenizeErr.code === 'VENMO_CANCELED') {
				this.props.changeFailMessage(t('addPaymentMethod.venmo.userCanceledOrNotAvailable', { defaultValue: 'User canceled Venmo, or Venmo app is not available.' }));
				console.log('App is not available or user aborted payment flow');
			} else if (tokenizeErr.code === 'VENMO_APP_CANCELED') {
				this.props.changeFailMessage(t('addPaymentMethod.venmo.userCanceled', { defaultValue: 'User canceled Venmo flow.' }));
				console.log('User canceled payment flow');
			} else {
				this.props.changeFailMessage(t('addPaymentMethod.venmo.failInProccess', { defaultValue: 'Failed in the process of payment Venmo' }));
				console.error('An error occurred:', tokenizeErr.message);
			}
			this.closeFail(tokenizeErr, 'Venmo-Fail');
		} else {
			const { orderId } = this.props;
			this.props.history.push(`/subscribe/${orderId}/process`);

			console.log('handleVenmoSuccess', payload)
			this.handlePaymentMethod(payload);
		}
	}

	render() {
		const {
			paypalReady,
			applePayReady,
			venmoReady,
			paymentLoading,
			addCardForm,
			submitVisible,
			cardValidateError
		} = this.state;
		const { methodLabel, disabledNextBtn, newMethod } = this.props;
		return (
			<React.Fragment>
				<div className={"sbOrder__action-row sbReview__submit "+(submitVisible ? 'visible': 'hide')}>
					<div className="sbReview__wrapBtn">
						<div className={"sbReview__prepare "+(paymentLoading ? 'loading' : 'hide')}>
						{t('subscribe__pay_preparing_payment', { defaultValue:  'Preparing payment' })}
						</div>
						{ methodLabel === 'card' ?
						<button type="button" disabled={disabledNextBtn} onClick={this.payWithCard} className="sbOrder__action-btn">
							{newMethod ? t('subscribe__pay_add_card_btn', { defaultValue:  'ADD CARD' }) : t('subscribe__pay_start_payment_btn', { defaultValue:  'PAY' }) }
						</button> : null }
						{ methodLabel === 'applePay' ?
						<button type="button" disabled={disabledNextBtn || !applePayReady} onClick={this.payWithApplePay} className="sbOrder__action-btn apple-pay">
							<img className="sbOrder__brand" src={AppleIcon} alt="" />
						</button> : null }
						{ methodLabel === 'venmo' ?
						<button type="button" disabled={disabledNextBtn || !venmoReady} onClick={this.payWithVenmo} className="sbOrder__action-btn venmo">
							<img className="sbOrder__brand" src={VenmoIcon} alt="" />
						</button> : null }
						{ methodLabel === 'paypal' && newMethod ?
							<div className={"sbReview__paypal "+(disabledNextBtn ? 'disabled' : 'active')}>
								<div className={"sbReview__paypal "+(paypalReady ? 'active' : 'disabled')}>
									<div className="sbReview__paypal-button" id="paypal-button"></div>
								</div>
							</div>
							: null }
						{ methodLabel === 'paypal' && !newMethod ?
							<button type="button" disabled={disabledNextBtn} onClick={this.payWithPayPal} className="sbOrder__action-btn paypal">
								<img className="sbOrder__brand" src={PaypalIcon} alt="" />
							</button>
							: null }
					</div>
				</div>
				<AddCardForm onSubmit={this.startPayCard} cardValidateError={cardValidateError} visible={addCardForm} />
			</React.Fragment>
		)
	}
};

export default OrderPay;