import React from 'react';
import Button from 'components/Button/Button';
import Auth from 'services/utils/Auth';
import { t, Trans } from 'components/Translate/Translate';
import GroupId from 'services/utils/GroupId';
import _ from 'lodash';

export default (props) => {
	const { venue } = props.module;
	const { groups } = venue.state;

	class VenueSelectScreen extends React.Component {
		enterStationCode = () => {
			if (!groups.length || groups.length > 1) {
				props.sendMessageToDialog({ 'type': 'text', 'value': t('supernova-enter-station-code', { defaultValue:  'Enter station code' }) });
				props.propSetState({ 
					userActions: {},
					dialogState: 1,
					headerState: 4
				}, () => {
					props.moveConversation({ name: 'supernova', step: 0 });
				});
			} else {
				props.sendMessageToDialog({ 'type': 'venue', 'value': venue });
				props.sendMessageToDialog({ 'type': 'text', 'value': t('supernova-get-success', { defaultValue:  'Whoa, Electron is available!' }) });
				let activeStep = props.state.activeStep + 1;
				const controller = props.state.controller;
				controller.status = false;					
				props.propSetState({ 
					activeStep: activeStep,
					attempts: 0,
					controller,
					activeMessageFromUser: null,
					groupId: groups[0],
					userActions: {},
					dialogState: 1,
				}, () => {
					props.moveConversation({ name: 'TakeBattery', step: 0 });
				});
			}
		}
		goChargerBooking = () => {
			const groups = _.get(props.module, 'venue.state.groups');
			if (groups && groups.length) {
				if (groups.length > 1) {
					props.history.push('/station')
				} else {
					let groupId = groups[0];
					GroupId.add(groupId);
					props.history.push(`/station?id=${groupId}`);
				}
			}			
		}
		render() {
      const cover = props.module.venue.photos.length ? props.module.venue.photos[0] : false
      let style = {};
      if (cover) {
        style = { 
          'backgroundImage': 'url('+cover+')'
        };
      }
			return (
				<div className="app-container">
					<div className="dialog-screen">
						<div className="dialog__container">
							<div className="dialog__window">
								<div className="dialog__messages no-reverse">
									<div className="dialog__row left">
										<span>
											<div className="dialog__venue">
												<div className={"dialog__venue-top" + (!cover ? " empty" : "") } style={style}></div>
												<div className="dialog__venue-bottom"><b>{props.module.venue.title}</b> {props.module.venue.address}</div>
											</div>
										</span>
									</div>
									<div className="dialog__row left">
										<span>
											<a 
											href={"http://maps.google.com/maps?daddr="+(props.module.venue.coordinates.lat)+","+(props.module.venue.coordinates.lng)} 
											target="_blank"
											className="UserController__btn"
											rel="noopener noreferrer"
											style={{
												'width': 'auto'
											}}
											><Trans i18nKey="single-venue__get-direction-btn">Get direction</Trans></a>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<React.Fragment>
						{Auth.isUserAuthenticated() ? 
							<React.Fragment>
							{props.state.userStory !== 'userWithBattery' && props.state.userStory !== 'userNoPlan' ?
								<div className="dialog-bottom__container">
									<Button
										onClick={this.goChargerBooking}
										text={t("single-venue_get-battery-button", { defaultValue:  'Get battery' })} />
								</div>
							: null }
							</React.Fragment>
							:
							<div className="dialog-bottom__container">
								<a className="UserController__btn" href="/">Login</a>
							</div>
						}
						</React.Fragment>
					</div>
				</div>
			)
		}
	};
	
	props.propSetState({
		fullScreenStore: <VenueSelectScreen />
	});
	
}