import React from 'react';
import Loading from 'components/Loading';
import windowDimensions from 'react-window-dimensions';
import fetchJSON from 'services/utils/fetchJSON';
import Analytics from 'services/utils/Analytics';
import Settings from 'services/config/Settings';
import _ from 'lodash';
import Message from '../../Flow/components/Message';
import { t } from 'components/Translate/Translate';
import Typing from '../../Flow/components/Dialog/components/Typing';
import Background from '../../Flow/components/Background';
import Socket from 'services/middleware/Socket';
import Button from 'components/Button/Button';
import readUserStatus from '../components/readUserStatus';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

import Survey from './components/Survey';
import CovidWarning from './components/CovidWarning';

let Api;
let socketReady = false;
class StationRelease extends React.Component {
	state = {
		fetching: false,
    typingText: false,
		group_id: '',
		cableType: '',
    dialogMessages: [],
    showButton: false,
    cable_by_default: '',
    cable_option_ask: '',
    survey_before_release: 0,
    covid_warning: 0,
    survey_hide_fields: [],
    survey_step: 0,
    instantRelease: 1,
    releaseInProgress: 0,
    startAutoRelease: 0
	}
	componentDidMount = () => {
    const gifPreload = new Image();
    gifPreload.src = 'https://getjuice.nyc3.cdn.digitaloceanspaces.com/etg-hiw.gif';
    console.log(gifPreload);
    const pageTitle = t('pageTitles.GetCharger', { defaultValue: 'Get charger'});
    document.title = `${pageTitle} | ${Settings.title}`;
    Analytics('Release screen');
    const { bookingId } = this.props.match.params;
    if (bookingId) {
      this.sessionStatus(bookingId);
    } else {
      this.props.history.push('/station/');
    }
    if (!socketReady) {
      socketReady = true;
      Api = new Socket();
      Api.socket.on('station_status', (response) => {
        const setStateObj = {};
        console.log('station_status', response);

        setStateObj.showButton = true;
        setStateObj.typingText = false;

        this.setState(setStateObj);

        if (response.releaseReady) {
          this.sendMessageToDialog({ 'type': 'text',
            'value': t("supernova-get-success", { defaultValue:  'Whoa, Electron is available!' })
          });
          if (response.covid_warning) {
            this.setState({
              covid_warning: 1
            });
          } else {
            const { instantRelease } = this.state;
            if (instantRelease) {
              this.startAutoRelease();
            }
          }
        }
      }); 

      Api.socket.on('electron', (response) => {
        console.log('electron feedback', response);

        this.setState({
          releaseInProgress: 0
        });

        if (response.code === 200) {
          Analytics('Take battery - User has taken the battery');
          setTimeout(() => {
            // TODO redirect
            this.bookingStatusChanged('charger_taken', () => {
              Api.socket.disconnect();
              socketReady = false;
              const { bookingId } = this.props.match.params;
              this.props.history.push(`/station/${bookingId}/done`);
            });
          }, 500)
          return false;
        }
        if (response.code === 201) {
          Analytics('Take battery - Proccess started');
          this.sendMessageToDialog({ 'type': 'text',
            'value': t("take-battery-look-at-supernovas", { defaultValue:  'Please look at Supernovas station your Electron battery is surrounded by light and it’s coming out soon' })
          });
          return false;
        }
        
        // if user not first in queue
        if (response.code === 301) {
          this.bookingStatusChanged('queue');
          Analytics('Take battery, ERROR - there\'s a queue');
          this.sendMessageToDialog({ 'type': 'text',
            'value': t("take-battery-screen-sorry-there-queue", { defaultValue:  'Sorry, there\'s a queue. Wait 10 seconds' })
          });
          this.setState({
            typingText: true,
            showButton: false
          });
          setTimeout(() => {
            this.setState({ 
              typingText: false,
              showButton: true
            });
          }, 10000);
          return false;
        }
        // if user did't take a battery
        if (response.code === 302) {
          this.bookingStatusChanged('user_doesnt_take');
          Analytics('Take battery ERROR - User did not take your battery');
          setTimeout(() => {
            this.sendMessageToDialog({ 'type': 'text',
              'value': t("take-battery-screen-sorry-didNotTake", { defaultValue:  'Sorry, you did not take your battery. Repeat?' })
            });
            this.setState({
              typingText: false,
              showButton: true
            })
          }, 500)
          return false;
        }
        // if battery is not available
        if (response.code === 303) {
          this.setState({ 
            typingText: true,
            showButton: false
          });
          this.bookingStatusChanged('fail');
          Analytics('Take battery ERROR - Station not ready for battery release');
          setTimeout(() => {
            this.sendMessageToDialog({ 'type': 'text',
              'value': t("take-battery-screen-something-wrong", { defaultValue:  'Something went wrong. Please try again in 5 minutes or contact us at support@electrontogo.com' })
            });
            setTimeout(() => {
              this.setState({ 
                typingText: false,
                showButton: true
              });
            }, 5000);
          })
          return false;
        }

        if (response.code === 304) {
          this.setState({ 
            typingText: true,
            showButton: false
          });
          this.bookingStatusChanged('fail');
          Analytics('Station offline or not ready for release');
          this.sendMessageToDialog({ 'type': 'text',
            'value': t("take-battery-screen-offline", { defaultValue:  'Sorry but this Station is offline. Please try again in 5 minutes or contact us at support@electrontogo.com' })
          });
          setTimeout(() => {
            this.setState({ 
              typingText: false,
              showButton: true
            });
          }, 5000);
          return false;
        }

        this.bookingStatusChanged('fail');
        Analytics('Take battery ERROR - something went wrong');
        // if not found
        this.sendMessageToDialog({ 'type': 'text',
          'value': t("take-battery-screen-something-wrong", { defaultValue:  'Something went wrong. Please try again in 5 minutes or contact us at support@electrontogo.com' })
        });
        this.setState({
          showButton: true,
          typingText: false
        })
      });
    }
  }

  bookingStatusChanged = (status, callback = () => {}) => {
    const { bookingId } = this.props.match.params;

    const body = {
      status,
      bookingId
    };

    if (callback) {
      this.setState({
        typingText: true
      });
    }

    Analytics('Release status changed to '+status, body);

    fetchJSON('/api/v1/sessions/booking/change', {
      method: 'post',
      body
    }).then(data => {
      //console.log('bookingStatusChanged OK', data);
      if (callback) {
        callback();
      }
    }).catch(error => {
      console.error(error);
      this.props.history.push('/station');
   });
  }
  sessionStatus = (bookingId) => {
  	this.setState({
  		fetching: true
  	});
    fetchJSON(`/api/v1/sessions/process/${bookingId}`, {
      method: 'get'
    }).then(data => {
      if (data.code === 200) {
        console.log(data);
        if (data.booking.status === 'charger_taken') {
          this.props.history.push(`/station/${bookingId}/done`);
          return false;
        }

        const userStatus = readUserStatus(data.user);
        if (userStatus === 'userSuspended' || userStatus === 'newUser' || userStatus === 'userNoPlan') {
          this.props.history.push('/');
          return;
        }

        if (userStatus === 'userWithBattery') {
          const exchangeChargerFlow = _.get(data, 'booking.settings.exchangeChargerFlow');
          if (!exchangeChargerFlow) {
            this.props.history.push('/');
            return;
          }
        }

        const cable_by_default = _.get(data, 'user.cable_by_default');
        const cable_option_ask = _.get(data, 'user.cable_option_ask');

	      this.setState({
		  		fetching: false,
          cable_by_default,
          cable_option_ask
		  	});

        const group_id = _.get(data, 'booking.settings.group_id');
        const cableType = _.get(data, 'booking.settings.cableType');

        if (!group_id || !cableType) {
        	this.props.history.push('/station');
        	return;
        }


				this.setState({
					group_id,
					cableType
				}, this.getStation);
      } else {
        this.props.history.push('/station');
      }
    }).catch(error => {
      console.error(error);
      this.props.history.push('/');
    });
  }
  getStation = () => {
    this.setState({
      showInput: false,
      fetching: true
    })
    const value = this.state.group_id;
    if (!value) {
      Analytics('Choose group - empty value', { group_id: value });
      this.result({
        status: 'error',
        message: t("supernova-get-error", { defaultValue:  'Sorry but this Supernova is offline or that was incorrect code. Please try again.' })
      });
      return;
    }
    fetchJSON(Settings.endpoindApi + '/api/v1/groups/get/' + value, {
      method: 'get',
      headers: {
        Source: 'robot'
      }
    }).then(data => {
      console.log('venues get', data);
      if (data.code === 200) {
        Analytics('Choose group - status: success', { group_id: value });
        const getElectrons = data.venue.state.electrons.get;
        if (getElectrons) {
          const cables = _.get(data, 'venue.state.cables');
          this.result({
            status: 'success',
            message: false, 
            venue: data.venue,
            user: data.user,
            group_id: value,
            cables: cables
          });
        } else {
          Analytics('ERROR Group - No batteries found', { group_id: value });
          this.result({
            status: 'error',
            message: t("supernova-no-available-electrons", { defaultValue:  'Sorry, in this venue there are no available Electron batteries.' })
          });
        }
      } else {
        Analytics('Choose group - status: error', { group_id: value });
        this.result({
          status: 'error',
          message: t("supernova-get-error", { defaultValue:  'Sorry but this Supernova is offline or that was incorrect code. Please try again.' })
        });
      }
    }).catch(error => {
      console.log(error);
      this.result({
        status: 'error',
        message: t("supernova-get-error", { defaultValue:  'Sorry but this Supernova is offline or that was incorrect code. Please try again.' })
      });
    });
  }
  sendMessageToDialog = (props) => {
    const { dialogMessages } = this.state;
    dialogMessages.push({
        value: props.value,
        type: props.type,
        content: props.content,
        side: true
    });
    this.setState({
      dialogMessages
    }, () => {
      this.scrollToBottom();
    });
  }
  result = (result) => {
    switch (result.status) {
        case 'error': {
          this.setState({
            dialogMessages: []
          }, () => {
            this.sendMessageToDialog({ 'type': 'error', 'value': result.message });
            this.setState({
              showInput: true,
              fetching: false
            });
          })
          break;
        }
        case 'success': {
          this.setState({
            dialogMessages: [],
            //showButton: true
          }, () => {
            result.venue.resetBtn = {
              title: t("reset-station-before-release", { defaultValue:  'Select another station' }),
              class: 'dialog__collapse-btn--plainText',
              func: (e) => {
                Analytics('Reset station choice');
                this.resetStationChoiceAndBack();
              },
            };

            this.sendMessageToDialog({ 'type': 'venue', 'value': result.venue });
            
            //. 
            const { group_id, cableType, cable_by_default, cable_option_ask } = this.state;
            const cables = { lightning: 'Apple Lightning', usb_c: 'USB-C', micro_usb: 'Micro USB' }

            if (!cable_option_ask && cable_by_default) {
              this.sendMessageToDialog({
                'type': 'text_resetBtn',
                'value': {
                  text: `Preferred Cable - <b>${cables[cable_by_default]}</b>`,
                  resetBtn: {
                    title: t("reset-cable-preference", { defaultValue:  'Change cable preference' }),
                    class: 'dialog__collapse-btn--plainText',
                    func: (e) => {
                      Analytics('Change cable preference');
                      console.log('Change cable preference');
                      this.resetCablePreference();
                    },
                  }
                },
              });
            }

            if (result.message) {
              this.sendMessageToDialog({ 'type': 'text', 'value': result.message });
            }

            const {
              survey_before_release,
              survey_hide_fields,
              survey_show_fields_second_step,
              country
            } = result.venue;

            const {
              age,
              gender,
              s_paid_services,
              total_sessions,
              nps_score
            } = result.user;

            const setStateObj = {
              fetching: false,
              group_id: result.group_id,
              cables: result.cables,
            };
            
            if (survey_before_release) {
              const firstStep = !age || !gender;
              const secondStep = !s_paid_services && total_sessions > 1;

              if (firstStep) {
                setStateObj.instantRelease = false;
                setStateObj.survey_before_release = 1;
                setStateObj.survey_step = 1;
                setStateObj.survey_hide_fields = survey_hide_fields;
              } else if (country === 'JP' || country === 'CY') {
                if (survey_show_fields_second_step && Array.isArray(survey_show_fields_second_step) && survey_show_fields_second_step.indexOf('s_paid_services') > -1) {
                  if (secondStep) {
                    setStateObj.instantRelease = false;
                    setStateObj.survey_before_release = 1;
                    setStateObj.survey_step = 2;
                  }
                }
              }
              if (!setStateObj.survey_step && !nps_score && total_sessions > 0) {
                setStateObj.instantRelease = false;
                setStateObj.survey_before_release = 1;
                setStateObj.survey_step = 3;
              }
            }
            if (!survey_before_release && !nps_score && total_sessions > 0) {
              setStateObj.instantRelease = false;
              setStateObj.survey_before_release = 1;
              setStateObj.survey_step = 3;
            }

            setStateObj.showButton = false;
            setStateObj.typingText = true;

            this.setState(setStateObj, () => {
              const { bookingId } = this.props.match.params;
              Api.emit('get_station_status', { group_id, cableType, bookingId });
            });
          });
          break;
        }
        default: {
          this.setState({
            dialogMessages: []
          }, () => {
            this.sendMessageToDialog({
              'type': 'error',
              'value': 'nothing to send'
            });
            this.setState({
              showInput: true,
              fetching: false
            });
          });
          break;
        }
      }
  }
  resetCablePreference = () => {
    this.setState({
      showButton: false,
      typingText: true
    }, () => {
      this.bookingStatusChanged('reset_cable_preference', () => {
        const { group_id } = this.state;
        this.props.history.push(`/station/?id=${group_id}`);
      });
    });
  }
  resetStationChoiceAndBack = () => {
    this.setState({
      showButton: false,
      typingText: true
    }, () => {
      this.bookingStatusChanged('reset_station', () => {
        this.props.history.push('/station/');
      });
    });
  }
  renderDialog = () => {
    const { dialogMessages } = this.state;
    if (dialogMessages.length) {
      let List = [];
      dialogMessages.forEach((message, index) => {
        List.push(
          <Message key={message.index || index} value={message.value} type={message.type} content={message.content} side={message.side} />
        )
      });
      return List;
    } else{
      return(<div></div>)
    }
  }
  endOfSurvey = () => {
    this.setState({
      survey_before_release: 0
    }, () => {
      const { covid_warning } = this.state;
      if (!covid_warning) this.startAutoRelease();
    });
  }
  hideWarning = () => {
    this.setState({
      covid_warning: 0
    }, this.startAutoRelease);
  }
  startAutoRelease = () => {
    const { startAutoRelease } = this.state;
    if (startAutoRelease) return false;

    this.setState({
      startAutoRelease: 1
    }, this.startRelease);
  }
  startRelease = () => {
    const { group_id, cableType, dialogMessages, releaseInProgress } = this.state;
    if (releaseInProgress) return;

    this.setState({
      showButton: false,
      typingText: true,
      releaseInProgress: 1
    }, () => {

      if (dialogMessages.length) {
        const dialogMessagesCopy = [...dialogMessages];
        dialogMessagesCopy.forEach((message, index) => {
          if (message.type === 'venue' || message.type === 'text_resetBtn') {
            dialogMessagesCopy[index].value.resetBtn = false;
            dialogMessagesCopy[index].index = Math.random();
          }
        });
        this.setState({
          dialogMessages: dialogMessagesCopy
        });
      }

      const { bookingId } = this.props.match.params;
    
      this.bookingStatusChanged('in_progress');      
      // TODO check here if group is ready to release - if not - inventory started
      // TODO if cellular if LOW - send message about it

      Api.emit('get_electron', { group_id, cableType, bookingId });

    });
  }
  scrollToBottom = () => {
    const { fetching, survey_before_release, covid_warning } = this.state;
    if (!fetching && !survey_before_release && !covid_warning) {
      scrollIntoViewIfNeeded(this.messagesEnd, false, {
        duration: 150
      });
    }
  } 
  render() {
  	const {
      fetching,
      typingText,
      showButton,
      survey_before_release,
      survey_hide_fields,
      covid_warning,
      survey_step
    } = this.state;
    let containerStyle = {
      width: this.props.width,
      height: this.props.height
    }
    return (
			<React.Fragment>
			{
				fetching ? 
				<Loading loading = {true} className="fill-bg" />
				: <React.Fragment>
            <Background type="Dark" />
            

            { survey_before_release ?
              <Survey
                containerStyle={containerStyle}
                endOfSurvey={this.endOfSurvey}
                survey_hide_fields={survey_hide_fields}
                survey_step={survey_step}
              />
            : null }

            { covid_warning && !survey_before_release ?
              <CovidWarning containerStyle={containerStyle} hideWarning={this.hideWarning}/>
            : null }

            {
              !survey_before_release && !covid_warning ?
              <div className="app-container" style={containerStyle}>
                <div className="dialog-screen">
                  <div className="dialog__container">
                    <div className="dialog__window">
                      <div className="dialog__messages no-reverse">
                        { this.renderDialog() }
                        { typingText ? <Typing /> : null }
                        <span ref={(el) => { this.messagesEnd = el; }} style={{paddingTop: '10px'}}></span>
                      </div>
                    </div>
                  </div>
                </div>
                { showButton ? <div className="control__fixed">
                  <div>
                    <div className="control__tableRow blurred showTab">
                      <div className="dialog-bottom__container">
                        <Button
                          onClick={this.startRelease}
                          text={t('take-battery-unlock-my-charger_btn', { defaultValue:  'Unlock my charger' })} />
                      </div>
                    </div>
                  </div>
                </div> : null }
            </div>

              : null
            }
            
          </React.Fragment>
			}
    	
			</React.Fragment>
  	);
  }
};
export default windowDimensions()(StationRelease);
