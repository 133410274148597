import React from 'react';
import GoogleMapReact from 'google-map-react';

export default class GMap extends React.Component {
  render() {
    const { center, zoom, options } = this.props;
    return (
      <GoogleMapReact
        zoom={zoom}
				center={center}
			 	options={options}
      >
      { this.props.children }
      </GoogleMapReact>
    );
  }
}