import React from 'react';
import Button from 'components/Button/Button';
import { t } from 'components/Translate/Translate';

export default (props) => {
	props.propSetState({
		dialogState: 0,
		showUserController: false,
		userStory: 'userWithBattery'
	})
	class TookBatteryScreen extends React.Component {
		findVenue = () => {
			props.propSetState({
				modalMap: true
			})
		}
		showHelp = () => {
			props.propSetState({
				showReport: true,
				reportItem: 'battery_pickup',
				reportItemId: ''
			});
		}
		render() {
			const messagePlugAndGo = t("took-success-message", { defaultValue:  'Your Electron lights around<br/>Just Plug it in and Go!' });
			// const tookSuccessLabel = t("took-success-label", { defaultValue:  'You can return Electron to any of our available Return spots' });
			return (
				<div className="app-container">
					<div className="dialog-screen">
						<div className="dialog-screen__container">
							<div className="dialog-screen__message">
								<div className="welcomeScreen__title" style={{ margin: '0 auto', marginBottom: '28px', maxWidth: '260px'}}>{messagePlugAndGo}</div>
								<img src="https://getjuice.nyc3.cdn.digitaloceanspaces.com/etg-hiw.gif" alt="" style={{ 'width': "100%", 'borderRadius': '8px'}} />
							</div>
						</div>
					</div>
					<div>
						<div className="dialog-bottom__container">
							<Button
								onClick={this.showHelp}
								text={t("took-success-having-problem-btn", { defaultValue:  'Having problem?' })} />
						</div>
					</div>
				</div>
			)
		}
	};
	
	props.propSetState({
		fullScreenStore: <TookBatteryScreen />
	}, () => {
		// setTimeout(() => {
		// 	props.doParticipantAction(null, { name: 'AdsPromoScreen' }, { prevScreen: <TookBatteryScreen /> });
		// }, 5000);
		setTimeout(() => {
			props.history.push('/');
		}, 10000);
	});
	
}