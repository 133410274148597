import React from 'react';
import windowDimensions from 'react-window-dimensions';

import './styles.less';

class Loading extends React.Component {
  render() {

    let full = true;
    
    if (this.props.hasOwnProperty('full')) {
      full = this.props.full;
    }

    let containerStyle = {}
    if (full) {
      containerStyle = {
        width: this.props.width,
        height: this.props.height
      }
    }

    return (
      <div className={"loadingScreen " + (this.props.loading ? "active" : "hide") + (this.props.className ? " "+this.props.className : "")} style={containerStyle}>
      	<div className="spinner">
				  <div className="spinner-hexagon spinner-hexagon-1"></div>
				  <div className="spinner-hexagon spinner-hexagon-2"></div>
				</div>
      </div>
    )
  }
};

export default windowDimensions()(Loading);