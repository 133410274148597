import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Settings from 'services/config/Settings';
import fetchJSON from 'services/utils/fetchJSON';
import Loading from 'components/Loading';
import BackIcon from 'components/images/ic-back.svg';
import { t, Trans } from 'components/Translate/Translate';
import OrderPay from '../components/OrderPay';
import Process from '../Process/Process';
import Fail from '../Fail/Fail';
import Analytics from 'services/utils/Analytics';
import _ from 'lodash';
import GroupId from 'services/utils/GroupId';
import Cookies from 'js-cookie';


const paymentsDictionary = {
  card: t('paymentScreen.paymentMethods.creditCard', { defaultValue:  'Credit card' }),
  paypal: t('paymentScreen.paymentMethods.PayPal', { defaultValue:  'PayPal' }),
  applePay: t('paymentScreen.paymentMethods.ApplePay', { defaultValue:  'Apple Pay' }),
  venmo: t('paymentScreen.paymentMethods.Venmo', { defaultValue:  'Venmo' })
}

class SubscribeReview extends React.Component {
  state = {
    fetch: true,
    price: null,
    order: {},
    plan_id: null,
    promo_id: null,
    paymentMethod: null,
    selectedMethod: {},
    disabledNextBtn: true,
    termsAgree: false,
    planTitle: null,
    paymentMethodTitle: null,
    addPaymentMethod: true,
    paymentDisplay: '',
    planUntil: '',
    methodLabel: '',
    paymentPurpose: '',
    failMessage: 'Something went wrong',
    termsVisible: true,
    currency_template: '$1',
    currency_grapheme: '$',
    planKeyId: '',
    savedLanguage: 'en'
  }
  componentDidMount = () => {
    const pageTitle = t('pageTitles.OrderReview', { defaultValue: 'Order Review'});
    document.title = `${pageTitle} | ${Settings.title}`;
    const { orderId, screenKey } = this.props.match.params;
    console.log('screenKey', screenKey);
    const screens = ['review', 'process', 'fail'];
    
    const savedLanguage = Cookies.get('app_language');
    if (savedLanguage) {
      console.log('savedLanguage', savedLanguage)
      moment.locale(savedLanguage);
      this.setState({
        savedLanguage
      });
    }
    console.log('moment.locales()', moment.locales())

    if (orderId && screens.indexOf(screenKey) > -1) {
      this.subscribeStart(orderId);
    } else {
      this.props.history.push('/');
    }

  }
  subscribeStart = (orderId) => {
    fetchJSON(`/api/v1/subscribe/process/${orderId}`, {
      method: 'get'
    }).then(data => {
      if (data.code === 200) {
        console.log(data);
        if (data.order.status === 'paid') {
          this.props.history.push(`/subscribe/${orderId}/purchase`);
          return false;
        }
        const order = data.order.order;
        let paymentMethodTitle = paymentsDictionary['card'];
        let addPaymentMethod = true;
        let paymentDisplay = '';
        let methodLabel = '';
        if (order.selectedMethod) {
          methodLabel = order.selectedMethod.label;
          addPaymentMethod = order.selectedMethod.newMethod;
          paymentMethodTitle = paymentsDictionary[order.selectedMethod.label];
          paymentDisplay = order.selectedMethod.viewLabel;

          console.log('paymentMethodTitle', paymentMethodTitle);
        }
        let planTitle = data.order.plan_view_label;
        let paymentPurpose = `${planTitle} Plan`;
        if (order.promo_id) {
          planTitle = `Promo ${order.promo.priceView}`;
          paymentPurpose = 'Validation of payment method';
        }
        let planUntil = '';
        let planKeyId = '';
        if (order.planKeyId) {
         planKeyId = order.planKeyId;
        }
        const { savedLanguage } = this.state; 

        if (order.planUntil) {
          planUntil = moment.unix(order.planUntil);

          let dateFormat = 'ddd Do MMM Y, h:mm a';
          if (order.planKeyId === 'hourly_rate') {
            dateFormat = 'h:mm a, ddd Do MMM Y';
          }
          

          if (savedLanguage === 'ja') {
            dateFormat = 'YYYY年 MMM DD (ddd) HH:mm';
          }

          planUntil = planUntil.tz(order.timezone).format(dateFormat);
        }
        let termsAgree = false;
        if (data.user) {
          if (data.user.agree_to_terms) {
            termsAgree = true;
          }
        }
        if (!methodLabel) {
          this.props.history.push(`/subscribe/${orderId}/payments`);
          return false;
        }
        const currency_grapheme = _.get(data, 'order.order.currency_grapheme');
        const currency_template = _.get(data, 'order.order.currency_template');

        let toFixed = 2;
        if (savedLanguage === 'ja') {
          toFixed = 0;
        }

        let price = parseFloat(data.order.plan_price);
        if (price) {
          price = price.toFixed(toFixed);
        }
        this.setState({
          fetch: false,
          price,
          planTitle: planTitle,
          plan_id: order.plan_id,
          promo_id: order.promo_id,
          paymentMethod: order.paymentMethod,
          order,
          selectedMethod: order.selectedMethod || {},
          paymentMethodTitle,
          addPaymentMethod,
          paymentDisplay,
          planUntil,
          planKeyId,
          methodLabel,
          paymentPurpose,
          termsAgree,
          disabledNextBtn: !termsAgree,
          currency_grapheme,
          currency_template
        });

        const cableType = _.get(data, 'order.order.cableType');
        const group_id = _.get(data, 'order.order.group_id');

        if (group_id) {
          GroupId.add(group_id);
        }
        if (cableType) {
          GroupId.addCableType(cableType);
        }
      } else {
        this.props.history.push(`/subscribe`);
      }
    }).catch(error => {
      console.error(error);
      this.props.history.push('/');
    });
  }
  termsAgree = (e) => {
    if (e.currentTarget.checked) {
      Analytics('Agree to terms and condition');
    } else {
      Analytics('Disagree to terms and condition');
    }
    
    const orderId = this.props.match.params.orderId;
    const body = {
      orderId: orderId,
      user: {
        agree_to_terms: e.currentTarget.checked ? 1 : 0
      }
    };

    this.setState({
      termsAgree: e.currentTarget.checked,
      disabledNextBtn: !e.currentTarget.checked
    })

    fetchJSON('/api/v1/subscribe/order/change', {
      method: 'post',
      body: body 
    }).then(data => {
      console.log('Saved OK', data);
    }).catch(error => {
      console.error(error);
    });
  }
  changeFailMessage = (text) => {
    console.log('text', text);
    if (!text) return;
    this.setState({
      failMessage: text
    })
  }
  hideTerms = () => {
    this.setState({
      termsVisible: false
    })
  }
  render() {
    const { 
      fetch,
      disabledNextBtn,
      termsAgree,
      price,
      promo_id,
      planTitle,
      paymentMethodTitle,
      addPaymentMethod,
      paymentDisplay,
      planUntil,
      methodLabel,
      paymentPurpose,
      failMessage,
      termsVisible,
      currency_template,
      currency_grapheme,
      planKeyId
    } = this.state;
    const { orderId, screenKey } = this.props.match.params;
    let label = planTitle;
    switch (planTitle) {
      case 'Daily':
        label = t('subscribe_order__1_day_plan_label', { defaultValue:  'For 1 day' });
        break;
      case 'Monthly':
        label = t('subscribe_order__1_month_plan_label', { defaultValue:  'For 1 month' });
        break;
      case 'Annual':
        label = t('subscribe_order__1_year_plan_label', { defaultValue:  'For 1 year' });
        break;
      default:
    }
    let agreeTermsText = t('subscribe__review_agree_terms_privacy', { defaultValue:  'I agree #TERMS_LINK_OPEN#Terms and Conditions#TERMS_LINK_CLOSE# and #PRIVACY_LINK_OPEN#Privacy Policy#PRIVACY_LINK_CLOSE#' });
    agreeTermsText = `<span class="sbReview__checkbox-icon"></span>${agreeTermsText}`
    agreeTermsText = agreeTermsText.replace("#TERMS_LINK_OPEN#", '<a href="https://electrontogo.com/terms" rel="noopener noreferrer" class="sbReview__link" target="_blank">');
    agreeTermsText = agreeTermsText.replace("#TERMS_LINK_CLOSE#", '</a>');
    agreeTermsText = agreeTermsText.replace("#PRIVACY_LINK_OPEN#", '<a href="https://electrontogo.com/privacy" class="sbReview__link" rel="noopener noreferrer" target="_blank">');
    agreeTermsText = agreeTermsText.replace("#PRIVACY_LINK_CLOSE#", '</a>');

    let ShowPrice = currency_template;
    ShowPrice = ShowPrice.replace('1', price);
    ShowPrice = ShowPrice.replace('$', currency_grapheme);

    return (
      <React.Fragment>
        <Loading loading = { fetch } className="fill-bg" />
        <div className={"subscribe__container "+(screenKey === 'review' ? 'active' : 'hide')}>
          <div className="subscribe__header">
            <Link to={"/subscribe/"+orderId+"/payments"} className="subscribe__back-btn">
              <img src={BackIcon} alt="back icon" />
            </Link>
            <div className="subscribe__title">
              <Trans i18nKey="subscribe-review-title">Review</Trans>
            </div>
            <p className="subscribe__description"><Trans i18nKey="subscribe__review-description">Check out your selection</Trans></p>
          </div>
          <div className="subscribe__body">
            <div className="sbReview">
              <div className="sbReview__receipt">
                <div className="sbReview__row">
                  <div className="sbReview__label"><Trans i18nKey="subscribe__review_membership_title">Membership</Trans></div>
                  <div className="sbReview__content">
                    <div className="sbReview__title">
                      {label}
                    </div>
                    <div className="sbReview__price">
                      {ShowPrice}
                    </div>
                  </div>
                </div>
                <div className="sbReview__row">
                  <div className="sbReview__label">
                    {
                    planKeyId === 'hourly_rate' ? 
                      <Trans i18nKey="subscribe__review_rental_period_valid_until">Rental period valid until</Trans>
                    : 
                      <Trans i18nKey="subscribe__review_membership_valid_until">Membership valid until</Trans>
                    }
                  </div>
                  <div className="sbReview__content">
                    <div className="sbReview__title">
                      {planUntil}
                    </div>
                  </div>
                </div>
                <div className="sbReview__row">
                  <div className="sbReview__label"><Trans i18nKey="subscribe__review_payment_method_selected">Payment method</Trans></div>
                  <div className={"sbReview__content "+(!addPaymentMethod ? 'column' : 'row')}>
                    <div className="sbReview__title">
                      {paymentMethodTitle} {!addPaymentMethod ? <Link to={"/subscribe/"+orderId+"/payments"} className="sbReview__editPayment">
                       <Trans i18nKey="subscribe__review_payment_method_edit">Edit</Trans>
                      </Link> : null }
                    </div>
                    {!addPaymentMethod ? <div className="sbReview__methodLabel">
                       {paymentDisplay}
                    </div> : null }
                  </div>
                </div>
              </div>
              <div className={"sbReview__terms "+(termsVisible ? 'visible' : 'hide')}>
                <input
                  id="termsAgree"
                  type="checkbox"
                  name="terms"
                  checked={termsAgree}
                  onChange={this.termsAgree}
                  className="sbReview__checkbox-input"
                />
                <label className="sbReview__terms-text" htmlFor="termsAgree" dangerouslySetInnerHTML={{__html: agreeTermsText}}></label>
              </div>
            </div>
            {promo_id ? <div className="sbReview__note"><Trans i18nKey="subscribe__review_promo_description">We will not charge money for a promo plan, but we will request $1 from your payment method to verify it. The money will immediately return to your bank account.</Trans></div>: null}
            {methodLabel ?
              <OrderPay
                methodLabel={methodLabel}
                disabledNextBtn={disabledNextBtn}
                price={price > 1 ? price : 1}
                paymentPurpose={paymentPurpose}
                orderId={orderId}
                newMethod={addPaymentMethod}
                history={this.props.history}
                changeFailMessage={this.changeFailMessage}
                hideTerms={this.hideTerms}
              /> : null }
          </div>
        </div>
        <Process screenKey={screenKey} orderId={orderId} />
        <Fail screenKey={screenKey} orderId={orderId} failMessage={failMessage} />
      </React.Fragment>
    )
  }
};

export default SubscribeReview;