const Share = {
	name: 'share',
	steps: [
		{
			action: 'message',
			type: 'text',
			value: 'Share with your friends!',
			timeout: 200,
			typing: false
		},
		{
			action: 'func',
			attempts: 0,
			name: 'shareBtn',
			controller: {}
		},
	]
};

export default Share;