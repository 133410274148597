import React from 'react';
import { Router, Switch, Route} from 'react-router-dom';
import ReactGA from 'react-ga';
import { TrackJS } from 'trackjs';
import Settings from 'services/config/Settings';
import App from './App';
import Flow from 'screens/Flow/Flow';
import Charges from 'screens/Account/Charges/Charges';
// import Referral from 'screens/Account/Referral/Referral';

import Contact from 'screens/Account/Contact/Contact';
import DelayFees from 'screens/Account/DelayFees/DelayFees';
import About from 'screens/About/About';
import Help from 'screens/Help/Help';
import Payments from 'screens/Account/Payments/Payments';
import LostElectron from 'screens/Account/LostElectron/LostElectron';
import PaymentsHistory from 'screens/Account/PaymentsHistory/PaymentsHistory';
import LogOut from 'screens/LogOut';
import Login from 'screens/Login';
import QRdetector from 'services/middleware/QRdetector';
import ExpressLink from 'services/middleware/ExpressLink';
import ReferralCode from 'services/middleware/ReferralCode';
import MarketingPromo from 'services/middleware/MarketingPromo';
import Subscribe from 'screens/Subscribe/';
import SubscribeOrder from 'screens/Subscribe/Order/Order';
import SubscribePayments from 'screens/Subscribe/Payments/Payments';
import SubscribePurchase from 'screens/Subscribe/Purchase/Purchase';
import SubscribeReview from 'screens/Subscribe/Review/Review';
import Track from 'services/utils/withTracker';
import Station from 'screens/Station/';
import StationBooking from 'screens/Station/Booking/Booking';
import StationRelease from 'screens/Station/Release/Release';
import StationDone from 'screens/Station/Done/Done';
// utils for check authorization status
import { checkLogin, checkAccessWrap } from 'services/utils/requireAuth';
import AppWrapper from 'components/AppWrapper/AppWrapper';
import createHistory from 'history/createBrowserHistory';

if (Settings.analytics.google) {
	ReactGA.initialize(Settings.analytics.google)
}

if (Settings.trackJsEnable) {
	TrackJS.install(Settings.trackJS);
}

export const history = createHistory();

const PrivateRoute = ({ component: Component, isAuthenticated, redirectTo, ...rest}) => (
	<Route
		{...rest}
		render = { props => (
			isAuthenticated(redirectTo, () => {
				return ( <Component {...props} /> );
			})
		)}
	/>
);
const WrapperRoute = ({ component: Component, ...rest}) => {
	return (
		<Route
			{...rest}
			render = { props => (
				<AppWrapper>
					<Component {...props} />
				</AppWrapper>
			)}
		/>
	);
};
//<Route exact path='/referral' component={checkAccessWrap(Track(Referral))} />

const router = () => (
	<Router history={history}>
		<App>
			<Switch>
				<Route exact path='/subscribe/' component={Subscribe} />
				<Route exact path='/subscribe/:orderId' component={checkAccessWrap(Track(SubscribeOrder))} />
				<Route exact path='/subscribe/:orderId/payments' component={checkAccessWrap(Track(SubscribePayments))} />
				<Route exact path='/subscribe/:orderId/purchase' component={checkAccessWrap(Track(SubscribePurchase))} />
				<Route exact path='/subscribe/:orderId/:screenKey' component={checkAccessWrap(Track(SubscribeReview))} />
				<Route exact path='/payments' component={checkAccessWrap(Track(Payments))} />
				<Route exact path='/payments/history' component={checkAccessWrap(Track(PaymentsHistory))} />
				<Route exact path='/charges' component={checkAccessWrap(Track(Charges))} />
				
				<Route exact path='/electron/lost' component={checkAccessWrap(Track(LostElectron))} />
				<Route exact path='/station/' component={checkAccessWrap(Track(Station))} />
				<Route exact path='/station/:bookingId' component={checkAccessWrap(Track(StationBooking))} />
				<Route exact path='/station/:bookingId/release' component={checkAccessWrap(Track(StationRelease))} />
				<Route exact path='/station/:bookingId/done' component={checkAccessWrap(Track(StationDone))} />
				<WrapperRoute exact path='/about' component={About} />
				<WrapperRoute exact path='/faq' component={Help} />
				<WrapperRoute exact path='/contact' component={Contact} />
				<WrapperRoute exact path='/delay' component={DelayFees} />
				<Route exact path='/map' component={Flow} />
				<PrivateRoute exact path="/login" component={Track(Login)} isAuthenticated={checkLogin} redirectTo='/' />
				<Route exact path="/logout" component={LogOut} />
				<Route exact path='/i/:groupId' component={QRdetector} />
				<Route exact path='/j/:groupId' component={ExpressLink} />
				<Route exact path='/r/:referralCode' component={ReferralCode} />
				<Route exact path='/m/:referralCode' component={MarketingPromo} />
				<Route path='/' component={Flow} />
			</Switch>
		</App>
	</Router>
);

export default router;
