//check number supernova
import async from 'async';
import fetchJSON from 'services/utils/fetchJSON';
import Settings from 'services/config/Settings';
import Analytics from 'services/utils/Analytics';
import { t } from 'components/Translate/Translate';

//import Socket from 'services/middleware/Socket';

export default (props) => {
	props.propSetState({
		controller: props.activeStep.controller
	});
	if (props.params.supernovaId && !props.state.activeMessageFromUser) {
		props.propSetState({
			activeMessageFromUser: props.params.supernovaId
		});
	}
	if (props.state.userStory === 'userNoPlan') {
		window.location.href = "/subscribe";
	}
	const options = (result) => {
			switch (result.status) {
				case 'error': {
					const controller = props.state.controller;
					controller.status = true;
					props.sendMessageToDialog({ 'type': 'error', 'value': result.message });
					props.propSetState({
						controller
					});
					break;
				}
				case 'success': {
					props.sendMessageToDialog({ 'type': 'venue', 'value': result.venue });
					props.sendMessageToDialog({ 'type': 'text', 'value': result.message });
					let activeStep = props.state.activeStep + 1;
					const controller = props.state.controller;
					controller.status = false;					
					props.propSetState({ 
						activeStep: activeStep,
						attempts: 0,
						controller,
						activeMessageFromUser: null,
						groupId: result.group_id
					}, () => {
						props.moveConversation();
					});
					break;
				}
				default: {
					props.sendMessageToDialog({
						'type': 'error',
						'value': 'nothing to send'
					});
					break;
				}
			}
	}
	const processing = (callback) => {
		const value = props.state.activeMessageFromUser;
		if (value) {
			const controller = props.state.controller;
			controller.status = false;
			props.propSetState({ 
				controller
			})
			fetchJSON(Settings.endpoindApi+'/api/v1/groups/get/'+value, {
				method: 'get',
				headers: {
					Source: 'user_type'
				}
			}).then(data => {
				console.log('venues get', data);
				if (data.code === 200) {
					Analytics('Choose group - status: success', { group_id: value });
					const { exchangeBatteryFlow } = props.state;
					const { free_nests } = data;
					if (exchangeBatteryFlow) {
						if (free_nests > 0) {
							Analytics('Station is not full', { group_id: value, free_nests: free_nests });
							callback(null, {
								status: 'error',
								message: "This station has empty nests, please return your battery in any empty nest. Battery exchange is available only for full stations."
							});
							return;
						}
					}
					const getElectrons = data.venue.state.electrons.get;
					if (getElectrons) {
						callback(null, {
							status: 'success',
							message: t("supernova-get-success", { defaultValue:  'Whoa, Electron is available!' }),
							venue: data.venue,
							group_id: value
						});
					} else {
						Analytics('ERROR Group - No batteries found', { group_id: value });
						callback(null, {
							status: 'error',
							message: t("supernova-no-available-electrons", { defaultValue:  'Sorry, in this venue there are no available Electron batteries.' })
						});
					}
				} else {
					Analytics('Choose group - status: error', { group_id: value });
					callback(null, {
						status: 'error',
						message: t("supernova-get-error", { defaultValue:  'Sorry but this Supernova is offline or that was incorrect code. Please try again.' })
					});
				}
			}).catch(error => {
				console.log(error);
				callback(null, {
					status: 'error',
					message: t("supernova-get-error", { defaultValue:  'Sorry but this Supernova is offline or that was incorrect code. Please try again.' })
				});
			});
		}
	}
	
	const done = (err, result) => {
		options(result);
	}

	async.waterfall([
		processing
	], done);

	return false;
};