// import React from 'react';
import async from 'async';
import qs from 'qs';
import { parse, format, isValidNumber } from 'libphonenumber-js'
import { t } from 'components/Translate/Translate';
import fetchJSON from 'services/utils/fetchJSON';
import Auth from 'services/utils/Auth';
import Settings from 'services/config/Settings';
import Analytics from 'services/utils/Analytics';
import GroupId from 'services/utils/GroupId';
import Cookies from 'js-cookie';

export default (props) => {
	props.setState({
		controller: props.activeStep.controller
	});
	if (props.state.activeMessageFromUser) {
		props.setState({
			typingText: true,
			controller: {}
		});
		const changeInput = (number, message = false) => {
			console.log('number, message', number, message);
			var valuesList = () => {
				return [{
					title: t("auth-dialog_request_edit_num", { defaultValue:  'Edit number' }),
					func: (e) => {
						var Children = [...e.target.parentNode.getElementsByClassName("dialog__collapse-btn")];
						Children.forEach((obj, index) => {
							obj.setAttribute('disabled', 'true');
						});
						props.userWriteToDialog(t("auth-dialog_user-edit-num", { defaultValue:  'Edit number' }));
						props.sendMessageToDialog({
							'type': 'text',
							'value': t("auth-dialog_what_is_num", { defaultValue:  'What is your phone number?' })
						});
						props.setState({
							controller: props.activeStep.controller,
							activeMessageFromUser: null,
							activePhoneCode: null,
							activePhoneState: 0
						}, () => {
							props.moveConversation();
						});
						Analytics('Click to "Edit number"');
					},
				},
				{
					title: t("auth-dialog_request_code", { defaultValue:  'Request new code' }),
					func: (e) => {
						var Children = [...e.target.parentNode.getElementsByClassName("dialog__collapse-btn")];
						Children.forEach((obj, index) => {
							obj.setAttribute('disabled', 'true');
						});
						props.userWriteToDialog(t("auth-dialog_get_new_code", { defaultValue:  'Get a new code' }));
						props.setState({
							activeMessageFromUser: number,
							activePhoneCode: null,
							activePhoneState: 0
						}, () => {
							props.moveConversation();
						});
						Analytics('Click to "Request new code"');
					},
				}];
			};
			const controller = props.activeStep.controller;
			controller.label = message;
// 			props.setState({
// 				controller
// 			});
			props.sendMessageToDialog({
				type: 'choice-v3',
				value: {
					message: t("auth-dialog__type_code_from_sms", { defaultValue:  'Type code from SMS' }),
					values: valuesList()
				}
			});
		};
		const options = (result) => {
			switch (result.status) {
				case 'error':
					props.sendMessageToDialog({
						'type': 'error',
						'value': result.message
					});
					props.setState({
						controller: props.activeStep.controller
					});
					break;
				case 'error-code':
					props.sendMessageToDialog({
						'type': 'error',
						'value': result.message
					});
					changeInput();
					props.setState({
						controller: {
							status: true,
							placeholder: t("auth-dialog__type_code_placeholder", { defaultValue:  'Type code' }),
							value: '',
							type: 'tel'
						}
					});
					break;
				case 'error-phone':
					{
						props.sendMessageToDialog({
							'type': 'error',
							'value': result.message
						});
						props.setState({
							controller: props.activeStep.controller
						});
						props.sendMessageToDialog({
							'type': 'choice',
							'value': [{
								title: t("auth-dialog_user-edit-num", { defaultValue:  'Edit number' }),
								class: 'dialog__collapse-btn--alone',
								func: (e) => {
									var Children = [...e.target.parentNode.getElementsByClassName("dialog__collapse-btn")];
									Children.forEach((obj, index) => {
										obj.setAttribute('disabled', 'true');
									});
									props.userWriteToDialog(t("auth-dialog_user-edit-num", { defaultValue:  'Edit number' }));
									props.sendMessageToDialog({
										'type': 'text',
										'value': t("auth-dialog_what_is_num", { defaultValue:  'What is your phone number?' })
									});
									props.setState({
										controller: props.activeStep.controller,
										activeMessageFromUser: null,
										activePhoneCode: null
									}, () => {
										props.moveConversation();
									});
									Analytics('Click to "Edit number"');
								},
							}]
						});
						break;
					}
				case 'code':
					props.setState({
						activePhoneCode: result.phoneNumber,
						activePhoneState: 1,
						controller: {
							status: true,
							placeholder: t("auth-dialog__type_code_placeholder", { defaultValue:  'Type code' }),
							value: '',
							type: 'tel'
						}
					});
					changeInput(result.phoneNumber, result.message);
					break;

				// // TODO: Remove this?
				// case 'success':
				// 	props.sendMessageToDialog({
				// 		'type': 'text',
				// 		'value': result.message
				// 	});
				// 	let activeStep = props.state.activeStep + 1;
				// 	props.setState({
				// 		activeStep: activeStep,
				// 		activeMessageFromUser: null,
				// 		typingText: true
				// 	}, () => {
				// 		// props.doParticipantAction(null, {name: 'isBrowserSupported'});
				// 	});

				// 	// 
				// 	break;
					
				default:
					props.sendMessageToDialog({
						'type': 'error',
						'value': 'nothing to say'
					});
					break;
			}
		}
		
		const processing = (callback) => {
			let value = props.state.activeMessageFromUser;
			//console.log(props.state.activePhoneState, value);

			if (props.state.activePhoneState === 0) {
				const validNumberUS = isValidNumber(value, 'US');
				// const validNumberRU = isValidNumber(value, 'RU');
				const validNumberJP = isValidNumber(value, 'JP');
				Analytics('User entered number', { Phone: value });
				// const validNumberCY = isValidNumber(value, 'CY');
				const validNumberUK = isValidNumber(value, 'GB');

				let parseNumber;
				let formatNumber;

				parseNumber = parse(value, 'US');
				formatNumber = format(parseNumber.phone, 'US', 'International_plaintext');

				// console.log('validNumberJP', validNumberJP, 'validNumberUK', validNumberUK, 'validNumberRU', validNumberRU)

				const appLanguage = Cookies.get('app_language');

				if (!validNumberUS) {
					if (validNumberUK && appLanguage !== 'ja') {
						parseNumber = parse(value, 'GB');
						formatNumber = format(parseNumber.phone, 'GB', 'International_plaintext');
					} else if (validNumberJP) {
						parseNumber = parse(value, 'JP');
						formatNumber = format(parseNumber.phone, 'JP', 'International_plaintext');
					} else {
						formatNumber = value;
					}
				}

				console.log('formatNumber', formatNumber, 'appLanguage', appLanguage);
				
				let number = formatNumber.replace(/[^\d]/g, '');
				props.setState({
					user: {
						phone: value
					}
				});
				

				fetchJSON(Settings.endpoindApi+'/api/v1/number', {
					method: 'post',
					body: {
						number: number,
						appLanguage
					}
				}).then(data => {
					console.log('number', data);
					if (data.code === 200) {
						Analytics('Enter phone - Waiting for the code from the SMS');
						if (data.token) {
							Auth.loginUser(data.token);
						}
						callback(null, {
							status: 'code',
							message: '',
							phoneNumber: value,
							number: number
						});
					} else {
						const wrong = t("auth-dialog_wrong-num", { defaultValue:  'Wrong number, try again' });
						Analytics('Enter phone - error backend')
						callback(null, {
							status: 'error-phone',
							message: wrong,
							phoneNumber: value
						});
					}
				}).catch(error => {
					console.log(error);
					callback(null, {
						status: 'error',
						message: "Something went wrong, try again"
					});
				});
			}
			if (props.state.activePhoneState === 1) {
				Analytics('User entered code from sms', { Code: value });
				fetchJSON(Settings.endpoindApi + '/api/v1/code', {
					method: 'post',
					body: {
						code: value,
						group_id: GroupId.get()
					}
				}).then(data => {
					console.log('code', data);
					if (data.code === 200) {
						if (data.token) {
							Analytics('Right code - Successful authorization');
							Auth.loginUser(data.token, data.user ? data.user.user_id : 0);
						}
						props.setState({
							activeMessageFromUser: null,
							typingText: true,
							user: data.user ? data.user : false
						}, () => {
							setTimeout(() => {
								let paramFromUrl;
								if (window.location.search) {
									paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
								}
								let path = window.location.pathname;
								path = path.substring(1);
								if (path.length === 6 || path.length === 4) {
									paramFromUrl = { m: 'groupIdRegistred', 'groupId': path.replace(/\D+/g, '') }
								}
								if (path === '/map') {
									paramFromUrl = { m: 'turnOnMap' }
								}
								props.doParticipantAction(null, { name: 'Welcome' }, paramFromUrl);
							}, 500);
						});
						
						return false;
					} else {
						Analytics('User entered the wrong code')
						const wrong = t("auth-dialog_wrong-num", { defaultValue:  'Wrong number, try again' });
						callback(null, {
							status: 'error-code',
							message: wrong,
							phoneNumber: value
						});
					}
				}).catch(error => {
					Analytics('ERROR! during authorization')
					callback(null, {
						status: 'error',
						message: "Something went wrong, try again"
					});
				});
			}
		}

		const done = (err, result) => {
			options(result);
		}

		async.waterfall([
			processing
		], done);

	}
	
	return false;
};