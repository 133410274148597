import React from 'react';
import closeIcon from 'components/images/close-icon.svg';

class HowDoPlansWork extends React.Component {
	render() {
		return (
			<div className="modal__box" style={{display: 'block'}}>
				<div className="modal__backdrop"></div>
				<div className="modal__containter">
					<div className="modal">
						<div className="modal__inner" style={{overflow: 'unset'}}>
								<div className="modal__wrapper">
									<div className="infoModal">
										<div className="infoModal__head">
											<button className="infoModal__close" onClick={this.props.closeModal}>
												<img src={closeIcon} alt="" />
											</button>
											<h1 className="infoModal__title">How do plans work?</h1>
										</div>
										<div className="infoModal__body">
											<h2>Does my plan Auto-Renew?</h2>
											<p>No, you must manually renew each time</p>

											<h2>How many Electron™ batteries can I get?</h2>
											<p>Only one but pick up and return as many times as you like</p>

											<h2>How long can I keep the charger?</h2>
											<p>Please return every within 24 hours. See User terms for detail on your plan</p>

											<h2>What happens if I don't return an Electron™ in time?</h2>
											<p>If you are daily user we will charge you $1 a day and after 3 days, we will upgrade your subscription to monthly and charge you $10. If you are a Monthly subscriber, you have up to 7 days to return the Electron, after which time we will upgrade your subscription to annual and charge you $60. If you are an Annual user, you have up to 7 days to return after which we will suspend your account until you return the Electron™.</p>

											<h2>Why do I need to add a payment method when I have a promotional code?</h2>
											<p>To ensure the return of the Electron™ battery to the station</p>
											
											<h2>What if I don’t like the service?</h2>
											<p>You have the first two weeks to get a full refund of your Monthly, Semester or Annual subscription.</p>
											
											<h2>How do I get my refund?</h2>
											<p>You can cancel your plan on payments page (<a href="https://electrontogo.app/payments">https://electrontogo.app/payments</a>). Only first time subscriber can get a refund.</p>

										</div>
									</div>
								</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HowDoPlansWork;