import React from 'react';

import './styles.less';

class Button extends React.Component {
  render() {
    return (
      <button
      	key={this.props.keyprop ? this.props.keyprop : null }
				className={"dialog-bottom__btn"+(this.props.addClass ? ' '+this.props.addClass : '' )}
				onClick={this.props.onClick}
				>{this.props.text}</button>
    )
  }
};

export default Button;