import React from 'react';
import Loading from 'components/Loading';
import fetchJSON from 'services/utils/fetchJSON';
import Analytics from 'services/utils/Analytics';
import qs from 'qs';
import _ from 'lodash';
import readUserStatus from './components/readUserStatus';
import GroupId from 'services/utils/GroupId';
import Auth from 'services/utils/Auth';

class Station extends React.Component {
	componentDidMount = () => {
		this.sessionStart();
  }
  sessionStart = () => {
  	let group_id = '';
		let paramFromUrl;
    if (window.location.search) {
      paramFromUrl = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }
    if (paramFromUrl) {
			if (_.get(paramFromUrl, 'id')) {
				group_id = _.get(paramFromUrl, 'id');
			}
    }
    if (!group_id) {
    	const fromCookie = GroupId.get();
    	if (fromCookie) {
    		group_id = GroupId.get();
    		if (Auth.isUserAuthenticated()) {
    			GroupId.remove();
    		}
    	}
    }

  	fetchJSON('/api/v1/sessions/start', {
			method: 'post',
			body: {
				settings: {
					group_id
				}
			}
		}).then(data => {
			console.log('start', data);
			if (data.code === 200) {
				Analytics('New station booking', { BookingId: data.booking.key_id });

				const userStatus = readUserStatus(data.user);
				if (userStatus === 'userSuspended' || userStatus === 'newUser') {
          this.props.history.push('/');
          return;
        }

        if (userStatus === 'userWithBattery') {
          this.props.history.push('/');
          return;
        }

				this.props.history.push(`/station/${data.booking.key_id}`);
				// TODO in_progress ?? redirect to page that another booking in progress

			} else {
				this.props.history.push('/');
			}
		}).catch(error => {
			console.error(error);
			this.props.history.push('/');
		});
  }
  render() {
    return <Loading loading = {true} className="fill-bg" />
  }
};

export default Station;