import React from 'react';
import Button from 'components/Button/Button';
import { t, Trans } from 'components/Translate/Translate';

export default (props) => {
	props.propSetState({
		dialogState: 0,
		userActions: {}
	})
	let buttonText = t("payment-success-get-battery-btn", { defaultValue:  'Get battery' });
	class SuccessPaymentScreen extends React.Component {
		goForward = () => {
			props.propSetState({
				activeStep: props.state.activeStep + 1,
			}, () => {
				props.moveConversation();
			});
		}
		render() {
			return (
				<div className="app-container">
					<div className="dialog-screen">
						<div className="dialog-screen__container">
							<div className="dialog-screen__message">
								<span role="img" aria-label="Star">✨ </span><br/><br/>
								<Trans i18nKey="payment-success-thankYou">Congratulations!<br/><br/>Juice your battery any time and place you want for 1 year</Trans> 
							</div>
						</div>
					</div>
					<div>
						<div className="dialog-bottom__container">
							<div className="UserController__label"><Trans i18nKey="payment-success-refund-guaranteed">One-month full refund guaranteed</Trans></div>
							<Button
								onClick={this.goForward}
								text={buttonText} />
						</div>
					</div>
				</div>
			)
		}
	};
	
	props.propSetState({
		fullScreenStore: <SuccessPaymentScreen />
	});
	
}