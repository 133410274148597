import React from 'react';
import { Link } from 'react-router-dom';
import BackIcon from 'components/images/ic-back.svg';
import { Trans } from 'components/Translate/Translate';
import FailPayment from 'components/images/fail-payment.svg';

class SubscribeFail extends React.Component {
  render() {
    const { screenKey, orderId, failMessage } = this.props;
    return (
      <div className={"subscribe__container "+(screenKey === 'fail' ? 'active' : 'hide')}>
        <div className="subscribe__header">
          <Link to={"/subscribe/"+orderId+"/payments"} className="subscribe__back-btn">
            <img src={BackIcon} alt="back icon" />
          </Link>
          <div className="subscribe__title">
            <Trans i18nKey="subscribe-fail-title">Fail</Trans>
          </div>
        </div>
        <div className="subscribe__body sbReviewFail">
           <img src={FailPayment} alt="Fail Payment" />
           <p className="sbReviewFail__description">{failMessage}</p>
           <div className="sbOrder__help-wrap sbReviewFail__help">
              <Link to="/contact" className="sbOrder__help">
                <Trans i18nKey="subscribe-fail-help">Help is needed? Contact us</Trans>
              </Link>
            </div>
            <div className="sbOrder__action-row">
              <Link to={"/subscribe/"+orderId+"/payments"} className="sbOrder__action-btn">
                <Trans i18nKey="subscribe-fail-retry">Retry</Trans>
              </Link>
            </div>
        </div>
      </div>
    )
  }
};

export default SubscribeFail;