import React from 'react';
// import BackIcon from 'components/images/ic-back.svg';
// import { Trans } from 'components/Translate/Translate';
import { t, Trans } from 'components/Translate/Translate';
import Button from 'components/Button/Button';
import covidWarning from 'components/images/gifs/clean-your-electron-warning.gif';

class CovidWarning extends React.Component {
  state = {
  }
  submitPage = () => {
    this.props.hideWarning();
  }
  render() {
    const { containerStyle } = this.props;
    // const {
    // } = this.state;

    const buttonSubmitText = t('covidWarning.gotIt', { defaultValue:  'Got it' });
    const textSize = '12px';

    return (
      <div className="app-container" style={containerStyle}>
          <div className="dialog-screen">
            <div className="dialog__container">
              <div className="subscribe__header">
                <div className="subscribe__title" style={{ paddingTop: '0px', fontSize: '18px', fontWeight: '500', paddingBottom: '5px' }}>
                  <Trans i18nKey="covidWarning.title">
                    Available chargers were returned less than 48 hours ago
                  </Trans>
                </div>
              </div>
              <div className="subscribe__body" style={{ padding: '0 10px' }}>
                <div className="surveyPage">
                  <div className="subscribe__title" style={{ fontSize: '14px', padding: 0, paddingBottom: '15px' }}>
                    <Trans i18nKey="covidWarning.text_one">
                      Exteriors can be safely wiped with a soft cloth dampened with water or 70% isopropyl alcohol solution, or with an electronics-safe antimicrobial spray. 
                    </Trans>
                  </div>
                  <div style={{ paddingBottom: '15px' }}>
                    <div
                     style={{
                      backgroundImage: `url(${covidWarning})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      width: '100%',
                      height: '250px'
                     }}
                    ></div>
                  </div>
                  <div className="subscribe__title subscribe__titleRedBold" style={{ fontSize: textSize, padding: 0, paddingBottom: '5px' }}>
                    <Trans i18nKey="covidWarning.text_two">
                      <b>DO NOT</b> use excessive liquid, or Electron while cleaning or at any other time.
                    </Trans>
                  </div>
                  <div className="subscribe__title subscribe__titleRedBold" style={{ fontSize: textSize, padding: 0, paddingBottom: '20px' }}>
                    <Trans i18nKey="covidWarning.text_three">
                      <b>DO NOT</b> plug in disinfected connectors until product is completely dry. 
                    </Trans>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="control__fixed">
            <div>
              <div className="control__tableRow blurred showTab">
                <div className="dialog-bottom__container">
                  <Button
                    onClick={this.submitPage}
                    text={buttonSubmitText} />
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
};

export default CovidWarning;