import React from 'react';
import { Redirect } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper/AppWrapper';
import Loading from 'components/Loading';

import Auth from './Auth';
import fetchJSON from './fetchJSON';

/**
 * when entering the page to check if the user has access to them
 */ 
const checkRole = (location, next) => {
	// create an AJAX request
	fetchJSON('/api/v1/users/access', {
		method: 'get',
		headers: {
			Page: location
		}
	}).then(data => {
		if (data.code === 200) return next(data.access, data.redirectTo);
		return next(false);
	}).catch((error) => {
		if (error.status === 500) {
			return next(500);
		} else {
			return next(false);
		}
	});
};

export const checkAccessWrap = (Component) => {
  class AuthenticatedComponent extends React.Component {
		state = {
			isLoggedIn: false
		}
    componentWillMount() {
      this.checkAuth();
    }
    checkAuth() {
    	const location = this.props.location;
    	const redirect = location.pathname + location.search;
			checkRole(redirect, (access, redirectTo = false) => {
				if (access) {
					this.setState({
						isLoggedIn: true
					})
				} else if (access === 500) {
					this.props.history.push(`/error500?redirect=${redirect}`);
				} else if (access === 302) {
					this.props.history.push(`/login?redirect=${redirect}`);
				} else {
					this.props.history.push('/');
				}
			})
    }
    render() {
      return this.state.isLoggedIn
        ? <AppWrapper><Component { ...this.props } /></AppWrapper>
        : <Loading loading = { false } className="fill-bg" />;
    }
  }
	return AuthenticatedComponent;
}

export const checkLogin = (redirectTo, next) => {
	if (Auth.isUserAuthenticated()) {
		return ( <Redirect to={{ pathname: redirectTo }} /> );
	} else {
		return next();
	}
};

export default checkAccessWrap;
