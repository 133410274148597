const readUserStatus = (user) => {
  let activePlan = false;
  let userStory = null;
  let userSuspended = typeof user === 'object' && user.blocking_reason ? true : false;

  if (!user) {
    userStory = 'newUser';
  } else if (!userSuspended) {
    const plan = user.plan_status ? user.plan_status === 'Active' || user.plan_status === 'Single' : false;
    if (user.subscription_id && plan) {
      activePlan = true;
    }

    if (user.sessions.length) {
      userStory = 'userWithBattery';
    } else if (activePlan) {
      userStory = 'userNoBattery';
    } else {
      userStory = 'userNoPlan';
    }
  } else {
    userStory = 'userSuspended';
  }
  return userStory;
}
export default readUserStatus;