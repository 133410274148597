import React from 'react';
import Cookies from 'js-cookie';
import { detect } from 'detect-browser';
import fetchJSON from 'services/utils/fetchJSON';
import GeoIcon from './components/images/icon-geolocation.svg';
import MapMarker from './components/MapMarker';
import UserPoint from './components/UserPoint';
import GMap from './components/GMap';
import Loading from 'components/Loading';
import { t, Trans } from 'components/Translate/Translate';
import PreferredCablesFilter from './components/PreferredCablesFilter';
import _ from 'lodash';

import './styles.less';

const browser = detect();

class Map extends React.Component {
	state = {
		coords: {},
		center: { lat: 33.979751, lng: -118.14469300000002 },
		loading: true,
		zoom: 13,
		typeVenues: this.props.userStory === 'userWithBattery' ? 1 : 0,
		accessToLocation: true,
		browserAllowedGeolocation: false,
		venues: [],
		userCenter: {},
		ipLocation: {},
		showCablesFilter: true,
		cablesFilterVisible: false,
		cablesToShow: ['lightning', 'usb_c', 'micro_usb']
	}
	componentDidMount = () => {
		if (this.props.location) {
			let position = {};
			console.log('this.props.location', this.props.location);
			if (this.props.location.lat && this.props.location.lng) {
				position = {
					lat: parseFloat(this.props.location.lat),
					lng: parseFloat(this.props.location.lng)
				};
			} else {
				position = {
					lat: 33.77751538,
					lng: -118.20848644
				};
			}
			
			this.setState({
				ipLocation: position,
				center: position,
				zoom: 14
			});
		}
		this.getListVenues();
		const cable_by_default = _.get(this.props, 'state.user.cable_by_default');
    const cable_option_ask = _.get(this.props, 'state.user.cable_option_ask');
		
		if (cable_option_ask === 0) {
			this.setState({
				showCablesFilter: false
			});
		}
		let cablesToShow = ['lightning', 'usb_c', 'micro_usb'];
		if (cable_by_default) {
			if (['lightning', 'usb_c', 'micro_usb'].indexOf(cable_by_default) > -1) {
				cablesToShow = [cable_by_default];
			}
		}

		let cablePreference = Cookies.get('cablePreference');
		if (cablePreference) {
			if (cablePreference.length) {
				cablePreference = cablePreference.split(',');
				if (cablePreference.length) {
					cablesToShow = cablePreference;
				}
			}
		}
		if (cablesToShow) {
			this.setState({
				cablesToShow
			});
		}
		
	}
	getListVenues = () => {
    fetchJSON('/api/v1/venues/get', {
			method: 'get'
		}).then(data => {
			console.log(data);
			if (data.code === 200) {
				const browserAllowedGeolocation = Cookies.get('browserAllowedGeolocation');
				this.setState({
					loading: false,
					venues: data.venues,
					accessToLocation: browserAllowedGeolocation === 'no' ? false : true
				});
				if (browserAllowedGeolocation === 'yes') {
					if (browser) {
						if (browser.name !== 'ios' && browser.name !== 'safari') {
							this.detectUserLocation();
						}
					}
				}
			}
     }).catch(error => {
				console.log(error);
				this.setState({
					loading: true
				});
     });
  }
	propsSetState = (param) => {
		this.setState(param);
	}
	detectUserLocation = () => {
		// Browser doesn't support Geolocation
    if (!navigator.geolocation) {
    	console.log("Browser doesn't support Geolocation");
    	return false;
  	}
  	const browserAllowedGeolocation = Cookies.get('browserAllowedGeolocation');
		if (browserAllowedGeolocation === undefined) {
			const answerForUser = t('map-ask-user-share-location', { defaultValue:  'For ease of navigation, let us use your location.' });
			if (window.confirm(answerForUser)) {
				Cookies.set('userAllowedGeolocation', 'yes', { expires: 7 });
				this.userAllowedGeolocation();
			} else {
				this.setState({
					accessToLocation: false
				});
				Cookies.set('userAllowedGeolocation', 'no', { expires: 7 });
			}
  	} else if (browserAllowedGeolocation === 'yes') {
			this.userAllowedGeolocation();  		
  	}
	}
	userAllowedGeolocation = () => {
		navigator.geolocation.getCurrentPosition((position) => {
      const pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      Cookies.set('browserAllowedGeolocation', 'yes', { expires: 100 });
      this.setState({
				center: pos,
				accessToLocation: true,
				browserAllowedGeolocation: true,
				userCenter: pos,
				zoom: 15
			});
    }, () => {
    	Cookies.set('browserAllowedGeolocation', 'no', { expires: 100 });
      console.log('User refused');
      this.setState({
				accessToLocation: false
			});
    });
	}
	
	createMapOptions = (maps) => {
		/* eslint-disable */
		return {
			mapTypeControl: false,
			fullscreenControl: false,
			zoomControl: true,
			clickableIcons: false,
			zoomControlOptions: {
		    style: google.maps.ZoomControlStyle.SMALL,
		    position: google.maps.ControlPosition.LEFT_CENTER
	    }
		};
		/* eslint-enable */
	}
	typeVenues = (e) => {
		const value = parseInt(e.target.value, 10);
		this.setState({
			typeVenues: value
		});
	}
	showUserPoint = () => {
		const { browserAllowedGeolocation, userCenter, center } = this.state;
		return <UserPoint
			lat={ browserAllowedGeolocation ? userCenter.lat : center.lat }
			lng={ browserAllowedGeolocation ? userCenter.lng : center.lng }
			accessToLocation={browserAllowedGeolocation}
		/>;
	}
	getMarkers = () => {
		return this.state.venues.map((venue, index) => {
			return <MapMarker
								key={index}
								venue = {venue}
								lat={venue.coordinates.lat}
								lng={venue.coordinates.lng}
								is_public={venue.is_public}
								sendMessageToDialog={this.props.sendMessageToDialog}
								moveConversation={this.props.moveConversation}
								doParticipantAction={this.props.doParticipantAction}
								flow={this.props.state}
								title={venue.title}
								address={venue.address}
								description={venue.description}
								electrons={venue.state.electrons}
								typeToShow={ this.state.typeVenues ? 'return' : 'get' }
								cablesAvailable={ venue.state.cables }
								cablesToShow={ this.state.cablesToShow }
							/>;
		});
	}
	changeCablesFilterVisibility = () => {
		const { cablesFilterVisible } = this.state;

		this.setState({
			cablesFilterVisible: !cablesFilterVisible
		});
	}
	hideCablesFilter = () => {
		setTimeout(() => {
			this.setState({
				cablesFilterVisible: false
			});
		}, 50);
	}
	changeCablesFilter = (cable) => {
		const { cablesToShow } = this.state;

		if (cablesToShow.indexOf(cable) > -1) {
      const filter = cablesToShow.filter(item => item !== cable);
      this.setState({
      	cablesToShow: filter
      });
      if (filter.length) {
      	Cookies.set('cablePreference', filter.join(','), { expires: 365 });
      }
    } else {
			const copy = [...cablesToShow];
			copy.push(cable);
			if (copy.length) {
				Cookies.set('cablePreference', copy.join(','), { expires: 365 });
			}
			
      this.setState({
      	cablesToShow: copy
      });
    }

	}
	render() {
		const { accessToLocation, cablesFilterVisible, cablesToShow, showCablesFilter } = this.state;
		return (
			<React.Fragment>
			{ this.state.loading ? 
				<Loading loading = {false} />
			: <div className="mainMap">
					{ accessToLocation ? <button className="maps__geolocation" onClick={this.detectUserLocation}><img src={GeoIcon} alt="" /></button> : null }
					{ showCablesFilter ? <button className={"maps__cables-btn mainPage "+(cablesToShow.length !== 3 ? 'active' : '')} onClick={this.changeCablesFilterVisibility}>
						<Trans i18nKey="map_preffered_cable_filter">Preferred Cables</Trans>
					</button> : null }
					{ cablesFilterVisible ? <PreferredCablesFilter
																		class="mainPage"
																		activeOptions={cablesToShow}
																		onChange={this.changeCablesFilter}
																		hide={this.hideCablesFilter} /> : null }
					{ false ? <div className="maps__control-types">
						<input id="get" type="radio" name="type" checked={this.state.typeVenues === 0} value="0" className="maps__type-input" onChange={this.typeVenues}/>
						<label htmlFor="get" className="maps__type-btn maps__type-btn--mrg">
							<Trans i18nKey="map_get_electron">Get Electron</Trans>
						</label>
						<input id="return" type="radio" name="type" value="1" checked={this.state.typeVenues === 1} className="maps__type-input" onChange={this.typeVenues} />
						<label htmlFor="return" className="maps__type-btn" >
							<Trans i18nKey="map_return_electron">Return</Trans>
						</label>
					</div> : null }
					 <GMap
							zoom={this.state.zoom}
							center={this.state.center}
						 	options={this.createMapOptions}
						>
							{ this.showUserPoint() }
							{ this.getMarkers() }
						</GMap>
				</div>
			}
			</React.Fragment>
		);
	}
}

export default Map;