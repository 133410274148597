import React from 'react';
import Cookies from 'js-cookie';
import { detect } from 'detect-browser';
import fetchJSON from 'services/utils/fetchJSON';
import Back from 'components/images/back-icon.svg';

import GeoIcon from '../Map/components/images/icon-geolocation.svg';
import MapMarker from '../Map/components/MapMarker';
import UserPoint from '../Map/components/UserPoint';
import GMap from '../Map/components/GMap';
import PreferredCablesFilter from '../Map/components/PreferredCablesFilter';

import Loading from 'components/Loading';
import { t, Trans } from 'components/Translate/Translate';
import Analytics from 'services/utils/Analytics';

import './styles.less';

const browser = detect();

class ModalMap extends React.Component {
	state = {
		coords: {},
		center: { lat: 33.979751, lng: -118.14469300000002 },
		loading: true,
		zoom: 13,
		typeVenues: this.props.state.userStory === 'userWithBattery' ? 1 : 0,
		accessToLocation: true,
		browserAllowedGeolocation: false,
		venues: [],
		userCenter: {},
		ipLocation: {},
		cablesFilterVisible: false,
		cablesToShow: ['lightning', 'usb_c', 'micro_usb']
	}
	componentDidMount = () => {
		console.log('modalmap');

		if (this.props.state.location) {
			const position = {
				lat: parseFloat(this.props.state.location.lat),
				lng: parseFloat(this.props.state.location.lng)
			};
			this.setState({
				ipLocation: position,
				center: position,
				zoom: 14
			});
		}
		this.getListVenues();
		let cablePreference = Cookies.get('cablePreference');
		if (cablePreference) {
			if (cablePreference.length) {
				cablePreference = cablePreference.split(',');
				if (cablePreference.length) {
					this.setState({
						cablesToShow: cablePreference
					});
				}
			}
		}
	}
	getListVenues = () => {
    fetchJSON('/api/v1/venues/get', {
			method: 'get'
		}).then(data => {
			console.log(data);
			if (data.code === 200) {
				const browserAllowedGeolocation = Cookies.get('browserAllowedGeolocation');
				this.setState({
					loading: false,
					venues: data.venues,
					accessToLocation: browserAllowedGeolocation === 'no' ? false : true
				});
				if (browserAllowedGeolocation === 'yes') {
					if (browser) {
						if (browser.name !== 'ios' && browser.name !== 'safari') {
							Analytics('Map - start auto detection of a location');
							this.detectUserLocation(false);
						}
					}
				}
			}
     }).catch(error => {
				console.log(error);
				this.setState({
					loading: true
				});
     });
  }
	propsSetState = (param) => {
		this.setState(param);
	}
	detectUserLocation = (user = true) => {
		if (user) {
			Analytics("Map - user clicked to define location");
		}
		// Browser doesn't support Geolocation
    if (!navigator.geolocation) {
    	Analytics("Map - Browser doesn't support Geolocation");
    	console.log("Browser doesn't support Geolocation");
    	return false;
  	}
  	const browserAllowedGeolocation = Cookies.get('browserAllowedGeolocation');
		if (browserAllowedGeolocation === undefined) {
			const answerForUser = t('map-ask-user-share-location', { defaultValue:  'For ease of navigation, let us use your location.' });
			if (window.confirm(answerForUser)) {
				Cookies.set('userAllowedGeolocation', 'yes', { expires: 7 });
				Analytics("Map - user confirm location");
				this.userAllowedGeolocation();
			} else {
				Analytics("Map - user rejected location");
				this.setState({
					accessToLocation: false
				});
				Cookies.set('userAllowedGeolocation', 'no', { expires: 7 });
			}
  	} else if (browserAllowedGeolocation === 'yes') {
  		Analytics('Map - browser allowed location before');
  		this.userAllowedGeolocation();
  	}
	}
	userAllowedGeolocation = () => {
		navigator.geolocation.getCurrentPosition((position) => {
      const pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      Analytics("Map - we got a geolocation", {Location: pos});
      Cookies.set('browserAllowedGeolocation', 'yes', { expires: 100 });
      this.setState({
				center: pos,
				accessToLocation: true,
				browserAllowedGeolocation: true,
				userCenter: pos,
				zoom: 15
			});
    }, () => {
    	Cookies.set('browserAllowedGeolocation', 'no', { expires: 100 });
      console.log('User refused');
      Analytics("Map - browser/user refused location");
      this.setState({
				accessToLocation: false
			});
    });
	}
	closeMap = () => {
		this.props.history.push('/');
		this.props.setState({
			modalMap: false
		})
	}
	createMapOptions = (maps) => {
		/* eslint-disable */
		return {
			mapTypeControl: false,
			fullscreenControl: false,
			zoomControl: true,
			clickableIcons: false,
			zoomControlOptions: {
		    style: google.maps.ZoomControlStyle.SMALL,
		    position: google.maps.ControlPosition.RIGHT_TOP
	    }
		};
		/* eslint-enable */
	}
	typeVenues = (e) => {
		const value = parseInt(e.target.value, 10);
		this.setState({
			typeVenues: value
		});
	}
	showUserPoint = () => {
		const { browserAllowedGeolocation, userCenter, center } = this.state;
		return <UserPoint
			lat={ browserAllowedGeolocation ? userCenter.lat : center.lat }
			lng={ browserAllowedGeolocation ? userCenter.lng : center.lng }
			accessToLocation={browserAllowedGeolocation}
		/>;
	}
	getMarkers = () => {
		// console.log('cablesToShow', this.state.cablesToShow);

		return this.state.venues.map((venue, index) => {
			// console.log('venue.is_public', venue, venue.is_public);
			return <MapMarker
								key={index}
								venue = {venue}
								lat={venue.coordinates.lat}
								lng={venue.coordinates.lng}
								is_public={venue.is_public}
								sendMessageToDialog={this.props.sendMessageToDialog}
								moveConversation={this.props.moveConversation}
								doParticipantAction={this.props.doParticipantAction}
								flow={this.props.state}
								title={venue.title}
								address={venue.address}
								description={venue.description}
								electrons={venue.state.electrons}
								typeToShow={ this.state.typeVenues ? 'return' : 'get' }
								cablesAvailable={ venue.state.cables }
								cablesToShow={ this.state.cablesToShow }
							/>;
		});
	}
	changeCablesFilterVisibility = () => {
		const { cablesFilterVisible } = this.state;

		this.setState({
			cablesFilterVisible: !cablesFilterVisible
		});
	}
	hideCablesFilter = () => {
		setTimeout(() => {
			this.setState({
				cablesFilterVisible: false
			});
		}, 50);
	}
	changeCablesFilter = (cable) => {
		const { cablesToShow } = this.state;

		if (cablesToShow.indexOf(cable) > -1) {
      const filter = cablesToShow.filter(item => item !== cable);
      this.setState({
      	cablesToShow: filter
      });
      if (filter.length) {
      	Cookies.set('cablePreference', filter.join(','), { expires: 365 });
      }
    } else {
			const copy = [...cablesToShow];
			copy.push(cable);
			if (copy.length) {
				Cookies.set('cablePreference', copy.join(','), { expires: 365 });
			}
      this.setState({
      	cablesToShow: copy
      });
    }

	}
	render() {
		let style = {
			'display': 'block'
		};
		const { accessToLocation, cablesFilterVisible, cablesToShow } = this.state;
		return (
			<div className="modal__box" style={style}>
				<div className="modal__backdrop"></div>
				<div className="modal__containter">
					<div className="modal maps-modal">
						{ this.state.loading ? 
							<Loading loading = {true} className="fill-bg" />
						: <div className="modal__inner">
								<div className="modal__wrapper">
									<button className="modal__back" onClick={this.closeMap}><img src={Back} alt="" /></button>
									{ this.state.typeVenues === 0 ? <button className={"maps__cables-btn "+(cablesToShow.length !== 3 ? 'active' : '')} onClick={this.changeCablesFilterVisibility}>
										<Trans i18nKey="map_preffered_cable_filter">Preferred Cables</Trans>
									</button> : null }
									{ cablesFilterVisible ? <PreferredCablesFilter activeOptions={cablesToShow} onChange={this.changeCablesFilter} hide={this.hideCablesFilter} /> : null }
									{ accessToLocation ? <button className="maps__geolocation" onClick={this.detectUserLocation}><img src={GeoIcon} alt="" /></button> : null }
									<div className="maps__control-types">
										<input id="get" type="radio" name="type" checked={this.state.typeVenues === 0} value="0" className="maps__type-input" onChange={this.typeVenues}/>
										<label htmlFor="get" className="maps__type-btn maps__type-btn--mrg">
											<Trans i18nKey="map_get_electron">Get Electron</Trans>
										</label>
										<input id="return" type="radio" name="type" value="1" checked={this.state.typeVenues === 1} className="maps__type-input" onChange={this.typeVenues} />
										<label htmlFor="return" className="maps__type-btn" >
											<Trans i18nKey="map_return_electron">Return</Trans>
										</label>
									</div>
									 <GMap
											zoom={this.state.zoom}
											center={this.state.center}
										 	options={this.createMapOptions}
										>
											{ this.showUserPoint() }
											{ this.getMarkers() }
										</GMap>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default ModalMap;