import React from 'react';
import windowDimensions from 'react-window-dimensions';
import fetchJSON from 'services/utils/fetchJSON';
import Analytics from 'services/utils/Analytics';
import Settings from 'services/config/Settings';
import Button from 'components/Button/Button';
import { t } from 'components/Translate/Translate';
import Background from '../../Flow/components/Background';
import Report from 'components/Report/Report';
import Cookies from 'js-cookie';

class StationDone extends React.Component {
  state = {
    showReport: false,
    reportItem: 'battery_pickup',
    reportItemId: '',
    sessions: []
  }
	componentDidMount = () => {
    const pageTitle = t('pageTitles.TheCharger', { defaultValue: 'The charger'});
    document.title = `${pageTitle} | ${Settings.title}`;
    Analytics("The charger was took");

    this.sessionStatus();

    const interval = setInterval(() => {
      const { sessions } = this.state;
      if (sessions && sessions.length) {
        this.sessionStatus();
      } else {
        clearInterval(interval);
      }
    }, 5000);
  }
  sessionStatus = () => {
    const { bookingId } = this.props.match.params;
    this.setState({
      fetching: true
    });
    fetchJSON(`/api/v1/sessions/process/${bookingId}`, {
      method: 'get'
    }).then(data => {
      if (data.code === 200) {
        console.log(data);
        if (data.booking.status !== 'charger_taken') {
          this.props.history.push('/station/');
          return false;
        }
        if (data.user) {
          if (data.user.sessions && !data.user.sessions.length) {
            this.setState({
              sessions: []
            });
            this.props.history.push('/');
          } else if (data.user.sessions && data.user.sessions.length) {
            this.setState({
              sessions: data.user.sessions
            });
          }
        }
      } else {
       this.props.history.push('/station');
      }
    }).catch(error => {
      console.error(error);
      this.props.history.push('/');
    });
  }
  showHelp = () => {
    this.setState({
      showReport: true,
      reportItem: 'battery_pickup',
      reportItemId: ''
    });
  }
  hideHelp = () => {
    this.setState({
      showReport: false
    });
  }
  render() {
    const { showReport, reportItem, reportItemId } = this.state;
    let containerStyle = {
      width: this.props.width,
      height: this.props.height
    }

    const savedLanguage = Cookies.get('app_language');

    const messagePlugAndGo = t("took-success-message", { defaultValue:  'Your Electron lights around<br/>Just Plug it in and Go!' });
    return (
      <React.Fragment>
        <Background type="Dark" />
        <div className="app-container" style={containerStyle}>
          <div className="dialog-screen">
            <div className="dialog-screen__container">
              <div className="dialog-screen__message">
                <div className="welcomeScreen__title" style={{ margin: '0 auto', marginBottom: '28px', maxWidth: '260px'}}>{messagePlugAndGo}</div>
                <img src="https://getjuice.nyc3.cdn.digitaloceanspaces.com/etg-hiw.gif" alt="" style={{ 'width': "100%", 'borderRadius': '8px'}} />
                {
                  savedLanguage === 'ja' &&
                  <div style={{
                    fontSize: '14px',
                    paddingTop: '30px'
                  }}>「充電器の裏面は磁石です。注意して下さい」</div>
                }
              </div>
            </div>
          </div>
          <div>
            <div className="dialog-bottom__container">
              <Button
                onClick={this.showHelp}
                text={t("took-success-having-problem-btn", { defaultValue:  'Having problem?' })} />
            </div>
          </div>
        </div>
        { showReport ?
            <Report
              modal={true}
              visible={showReport}
              canExchangeBattery={false}
              item={reportItem}
              itemId={reportItemId}
              hideIt={this.hideHelp}
            /> : null }
      </React.Fragment>)
  }
};

export default windowDimensions()(StationDone);
