const Welcome = {
	name: 'welcome',
	steps: [
		{
			action: 'func',
			name: 'WelcomeScreen'
		}
	]
};

export default Welcome;