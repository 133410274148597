import React from 'react';
import Button from 'components/Button/Button';
import { observer } from "mobx-react";
import Analytics from 'services/utils/Analytics';
// import Carousel from 'nuka-carousel';
import { t } from 'components/Translate/Translate';
import Cookies from 'js-cookie';

import './styles.less';

export default (props) => {
	if (props.state.showLoginPage) {
		props.history.push('/');
		props.moveConversation({ name: 'phone' });
		return;
	}

	@observer
	class WelcomeScreen extends React.Component {
		state = {
			counter: 0,
		}
		goForward = () => {
			const { counter } = this.state;
			const newCounter = counter + 1;
			Analytics('Onboarding step', { Step: newCounter });
			if (newCounter === 4) {
				this.loginNow() 
			}
			this.setState({
				counter: newCounter
			})
		}
		loginNow = () => {
			Analytics('Go to login page');
			props.propSetState({
				background: 'dark'
			}, props.moveConversation({ name: 'phone' }))
		}
		setCount = (counter) => {
			this.setState({ counter });
		}
		render() {
			//const { counter } = this.state;
			const appLanguage = Cookies.get('app_language') || 'en';

			const baseUrl = 'https://getjuice.nyc3.cdn.digitaloceanspaces.com/';
			let activeText = t("welcome__item__1", { defaultValue:  'Get unlimited access<br/> to shareable chargers' });

			if (appLanguage === 'en') {
				activeText = t("welcome__item__5", { defaultValue:  '<b>Forget your own charger.</b><br/><br/>Grab an Electron & Go.<br/>Return Anywhere. Repeat.' });
			}
			// const btnTextFirst = t("welcome__btn__first", { defaultValue:  'How to use' });
			let btnTextNext = t("welcome__item__btn__next", { defaultValue:  'Next' });
			
			if (appLanguage === 'en') {
				btnTextNext = t("welcome__item__btn__next_get_a_charger", { defaultValue:  'Get a Charger' });
				
				// if (props.state.freeFirstDay) {
				// 	btnTextNext = t("welcome__item__btn__next_try_for_free", { defaultValue:  'Try it for free' });
				// }
			}
			

			return (
				<div className="app-container welcomeScreen">
					<div className="dialog-screen">
						<div className="dialog-screen__container">
							<div className="welcomeScreen__wrapper">
									<div>
										<div className="welcomeScreen__title" dangerouslySetInnerHTML={{__html: activeText}} style={{'lineHeight': '1.6'}}></div>
										<img className="welcomeScreen__picture" src={`${baseUrl}etg_guide_6_sec_step_1.gif`} alt="" />
									</div>
							</div>
						</div>
					</div>
					<div>
						<div className="dialog-bottom__container">
							<Button
								onClick={this.loginNow}
								text={ btnTextNext } />
						</div>
					</div>
				</div>
			)
		}
	};
	
	props.propSetState({
		fullScreenStore: <WelcomeScreen />
	});
	
}