const HaveBattery = {
	name: 'HaveBattery',
	steps: [
		{
			action: 'func',
			name: 'HaveBatteryScreen'
		}
	]
};

export default HaveBattery;