import fetchJSON from 'services/utils/fetchJSON';
import Auth from 'services/utils/Auth';
import Settings from 'services/config/Settings';

export default (props) => {
	props.propSetState({
		controller: props.activeStep.controller
	});
	if (props.state.attempts < props.activeStep.attempts) {
		if (props.state.activeMessageFromUser) {
			const user = props.state.user;
			user.name = props.state.activeMessageFromUser;
			
			fetchJSON(Settings.endpoindApi+'/api/v1/name', {
				method: 'post',
				body: {
					name: user.name
				}
			}).then(data => {
					//console.log('name', data);
					if (data.code === 200) {
						if (data.token) {
							Auth.loginUser(data.token, data.user_id ? data.user_id : 0);
						}
						const message = props.state.dictionary.nameNice.replace("#NAME#", user.name);
						props.sendMessageToDialog({ 'type': 'text', 'value': message});
						props.propSetState({
							activeStep: props.state.activeStep + 1,
							attempts: 0,
							controller: {},
							activeMessageFromUser: null,
							user
						}, () => {
							props.moveConversation();
						});
					} else {
						props.sendMessageToDialog({ 'type': 'error', 'value': "Something went wrong, try again"});
					}
			 }).catch(error => {
				//console.log(error);
				props.sendMessageToDialog({ 'type': 'error', 'value': "Something went wrong, try again"});
			});
			
		}
	}
	return false;
}