import React from 'react';
import Transition from 'react-transition-group/Transition';

const duration = {
 enter: 100,
 exit: 0,
};

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered:  { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 }
};

class RenderScreen extends React.Component {
	state = {
		appear: false
	}
	componentDidMount() {
		this.setState({
			appear: true
		});
	}
	render() {
		return (
			<Transition className="app-container" in={this.state.appear} timeout={duration}>
				{(state) => (
					<div
					className="app-container"
					style={{
		        ...defaultStyle,
		        ...transitionStyles[state]
		      }}>
						{ this.props.screen }
					</div>
				)} 
			</Transition>
		)
	}
}
export default RenderScreen;