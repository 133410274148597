import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

class CountryDropDown extends React.Component {
	handleClickOutside() {
		const { showCountryMenu, closeCountry } = this.props;
		if (showCountryMenu) {
			closeCountry();
		}
  }
	render() {
		const { showCountryMenu, listCountryNames, activeCountry, changeCountryLang } = this.props;
		return (
			<div className={"slideMenuLang__list delayFeeMenu__switchList" + (showCountryMenu ? ' active' : ' closed') }>
					<ul className="slideMenuLang__listWrapper">
						{ Object.keys(listCountryNames).map((country, key) =>
							<li key={key}
									className={"slideMenuLang__listItem" + (country === activeCountry ? ' active' : '')}
									onClick={() => changeCountryLang(country)}>{listCountryNames[country]}</li>
						) }
					</ul>
				</div>
			)
	}
}

export default enhanceWithClickOutside(CountryDropDown);