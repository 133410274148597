import React from 'react';

export default class UserPoint extends React.Component {
  render() {
  	const style = {
			display: this.props.accessToLocation ? 'block' : 'none'
		};
    return (
			<div className="userPoint" style={style}>
		    <div className="pin"></div>
		    <div className="pin-effect"></div>
		  </div>
    );
  }
}