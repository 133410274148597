import React from 'react';
import Analytics from 'services/utils/Analytics';
import Settings from 'services/config/Settings';
import closeIcon from 'components/images/ic-close-plain.svg';
import fetchJSON from 'services/utils/fetchJSON';
import { t, Trans } from 'components/Translate/Translate';

class Report extends React.Component {
	state = {
		options: [
			{
				id: 'battery_back',
				title: t('report__how_return_question', { defaultValue:  'How do I return the battery?' }),
				action: 'text',
				answer: t('report__how_return_answer', { defaultValue:  'Return the battery to any available Station’s nest. Please be sure to fully insert cable back into the battery frame.' })
			},
			{
				id: 'battery_doesnt_work',
				title: t('report__how_battery_doesnt_work_question', { defaultValue:  'The battery doesn’t work' }),
				action: 'text',
				answer: t('report__how_battery_doesnt_work_answer', { defaultValue:  'Return the battery to the station and get another one.' }),
			},
			{
				id: 'can_do_pit_stop',
				title: t('report__exchange_charger_question', { defaultValue:  'Station is full: Exchange your Electron with one of the other Electrons at this station.' }),
				action: 'can_do_pit_stop'
			},
			{
				id: 'cant_take_battery',
				title: t('report__cant_take_battery_question', { defaultValue:  'I can\'t get the battery' }),
				action: 'something_wrong'
			},
			{
				id: 'supernova_doesnt_work',
				title: t('report__supernova_doesnt_work_question', { defaultValue:  'The station is not working' }),
				action: 'something_wrong'
			},
			{
				id: 'session_is_still_open',
				title: t('report__session_is_still_open_question', { defaultValue:  'I already returned the battery, but my session is still open' }),
				action: 'find_battery'
			},
			{
				id: 'other',
				title: t('report__other_question', { defaultValue:  'Other problem' }),
				action: 'free_form'
			}
		],
		option_id: '',
		isFetching: false,
		station_number: '',
		message: '',
		showPoints: true,
		result: ''
	}
	componentDidMount = () => {
		Analytics('Report - open');
	}
	hideIt = () => {
		Analytics('Report - close');
		const { hideIt } = this.props;
		hideIt();
	}
	onOptionChanged = (e) => {
		this.setState({
      option_id: e.currentTarget.value,
      station_number: '',
      message: ''
    });
	}
	onSubmit = (e, option) => {
		e.preventDefault();
		const { station_number, message } = this.state;
		const { itemId, item } = this.props;
		this.setState({
			isFetching: true
		});
		Analytics('Report - start submit', { Theme: option.id });
		if (!station_number && !message) {
			Analytics('Report - empty form', { Theme: option.id });
			return false;
		}
		
		fetchJSON(Settings.endpoindApi+'/api/v1/users/report', {
			method: 'POST',
			body: {
				station_number,
				problem: message,
				option,
				itemId: itemId,
				item: item
			}
		}).then(data => {
			// console.log(data);
			this.setState({
				isFetching: false
			});
			if (data.code === 200) {
				Analytics('Report - done', { Theme: option.id });
				this.setState({
					showPoints: false,
					result: t('report__report_submitted', { defaultValue: 'Thank you! We will contact you shortly.' })
				});
			} else {
				Analytics('Report - ERROR', { Theme: option.id });
			}
		})
		.catch(error => {
			console.log(error);
			this.setState({
				isFetching: false
			});
			Analytics('Report - ERROR', { Theme: option.id });
		});
	}
	renderOptions = () => {
		const { options, option_id, isFetching } = this.state;
		const { canExchangeBattery } = this.props;
		return options.map((option, key) => {
			if (option.id === 'can_do_pit_stop') {
				if (!canExchangeBattery) return null;
			}
			return (
				<li className="reportOption" key={key}>
					<input
						id={`option-${option.id}`}
						className="reportOption__radio"
						type="radio"
						name="option"
						disabled={isFetching}
						value={ option.id }
						checked={ option_id === option.id }
						onChange={ this.onOptionChanged }
					/>
					<label htmlFor={`option-${option.id}`} className="reportOption__label">
						<span className="reportOption__labelHead">
							<span className="reportOption__icon"></span>
							<span className="reportOption__title">{option.title}</span>
						</span>
						<span className="reportOption__answer">
							{option_id === option.id ? this.renderAnswer(option) : null}
						</span>
					</label>
				</li>
				);
		})
	}
	changeField = (e) => {
		this.setState({
			[e.currentTarget.name]: e.currentTarget.value
		});
	}
	exchangeNow = () => {
		this.props.goExchangeBattery();
	}
	renderAnswer = (option) => {
		const { isFetching, station_number, message } = this.state;
		let toRender = '';
		switch (option.action) {
			case 'text':
				toRender = option.answer;
				break;
			case 'find_battery':
				toRender = (
					<form className="reportForm__wrap" onSubmit={(e) => this.onSubmit(e, option)}>
						<div className="reportForm__warning"><Trans i18nKey="report__find_battery_enter_station_number">Please enter the station number where you returned the battery and describe how long ago you returned the battery.</Trans></div>
						<label className="reportForm__label"><Trans i18nKey="report__find_battery_label">Station number or Location</Trans></label>
						<input className="reportForm__input" onChange={this.changeField} type="text" placeholder={t('report__find_battery_placeholder', { defaultValue:  'Station number or Location' })} name="station_number" value={station_number} />
						<label className="reportForm__label" htmlFor="comment"><Trans i18nKey="report__find_battery_describe">Describe the problem</Trans></label>
						<textarea id="comment" className="reportForm__textarea" onChange={this.changeField} name="message" rows="2" placeholder={t('report__find_battery_describe_field_placeholder', { defaultValue:  'Describe the problem' })} value={message}></textarea>
						<button className="reportForm__submit" disabled={isFetching} type="submit"><Trans i18nKey="report__find_battery_submit">Submit</Trans></button>
					</form>
				);
				break;
			case 'something_wrong':
					toRender = (
						<form className="reportForm__wrap" onSubmit={(e) => this.onSubmit(e, option)}>
							<div className="reportForm__warning"><Trans i18nKey="report__find_battery_provide_details">Please provide the following details:</Trans></div>
							<label className="reportForm__label"><Trans i18nKey="report__find_battery_label">Station number or Location</Trans></label>
							<input className="reportForm__input" onChange={this.changeField} type="text" placeholder={t('report__find_battery_placeholder', { defaultValue:  'Station number or Location' })} name="station_number" value={station_number} />
							<label className="reportForm__label" htmlFor="comment"><Trans i18nKey="report__find_battery_describe">Describe the problem</Trans></label>
							<textarea id="comment" className="reportForm__textarea" onChange={this.changeField} name="message" rows="2" placeholder={t('report__find_battery_describe_field_placeholder', { defaultValue:  'Describe the problem' })} value={message}></textarea>
							<button className="reportForm__submit" disabled={isFetching} type="submit"><Trans i18nKey="report__find_battery_submit">Submit</Trans></button>
						</form>
					);
					break;
			case 'can_do_pit_stop':
					toRender = (
						<div className="reportForm__wrap">
							<div className="reportForm__warning"><Trans i18nKey="report__exchange_charger_answer">You can use an exchange battery only if there are no free nests in the station.</Trans></div>
							<button className="reportForm__submit" onClick={this.exchangeNow} type="button"><Trans i18nKey="report__exchange_charger_btn_start">Start battery exchange</Trans></button>
						</div>
					);
					break;
			default:
				toRender = (
					<form className="reportForm__wrap" onSubmit={(e) => this.onSubmit(e, option)}>
						<label className="reportForm__label" htmlFor="otherQuestion"><Trans i18nKey="report__custom_question">Your message</Trans></label>
						<textarea id="otherQuestion" name="message" onChange={this.changeField} className="reportForm__textarea" rows="4" placeholder={t('report__custom_type_your_message', { defaultValue:  'Type your message' })} value={message}></textarea>
						<button className="reportForm__submit" disabled={isFetching} type="submit"><Trans i18nKey="report__submit_question">Submit</Trans></button>
					</form>
				);
				break;
		}
		return toRender;
	}
  render() {
  	const { modal, visible } = this.props;
  	const { showPoints, result } = this.state;
    return (
    	<React.Fragment>
    		{ visible ?
    			<React.Fragment>
		    		{modal ? <div className="report__bg"></div> : null }
			      <div className={"report "+(modal ? 'popup' : '')}>
				      <div className={"report__wrapper "+(modal ? 'popup' : '')}>
								{modal ? <button type="button" className="report__close" onClick={this.hideIt}>
									<img src={closeIcon} alt="close report" />
								</button> : null }
								<div className="report__desc"><Trans i18nKey="report__title">Need help? Choose one of the options or write your question</Trans></div>
								{ showPoints ? <ul className="report__options">
									{this.renderOptions()}
								</ul> : null }
								{result ? <div className="report__result">{result}</div> : null }
				      </div>
			      </div>
	      	</React.Fragment>
	      : null }
      </React.Fragment>
    )
  }
};

export default Report;