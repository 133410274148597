import React from 'react';

class Promo extends React.Component {
  render() {
  	const {
  		promo,
  		showLabel,
  		showInput,
  		submitDisabled,
  		alert,
  		isFetching,
      showPromo
  	} = this.props;

    return (
    	<React.Fragment>
	      { showPromo ? <li className="sbOrder__item">
	        <form onSubmit={this.props.onSubmit} className="sbOrderPromo">
	        	<span className="sbOrderPromo__icon"></span>
	        	{ showLabel ?
		        	<React.Fragment>
		        	{ !promo.length ?
			        	<label className="sbOrderPromo__label" htmlFor="promo">I have promocode</label>
		        	: null }
		        	</React.Fragment>
	        	: null }
		        <input
	        			className={"sbOrderPromo__input "+(showInput ? 'bordered' : 'borderless')}
	      				id="promo"
	      				onChange={this.props.onChanged}
	      				onFocus={this.props.onFocus}
								onBlur={this.props.onBlur}
	      				type="text"
	      				name="promo"
	      				placeholder="Enter promo"
	      				value={promo}
	    				/>
	    			<button
	  					className={"sbOrderPromo__submit "+(showInput ? 'show' : 'hide')}
	  					type="submit"
	  					disabled={submitDisabled || isFetching}
	  					title="submit"></button>
		      </form>
	      </li> : null }
	      { alert.active ?
	        <div className={"sbOrderPromo__message "+alert.type}>{alert.value}</div>
	      : null }
      </React.Fragment>
    )
  }
};

export default Promo;