import * as Stickers from 'scenarios/components/Stickers';
import { t } from 'components/Translate/Translate';

const LostBattery = {
	name: 'LostBattery',
	steps: [
		{
			action: 'func',
			name: 'LostBattery'
		},
		{
			action: 'message',
			type: 'sticker',
			value: Stickers.Crying,
			timeout: 1000
		},
		{
			action: 'message',
			type: 'text',
			value: 'We are so sad…',
			timeout: 1500,
			typing: true
		},
		
		{
			action: 'func',
			name: 'BatteryStory'
		},
		{
			action: 'message',
			type: 'text',
			value: t("lost_battery-are-you-sure", { defaultValue:  'Are you sure there is no chance to find and return battery?<br/>If not we have to charge you for 30$ as a Penalty<br/>Read <b style="text-decoration: underline;">Return and Penalty Rules</b>' }),
			typing: false,
			timeout: 2500
		},
		
	]
};

export default LostBattery;