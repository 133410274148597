import React from 'react';
import Cookies from 'js-cookie';
import Analytics from 'services/utils/Analytics';
import SlideMenu from './components/SlideMenu';
import Auth from 'services/utils/Auth';
import { Link } from 'react-router-dom';

import Closed from './components/images/menu-closed.svg';
import Logo from './components/images/electron-to-go-logo-long.svg';
import LogoJa from './components/images/electron-to-go-logo-ja.svg';

import './components/styles.less';

class Header extends React.Component {
	constructor(props) {
    super(props);
		this.state = {
			opened: false,
			appLanguage: 'en'
		}
	}
	componentDidMount = () => {
		const appLanguage = Cookies.get('app_language');
		this.setState({
			appLanguage
		});
  }
	openMenu = (e) => {
		const opened = this.state.opened ? false : true;
		const status = opened ? 'Open menu' : 'Close menu';
		
		Analytics('Clicked on the menu - ' + status);
		this.setState({
			opened: opened
		});
	}
	closeMenu = () => {
		setTimeout(() => {
			this.setState({
				opened: false
			})
		}, 50);
	}
  render() {
  	const { appLanguage } = this.state;
  	const isUserAuthenticated = Auth.isUserAuthenticated();  	
    return (
      <div className="headerWrapper">
				<div className="headerMenu">
					<div className="headerMenu__container">
						<div className="headerMenu__logo">
							<a className="headerMenu__link" href="/">
								<img src={ appLanguage === 'ja' ? LogoJa : Logo } alt="" />
							</a>
						</div>
						{
							isUserAuthenticated ?
							<div className="headerMenu__burger">
								<button
									className="headerMenu__burger-btn"
									type="button"
									onClick = { this.openMenu }
								 ><img src={ Closed } alt="" /></button>
							 </div>
							: 
							<div className="headerMenu__login" style={{ 'display': 'none' }}>
								<Link to="/login" className="headerMenu__loginLink">Log in</Link>
							</div>
						}
						
						
					</div>
				</div>
				<React.Fragment>
					<div className={"slideMenu__backdrop" + ( this.state.opened ? " opened" : " closed" )}></div>
					<SlideMenu 
						closeMenu={this.closeMenu}
						opened={this.state.opened}
						flow={this.props.flow}
						sendMessageToDialog={this.props.sendMessageToDialog}
						/>
				</React.Fragment>
			</div>
    )
  }
};

export default Header;